import ApiService, { Params } from 'api/helpers/Sender';
import { Agency } from 'api/models/Agencies/agency.model';
import { AgencyDetail } from 'api/models/Agencies/AgencyDetail.model';
import { Pagination, PaginationResponse } from 'types/Pagination';

const API = new ApiService();

export const getAgencies = async (body: Pagination): Promise<PaginationResponse<Agency[]>> => {
  const { data } = await API.get('agencies', body as Params);
  return data;
};

export const getSubAgencies = async (body: Pagination): Promise<PaginationResponse<Agency[]>> => {
  const { data } = await API.get('subagencies', body as Params);
  return data;
};

export const getAgencyDetail = async (id: string): Promise<AgencyDetail> => {
  const { data } = await API.get(`agencies/${id}`);
  return data;
};
