import ApiService from 'api/helpers/Sender';
import {
  Payment,
  PaymentIntent,
  SetupIntent,
  StripeConfig,
} from 'api/models/Payments/payments.model';

const API = new ApiService();

export const getPaymentLink = async (key: string): Promise<Payment> => {
  const { data } = await API.get(`services/service-stripe/pay`, { key });
  return data;
};

export const getStripeConfig = async (): Promise<StripeConfig> => {
  const { data } = await API.get(`services/service-stripe/config`);

  return data;
};

export const getPaymentIntent = async ({
  policyLocator,
  paymentIntentId,
}: {
  policyLocator: string;
  paymentIntentId: string;
}): Promise<PaymentIntent> => {
  const { data } = await API.get(
    `services/service-stripe/${policyLocator}/payment-intents/${paymentIntentId}`,
  );

  return data;
};

export const setPaymentIntent = async ({
  policyLocator,
  paymentIntentId,
}: {
  policyLocator: string;
  paymentIntentId: string;
}): Promise<PaymentIntent> => {
  const { data } = await API.patch(
    `services/service-stripe/${policyLocator}/payment-intents/${paymentIntentId}`,
  );

  return data;
};

export const getSetupIntent = async ({
  policyLocator,
  setupIntentId,
}: {
  policyLocator: string;
  setupIntentId: string;
}): Promise<SetupIntent> => {
  const { data } = await API.get(
    `services/service-stripe/${policyLocator}/setup-intents/${setupIntentId}`,
  );

  return data;
};
