import { QuoteDetailContext } from 'providers/QuoteDetailProvider/QuoteDetailProviderContext';
import { IQuoteDetailContextType } from 'providers/QuoteDetailProvider/types';
import { useContext } from 'react';

export default function useQuoteDetail(): IQuoteDetailContextType {
  const context = useContext(QuoteDetailContext);

  /* istanbul ignore if */
  if (!context) {
    throw new Error('useQuoteDetail must be used within a QuoteDetailProvider');
  }

  return context;
}
