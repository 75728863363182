import ApiService, { Params } from 'api/helpers/Sender';
import { KeyValues } from 'api/models/KeyValues/keyValues.model';
import { prepareCacheOptions } from 'helpers/AxiosInterceptor/cache';

const API = new ApiService();

export const getKeyValuesService = async (
  key: string,
  { query = {}, cache = false }: { query?: Params; cache?: boolean } = {},
): Promise<KeyValues> => {
  const { data } = await API.get(
    `key-values/${key}`,
    query,
    cache ? { ...prepareCacheOptions() } : {},
  );
  return data;
};
