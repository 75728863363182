import { createContext } from 'react';

/// types
import { IKeyValuesContextType, IKeyValuesStore } from './types';

export const initialKeyValuesData: IKeyValuesStore = {
  data: {},
  loading: false,
  loaded: false,
};

export const keyValuesContextDefault: IKeyValuesContextType = {
  ...initialKeyValuesData,
  resetKeyValuesState: () => {},
  fetch: () => new Promise(() => {}),
};

export const KeyValuesContext = createContext<IKeyValuesContextType>(null!);
