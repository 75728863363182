import { Box, Button, Skeleton, Typography } from '@mui/material';
import { PolicyPaymentInfo } from 'api/models/Policy/policyPaymentInfo.model';
import { currencyFormat } from 'helpers/Utils';
import { IBillingPaymentAmounts } from 'providers/PolicyDetailProvider/types';
import { IContext } from 'providers/types';
import React, { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';

interface IPaymentInfo {
  paymentPlanValue: string | undefined;
  loading: boolean;
  billingPaymentAmounts: IBillingPaymentAmounts | undefined;
  billingPaymentInfo: IContext<PolicyPaymentInfo>;
  isEnrollInAutopayButtonVisible?: boolean;
}

const PaymentInfo: FC<IPaymentInfo> = ({
  paymentPlanValue = '-',
  loading,
  billingPaymentAmounts,
  billingPaymentInfo,
  isEnrollInAutopayButtonVisible = true,
}) => {
  const { t } = useTranslation();
  const HISTORY = useHistory();

  return (
    <Box
      sx={{
        boxShadow: (theme) => theme.customShadows.policyInfoCardEffect,
        px: 3,
        py: 2,
        mb: 3,
      }}
    >
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'row',
          justifyContent: 'space-between',
          alignItems: 'center',
          mb: 1.5,
        }}
      >
        <Box>
          <Typography fontWeight={500}>{t('Payment Plan')}</Typography>
          {loading ? (
            <Skeleton animation="wave" width="100%" />
          ) : (
            <Typography fontWeight={700} color={(theme) => theme.customColors.chipPrimary}>
              {paymentPlanValue}
            </Typography>
          )}
        </Box>

        <Box display="flex" gap={4}>
          <Box minWidth="100px">
            <Typography fontWeight={500} textAlign="right">
              {t('Total Premium')}
            </Typography>
            {loading ? (
              <Skeleton animation="wave" width="100%" />
            ) : (
              <Typography
                fontWeight={700}
                color={(theme) => theme.customColors.chipPrimary}
                textAlign="right"
              >
                {currencyFormat(undefined, `${billingPaymentAmounts?.totalPremium}`).merged}
              </Typography>
            )}
          </Box>

          <Box minWidth="100px">
            <Typography fontWeight={500} textAlign="right">
              {t('Total Fees')}
            </Typography>
            {loading ? (
              <Skeleton animation="wave" width="100%" />
            ) : (
              <Typography
                fontWeight={700}
                color={(theme) => theme.customColors.chipPrimary}
                textAlign="right"
              >
                {currencyFormat(undefined, `${billingPaymentAmounts?.totalFees}`).merged}
              </Typography>
            )}
          </Box>

          <Box minWidth="100px">
            <Typography fontWeight={500} textAlign="right">
              {t('Grand Total')}
            </Typography>
            {loading ? (
              <Skeleton animation="wave" width="100%" />
            ) : (
              <Typography
                fontWeight={700}
                color={(theme) => theme.customColors.chipPrimary}
                textAlign="right"
              >
                {currencyFormat(undefined, `${billingPaymentAmounts?.total}`).merged}
              </Typography>
            )}
          </Box>
        </Box>
      </Box>

      <Box
        sx={{
          display: 'flex',
          flexDirection: 'row',
          justifyContent: 'space-between',
          alignItems: 'center',
          mb: 1.5,
        }}
      >
        {billingPaymentInfo.loading ? (
          <Skeleton animation="wave" width="100px" />
        ) : (
          <Box sx={{ display: 'flex', flexDirection: 'column' }}>
            {billingPaymentInfo.data?.is_recurring && (
              <>
                <Typography fontWeight={500}>{t('Recurring Autopay')}</Typography>
                <Typography
                  data-test="enrolled-text"
                  color={(theme) => theme.customColors.chipPrimary}
                  fontWeight={700}
                >
                  {t('Enrolled')}
                </Typography>
                <Typography>
                  {billingPaymentInfo?.data?.payment_method?.brand ? (
                    <Typography component="span">
                      {billingPaymentInfo?.data?.payment_method?.brand.toUpperCase()}
                    </Typography>
                  ) : null}
                  {billingPaymentInfo?.data?.payment_method?.last4 ? (
                    <Typography component="span">
                      {' '}
                      x{billingPaymentInfo?.data?.payment_method?.last4}
                    </Typography>
                  ) : null}
                </Typography>
              </>
            )}
          </Box>
        )}

        <Box minWidth="100px">
          <Typography fontWeight={500} textAlign="right">
            {t('Paid to Date')}
          </Typography>
          {loading ? (
            <Skeleton animation="wave" width="100%" />
          ) : (
            <Typography
              color={(theme) => theme.customColors.chipPrimary}
              fontWeight={700}
              textAlign="right"
            >
              {currencyFormat(undefined, `${billingPaymentAmounts?.paidToDate}`).merged}
            </Typography>
          )}
        </Box>
      </Box>

      <Box
        sx={{
          display: 'flex',
          flexDirection: 'row',
          justifyContent: 'space-between',
          alignItems: 'center',
        }}
      >
        {billingPaymentInfo.loading ? (
          <Skeleton animation="wave" width="100px" />
        ) : isEnrollInAutopayButtonVisible ? (
          <Box>
            <Button
              onClick={() => {
                HISTORY.push({
                  search: billingPaymentInfo.data?.is_recurring
                    ? '?stopAutopay=true'
                    : '?enrollInAutopay=true',
                });
              }}
              sx={{
                m: 0,
                p: 0,
                color: (theme) => theme.customColors.copper,
                '&:hover': {
                  textDecoration: 'underline',
                  backgroundColor: 'transparent',
                },
              }}
            >
              {billingPaymentInfo.data?.is_recurring ? t('Stop Autopay') : t('Enroll in Autopay')}
            </Button>
          </Box>
        ) : (
          <Box />
        )}

        <Box display="flex" gap={4}>
          <Box minWidth="100px">
            <Typography fontWeight={500} textAlign="right">
              {t('Past Due')}
            </Typography>
            {loading ? (
              <Skeleton animation="wave" width="100%" />
            ) : (
              <Typography
                fontWeight={700}
                color={(theme) => theme.customColors.darkCopper}
                textAlign="right"
              >
                {currencyFormat(undefined, `${billingPaymentAmounts?.pastDue}`).merged}
              </Typography>
            )}
          </Box>

          <Box minWidth="100px">
            <Typography fontWeight={500} textAlign="right">
              {t('Current Due')}
            </Typography>
            {loading ? (
              <Skeleton animation="wave" width="100%" />
            ) : (
              <Typography
                fontWeight={700}
                color={(theme) => theme.customColors.chipPrimary}
                textAlign="right"
              >
                {currencyFormat(undefined, `${billingPaymentAmounts?.currentDue}`).merged}
              </Typography>
            )}
          </Box>

          <Box minWidth="100px">
            <Typography fontWeight={500} textAlign="right">
              {t('Total Due')}
            </Typography>
            {loading ? (
              <Skeleton animation="wave" width="100%" />
            ) : (
              <Typography
                fontWeight={700}
                color={(theme) => theme.customColors.chipPrimary}
                textAlign="right"
              >
                {currencyFormat(undefined, `${billingPaymentAmounts?.totalDue}`).merged}
              </Typography>
            )}
          </Box>
        </Box>
      </Box>
    </Box>
  );
};

export default PaymentInfo;
