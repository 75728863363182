import { getModByFeinNcci } from 'api/services/Integrations/NCCI';
import { getModByFeinPcrb } from 'api/services/Integrations/PCRB';
import { getModByFeinWcirb } from 'api/services/Integrations/WCIRB';
import { defaultTimezone } from 'common/constants';
import { pick } from 'lodash-es';
import { IConfigStore } from 'providers/ConfigProvider/types';
import { parseNcciResponse } from './Integrations/ncci';
import { parsePCRBResponse } from './Integrations/pcrb';
import { parseWcirbResponse } from './Integrations/wcirb';

export interface IIntegrationsParams {
  fein: string;
  employer: { [key: string]: any };
  effectiveDate: string;
  willUpdateEmployers: any[];
  timezoneConfig: IConfigStore['timezoneConfig'];
  state: string;
}

/**
 * Validate condition before run the EXP mod integrations
 *
 * Validation:
 * When total employees is >= 6, years in business is >=3 and FEIN is entered
 */
export const validateExpModIntegrationsCanRun = (state: any, effectiveDate: string) => {
  let run = false;
  const effectiveYear = new Date(effectiveDate).getFullYear();

  const fields = ['numEmployees', 'fein', 'yearFounded'];
  const picked = pick(state, fields);

  const allFieldsFilled = fields.every((f) => f in picked);

  if (allFieldsFilled) {
    const { numEmployees, yearFounded } = picked;
    const yearDiff = effectiveYear - yearFounded;

    if (numEmployees >= 6 && yearDiff >= 3) {
      run = true;
    }
  }

  return run;
};

/**
 * Summary/pricing helper for parallelization
 */
export const fetchNCCI = async ({
  fein,
  employer,
  willUpdateEmployers,
  timezoneConfig,
  effectiveDate,
}: Omit<IIntegrationsParams, 'state'>) => {
  const res = await getModByFeinNcci({ fein, modeType: 'H' });

  const payload = parseNcciResponse(res, timezoneConfig, effectiveDate);

  willUpdateEmployers.push({
    locator: employer.locator,
    data: { ...employer.data, ...payload },
  });
};

/**
 * Summary/pricing helper for parallelization
 */
export const fetchWCIRB = async ({
  fein,
  employer,
  willUpdateEmployers,
  timezoneConfig,
  effectiveDate,
}: Omit<IIntegrationsParams, 'state'>) => {
  const res = await getModByFeinWcirb({ fein });

  const payload = parseWcirbResponse(res, timezoneConfig, effectiveDate);

  willUpdateEmployers.push({
    locator: employer.locator,
    data: { ...employer.data, ...payload },
  });
};

/**
 * Summary/pricing helper for parallelization
 */
export const fetchPCRB = async ({
  fein,
  employer,
  willUpdateEmployers,
  timezoneConfig,
  effectiveDate,
  state,
}: IIntegrationsParams) => {
  const timezone = timezoneConfig?.data?.code ?? defaultTimezone;

  const res = await getModByFeinPcrb({
    fein,
    policyEffectiveDate: effectiveDate,
    timezone,
    state,
  });

  const payload = parsePCRBResponse(res, timezone);

  willUpdateEmployers.push({
    locator: employer.locator,
    data: { ...employer.data, ...payload },
  });
};
