import { useDisclosure } from '@mantine/hooks';
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Box,
  Button,
  Tooltip,
  Typography,
} from '@mui/material';
import SvgIcon, { SvgIconProps } from '@mui/material/SvgIcon';
import LinkComponent from 'components/LinkComponent';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { useLocation } from 'react-router-dom';
import { ReactSVG } from 'react-svg';

const ArrowDropDown = (props: SvgIconProps) => (
  <SvgIcon {...props}>
    <svg width="24" height="24" viewBox="0 0 24 24" fill="none">
      <path
        d="M8 10L12 14L16 10"
        stroke="#323232"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  </SvgIcon>
);

interface LinkMetadata {
  title: string;
  path: string;
  icon?: string;
}

type SidebarLinkProps = LinkMetadata & {
  isSidebarExpanded: boolean;
  onClick?: () => void;
  children?: React.ReactNode | React.ReactNode[];
  isNested?: boolean; // could auto-calculate by looking at the parent
};

const SidebarLink = ({
  title,
  path,
  icon,
  isSidebarExpanded,
  onClick,
  children,
  isNested = false,
}: SidebarLinkProps) => {
  const location = useLocation();
  const { t: translate } = useTranslation();
  const [isAccordionExpanded, accordionHandlers] = useDisclosure(false);

  const translatedTitle = translate(title); // consider memoizing if translation is expensive
  const hasNestedLinks = Boolean(Array.isArray(children) ? children.length : children);
  const isPathActive = location.pathname.startsWith(path);

  return (
    <Box
      sx={{
        width: 1,
        '& > a': {
          color: (theme) => theme.customColors.topNavBar.bg,
          textDecoration: 'none',
          width: 1,
        },
      }}
    >
      {hasNestedLinks && isSidebarExpanded ? (
        <Accordion
          expanded={isAccordionExpanded}
          onChange={accordionHandlers.toggle}
          sx={{
            boxShadow: 'none',
            '&.MuiAccordion-root:before': {
              height: 0,
            },
            '&.MuiAccordion-root': {
              '&.Mui-expanded': {
                margin: 0,
              },
            },
            width: (theme) => theme.sidebarWidth,
          }}
        >
          <AccordionSummary
            expandIcon={isSidebarExpanded && <ArrowDropDown />}
            sx={{
              width: '100%',
              p: 0,
              backgroundColor: (theme) => theme.customColors.grey1150,
              height: '48px',
              '& .MuiAccordionSummary-content': {
                margin: '0 !important',
                '& .Mui-expanded': {
                  margin: 0,
                },
              },
              '& .MuiAccordionSummary-expandIconWrapper': {
                width: '40px',
                pr: 5,
                pl: 2,
                '& svg': {
                  '& path': {
                    stroke: (theme) => theme.customColors.topNavBar.bg,
                  },
                },
              },
            }}
          >
            <Button
              sx={{
                '&:not(&:first-of-type)': {
                  mt: 0.5,
                },
                fontWeight: isPathActive ? 500 : 400,
                color: (theme) => theme.customColors.topNavBar.bg,
                pr: 1,
                pl: 2.5,
                width: 1,
                fontSize: '14px',
                justifyContent: 'flex-start',
                '&:hover': {
                  backgroundColor: 'transparent',
                },
                '& svg': {
                  '& path, rect, circle': {
                    stroke: (theme) => theme.customColors.topNavBar.bg,
                  },
                },
              }}
            >
              <Box
                sx={{
                  width: 20,
                  height: 20,
                  mr: 1.5,
                  color: (theme) => theme.customColors.topNavBar.bg,
                }}
              >
                {icon && <ReactSVG src={icon} />}
              </Box>
              {isSidebarExpanded && translatedTitle}
            </Button>
          </AccordionSummary>
          <AccordionDetails
            sx={{
              backgroundColor: (theme) => theme.customColors.grey1150,
              p: 0,
            }}
          >
            {children}
          </AccordionDetails>
        </Accordion>
      ) : hasNestedLinks ? (
        // nested links should get rendered directly on mobile, rather than using a popout
        children
      ) : (
        <LinkComponent
          to={path}
          style={{
            display: 'flex',
            width: '100%',
            textDecoration: 'none',
            height: '48px',
          }}
        >
          <Tooltip
            arrow
            title={!hasNestedLinks && !isSidebarExpanded ? translatedTitle : ''}
            enterDelay={500}
            placement="right"
          >
            <Button
              onClick={() => onClick?.()}
              sx={{
                height: '40px',
                width: '100%',
                minWidth: '45px',
                display: 'flex',
                justifyContent: 'flex-start',
                fontSize: '14px',
                borderRadius: '4px',
                my: 0.5,
                fontWeight: isPathActive ? 500 : 400,
                color: (theme) =>
                  isPathActive ? theme.customColors.white50 : theme.customColors.topNavBar.bg,
                mx: 1,
                px: 1.5,
                bgcolor: (theme) =>
                  isPathActive ? theme.customColors.commonSearchIcons : 'transparent',
                transition: 'background-color 300ms, color 300ms',
                transitionDelay: '50ms',
                '&:hover': {
                  bgcolor: (theme) => theme.customColors.commonSearchIcons,
                  color: (theme) => theme.customColors.white50,
                  '& svg': {
                    '& path, rect, circle': {
                      stroke: (theme) => theme.customColors.white50,
                    },
                  },
                },
                '& svg': {
                  '& path, rect, circle': {
                    transition: 'stroke 300ms',
                    transitionDelay: '50ms',
                    stroke: (theme) =>
                      isPathActive ? theme.customColors.white50 : theme.customColors.topNavBar.bg,
                  },
                },
                '.MuiTouchRipple-root .MuiTouchRipple-child': {
                  backgroundColor: (theme) => theme.customColors.tableLoadingBackdrop,
                },
              }}
            >
              <Box sx={{ mr: isSidebarExpanded ? 1.5 : 0, width: 20, height: 20 }}>
                {/* Suppress icons for nested routes in the desktop view. */}
                {(!isSidebarExpanded || !isNested) &&
                  (icon ? (
                    <ReactSVG src={icon} />
                  ) : (
                    <Typography
                      sx={{
                        color: (theme) =>
                          isPathActive
                            ? theme.customColors.white50
                            : theme.customColors.topNavBar.bg,
                        fontWeight: 500,
                        fontSize: '20px',
                      }}
                    >
                      {translate(title)?.[0]}
                    </Typography>
                  ))}
              </Box>
              {isSidebarExpanded && translatedTitle}
            </Button>
          </Tooltip>
        </LinkComponent>
      )}
    </Box>
  );
};

export default SidebarLink;
