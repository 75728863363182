import { KeyboardArrowDownRounded } from '@mui/icons-material';
import {
  Box,
  Checkbox,
  FormControl,
  FormHelperText,
  Grid,
  InputLabel,
  Skeleton,
  Stack,
  SxProps,
  TextField,
  Tooltip,
  Typography,
} from '@mui/material';
import { Theme } from '@mui/system';
import { DynamicField } from 'api/models/DynamicFields/dynamicField.model';
import CalendarSchedule from 'assets/images/CalendarSchedule.svg';
import { dataFieldTypes, defaultDateFormat, selectionChoices } from 'common/constants';
import CustomNativeSelect from 'components/CustomNativeSelect';
import DatePickerComponent from 'components/DatePickerComponent';
import NumberFormatComponent from 'components/NumberFormatComponent';
import { DetailDrawerLoader } from 'components/QuotePolicyDetailEndorsement/DrawerLoader';
import SmartyAutocompleteInput from 'components/SmartyAutocompleteInput';
import { IField } from 'components/SmartyAutocompleteInput/SmartyAutocompleteInput';
import {
  calendarIconStyle,
  checkboxStyle,
  readOnlyCheckboxStyle,
  readOnlyInputStyle,
  readOnlySelectInputStyle,
} from 'helpers/MuiSharedStyles';
import { preventCharactersOnInputChange } from 'helpers/Utils';
import useUser from 'hooks/useUser';
import { isEmpty, omit, uniqBy } from 'lodash-es';
import React, { ChangeEvent, FC, useEffect } from 'react';
import InputMask from 'react-input-mask';
import { ReactSVG } from 'react-svg';

interface IProps {
  formik: any;
  isEdit?: boolean;
  showLoader?: boolean;
  fields?: DynamicField[];
  loaded?: boolean;
  splitSize?: number;
  state: any;
  policyState?: any;
  setState?: any;
  rowSpacing?: number;
  columnSpacing?: number;
  isReadOnly?: boolean;
  sx?: SxProps<Theme>;
  addressFieldCodes?: string[];
  onSmartySelect?: <T>(newState: T) => void;
  smartyIncludedStates?: string[];
}

export const LocationFieldParser: FC<IProps> = ({
  isEdit = false,
  showLoader = false,
  isReadOnly = false,
  fields,
  loaded,
  splitSize = 2,
  state,
  policyState,
  setState,
  rowSpacing = 1.5,
  columnSpacing = 3,
  formik,
  sx,
  addressFieldCodes,
  onSmartySelect,
  smartyIncludedStates,
}) => {
  const validationRelatedFieldNames = [
    'loc_address_isvalidated',
    'loc_flood_zone',
    'loc_violent_crime_score',
    'loc_property_crime_score',
    'loc_address_lat',
    'loc_address_long',
  ];

  const commaValidationRelatedFieldNames = [
    'loc_address_line2',
    'loc_address_city',
    'loc_address_zip',
  ];

  const commaValidationRelatedFieldNamesForSmarty = ['loc_address_line1'];

  const handleInputChange = (
    event: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>,
    field?: DynamicField,
  ) => {
    let inputValue = event.target.value;
    const inputName = event.target.name;

    if (field) {
      inputValue = preventCharactersOnInputChange({
        inputValue,
        field,
        validationRelatedFieldNames: [
          {
            fields: commaValidationRelatedFieldNames,
            inputCharactersAllowed: ['nonComma'],
          },
        ],
      });
    }

    let newState = { ...state, [inputName]: inputValue };
    // Reset validation related fields if address fields are changed
    if (addressFieldCodes?.includes(inputName)) {
      newState = omit(newState, validationRelatedFieldNames);
    }

    setState?.(newState);
  };

  const { data: user, loading: userLoading } = useUser();

  const handleFieldType = (field: DynamicField) => {
    const testPrefix = `${field.code}`;
    const { showAsKeyValueForRoles, showAsKeyValue, integration, loading } =
      field?.additional_data ?? {};

    if (showAsKeyValue && showAsKeyValueForRoles?.includes(user?.role?.code)) {
      return (
        <Box
          sx={{
            display: 'flex',
            justifyContent: 'space-between',
            flex: 1,
          }}
          key={field.code}
        >
          {field?.additional_data?.loading ? (
            <>
              <Skeleton sx={{ mr: '40%' }} animation="wave" width="20%" height={20} />
              <Skeleton animation="wave" width="40%" height={20} />
            </>
          ) : (
            <>
              <Typography
                sx={{
                  fontSize: 14,
                  lineHeight: '21px',
                  fontWeight: 500,
                }}
              >
                {field?.name}
              </Typography>
              <Typography
                sx={{
                  fontSize: 14,
                  lineHeight: '21px',
                  fontWeight: 400,
                }}
              >
                {state[field.code] ?? ''}
              </Typography>
            </>
          )}
        </Box>
      );
    } else if (integration && loading) {
      return (
        <FormControl variant="standard" sx={{ width: '100%' }} key={field.code}>
          <Skeleton sx={{ mr: '40%' }} animation="wave" width="100%" height={37} />
        </FormControl>
      );
    }

    switch (field.type) {
      case dataFieldTypes.AUTOCOMPLETE:
        return (
          <FormControl variant="standard" sx={{ width: '100%' }} key={field.code}>
            {field.service_bridge?.name === 'smarty' && (
              <SmartyAutocompleteInput
                addressState={state}
                setAddressState={setState}
                formik={formik}
                field={field as IField}
                label={field.name}
                showLabel
                error={formik.touched[`${field.code}`] && Boolean(formik.errors[`${field.code}`])}
                helperText={formik.touched[`${field.code}`] && formik.errors[`${field.code}`]}
                makeInitialSearch={false}
                isReadOnly={field?.is_readonly || isReadOnly}
                onSelect={onSmartySelect}
                omitFieldsOnInputChange={validationRelatedFieldNames}
                includedStates={smartyIncludedStates}
                removeCommasOnInputChange={commaValidationRelatedFieldNamesForSmarty}
              />
            )}
          </FormControl>
        );

      case dataFieldTypes.STRING:
        return (
          <FormControl variant="standard" sx={{ width: '100%' }} key={field.code}>
            <TextField
              id={field.code}
              name={field.code}
              label={field.name}
              size="small"
              required={!field.is_optional}
              onChange={(event) => handleInputChange(event, field)}
              value={state[field.code] ?? ''}
              sx={field?.is_readonly || isReadOnly ? readOnlyInputStyle : {}}
              InputLabelProps={field?.is_readonly || isReadOnly ? { shrink: true } : {}}
              inputProps={{
                autoComplete: 'nope',
                ...((field?.is_readonly || isReadOnly) && { readOnly: true }),
                'data-test': testPrefix,
              }}
              error={formik.touched[`${field.code}`] && Boolean(formik.errors[`${field.code}`])}
              helperText={formik.touched[`${field.code}`] && formik.errors[`${field.code}`]}
            />
          </FormControl>
        );

      case dataFieldTypes.PHONE:
        return (
          <FormControl
            variant="standard"
            sx={{ width: '100%' }}
            key={field.code}
            required={!field.is_optional}
          >
            <Stack
              sx={{
                '& .MuiFormControl-root': {
                  flex: 'auto',
                },
                py: 1,
                display: 'flex',
                flexDirection: 'row',
                alignItems: 'center',
                justifyContent: 'space-between',
              }}
            >
              <InputMask
                mask="(999) 999-9999"
                disabled={false}
                maskChar=" "
                onChange={handleInputChange}
                value={state[field.code] || ''}
                label={field.name}
              >
                {() => (
                  <TextField
                    sx={[
                      { flex: 'auto' },
                      field?.is_readonly || isReadOnly ? readOnlyInputStyle : {},
                    ]}
                    id={field.code}
                    name={field.code}
                    label={field.name}
                    size="small"
                    required={!field.is_optional}
                    value={state[field.code] || ''}
                    inputProps={{
                      autoComplete: 'nope',
                      ...((field?.is_readonly || isReadOnly) && {
                        readOnly: true,
                      }),
                      'data-test': testPrefix,
                    }}
                    error={
                      formik.touched[`${field.code}`] && Boolean(formik.errors[`${field.code}`])
                    }
                    helperText={formik.touched[`${field.code}`] && formik.errors[`${field.code}`]}
                  />
                )}
              </InputMask>
            </Stack>
          </FormControl>
        );

      case dataFieldTypes.NUMBER:
        return (
          <FormControl variant="standard" sx={{ width: '100%' }} key={field.code}>
            <TextField
              sx={[
                {
                  '& input::-webkit-outer-spin-button, & input::-webkit-inner-spin-button': {
                    display: 'none',
                  },
                  '& input[type=number]': {
                    MozAppearance: 'textfield',
                  },
                },
                field?.is_readonly || isReadOnly ? readOnlyInputStyle : {},
              ]}
              label={field.name}
              id={field.code}
              name={field.code}
              size="small"
              required={!field.is_optional}
              type="text"
              onChange={handleInputChange}
              value={state[field.code] ?? ''}
              inputProps={{
                autoComplete: 'nope',
                ...((field?.is_readonly || isReadOnly) && {
                  readOnly: true,
                }),
                ...(typeof field?.additional_data?.allowDecimal === 'boolean'
                  ? {
                      allowDecimal: field?.additional_data?.allowDecimal,
                    }
                  : {}),
                ...(typeof field?.additional_data?.thousandSeparator === 'boolean'
                  ? {
                      thousandSeparator: field?.additional_data?.thousandSeparator,
                    }
                  : {}),
                ...(typeof field?.additional_data?.allowLeadingZeros === 'boolean'
                  ? {
                      allowLeadingZeros: field?.additional_data?.allowLeadingZeros,
                    }
                  : {}),
                'data-test': testPrefix,
              }}
              InputLabelProps={field?.is_readonly || isReadOnly ? { shrink: true } : {}}
              // eslint-disable-next-line react/jsx-no-duplicate-props
              InputProps={{
                autoComplete: 'new-password',
                inputComponent: NumberFormatComponent as any,
              }}
              error={formik.touched[`${field.code}`] && Boolean(formik.errors[`${field.code}`])}
              helperText={formik.touched[`${field.code}`] && formik.errors[`${field.code}`]}
            />
          </FormControl>
        );

      case dataFieldTypes.EMAIL:
        return (
          <FormControl variant="standard" sx={{ width: '100%' }} key={field.code}>
            <TextField
              sx={[field?.is_readonly || isReadOnly ? readOnlyInputStyle : {}]}
              id={field.code}
              name={field.code}
              size="small"
              type="email"
              required={!field.is_optional}
              label={field.name}
              onChange={handleInputChange}
              value={state[field.code] || ''}
              error={formik.touched[field.code] && Boolean(formik.errors[field.code])}
              helperText={formik.touched[field.code] && formik.errors[field.code]}
              inputProps={{
                autoComplete: 'nope',
                ...((field?.is_readonly || isReadOnly) && { readOnly: true }),
                'data-test': testPrefix,
              }}
            />
          </FormControl>
        );

      case dataFieldTypes.DATE:
        return (
          <FormControl
            variant="standard"
            sx={{ width: '100%', ...calendarIconStyle }}
            key={field.code}
          >
            <DatePickerComponent
              disableFuture={field?.additional_data?.disableFuture}
              disablePast={field?.additional_data?.disablePast}
              format={defaultDateFormat}
              minDate={field?.minimum_date}
              maxDate={field?.maximum_date}
              label={field.name}
              onChange={(newValue) => {
                setState?.({ ...state, [field.code]: newValue! });
              }}
              value={state[field.code] ?? null}
              slots={{
                openPickerIcon: () => <ReactSVG className="calendar-icon" src={CalendarSchedule} />,
              }}
              slotProps={{
                textField: {
                  size: 'small',
                  required: !field.is_optional,
                  id: field.code,
                  name: field.code,
                  label: field.name,
                  inputProps: {
                    autoComplete: 'nope',
                    'data-test': testPrefix,
                  },
                  error: formik.touched[`${field.code}`] && Boolean(formik.errors[`${field.code}`]),
                  helperText: formik.touched[`${field.code}`] && formik.errors[`${field.code}`],
                },
              }}
            />
          </FormControl>
        );

      case dataFieldTypes.SELECT:
        return (
          <FormControl
            required={!field.is_optional}
            size="small"
            sx={[
              { width: '100%' },
              isReadOnly || field?.is_readonly ? readOnlySelectInputStyle : {},
            ]}
            key={field.code}
            error={formik.touched[`${field.code}`] && Boolean(formik.errors[`${field.code}`])}
          >
            <Stack
              sx={{
                '& .MuiFormControl-root': {
                  flex: 'auto',
                },
              }}
            >
              <InputLabel
                required={!field.is_optional}
                id={`${field.code}_label`}
                // shrink={field?.is_readonly || isReadOnly}
              >
                {field.name}
              </InputLabel>
              <CustomNativeSelect
                required={!field.is_optional}
                id={field.code}
                labelId={`${field.code}_label`}
                label={field.name}
                name={field.code}
                IconComponent={KeyboardArrowDownRounded}
                error={formik.touched[`${field.code}`] && Boolean(formik.errors[`${field.code}`])}
                value={state[field.code] ?? ''}
                inputProps={{
                  autoComplete: 'off',
                  readOnly: field?.is_readonly || isReadOnly,
                  'data-test': testPrefix,
                }}
                sx={{
                  '& > .MuiSelect-select': {
                    display: 'flex',
                    alignItems: 'center',
                  },
                  width: '100%',
                }}
                onChange={(event) => {
                  let newState = { ...state, [field.code]: event.target.value };
                  // Reset validation related fields if address fields are changed
                  if (addressFieldCodes?.includes(event.target.name)) {
                    newState = omit(newState, validationRelatedFieldNames);
                  }
                  setState?.(newState);
                }}
              >
                {field?.choices &&
                  uniqBy([...field.choices], 'code')?.map((type) => (
                    <option key={type.code} value={type.code}>
                      {type.name}
                    </option>
                  ))}
              </CustomNativeSelect>

              {formik.touched[`${field.code}`] && Boolean(formik.errors[`${field.code}`]) && (
                <FormHelperText
                  sx={{
                    mt: 1,
                    fontSize: '12px',
                    lineHeight: '14px',
                    color: (theme) => theme.customColors.alert,
                  }}
                >
                  {formik.errors[`${field.code}`]}
                </FormHelperText>
              )}
            </Stack>
          </FormControl>
        );

      case dataFieldTypes.CHECKBOX:
        return (
          <FormControl
            variant="standard"
            sx={{
              maxHeight: '21px',
              width: '100%',
              display: 'inline-flex',
              flexDirection: 'row',
              alignItems: 'center',
            }}
            key={field.code}
          >
            {field.additional_data?.asYesNo ? (
              <Tooltip
                title={
                  !isReadOnly && state[field.code] === selectionChoices.YES && field?.is_readonly
                    ? `${field.additional_data?.checkbox_tooltip}`
                    : ''
                }
                placement="top-start"
                componentsProps={{
                  tooltip: {
                    sx: {
                      maxWidth: 400,
                      '&.MuiTooltip-tooltip': {
                        '&.MuiTooltip-tooltipPlacementTop': {
                          marginBottom: '2px',
                        },
                      },
                    },
                  },
                }}
              >
                <Checkbox
                  data-test={`${testPrefix}`}
                  sx={field?.is_readonly || isReadOnly ? readOnlyCheckboxStyle : checkboxStyle}
                  id={field.name}
                  name={field.name}
                  checked={state[field.code] === 'Yes' ?? false}
                  onChange={(event) => {
                    if (!field?.is_readonly && !isReadOnly) {
                      setState?.({ ...state, [field.code]: event.target.checked ? 'Yes' : 'No' });
                    }
                  }}
                  inputProps={{
                    readOnly: isReadOnly,
                  }}
                />
              </Tooltip>
            ) : (
              <Checkbox
                data-test={`${testPrefix}`}
                sx={field?.is_readonly || isReadOnly ? readOnlyCheckboxStyle : checkboxStyle}
                id={field.name}
                name={field.name}
                checked={state[field.code] ?? false}
                onChange={(event) => {
                  if (!field?.is_readonly && !isReadOnly) {
                    setState?.({ ...state, [field.code]: event.target.checked });
                  }
                }}
                inputProps={{
                  readOnly: isReadOnly,
                }}
              />
            )}
            <Typography
              sx={{
                fontSize: 14,
                color: (theme) => theme.customColors.black,
                lineHeight: '21px',
                fontWeight: 400,
                ml: 2,
              }}
            >
              {field.name}
            </Typography>
          </FormControl>
        );

      default:
        return <></>;
    }
  };

  const handleConditionalField = (field) => {
    const handleCondition = {
      select: (condition, fieldCode) => {
        const { value } = condition;
        return value.includes(state[fieldCode]);
      },
      number: (condition, fieldCode) => {
        const { value, operator } = condition;
        const operators = {
          '>': () => state[fieldCode] > +value,
          '<': () => state[fieldCode] < +value,
        };
        return operators[operator]();
      },
    };

    if (field?.condition && Object.keys(field.condition).length > 0) {
      const results: boolean[] = [];
      // eslint-disable-next-line no-restricted-syntax
      for (const [fieldCode, fieldConditions] of Object.entries(
        field.condition,
      ) as unknown as any[]) {
        const result = fieldConditions.every((condition) =>
          handleCondition[condition.type](condition, fieldCode),
        );
        results.push(result);
      }

      return results.every((result) => result);
    }

    return true;
  };

  useEffect(() => {
    fields?.forEach((f) => {
      if (f.type === dataFieldTypes.SELECT && f.choices?.length === 1) {
        setState?.({ ...state, [f.code!]: f.choices[0].code });
      }
    });
  }, []);

  return (
    <>
      {showLoader || userLoading ? (
        <DetailDrawerLoader />
      ) : (!isEdit ? loaded : !isEmpty(fields)) ? (
        <Grid
          sx={{ alignItems: 'flex-start' }}
          container
          rowSpacing={rowSpacing}
          columnSpacing={columnSpacing}
          columns={splitSize}
        >
          {fields?.map((field) => {
            const { showOnlyForRoles, enabledStates } = field?.additional_data ?? {};

            const isNotAuthorized =
              !isEmpty(showOnlyForRoles) && !showOnlyForRoles?.includes(user?.role?.code);

            const isNotEnabledStates =
              !isEmpty(enabledStates) && !enabledStates?.includes(policyState);

            if (isNotEnabledStates || isNotAuthorized) {
              return <React.Fragment key={`${field.code + field.type}__hidden`} />;
            }

            return (
              handleConditionalField(field) &&
              field &&
              !field?.is_hidden && (
                <React.Fragment key={field.code + field.type}>
                  {(field.heading ||
                    field.additional_data?.section_heading ||
                    field.newline ||
                    field.additional_data?.space_above) && (
                    <Grid
                      item
                      key={`${field.code}_header`}
                      xs={splitSize}
                      sx={[
                        field.newline ? { p: '0 !important' } : {},
                        field.additional_data?.space_above ? { mt: 1 } : {},
                      ]}
                    >
                      {field.heading && (
                        <Typography
                          sx={{
                            fontWeight: 500,
                            mt: 1.5,
                            fontSize: '16px',
                            lineHeight: 1.5,
                          }}
                        >
                          {field.heading}
                        </Typography>
                      )}
                      {field.additional_data?.section_heading && (
                        <Typography
                          sx={{
                            mt: 6,
                            fontWeight: 500,
                            fontSize: '20px',
                            lineHeight: '26px',
                            color: '#2B2B2A',
                          }}
                        >
                          {field.additional_data.section_heading}
                        </Typography>
                      )}
                    </Grid>
                  )}

                  <Grid
                    item
                    key={field.code + field.type}
                    xs={3}
                    sx={[...(Array.isArray(sx) ? sx : [sx])]}
                  >
                    {handleFieldType(field)}
                  </Grid>
                </React.Fragment>
              )
            );
          })}
        </Grid>
      ) : (
        <></>
      )}
    </>
  );
};
