/* eslint-disable @typescript-eslint/no-use-before-define */
/* eslint-disable no-case-declarations */
/* eslint-disable react/jsx-indent */
/* eslint-disable array-callback-return */
/* eslint-disable consistent-return */
import { KeyboardArrowDownRounded } from '@mui/icons-material';
import {
  Box,
  Checkbox,
  FormControl,
  FormControlLabel,
  FormHelperText,
  Grid,
  InputLabel,
  MenuItem,
  Radio,
  Select,
  SelectChangeEvent,
  Skeleton,
  Stack,
  SxProps,
  TextField,
  ToggleButton,
  ToggleButtonGroup,
  Typography,
} from '@mui/material';
import { Theme } from '@mui/system';
import { DynamicField } from 'api/models/DynamicFields/dynamicField.model';
import { dataFieldTypes, defaultDateFormat } from 'common/constants';
import CustomNativeSelect from 'components/CustomNativeSelect';
import DatePickerComponent from 'components/DatePickerComponent';
import NumberFormatComponent from 'components/NumberFormatComponent';
import SmartyAutocompleteInput from 'components/SmartyAutocompleteInput';
import { IField } from 'components/SmartyAutocompleteInput/SmartyAutocompleteInput';
import {
  readOnlyCheckboxStyle,
  readOnlyInputStyle,
  readOnlySelectInputStyle,
} from 'helpers/MuiSharedStyles';
import { usePreviousValue } from 'helpers/PreviousValue';
import {
  handleShowCondition,
  maskedInputsConfig as questionMaskedInputsConfig,
} from 'helpers/QuestionEngine';
import {
  currencyFormat,
  getSymmetricDiffrence,
  preventCharactersOnInputChange,
} from 'helpers/Utils';
import useUser from 'hooks/useUser';
import { isEmpty, omit, uniqBy } from 'lodash-es';
import React, { ChangeEvent, FC, useEffect, useMemo } from 'react';
import InputMask from 'react-input-mask';
import { PatternFormat } from 'react-number-format';

interface IProps {
  formik: any;
  isEdit?: boolean;
  showLoader?: boolean;
  fields?: DynamicField[];
  loaded?: boolean;
  splitSize?: number;
  state?: any;
  setState?: any;
  rowSpacing?: number;
  columnSpacing?: number;
  isReadOnly?: boolean;
  sx?: SxProps<Theme>;
  onSmartySelect?: <T>(newState: T) => void;
  onSelectChange?: (e: any) => void;
}

export const AdditionalInterestsDrawerFieldParser: FC<IProps> = ({
  isEdit = false,
  showLoader = false,
  isReadOnly = false,
  fields,
  loaded,
  splitSize = 2,
  state,
  setState,
  rowSpacing = 1.5,
  columnSpacing = 3,
  formik,
  sx,
  onSmartySelect,
  onSelectChange,
}) => {
  const { data: user, loading: userLoading } = useUser();
  const commaValidationRelatedFieldNames = [
    'info_ai_address_line2',
    'info_ai_address_city',
    'info_ai_address_zip',
    'info_ai_address_state',
  ];

  const maskedInputsConfig = {
    info_ai_ssn: questionMaskedInputsConfig.ssn,
    info_ai_fein: questionMaskedInputsConfig.fein,
  };

  const commaValidationRelatedFieldNamesForSmarty = ['info_ai_address_line1'];

  const updateState = (name: string, value: any) => {
    setState((prevState) => ({ ...prevState, [name]: value }));

    formik.setFieldValue(name, value);
  };

  const handleInputChange = (
    event: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>,
    field?: DynamicField,
  ) => {
    let inputValue = event.target.value;
    const inputName = event.target.name;

    if (field) {
      inputValue = preventCharactersOnInputChange({
        inputValue,
        field,
        validationRelatedFieldNames: [
          {
            fields: commaValidationRelatedFieldNames,
            inputCharactersAllowed: ['nonComma'],
          },
        ],
      });
    }

    updateState(inputName, inputValue);
  };

  const handlePatternFormatInputChange = (values, field) => {
    const name = field?.code ?? '';
    const value = values?.value ?? '';

    updateState(name, value);
  };

  // Within `visibleFields`, it filters visible fields and controls their visibility conditionally based on a specific state.
  const visibleFields =
    fields?.filter((field) => field && handleShowCondition(field, state) && !field?.is_hidden) ??
    [];

  // Lists `visibleFieldCodes` and `nonVisibleFieldCodes` are used to store visible and non-visible field codes, respectively.
  const visibleFieldCodes: string[] = useMemo(
    () => visibleFields.map((f) => f.code),
    [JSON.stringify(visibleFields)],
  );

  const nonVisibleFieldCodes = useMemo(
    () =>
      fields?.reduce(
        (c: string[], f) => (!visibleFieldCodes.includes(f.code) ? [...c, f.code] : c),
        [],
      ) ?? [],
    [JSON.stringify(visibleFieldCodes)],
  );

  const prevNonVisibleFieldCodes = usePreviousValue(nonVisibleFieldCodes);

  useEffect(() => {
    const diff = getSymmetricDiffrence(nonVisibleFieldCodes, prevNonVisibleFieldCodes ?? []);

    // Upon detection of changes, it disables the relevant fields and updates form values accordingly.
    if (diff?.length) {
      setState?.((prevState) => omit(prevState, nonVisibleFieldCodes));
      formik?.setValues(omit(formik?.values, nonVisibleFieldCodes));
    }
  }, [JSON.stringify(nonVisibleFieldCodes)]);

  const handleFieldType = (field: DynamicField) => {
    switch (field.type) {
      case dataFieldTypes.STRING:
        if (Object.keys(maskedInputsConfig).includes(field.code)) {
          const { format, mask } = maskedInputsConfig[`${field.code}`];
          const testPrefix = `uw_${field.code}`;

          return (
            <FormControl
              variant="standard"
              sx={{ width: '100%', maxWidth: '480px' }}
              key={field.code}
            >
              <PatternFormat
                format={format}
                customInput={TextField}
                valueIsNumericString
                mask={mask}
                id={field.code}
                name={field.code}
                label={field.name}
                size="small"
                required={!field.is_optional}
                value={state[field.code] || ''}
                onValueChange={(values, _) => handlePatternFormatInputChange(values, field)}
                sx={field?.is_read_only || isReadOnly ? readOnlyInputStyle : {}}
                InputLabelProps={
                  field?.is_read_only && !isReadOnly && !state[field.code]
                    ? { shrink: false }
                    : isReadOnly
                    ? { shrink: true }
                    : {}
                }
                inputProps={{
                  autoComplete: 'off',
                  ...((field?.is_read_only || isReadOnly) && { readOnly: true }),
                  'data-test': testPrefix,
                  // This CSS Class will automatically mask the Full Story field.
                  ...(field.code === 'info_ai_ssn' && { className: 'fs-mask' }),
                }}
                error={formik.touched[`${field.code}`] && Boolean(formik.errors[`${field.code}`])}
                helperText={formik.touched[`${field.code}`] && formik.errors[`${field.code}`]}
              />
            </FormControl>
          );
        }

        return (
          <FormControl variant="standard" sx={{ width: '100%' }} key={field.code}>
            <TextField
              id={field.code}
              name={field.code}
              label={field.name}
              size="small"
              required={!field.is_optional}
              onChange={(event) => handleInputChange(event, field)}
              value={state[field.code] ?? ''}
              sx={field?.is_read_only || isReadOnly ? readOnlyInputStyle : {}}
              InputLabelProps={
                field?.is_read_only && !isReadOnly && !state[field.code]
                  ? { shrink: false }
                  : isReadOnly
                  ? { shrink: true }
                  : {}
              }
              inputProps={{
                autoComplete: 'nope',
                ...((field?.is_read_only || isReadOnly) && { readOnly: true }),
              }}
              error={formik.touched[`${field.code}`] && Boolean(formik.errors[`${field.code}`])}
              helperText={formik.touched[`${field.code}`] && formik.errors[`${field.code}`]}
            />
          </FormControl>
        );

      case dataFieldTypes.AUTOCOMPLETE:
        return (
          <FormControl variant="standard" sx={{ width: '100%' }} key={field.code}>
            {field.service_bridge?.name === 'smarty' && (
              <SmartyAutocompleteInput
                addressState={state}
                setAddressState={setState}
                formik={formik}
                field={field as IField}
                label={field.name}
                showLabel
                error={formik.touched[`${field.code}`] && Boolean(formik.errors[`${field.code}`])}
                helperText={formik.touched[`${field.code}`] && formik.errors[`${field.code}`]}
                makeInitialSearch={false}
                isReadOnly={field?.is_readonly || isReadOnly}
                onSelect={onSmartySelect}
                preferStates={field?.additional_data?.prefer_states}
                preferRatio={field?.additional_data?.prefer_ratio}
                removeCommasOnInputChange={commaValidationRelatedFieldNamesForSmarty}
              />
            )}
          </FormControl>
        );

      case dataFieldTypes.PHONE:
        return (
          <FormControl
            variant="standard"
            sx={{ width: '100%' }}
            key={field.code}
            required={!field.is_optional}
          >
            <Stack
              sx={{
                '& .MuiFormControl-root': {
                  flex: 'auto',
                },
                py: 1,
                display: 'flex',
                flexDirection: 'row',
                alignItems: 'center',
                justifyContent: 'space-between',
              }}
            >
              <InputMask
                mask="(999) 999-9999"
                disabled={false}
                maskChar=" "
                onChange={handleInputChange}
                value={state[field.code] || ''}
              >
                {() => (
                  <TextField
                    sx={[
                      { flex: 'auto' },
                      field?.is_read_only || isReadOnly ? readOnlyInputStyle : {},
                    ]}
                    id={field.code}
                    name={field.code}
                    size="small"
                    label={field.name}
                    required={!field.is_optional}
                    value={state[field.code] || ''}
                    InputLabelProps={
                      field?.is_read_only && !isReadOnly && !state[field.code]
                        ? { shrink: false }
                        : field?.is_read_only || isReadOnly
                        ? { shrink: true }
                        : {}
                    }
                    inputProps={{
                      autoComplete: 'nope',
                      ...((field?.is_read_only || isReadOnly) && { readOnly: true }),
                    }}
                    error={
                      formik.touched[`${field.code}`] && Boolean(formik.errors[`${field.code}`])
                    }
                    helperText={formik.touched[`${field.code}`] && formik.errors[`${field.code}`]}
                  />
                )}
              </InputMask>
            </Stack>
          </FormControl>
        );

      case dataFieldTypes.NUMBER:
        return (
          <FormControl variant="standard" sx={{ width: '100%' }} key={field.code}>
            <TextField
              sx={[
                {
                  '& input::-webkit-outer-spin-button, & input::-webkit-inner-spin-button': {
                    display: 'none',
                  },
                  '& input[type=number]': {
                    MozAppearance: 'textfield',
                  },
                },
                field?.is_read_only || isReadOnly ? readOnlyInputStyle : {},
              ]}
              id={field.code}
              label={field.name}
              name={field.code}
              size="small"
              required={!field.is_optional}
              type="text"
              onChange={handleInputChange}
              value={state[field.code] || ''}
              inputProps={{
                autoComplete: 'off',
                ...((field?.is_read_only || isReadOnly) && { readOnly: true }),
                ...(typeof field?.additional_data?.allowDecimal === 'boolean'
                  ? {
                      allowDecimal: field?.additional_data?.allowDecimal,
                    }
                  : {}),
                ...(typeof field?.additional_data?.thousandSeparator === 'boolean'
                  ? {
                      thousandSeparator: field?.additional_data?.thousandSeparator,
                    }
                  : {}),
                ...(typeof field?.additional_data?.allowLeadingZeros === 'boolean'
                  ? {
                      allowLeadingZeros: field?.additional_data?.allowLeadingZeros,
                    }
                  : {}),
              }}
              InputLabelProps={field?.is_readonly || isReadOnly ? { shrink: true } : {}}
              // eslint-disable-next-line react/jsx-no-duplicate-props
              InputProps={{
                autoComplete: 'new-password',
                inputComponent: NumberFormatComponent as any,
              }}
              error={formik.touched[`${field.code}`] && Boolean(formik.errors[`${field.code}`])}
              helperText={formik.touched[`${field.code}`] && formik.errors[`${field.code}`]}
            />
          </FormControl>
        );

      case dataFieldTypes.EMAIL:
        return (
          <FormControl variant="standard" sx={{ width: '100%' }} key={field.code}>
            <TextField
              sx={[{ flex: 'auto' }, field?.is_read_only || isReadOnly ? readOnlyInputStyle : {}]}
              id={field.code}
              name={field.code}
              size="small"
              required={!field.is_optional}
              label={field.name}
              onChange={handleInputChange}
              value={state[field.code] || ''}
              error={formik.touched[field.code] && Boolean(formik.errors[field.code])}
              helperText={formik.touched[field.code] && formik.errors[field.code]}
              inputProps={{
                autoComplete: 'nope',
                ...((field?.is_read_only || isReadOnly) && { readOnly: true }),
              }}
              InputLabelProps={field?.is_read_only || isReadOnly ? { shrink: true } : {}}
            />
          </FormControl>
        );

      case dataFieldTypes.DATE:
        return (
          <FormControl size="small" variant="standard" key={field.code} fullWidth>
            <DatePickerComponent
              format={defaultDateFormat}
              onChange={(newValue) => {
                setState({ ...state, [field.code]: newValue! });
              }}
              value={state[field.code] ?? null}
              minDate={field?.minimum_date}
              maxDate={field?.maximum_date}
              label={field.name}
              readOnly={field?.is_read_only || isReadOnly}
              slotProps={{
                textField: {
                  size: 'small',
                  sx: field?.is_read_only || isReadOnly ? readOnlyInputStyle : {},
                  required: !field.is_optional,
                  id: field.code,
                  name: field.code,
                  InputLabelProps:
                    field?.is_read_only && !isReadOnly && !state[field.code]
                      ? { shrink: false }
                      : isReadOnly
                      ? { shrink: true }
                      : {},
                  inputProps: {
                    autoComplete: 'off',
                    ...((field?.is_read_only || isReadOnly) && { readOnly: true }),
                  },
                  error: formik.touched[`${field.code}`] && Boolean(formik.errors[`${field.code}`]),
                  helperText: formik.touched[`${field.code}`] && formik.errors[`${field.code}`],
                },
              }}
            />
          </FormControl>
        );

      case dataFieldTypes.SELECT:
        const isMultiSelect =
          Boolean(field?.additional_data?.multiselect_condition) &&
          handleMultiSelectCondition(field);

        // Option is shown only for the roles that are in the showOnlyForRoles array
        const filteredSelectOptions = [...(field.choices ?? [])].filter((option) => {
          if (
            !option?.additional_data?.showOnlyForRoles ||
            !Array.isArray(option?.additional_data?.showOnlyForRoles)
          ) {
            return true;
          }

          const userRoleCode = user?.role?.code;
          return option?.additional_data?.showOnlyForRoles.includes(userRoleCode);
        });

        const selectOptions = uniqBy([...filteredSelectOptions], 'code').filter(
          (option) => !!option.code && !!option.name,
        );

        if (isMultiSelect) {
          const renderValue = Array.isArray(state[field.code])
            ? state[field.code]?.map(
                (item: any) => selectOptions.find((option) => option.code === item)?.name,
              )
            : [];

          return (
            <FormControl
              required={!field.is_optional}
              size="small"
              sx={[
                { width: '100%' },
                isReadOnly || field?.is_read_only ? readOnlySelectInputStyle : {},
              ]}
              key={field.code}
              error={formik.touched[`${field.code}`] && Boolean(formik.errors[`${field.code}`])}
            >
              <Stack
                sx={{
                  '& .MuiFormControl-root': {
                    flex: 'auto',
                  },
                }}
              >
                <InputLabel
                  required={!field.is_optional}
                  id={`${field.code}_label`}
                  shrink={isReadOnly || field?.is_read_only ? true : undefined}
                >
                  {field.name}
                </InputLabel>
                <Select
                  required={!field.is_optional}
                  id={field.code}
                  labelId={`${field.code}_label`}
                  label={field.name}
                  name={field.code}
                  multiple={isMultiSelect}
                  IconComponent={KeyboardArrowDownRounded}
                  value={Array.isArray(state[field.code]) ? state[field.code] : []}
                  inputProps={{
                    autoComplete: 'off',
                    readOnly: field?.is_read_only || isReadOnly,
                  }}
                  onChange={(event: SelectChangeEvent) => {
                    onSelectChange?.(event);
                    setState({
                      ...state,
                      [field.code]: event.target.value,
                    });
                    formik.setFieldValue([field.code], event.target.value);
                  }}
                  renderValue={() => renderValue.join(', ')}
                >
                  {selectOptions?.map((type) => (
                    <MenuItem
                      key={type.code}
                      value={type.code}
                      sx={{
                        '&:hover': {
                          backgroundColor: 'inherit',
                          color: '#000',
                        },
                        '&.Mui-selected, &.Mui-selected:hover': {
                          backgroundColor: 'inherit',
                          color: '#000',
                        },
                      }}
                    >
                      <Checkbox
                        checked={
                          Boolean(
                            state[field.code]?.length && state[field.code].indexOf(type.code) > -1,
                          ) ?? false
                        }
                      />
                      {type.name ?? '-'}
                    </MenuItem>
                  ))}
                </Select>

                {formik.touched[`${field.code}`] && Boolean(formik.errors[`${field.code}`]) && (
                  <FormHelperText
                    sx={{
                      mt: 1,
                      fontSize: '12px',
                      lineHeight: '14px',
                      color: (theme) => theme.customColors.alert,
                    }}
                  >
                    {formik.errors[`${field.code}`]}
                  </FormHelperText>
                )}
              </Stack>
            </FormControl>
          );
        }

        return (
          <FormControl
            required={!field.is_optional}
            size="small"
            sx={[
              { width: '100%' },
              isReadOnly || field?.is_read_only ? readOnlySelectInputStyle : {},
            ]}
            key={field.code}
            error={formik.touched[`${field.code}`] && Boolean(formik.errors[`${field.code}`])}
          >
            <Stack
              sx={{
                '& .MuiFormControl-root': {
                  flex: 'auto',
                },
              }}
            >
              <InputLabel
                required={!field.is_optional}
                id={`${field.code}_label`}
                shrink={isReadOnly || field?.is_read_only ? true : undefined}
              >
                {field.name}
              </InputLabel>
              <CustomNativeSelect
                required={!field.is_optional}
                id={field.code}
                labelId={`${field.code}_label`}
                label={field.name}
                name={field.code}
                IconComponent={KeyboardArrowDownRounded}
                value={state[field.code] ?? ''}
                inputProps={{
                  autoComplete: 'off',
                  readOnly: field?.is_read_only || isReadOnly,
                }}
                onChange={(event: SelectChangeEvent<unknown>) => {
                  onSelectChange?.(event);

                  setState({
                    ...state,
                    [field.code]: event.target.value,
                  });
                  formik.setFieldValue([field.code], event.target.value);
                }}
              >
                {selectOptions?.map((type) => (
                  <option key={type.code} value={type.code}>
                    {type.name ?? '-'}
                  </option>
                ))}
              </CustomNativeSelect>

              {formik.touched[`${field.code}`] && Boolean(formik.errors[`${field.code}`]) && (
                <FormHelperText
                  sx={{
                    mt: 1,
                    fontSize: '12px',
                    lineHeight: '14px',
                    color: (theme) => theme.customColors.alert,
                  }}
                >
                  {formik.errors[`${field.code}`]}
                </FormHelperText>
              )}
            </Stack>
          </FormControl>
        );

      case dataFieldTypes.SELECT_BUTTON:
        return (
          <FormControl size="small" sx={{ width: '100%', position: 'relative' }} key={field.code}>
            {field.name && (
              <Typography
                sx={{
                  fontWeight: 400,
                  fontSize: '12px',
                  lineHeight: '14px',
                  whiteSpace: 'nowrap',
                  mb: 1,
                }}
              >
                {field.name} {!field?.is_optional && '*'}
              </Typography>
            )}
            <ToggleButtonGroup
              exclusive
              value={state[field.code] ?? null}
              onChange={(event, newValue) => {
                if (newValue) {
                  setState({ ...state, [field.code]: newValue });
                  formik.setFieldValue([field.code], newValue);
                }
              }}
              sx={{ gap: 2 }}
              aria-label={field.name}
            >
              {field.choices?.map((choice) => (
                <ToggleButton
                  disabled={field?.is_read_only || isReadOnly}
                  sx={{
                    whiteSpace: 'nowrap',
                    backgroundColor: (theme) => theme.customColors.grey1150,
                    width: '120px',
                    height: '44px',
                    mb: 1,
                    '&.MuiToggleButton-root': {
                      py: 1.25,
                      px: 5,
                      color: (theme) => theme.customColors.gunMetal,
                      borderColor: (theme) => theme.customColors.gunMetal,
                      '&.MuiToggleButtonGroup-grouped:not(:first-of-type) ': {
                        borderLeft: '1px solid #2B2B2A',
                        ml: 0,
                        borderRadius: '2px',
                      },
                      '&.MuiToggleButtonGroup-grouped:not(:last-of-type) ': {
                        borderLeft: '1px solid #2B2B2A',
                        ml: 0,
                        borderRadius: '2px',
                      },
                      '&.Mui-selected': {
                        border: '2px solid !important',
                        borderColor: '#BB4F09 !important',
                      },
                    },
                  }}
                  value={choice.code}
                  key={choice.code}
                  aria-label={choice.name}
                >
                  <Radio
                    checked={state[field.code] === choice.code}
                    size="small"
                    disableRipple
                    sx={{
                      fontSize: 15,
                      width: 15,
                      height: 15,
                      mr: 1,
                      '&:hover': {
                        background: 'none',
                      },
                      color:
                        state[field.code] === choice.code
                          ? (theme) => theme.customColors.copper
                          : (theme) => theme.customColors.gunMetal,
                      '&.Mui-checked': {
                        color:
                          state[field.code] === choice.code
                            ? (theme) => theme.customColors.copper
                            : (theme) => theme.customColors.gunMetal,
                      },
                    }}
                  />

                  <Typography
                    sx={{
                      fontWeight: 500,
                      fontSize: '16px',
                      lineHeight: '24px',
                      textTransform: 'none',
                    }}
                  >
                    {Number.isNaN(Number(choice.name)) ? (
                      choice.name
                    ) : field.additional_data?.withDollarIcon ? (
                      `${currencyFormat('USD', choice.name!).merged}`
                    ) : (
                      <NumberFormatComponent displayType="text" value={choice.name} />
                    )}
                  </Typography>
                </ToggleButton>
              ))}
            </ToggleButtonGroup>
            <FormHelperText
              sx={{ position: 'absolute', bottom: '-24px' }}
              error={formik.touched[field.code] && Boolean(formik.errors[field.code])}
            >
              {formik.touched[field.code] && Boolean(formik.errors[field.code])
                ? formik.errors[field.code]
                : null}
            </FormHelperText>
          </FormControl>
        );

      case dataFieldTypes.LABEL:
        return (
          <Box
            sx={{
              display: 'flex',
              justifyContent: 'space-between',
              flex: 1,
            }}
            key={field.code}
          >
            <>
              <Typography
                sx={{
                  fontSize: 14,
                  lineHeight: '21px',
                  fontWeight: 500,
                }}
              >
                {field?.name}
              </Typography>
              <Typography
                sx={{
                  fontSize: 14,
                  lineHeight: '21px',
                  fontWeight: 400,
                }}
              >
                {state[field.code] ?? ''}
              </Typography>
            </>
          </Box>
        );

      case dataFieldTypes.CHECKBOX:
        return (
          <FormControl
            required={!field.is_optional}
            size="small"
            key={field.code}
            sx={[
              { display: 'flex', flexDirection: 'row', alignItems: 'center' },
              isReadOnly || field?.is_read_only ? readOnlySelectInputStyle : {},
            ]}
          >
            <FormControlLabel
              sx={{ ml: 0 }}
              control={
                <Checkbox
                  sx={[
                    { width: 24, height: 24 },
                    isReadOnly || field?.is_read_only ? readOnlyCheckboxStyle : {},
                  ]}
                  id={field.name}
                  name={field.name}
                  onChange={(event, checked) => {
                    if (!(isReadOnly || field?.is_read_only)) {
                      setState({ ...state, [field.code]: checked });
                      formik.setFieldValue([field.code], checked);
                    }
                  }}
                  checked={state[field.code] ?? false}
                  inputProps={{
                    readOnly: isReadOnly,
                  }}
                />
              }
              label={
                <Typography
                  sx={{
                    fontSize: 16,
                    color: (theme) => theme.customColors.black,
                    lineHeight: '24px',
                    fontWeight: 400,
                    ml: 1.5,
                  }}
                >
                  {field?.name!}
                </Typography>
              }
            />
          </FormControl>
        );

      default:
    }
  };

  const handleMultiSelectCondition = (field) => {
    const handleCondition = {
      checkbox: (condition, fieldCode) => {
        const { value } = condition;
        return value === state[fieldCode];
      },
      select: (condition, fieldCode) => {
        const { value } = condition;
        return value.includes(state[fieldCode]);
      },
      number: (condition, fieldCode) => {
        const { value, operator } = condition;
        const operators = {
          '>': () => state[fieldCode] > +value,
          '<': () => state[fieldCode] < +value,
        };
        return operators[operator]();
      },
    };

    if (
      field?.additional_data?.multiselect_condition &&
      Object.keys(field?.additional_data?.multiselect_condition).length > 0
    ) {
      const results: boolean[] = [];
      // eslint-disable-next-line no-restricted-syntax
      for (const [fieldCode, fieldConditions] of Object.entries(
        field?.additional_data?.multiselect_condition,
      ) as unknown as any[]) {
        const result = fieldConditions.every((condition) =>
          handleCondition[condition.type](condition, fieldCode),
        );
        results.push(result);
      }
      return results.every((result) => result);
    }
    return true;
  };

  const handleConditionalField = (field) => {
    const handleCondition = {
      checkbox: (condition, fieldCode) => {
        const { value } = condition;
        return value === state[fieldCode];
      },
      select: (condition, fieldCode) => {
        const { value } = condition;
        return value.includes(state[fieldCode]);
      },
      number: (condition, fieldCode) => {
        const { value, operator } = condition;
        const operators = {
          '>': () => state[fieldCode] > +value,
          '<': () => state[fieldCode] < +value,
        };
        return operators[operator]();
      },
    };

    if (field?.condition && Object.keys(field.condition).length > 0) {
      const results: boolean[] = [];
      // eslint-disable-next-line no-restricted-syntax
      for (const [fieldCode, fieldConditions] of Object.entries(
        field.condition,
      ) as unknown as any[]) {
        const result = fieldConditions.every((condition) =>
          handleCondition[condition.type](condition, fieldCode),
        );
        results.push(result);
      }
      return results.every((result) => result);
    }
    return true;
  };

  return (
    <>
      {showLoader || userLoading ? (
        [...Array(4).keys()].map((v) => (
          <Stack
            key={`skel${v}`}
            gap={2}
            sx={{
              width: 1,
              flexDirection: {
                xs: 'column',
                sm: 'row',
              },
            }}
          >
            <FormControl variant="standard" sx={{ flex: 1 }}>
              <Skeleton animation="wave" width="100%" height={37} />
            </FormControl>
            <FormControl variant="standard" sx={{ flex: 1 }}>
              <Skeleton animation="wave" width="100%" height={37} />
            </FormControl>
          </Stack>
        ))
      ) : (!isEdit ? loaded : !isEmpty(visibleFields)) ? (
        <Grid
          sx={{ alignItems: 'flex-start' }}
          container
          rowSpacing={rowSpacing}
          columnSpacing={columnSpacing}
          columns={splitSize}
        >
          {visibleFields.map(
            (field) =>
              handleConditionalField(field) &&
              field &&
              !field?.is_hidden && (
                <React.Fragment key={field.code + field.type}>
                  {(field.heading ||
                    field.additional_data?.section_heading ||
                    field.newline ||
                    field.additional_data?.space_above) && (
                    <Grid
                      item
                      key={`${field.code}_header`}
                      xs={splitSize}
                      sx={[
                        field.newline ? { p: '0 !important' } : {},
                        field.additional_data?.space_above ? { mt: '26px' } : {},
                      ]}
                    >
                      {field.heading && (
                        <Typography
                          sx={{
                            fontWeight: 500,
                            mt: 1.5,
                            fontSize: '16px',
                            lineHeight: 1.5,
                          }}
                        >
                          {field.heading}
                        </Typography>
                      )}
                      {field.additional_data?.section_heading && (
                        <Typography
                          sx={{
                            mt: 6,
                            fontWeight: 500,
                            fontSize: '20px',
                            lineHeight: '26px',
                            color: '#2B2B2A',
                          }}
                        >
                          {field.additional_data.section_heading}
                        </Typography>
                      )}
                    </Grid>
                  )}

                  <Grid
                    item
                    key={field.code + field.type}
                    xs={3}
                    sx={
                      Array.isArray(sx)
                        ? [
                            ...sx,
                            {
                              pt: field.type === 'label' && '6px !important',
                            },
                          ]
                        : [
                            sx,
                            {
                              pt: field.type === 'label' && '6px !important',
                            },
                          ]
                    }
                  >
                    {handleFieldType(field)}
                  </Grid>
                </React.Fragment>
              ),
          )}
        </Grid>
      ) : (
        <></>
      )}
    </>
  );
};
