import { Box, CircularProgress, SxProps, Theme, Typography } from '@mui/material';
import {
  DataGridPro,
  DataGridProProps,
  GridColDef,
  GridRowId,
  GridRowSelectionModel,
} from '@mui/x-data-grid-pro';
import DataGridPagination from 'components/DataGridPagination';
import React, { FC, useState } from 'react';
import { useTranslation } from 'react-i18next';

interface DataTableProProps extends DataGridProProps {
  autoRowCellHeight?: boolean;
  alignContentToTop?: boolean;
  columns: GridColDef[];
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  rows: any[];
  focusedRowId?: number | string;
  isSummaryTable?: boolean | undefined;
  tableHeader?: string | undefined;
  onSelect?: (ids: GridRowSelectionModel) => void;
  loading?: boolean;
  onPageChange?: (page: number) => void;
  isHeaderWidthFit?: boolean;
  wrapperSx?: SxProps<Theme>;
  isTotalRowVisible?: boolean;
  pageSize?: number;
}

const DataTablePro: FC<DataTableProProps> = ({
  autoRowCellHeight = false,
  alignContentToTop = false,
  isTotalRowVisible = false,
  rows,
  columns,
  isSummaryTable = false,
  tableHeader,
  onSelect,
  loading = false,
  onPageChange,
  focusedRowId,
  isHeaderWidthFit,
  wrapperSx = [],
  pageSize = 20,
  ...rest
}: DataTableProProps) => {
  const { t } = useTranslation();
  const [selectionModel, setSelectionModel] = useState<GridRowSelectionModel>([]);
  const [page, setPage] = useState(0);
  return (
    <Box
      sx={[
        {
          backgroundColor: (theme) => theme.palette.background.default,
          boxShadow: 0,
        },
        ...(Array.isArray(wrapperSx) ? wrapperSx : [wrapperSx]),
      ]}
    >
      <DataGridPro
        disableRowSelectionOnClick={!onSelect}
        sortingOrder={['asc', 'desc']}
        rows={rows}
        columns={columns}
        autoHeight
        paginationModel={{ page, pageSize }}
        pageSizeOptions={[pageSize]}
        pagination
        density="compact"
        hideFooterSelectedRowCount
        disableColumnMenu
        loading={loading}
        getRowClassName={(params) => (params.id === focusedRowId ? 'focused' : '')}
        onRowSelectionModelChange={(ids) => {
          const selectedIDs = new Set<GridRowId>(ids);
          if (selectionModel.length > 0 && [...selectedIDs].includes(selectionModel[0])) {
            onSelect?.([]);
            setSelectionModel([]);
          } else {
            setSelectionModel([...selectedIDs]);
            onSelect?.([...selectedIDs]);
          }
        }}
        onPaginationModelChange={(paginationModel) => {
          if (onPageChange) {
            onPageChange(paginationModel.page);
          } else {
            setPage(paginationModel.page);
          }
          onSelect?.([]);
          setSelectionModel([]);
        }}
        rowSelectionModel={selectionModel}
        slots={{
          pagination: DataGridPagination,
          noRowsOverlay: () => (
            <Typography sx={{ mt: 2, ml: 2 }}>
              {tableHeader ? `${t('No {{tableHeader}}', { tableHeader })}` : t(`No data`)}
            </Typography>
          ),
          loadingOverlay: () => (
            <Box
              sx={{
                background: (theme) => theme.customColors.tableLoadingBackdrop,
                width: '100%',
                height: '100%',
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                zIndex: (theme) => theme.zIndex.appBar - 1,
              }}
            >
              <CircularProgress
                color="inherit"
                sx={{
                  animationDuration: '2s',
                }}
              />
            </Box>
          ),
        }}
        {...rest}
        sx={{
          ...(autoRowCellHeight && {
            '& .MuiDataGrid-renderingZone': {
              maxHeight: 'none !important',
            },
            '& .MuiDataGrid-cell': {
              maxHeight: 'none !important',
              whiteSpace: 'normal',
            },
            '& .MuiDataGrid-row': {
              maxHeight: 'none !important',
            },
            '& .MuiDataGrid-main': {
              overflowX: 'auto',
            },
            '&.MuiDataGrid-root .MuiDataGrid-virtualScroller': {
              overflowY: 'hidden',
              minHeight: rows?.length > 0 ? 'unset' : '200px',
            },
            '&.MuiDataGrid-root .MuiDataGrid-virtualScrollerContent': {
              height: 'auto !important',
            },
            '&.MuiDataGrid-root .MuiDataGrid-virtualScrollerRenderZone': {
              position: 'relative',
            },
            '&.MuiDataGrid-root .MuiDataGrid-main > div': {
              height: loading ? '100% !important' : '',
            },
          }),
          ...(rows?.length === 0 && {
            '& .MuiDataGrid-footerContainer': {
              borderTop: 'none',
            },
          }),
          ...(alignContentToTop && {
            '& .MuiDataGrid-cell': {
              alignItems: 'flex-start',
              py: 0.75,
              height: '100%',
            },
          }),
          ...(isHeaderWidthFit && {
            '& .MuiDataGrid-row > .MuiDataGrid-cell': {
              minHeight: '100%',
            },
            '&.MuiDataGrid-root .MuiDataGrid-main': {
              '& .MuiDataGrid-columnHeaders': {
                alignItems: 'center',
                '&  .MuiDataGrid-columnHeader--alignRight': {
                  textAlign: 'right',
                },
              },
            },
          }),
          '&.MuiDataGrid-root': {
            border: 0,
          },
          '&.MuiDataGrid-root .MuiDataGrid-row:not(.MuiDataGrid-row--dynamicHeight)>.MuiDataGrid-cell':
            {
              '&.not(&>span)': {
                whiteSpace: 'unset',
              },
            },
          '&.MuiDataGrid-root .MuiDataGrid-cell:focus': {
            outline: 'none',
          },
          '&.MuiDataGrid-root .MuiDataGrid-cell': {
            pl: 1,
            fontWeight: 400,
            color: (theme) => theme.customColors.table.item,
            '&[data-field="scroll"]': {
              bgcolor: (theme) => theme.customColors.grey1200,
            },
            lineHeight: isHeaderWidthFit ? 1.5 : 1.2,
            display: 'flex',
            alignItems: 'center',
          },
          '&.MuiDataGrid-root .MuiDataGrid-columnHeader': {
            pl: 1,
          },
          '&.MuiDataGrid-root .MuiDataGrid-columnHeaderTitleContainer': {
            pl: 0,
            alignItems: 'center',
          },
          '&.MuiDataGrid-root .MuiDataGrid-columnHeaderTitle': {
            pl: 0,
            fontWeight: 900,
            color: (theme) => theme.customColors.table.header,
            whiteSpace: isHeaderWidthFit ? 'normal' : 'nowrap',
            textOverflow: isHeaderWidthFit ? 'unset' : 'ellipsis',
            lineHeight: isHeaderWidthFit ? 1.2 : 1.3,
          },
          '&.MuiDataGrid-root .MuiDataGrid-columnHeader:focus': {
            outline: 'none',
          },
          '&.MuiDataGrid-root .MuiDataGrid-columnSeparator': {
            display: 'none',
          },
          '&.MuiDataGrid-root .MuiDataGrid-columnHeader:focus-within': {
            outline: 'none',
          },
          '&.MuiDataGrid-root .MuiDataGrid-cell:focus-within': {
            outline: 'none',
          },
          '&.MuiDataGrid-root .MuiDataGrid-row:hover': {
            backgroundColor: isSummaryTable ? 'unset' : (theme) => theme.customColors.listHover,
          },
          '&.MuiDataGrid-root .MuiDataGrid-selected': {
            backgroundColor: (theme) => theme.customColors.listHover,
          },
          '&.MuiDataGrid-root .MuiDataGrid-row.focused': {
            backgroundColor: (theme) => theme.customColors.listHover,
          },
          '&.MuiDataGrid-root .MuiDataGrid-columnHeader:hover .MuiDataGrid-iconButtonContainer': {
            padding: 0,
          },
          '&.MuiDataGrid-root .MuiDataGrid-columnHeader .MuiDataGrid-iconButtonContainer': {
            padding: 0,
            ml: 1,
          },
          '&.MuiDataGrid-root .MuiDataGrid-columnHeader--alignRight .MuiDataGrid-iconButtonContainer':
            {
              padding: 0,
              mr: 1,
              ml: 0,
            },
          '&.MuiDataGrid-root .MuiDataGrid-columnHeader--alignCenter .MuiDataGrid-iconButtonContainer':
            {
              width: {
                xs: 'auto',
                sm: 'auto',
                md: 'auto',
                lg: 0,
              },
            },
          '&.MuiDataGrid-root.MuiDataGrid-autoHeight .MuiDataGrid-row--lastVisible .MuiDataGrid-cell':
            {
              borderBottom: 'none',
            },
          '&.MuiDataGrid-root .MuiDataGrid-cell--editing': {
            paddingLeft: 0,
            paddingRight: '2px',
            '& input': {
              paddingX: 1,
            },
            '& .MuiInputBase-root': {
              height: '32px',
            },
          },
          ...(isSummaryTable && {
            '&.MuiDataGrid-root .MuiDataGrid-cell--editing': {
              p: '0px !important',
              boxShadow: 'none',
              backgroundColor: '#f5f5f5',
              '& input': {},
              '& .MuiInputBase-root': {
                '& > .MuiSelect-select': {
                  textAlign: 'end',
                  display: 'flex',
                  alignItems: 'flex-end',
                  justifyContent: 'flex-end',
                  // pr: 0,
                  ml: 'auto',
                  width: '100%',
                  backgroundColor: (theme) => theme.customColors.black750,
                  borderRadius: 0,
                  '&:hover': {
                    backgroundColor: 'rgba(0, 0, 0, 0.1)',
                  },
                },
                '& > fieldset': {
                  border: 'none',
                },
                '& .Mui-focused': {
                  border: 'none',
                },
                width: '100%',
              },
            },

            '&.MuiDataGrid-root .MuiDataGrid-cell--editable': {
              px: 1,
            },
            ...(isTotalRowVisible && {
              '& .MuiDataGrid-row.MuiDataGrid-row--lastVisible': {
                borderTop: (theme) => `1px solid  ${theme.customColors.gunMetal}`,
                borderBottom: (theme) => `1px solid  ${theme.customColors.gunMetal}`,
              },
            }),
          }),
        }}
      />
    </Box>
  );
};
export default DataTablePro;
