/* eslint-disable consistent-return */
import { Autocomplete, FormControl, Grid, Stack, TextField } from '@mui/material';
import { Field } from 'api/models/AdvancedSearch/advancedSearchConfig.model';
import Arrow from 'assets/images/Arrow.svg';
import CalendarSchedule from 'assets/images/CalendarSchedule.svg';
import { dataFieldTypes, defaultDateFormat } from 'common/constants';
import DatePickerComponent from 'components/DatePickerComponent';
import MultiSelectChips from 'components/MultiSelectChips/MultiSelectChips';
import {
  autocompleteArrowStyle,
  autocompleteTextFieldStyle2,
  calendarIconStyle,
} from 'helpers/MuiSharedStyles';
import splitToChunks from 'helpers/SplitChunks';
import useUser from 'hooks/useUser';
import { isEmpty, uniqBy } from 'lodash-es';
import React, { ChangeEvent, FC } from 'react';
import { useTranslation } from 'react-i18next';
import InputMask from 'react-input-mask';
import { ReactSVG } from 'react-svg';

interface ISearchFieldParserProps {
  state: any;
  formik: any;
  setState: (value: any) => void;
  fields: Field[] | undefined | any;
}

export const SearchFieldParser: FC<ISearchFieldParserProps> = ({
  state,
  formik,
  fields,
  setState,
}) => {
  const { t } = useTranslation();
  const { data: user } = useUser();

  const handleInputChange = (event: ChangeEvent<HTMLInputElement>) => {
    setState({ [event.target.name]: event.target.value });
    formik.handleChange(event);
  };

  const handleFieldType = (field) => {
    switch (field.type) {
      case dataFieldTypes.STRING:
        return (
          <FormControl variant="standard" sx={{ width: '100%', py: 1 }} key={field.code}>
            <TextField
              id={field.code}
              name={field.code}
              size="small"
              label={t(field.name)}
              onChange={handleInputChange}
              value={state[field.code] ?? ''}
              autoComplete="off"
              inputProps={{
                autoComplete: 'new-password',
                maxLength: field.additional_data?.maximum ?? 50,
              }}
            />
          </FormControl>
        );

      case dataFieldTypes.EMAIL:
        return (
          <FormControl variant="standard" sx={{ width: '100%', py: 1 }} key={field.code}>
            <TextField
              id={field.code}
              name={field.code}
              size="small"
              // type="email"
              label={t(field.name)}
              onChange={handleInputChange}
              value={state[field.code] ?? ''}
              autoComplete="off"
              InputProps={{
                autoComplete: 'new-password',
                inputProps: {
                  maxLength: 50,
                  style: { textAlign: 'left' },
                },
              }}
            />
          </FormControl>
        );

      case dataFieldTypes.PHONE:
        return (
          <FormControl variant="standard" sx={{ width: '100%', py: 1 }} key={field.code}>
            <Stack
              sx={{
                '& .MuiFormControl-root': {
                  flex: 'auto',
                },
                display: 'flex',
                flexDirection: 'row',
                alignItems: 'center',
                justifyContent: 'space-between',
              }}
            >
              <InputMask
                mask="(999) 999-9999"
                disabled={false}
                maskChar=" "
                onChange={handleInputChange}
                value={state[field.code] || ''}
              >
                {() => (
                  <TextField
                    id={field.code}
                    name={field.code}
                    size="small"
                    label={t(field.name)}
                    value={state[field.code] ?? ''}
                    autoComplete="off"
                    inputProps={{ autoComplete: 'new-password' }}
                  />
                )}
              </InputMask>
            </Stack>
          </FormControl>
        );

      case dataFieldTypes.NUMBER:
        return (
          <FormControl variant="standard" sx={{ width: '100%', py: 1 }} key={field.code}>
            <TextField
              sx={{
                '& input::-webkit-outer-spin-button, & input::-webkit-inner-spin-button': {
                  display: 'none',
                },
                '& input[type=number]': {
                  MozAppearance: 'textfield',
                },
              }}
              id={field.code}
              name={field.code}
              size="small"
              label={t(field.name)}
              type="number"
              onChange={handleInputChange}
              value={state[field.code] || ''}
              autoComplete="off"
              inputProps={{ autoComplete: 'new-password' }}
              onInput={(e) => {
                (e.target as HTMLInputElement).value = (e.target as HTMLInputElement).value
                  .toString()
                  .slice(0, 50);
              }}
            />
          </FormControl>
        );

      case dataFieldTypes.SELECT:
        return (
          <FormControl size="small" sx={{ width: '100%', py: 1 }} key={field.code}>
            <Autocomplete
              openOnFocus
              sx={autocompleteArrowStyle}
              size="small"
              popupIcon={<ReactSVG className="arrow-icon" src={Arrow} />}
              value={field.options?.find((v) => v.code === state[field.code]) ?? null}
              onChange={(event, newValue) => {
                formik.setFieldValue([field.code], newValue ? newValue.code : '');
              }}
              placeholder={t(field.name)}
              options={
                field.is_optional
                  ? uniqBy([field.is_optional && { code: '', name: '' }, ...field.options], 'code')
                  : uniqBy([...field.options], 'code')
              }
              getOptionLabel={(option) => (typeof option === 'string' ? option : `${option.name}`)}
              renderInput={(params) => (
                <TextField
                  {...params}
                  id={field.code}
                  name={field.code}
                  inputProps={{
                    ...params.inputProps,
                    autoComplete: 'new-password',
                  }}
                  sx={autocompleteTextFieldStyle2}
                  label={t(field.name)}
                />
              )}
              renderOption={(props, option) => (
                <li {...props} key={option.code ?? 0}>
                  {option.name === '' ? <span>&#8203;</span> : option.name}
                </li>
              )}
              isOptionEqualToValue={(option, value) => option.code === value.code}
            />
          </FormControl>
        );

      case dataFieldTypes.DATE:
        return (
          <FormControl
            variant="standard"
            sx={{ width: '100%', py: 1, ...calendarIconStyle }}
            key={field.code}
          >
            <Stack
              sx={{
                '& .MuiFormControl-root': {
                  flex: 'auto',
                },
                display: 'flex',
                flexDirection: 'row',
                alignItems: 'center',
                justifyContent: 'space-between',
              }}
            >
              <DatePickerComponent
                format={defaultDateFormat}
                label={t(field.name)}
                onChange={(newValue) => {
                  formik.setFieldValue([field.code], newValue!);
                  setState({ ...state, [field.code]: newValue });
                }}
                value={state[field.code] ?? null}
                slots={{
                  openPickerIcon: () => (
                    <ReactSVG className="calendar-icon" src={CalendarSchedule} />
                  ),
                }}
                slotProps={{
                  textField: {
                    sx: { flex: 'auto' },
                    size: 'small',
                    id: field.code,
                    name: field.code,
                  },
                }}
              />
            </Stack>
          </FormControl>
        );

      case dataFieldTypes.MULTISELECT:
        return (
          <React.Fragment key={field.code}>
            <MultiSelectChips
              setState={setState}
              defaultOptions={field?.options}
              field={field}
              state={state}
              formik={formik}
            />
          </React.Fragment>
        );

      default:
    }
  };

  return (
    <>
      {!isEmpty(fields) ? (
        splitToChunks(fields, Math.ceil(Object.keys(fields).length)).map((fieldChunk) => (
          <Grid
            container
            columns={1}
            key={`${fieldChunk.code}fc`}
            sx={{ width: '100%', height: 'min-content' }}
          >
            {fieldChunk.slice(0, Math.ceil(fieldChunk.length / 1)).map((field) => {
              const { showOnlyForRoles } = field?.additional_data ?? {};

              if (!isEmpty(showOnlyForRoles) && !showOnlyForRoles?.includes(user?.role?.code)) {
                return <React.Fragment key={`${field.code + field.type}__hidden`} />;
              }
              return handleFieldType(field);
            })}
          </Grid>
        ))
      ) : (
        <></>
      )}
    </>
  );
};
