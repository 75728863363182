import React from 'react';
import { Link, LinkProps, useHistory, useLocation } from 'react-router-dom';

const LinkComponent = (props: LinkProps) => {
  const HISTORY = useHistory();
  const LOCATION = useLocation();

  const { to: destination } = props;

  return (
    <Link
      {...props}
      onClick={(e) => {
        e.preventDefault();

        if (destination !== LOCATION.pathname) {
          HISTORY.push(`${destination}`);
        }
      }}
    />
  );
};

export default LinkComponent;
