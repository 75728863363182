import { createContext } from 'react';

/// types
import { INotesContextType, INotesStore } from './types';

export const initialNotesData: INotesStore = {
  data: [],
  loading: true,
  loaded: false,
  selectedNoteRow: undefined,
  activeNote: {
    data: {},
    loading: true,
    loaded: false,
  },
  pagination: {
    ordering: '',
    page: 1,
    page_size: 20,
    search: '',
    total_count: 0,
  },
};

export const notesContextDefault: INotesContextType = {
  ...initialNotesData,
  resetNotesState: () => {},
  fetch: () => new Promise(() => {}),
  fetchNote: () => new Promise(() => {}),
  createNote: () => new Promise(() => {}),
  deleteNote: () => new Promise(() => {}),
  reFetchData: () => new Promise(() => {}),
  updateNote: () => new Promise(() => {}),
};

export const NotesContext = createContext<INotesContextType>(null!);
