import { KeyboardArrowDown } from '@mui/icons-material';
import {
  AppBar,
  Box,
  Button,
  ClickAwayListener,
  MenuItem,
  Skeleton,
  Stack,
  Toolbar,
  Typography,
} from '@mui/material';
import logoSecondary from 'assets/images/logo-secondary.svg';
import { environmentChips } from 'common/constants';
import SearchInput from 'components/Header/global-search-input';
import MenuComponent from 'components/MenuComponent';
import useAuth from 'hooks/useAuth';
import useConfig from 'hooks/useConfig';
import useUser from 'hooks/useUser';
import React, { FC, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Link, useHistory } from 'react-router-dom';

const Header: FC = () => {
  const HISTORY = useHistory();
  const { formatDateInTimeZone } = useConfig();
  const { signOutSSO } = useAuth();
  const { t } = useTranslation();

  const accountSubMenu = [
    { key: 'settings', title: t('Settings'), route: '/settings' },
    { key: 'logout', title: t('Log out') },
  ];

  const { data: user, loading } = useUser();

  const handleAccountMenuLink = (route: string | undefined) => {
    if (route) {
      HISTORY.push(route);
    } else {
      signOutSSO();
    }
  };

  const [isclickeduser, setIsclickeduser] = useState(false);

  const [openUser, setOpenUser] = useState(false);

  const handleClickAwayUser = () => {
    setIsclickeduser(false);
    setOpenUser(false);
  };

  const handleClickUser = () => {
    setOpenUser((prev) => !prev);
  };

  return (
    <>
      <AppBar
        sx={{
          backgroundColor: (theme) => theme.palette.background.default,
          position: {
            xs: 'fixed',
            md: 'fixed',
          },
          display: {
            xs: 'none',
            md: 'none',
            lg: 'flex',
          },
          boxShadow: 'none',
          // zIndex: (theme) => theme.appBarZIndex,
          '& > .MuiToolbar-root': {
            minHeight: (theme) => `${theme.appBarHeight}px`,
            padding: 0,
          },
        }}
        id="headerDesktop"
      >
        <Toolbar
          sx={{
            paddingLeft: {
              xs: 0,
              md: 2,
            },
            py: 1,
            alignItems: 'center',
            maxHeight: (theme) => `${theme.appBarHeight}px`,
            backgroundColor: (theme) => theme.customColors.topNavBar.bg,
          }}
        >
          <Box
            sx={{
              flex: 1,
              display: 'flex',
              alignItems: 'center',
              gap: '16px',
              '& a': {
                display: 'flex',
                ml: 2,
                my: 1.25,
                width: 65,
              },
            }}
          >
            <Link to="/">
              <Box
                component="img"
                src={logoSecondary}
                sx={{
                  width: '100%',
                  height: '100%',
                }}
              />
            </Link>
            {environmentChips[import.meta.env.MODE] && (
              <Box
                sx={{
                  height: '22px',
                  paddingInline: '10px',
                  lineHeight: '18px',
                  borderRadius: '4px',
                  display: 'flex',
                  alignItems: 'center',
                  backgroundColor: (theme) =>
                    theme.customColors.environmentChipColor[import.meta.env.MODE],
                }}
              >
                {environmentChips[import.meta.env.MODE]}
              </Box>
            )}
          </Box>
          <Box sx={{ flex: 1 }}>
            <SearchInput />
          </Box>

          <Stack direction="row" sx={{ flex: 1, justifyContent: 'flex-end', py: 0 }}>
            <MenuComponent
              renderDefaultIcon={false}
              titleMenu
              margintop="36px"
              controlOpenMenu
              openMenu={openUser}
              left="16px"
              width={120}
              data-test="headerMenu"
              anchor={
                <ClickAwayListener onClickAway={handleClickAwayUser}>
                  <Button
                    disableRipple
                    variant="text"
                    endIcon={
                      isclickeduser ? (
                        <KeyboardArrowDown
                          sx={{
                            pt: 0.3,
                            color: (theme) => theme.customColors.white50,
                            transform: 'rotateZ(180deg)',
                          }}
                        />
                      ) : (
                        <KeyboardArrowDown
                          sx={{
                            pt: 0.3,
                            color: (theme) => theme.customColors.white50,
                            justifySelf: 'flex-end',
                          }}
                        />
                      )
                    }
                    onClick={() => {
                      setIsclickeduser(!isclickeduser);
                      handleClickUser();
                    }}
                    sx={{
                      height: (theme) => `${theme.appBarHeight}px`,
                      minWidth: (theme) => `${theme.appBarUserMinWidth}px`,
                      maxWidth: (theme) => `${theme.appBarUserCharacterLimit}ch`,
                      width: '100%',
                      py: 0,
                      px: '10px',
                      '&:hover': {
                        bgcolor: !isclickeduser
                          ? (theme) => theme.customColors.topNavBar.itemHover
                          : (theme) => theme.customColors.topNavBar.itemClicked,
                      },
                      bgcolor: isclickeduser
                        ? (theme) => theme.customColors.topNavBar.itemClicked
                        : 'none',
                      display: {
                        xs: 'none',
                        sm: 'flex',
                      },
                      '& .MuiButton-endIcon': {
                        p: 0,
                        ml: 0,
                      },
                    }}
                  >
                    <Typography
                      data-test="headerUserInfo"
                      sx={{
                        color: (theme) => theme.customColors.white50,
                        fontSize: '16px',
                        maxWidth: (theme) => `${theme.appBarUserCharacterLimit}ch`,
                        whiteSpace: 'nowrap',
                        overflow: 'hidden',
                        textOverflow: 'ellipsis',
                        flex: 1,
                        mr: 0.5,
                      }}
                    >
                      {!loading ? (
                        user?.full_name
                      ) : (
                        <Skeleton
                          animation="wave"
                          variant="text"
                          width="11ch"
                          height={30}
                          sx={{ bgcolor: (theme) => theme.customColors.topNavBar.itemHover }}
                        />
                      )}
                    </Typography>
                  </Button>
                </ClickAwayListener>
              }
            >
              {openUser &&
                accountSubMenu.map(({ key, title, route }) => (
                  <MenuItem
                    key={key}
                    data-test={key}
                    divider={key === 'logout'}
                    onClick={() => handleAccountMenuLink(route)}
                    sx={{
                      minWidth: 120,
                      width: '100%',
                    }}
                  >
                    <Typography textAlign="center">{title}</Typography>
                  </MenuItem>
                ))}
              <MenuItem
                key="timezone"
                sx={{
                  minWidth: 120,
                  width: '100%',
                  pointerEvents: 'none',
                  cursor: 'default',
                  '& .timezone-icon>div': {
                    display: 'flex',
                    mr: 0.5,
                  },
                }}
              >
                <Box display="flex" alignItems="center">
                  <Typography fontSize={12} textAlign="center">
                    TZ: (
                    {formatDateInTimeZone(new Date().toISOString(), 'zzzz').replace('GMT', 'UTC')}){' '}
                    {Intl.DateTimeFormat().resolvedOptions().timeZone}
                  </Typography>
                </Box>
              </MenuItem>
            </MenuComponent>
          </Stack>
        </Toolbar>
      </AppBar>
    </>
  );
};

export default Header;
