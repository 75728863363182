import { TableCell, TableRow, TextField, useTheme } from '@mui/material';
import PercentageTextfield from 'components/PercentageTextfield/PercentageTextfield';
import { calculateTotalOfRow, decimalToPercentage } from 'helpers/ScheduleModificationHelpers';
import { isNaN } from 'lodash-es';
import React, { FC, useEffect } from 'react';
import { ISchedModTableRow } from './ScheduleModTable';

interface ScheduleModTableRowProps {
  rowData: ISchedModTableRow;
  updateObject: (updatedObject: ISchedModTableRow) => void;
  isEdit: boolean;
}

const ScheduleModTableRow: FC<ScheduleModTableRowProps> = ({
  rowData,
  updateObject: updateState,
  isEdit,
}) => {
  const themeHook = useTheme();

  useEffect(() => {
    const newTotal = calculateTotalOfRow(
      +rowData.pol_sched_mod_value_rule,
      +rowData.pol_sched_mod_value_uw,
      rowData.MaxDebit,
      rowData.MaxCredit,
    );
    const updatedState = { ...rowData, total: newTotal };
    updateState(updatedState);
  }, [rowData.pol_sched_mod_value_uw]);

  const handleInputChange = (event) => {
    const { name, value } = event.target;
    const updatedObject = { ...rowData, [name]: value };
    updateState(updatedObject);
  };

  const getTextColor = (value: number) => {
    if (value < 0) {
      return themeHook.customColors.successGreen;
    } else if (value > 0) {
      return themeHook.customColors.errorRed;
    }

    return themeHook.customColors.gunMetal;
  };

  return (
    <TableRow>
      <TableCell>{rowData.Category}</TableCell>
      <TableCell align="center">{decimalToPercentage(rowData.MaxCredit)}</TableCell>
      <TableCell align="center">{decimalToPercentage(rowData.MaxDebit)}</TableCell>
      <TableCell
        align="center"
        sx={{
          color: getTextColor(+rowData.pol_sched_mod_value_rule),
        }}
      >
        {rowData.pol_sched_mod_value_rule ? `${rowData.pol_sched_mod_value_rule}%` : ''}
      </TableCell>
      <TableCell
        align="center"
        sx={{
          color: getTextColor(+rowData.pol_sched_mod_value_rule),
          ...(isEdit && {
            border: (theme) => `1px solid ${theme.customColors.drawer.tableBorderColor}`,
          }),
        }}
      >
        {isEdit ? (
          <PercentageTextfield
            initialValue={rowData.pol_sched_mod_value_uw ?? ''}
            onChange={(value) => {
              const updatedObject = { ...rowData, pol_sched_mod_value_uw: value };
              updateState(updatedObject);
            }}
          />
        ) : (
          `${rowData.pol_sched_mod_value_uw}%`
        )}
      </TableCell>
      <TableCell align="center">
        {isNaN(rowData.total) ? '-' : `${rowData.total.toString()}%`}
      </TableCell>
      <TableCell
        align="left"
        padding="none"
        sx={{
          ...(isEdit && {
            border: (theme) => `1px solid ${theme.customColors.drawer.tableBorderColor}`,
          }),
        }}
      >
        <TextField
          defaultValue={rowData.pol_sched_mod_comment}
          fullWidth
          name="pol_sched_mod_comment"
          variant="standard"
          disabled={!isEdit}
          InputProps={{
            disableUnderline: true,
            inputProps: {
              style: {
                fontWeight: '500',
              },
            },
          }}
          onChange={handleInputChange}
          sx={{
            padding: '0 8px',
            '& .MuiInputBase-input.Mui-disabled': {
              WebkitTextFillColor: (theme) => theme.customColors.gunMetal,
            },
          }}
        />
      </TableCell>
    </TableRow>
  );
};

export default ScheduleModTableRow;
