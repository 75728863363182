import { INCCIResponse } from 'api/models/Integrations/NCCI/INCCIResponse.model';
import { defaultTimezone, empXratedOptions } from 'common/constants';
import { parse } from 'date-fns';
import { zonedTimeToUtc } from 'date-fns-tz';
import { fieldHasValue, getRelevantUnderwritingInformation } from 'helpers/Utils';
import { IContext } from 'providers/types';

export const parseNcciResponse = (
  res: INCCIResponse,
  timezoneConfig: IContext<{ code: string; name: string }>,
  effectiveDate: string,
) => {
  const payload: any = {};

  payload.emp_response_code = res.RiskHeaderInformation?.ResponseCode?.toString() ?? '';
  payload.emp_response_message = res.RiskHeaderInformation?.ResponseMessage?.join('\n');

  switch (payload.emp_response_code) {
    case '0': {
      const effectiveYear = new Date(effectiveDate)?.getFullYear();

      const results = res.RatingValuesInformation ?? [];

      const activeMod = results?.find(
        (r) =>
          parse(r.RatingEffectiveDate!, 'MM-dd-yyyy', new Date())?.getFullYear() === effectiveYear,
      );
      const previousActiveMod = results?.find(
        (r) =>
          parse(r.RatingEffectiveDate!, 'MM-dd-yyyy', new Date())?.getFullYear() ===
          effectiveYear - 1,
      );

      // https://dev.azure.com/radity-gmbh/THREE-insurance/_workitems/edit/11248/
      if (!activeMod && !previousActiveMod) {
        payload.emp_response_code = '';
        payload.emp_response_message = '';
        payload.emp_red = undefined;
        payload.emp_xmod = undefined;
        payload.emp_xrated = empXratedOptions.NOT_FOUND;

        return payload;
      }

      // Underwriting success responses has variations. Find the relevant variation using business logic.
      // Check task link for details: https://dev.azure.com/radity-gmbh/THREE-insurance/_workitems/edit/13681/
      const underwritingInformationResult = getRelevantUnderwritingInformation(
        results,
        effectiveDate,
      );

      if (
        underwritingInformationResult?.RatingTypeCode === 'E' &&
        underwritingInformationResult?.RatingEffectiveDate
      ) {
        payload.emp_xrated = empXratedOptions.SUCCESS_E;
        payload.emp_xmod = underwritingInformationResult?.ExperienceModificationFactor ?? undefined;
      }

      if (
        underwritingInformationResult?.RatingTypeCode === 'E' &&
        !underwritingInformationResult?.RatingEffectiveDate
      ) {
        payload.emp_xrated = empXratedOptions.SUCCESS_E_OLD;
        payload.emp_xmod = undefined;
      }

      if (underwritingInformationResult?.RatingTypeCode !== 'E') {
        payload.emp_xrated =
          empXratedOptions[`SUCCESS_${underwritingInformationResult?.RatingTypeCode}`];
        payload.emp_xmod = undefined;
      }

      const result = activeMod ?? previousActiveMod;

      const dateRef = result?.RatingEffectiveDate;

      payload.emp_red = fieldHasValue(dateRef)
        ? zonedTimeToUtc(
            parse(dateRef!, 'MM-dd-yyyy', new Date()),
            timezoneConfig?.data?.code ?? defaultTimezone,
          )
        : undefined;

      break;
    }

    case '1': {
      payload.emp_xrated = empXratedOptions.ERROR_IN_PARAMS;
      break;
    }

    default:
      break;
  }

  return payload;
};
