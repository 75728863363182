import { PoliciesContext } from 'providers/PoliciesProvider/PoliciesProviderContext';
import { IPoliciesContextType } from 'providers/PoliciesProvider/types';
import { useContext } from 'react';

export default function usePolicies(): IPoliciesContextType {
  const context = useContext(PoliciesContext);

  /* istanbul ignore if */
  if (!context) {
    throw new Error('usePolicies must be used within a PoliciesProvider');
  }

  return context;
}
