import { createContext } from 'react';

/// types
import { IAdvancedSearchContextType, IAdvancedSearchStore } from './types';

export const initialAdvancedSearchData: IAdvancedSearchStore = {
  data: [],
  loading: true,
  loaded: false,
  pagination: {
    page: 1,
    page_size: 20,
    total_count: 0,
  },
  searchType: '',
  activeTabIndex: 0,
  activeTabType: '',
  activeTabValues: {},
};

export const advancedSearchContextDefault: IAdvancedSearchContextType = {
  ...initialAdvancedSearchData,
  resetAdvancedSearchState: () => {},
  fetch: () => new Promise(() => {}),
  setActiveTabIndex: () => {},
  setActiveTabValues: () => {},
  resetActiveTabValues: () => {},
  resetActiveTabIndex: () => {},
  setActiveTabType: () => {},
};

export const AdvancedSearchContext = createContext<IAdvancedSearchContextType>(null!);
