import { Link, Typography } from '@mui/material';
import { GridColDef, GridSortModel } from '@mui/x-data-grid';
import { useQuery } from '@tanstack/react-query';
import { getAgents } from 'api/services/Agents';
import {
  dataFieldTypes,
  defaultDateFormat,
  defaultDateTimeFormat,
  defaultDebounceDelay,
} from 'common/constants';
import DataTable from 'components/DataTable';
import PageHeader from 'components/PageHeader';
import ViewSearch from 'components/ViewSearch';
import { getNestedValueFromObject } from 'helpers/Utils';
import useConfig from 'hooks/useConfig';
import { debounce } from 'lodash-es';
import React, { createRef, useEffect, useState } from 'react';
import { Link as RouterLink } from 'react-router-dom';
import { AGENT_TABLE_CONFIG } from '../constants';

const AgentTable = ({ agencyId = null }: { agencyId: null | string }) => {
  const [searchValue, setSearchValue] = useState<string>('');
  const [pageNumber, setPage] = useState(0);
  const [sortModel, setSortModel] = React.useState<GridSortModel>([
    { field: 'full_name', sort: 'asc' },
  ]);
  const {
    data: agents,
    isLoading,
    isFetching,
    refetch,
  } = useQuery({
    queryKey: ['agents'],
    queryFn: () => {
      const searchParams: {
        search?: string;
        agency_id?: string;
      } = {};
      if (searchValue) {
        searchParams.search = encodeURIComponent(searchValue);
      }
      if (agencyId) {
        searchParams.agency_id = agencyId;
      }
      return getAgents(searchParams);
    },
  });

  const { formatDateInTimeZone } = useConfig();
  useEffect(() => {
    refetch();
  }, [searchValue]);

  const searchInputRef = createRef<HTMLInputElement>();

  const agentListColumns: GridColDef[] = AGENT_TABLE_CONFIG.map((field) => ({
    ...field,
    renderCell: (params) => {
      const fieldValue = getNestedValueFromObject(params.row, field.field);

      if (field.field === 'full_name') {
        return (
          <Link
            component={RouterLink}
            to={`/agents/${params.row.id}`}
            underline="hover"
            sx={{
              color: (theme) => theme.customColors.table.link,
              textOverflow: 'ellipsis',
              overflow: 'hidden',
              whiteSpace: 'nowrap',
            }}
            title={fieldValue}
          >
            {fieldValue || '-'}
          </Link>
        );
      } else if (field.type === dataFieldTypes.DATE) {
        return fieldValue
          ? formatDateInTimeZone(fieldValue, `${defaultDateFormat} ${defaultDateTimeFormat}`)
          : '-';
      } else if (field.field === 'email') {
        return (
          <Link
            href={`mailto:${fieldValue}`}
            underline="hover"
            sx={{
              color: (theme) => theme.customColors.table.link,
              textOverflow: 'ellipsis',
              overflow: 'hidden',
              whiteSpace: 'nowrap',
            }}
            title={fieldValue}
          >
            {fieldValue || '-'}
          </Link>
        );
      } else {
        return (
          <Typography
            sx={{
              textOverflow: 'ellipsis',
              overflow: 'hidden',
              whiteSpace: 'nowrap',
            }}
            title={fieldValue}
          >
            {fieldValue || '-'}
          </Typography>
        );
      }
    },
  }));

  const handleSortModelChange = (newModel: GridSortModel) => {
    setSortModel(newModel);
    setPage(0);
  };

  return (
    <>
      <PageHeader
        sx={{ px: 0, pt: 0 }}
        containerSx={{ mt: 0 }}
        left={
          <Typography
            variant="h6"
            sx={{
              color: (theme) => theme.customColors.pageHeader.title,
              fontWeight: '500',
            }}
          >
            Agents {agents && agents.length ? `(${agents.length})` : ''}
          </Typography>
        }
        right={
          <ViewSearch
            onChange={debounce((event) => setSearchValue(event.target.value), defaultDebounceDelay)}
            inputRef={searchInputRef}
          />
        }
      />

      <DataTable
        isHeaderWidthFit
        loading={isLoading || isFetching}
        columns={agentListColumns}
        rows={agents ?? []}
        rowCount={agents?.length}
        paginationModel={{ page: pageNumber, pageSize: 20 }}
        pageSize={20}
        onPageChange={(page) => {
          setPage(page);
        }}
        sortingMode="client"
        paginationMode="client"
        hideFooterPagination={agents && agents?.length <= 20}
        sortModel={sortModel}
        onSortModelChange={handleSortModelChange}
      />
    </>
  );
};
export default AgentTable;
