import { RadioGroup, Stack, Typography } from '@mui/material';
import OriginalSuggestedLocationBox from 'components/QuotePolicyDetailEndorsement/Location/OriginalSuggestedLocationsBox';
import { createAddressInfo, formatLocation } from 'helpers/Utils';
import React, { FC, useState } from 'react';
import { useTranslation } from 'react-i18next';

interface LocationDrawerValidationContentProps {
  addressComparisonResult: any;
  selectedAddressVariant: string;
  setSelectedAddressVariant: any;
}

const LocationDrawerValidationContent: FC<LocationDrawerValidationContentProps> = ({
  addressComparisonResult,
  selectedAddressVariant,
  setSelectedAddressVariant,
}) => {
  const { t } = useTranslation();

  const [selectedRadioButton, setSelectedRadioButton] = useState('');

  const originalAdress = createAddressInfo(
    addressComparisonResult?.differenceAddressParts,
    'original',
  );
  const verifiedAdress = createAddressInfo(
    addressComparisonResult?.differenceAddressParts,
    'verified',
  );

  return (
    <Stack flexDirection="column" spacing={3}>
      <Typography>
        {t(
          'We couldn’t recognize the address exactly as entered. Please confirm the verified address.',
        )}
      </Typography>
      <RadioGroup
        aria-labelledby="controlled-radio-buttons-location"
        name="controlled-radio-buttons-location"
        value={selectedAddressVariant}
        sx={{
          '& .MuiFormControlLabel-root': {
            alignItems: 'flex-start',
            mx: 0,
            minHeight: 110,
            border: (theme) => `1px solid ${theme.customColors.paleSlate}`,
            borderRadius: 0.5,
            '& .MuiRadio-root': {
              mt: '17px',
              mx: 2,
            },
            '& .Mui-checked': {
              mt: '17px',
              mx: 2,
            },
            '& .MuiFormControlLabel-label': {
              my: '15px',
            },
          },
          '.radio-button-checked': {
            border: (theme) => `1px solid ${theme.customColors.copper}`,
          },
          gap: 2,
        }}
        onChange={(event) => {
          setSelectedAddressVariant(event.target.value);
          setSelectedRadioButton(event.target.name);
        }}
      >
        <OriginalSuggestedLocationBox
          value={
            formatLocation({
              ...originalAdress,
            }).storing
          }
          address={addressComparisonResult.differenceAddressParts}
          inputProps={{ name: 'original' }}
          isChecked={selectedRadioButton === 'original'}
        />

        <OriginalSuggestedLocationBox
          value={
            formatLocation({
              ...verifiedAdress,
            }).storing
          }
          isSuggestedLocation
          address={addressComparisonResult.differenceAddressParts}
          inputProps={{ name: 'verified' }}
          isChecked={selectedRadioButton === 'verified'}
        />
      </RadioGroup>
    </Stack>
  );
};

export default LocationDrawerValidationContent;
