import ApiService, { Params } from 'api/helpers/Sender';
import {
  IAuxDataPrometrix,
  IPrometrixData,
} from 'api/models/Integrations/Prometrix/addressRequest.model';
import { Quote } from 'api/models/Quote/quote.model';
import { QuoteDetailResponse } from 'api/models/Quote/quoteDetailResponse.model';
import { QuoteDocumentCreate } from 'api/models/Quote/QuoteDocumentCreate.model';
import { QuoteDocuments } from 'api/models/Quote/quoteDocuments.model';
import { QuoteDocumentsRetrieve } from 'api/models/Quote/QuoteDocumentsRetrieve.model';
import { QuoteEditDocuments } from 'api/models/Quote/QuoteEditDocuments.model';
import { QuoteExposureDetail } from 'api/models/Quote/quoteExposureDetail.model';
import { QuoteExposuresList } from 'api/models/Quote/quoteExposuresList.model';
import { QuoteInvoicesList } from 'api/models/Quote/quoteInvoicesList.model';
import { QuotePrice } from 'api/models/Quote/quotePrice.model';
import { QuoteProducer } from 'api/models/Quote/quoteProducer.model';
import { ProgressData, QuoteProgress } from 'api/models/Quote/quoteProgress.model';
import { QuotesFilteredChips } from 'api/models/Quote/QuotesFilteredChips.model';
import { Task } from 'api/models/Tasks/task.model';
import { ITaskPagination } from 'providers/TasksProvider/types';
import { Pagination, PaginationResponse } from 'types/Pagination';

const API = new ApiService();

export const getQuotes = async (query: Pagination): Promise<PaginationResponse<Quote[]>> => {
  const { data } = await API.get('quotes', query as Params);
  return data;
};

export const getThreeSubmissionsStatus = async (): Promise<QuotesFilteredChips[]> => {
  const { data } = await API.get(`quotes/get-quote-count-and-status`);
  return data;
};

export const getQuoteDetail = async (
  locator: string,
  query?: Params,
): Promise<QuoteDetailResponse> => {
  const { data } = await API.get(`quotes/${locator}`, query);
  return data;
};

export const lockQuoteRequest = async (locator: string): Promise<any> => {
  const { data } = await API.post(`quotes/${locator}/lock`);
  return data;
};

export const referQuoteRequest = async (locator: string): Promise<any> => {
  const { data } = await API.post(`quotes/${locator}/refer`);
  return data;
};

export const acceptQuoteRequest = async (locator: string): Promise<any> => {
  const { data } = await API.post(`quotes/${locator}/accept`);
  return data;
};

export const invalidateQuoteRequest = async (locator: string): Promise<any> => {
  const { data } = await API.post(`quotes/${locator}/invalidate`);
  return data;
};

export const discardQuoteRequest = async (locator: string): Promise<any> => {
  const { data } = await API.post(`quotes/${locator}/discard`);
  return data;
};

export const issueQuoteRequest = async (locator: string): Promise<any> => {
  const { data } = await API.post(`quotes/${locator}/issue`);
  return data;
};

export const cloneQuoteRequest = async (locator: string): Promise<Quote> => {
  const { data } = await API.post(`quotes`, { quote_locator: locator });
  return data;
};

export const getQuotesExposuresWithLocator = async (
  quotelocator: string,
  query: Pagination,
): Promise<PaginationResponse<QuoteExposuresList[]>> => {
  const { data } = await API.get(`quotes/${quotelocator}/exposures`, query as Params);
  return data;
};

export const getQuoteExposureWithLocator = async (
  policylocator: string,
  exposurelocator: string,
): Promise<QuoteExposureDetail> => {
  const { data } = await API.get(`policies/${policylocator}/exposures/${exposurelocator}`);
  return data;
};

export const getQuotePriceRequest = async (quotelocator: string): Promise<QuotePrice> => {
  const { data } = await API.get(`quotes/${quotelocator}/price`);
  return data;
};

export const getQuotesDcoumentsWithLocator = async (
  quotelocator: string,
  query: Pagination,
): Promise<PaginationResponse<QuoteDocuments[]>> => {
  const { data } = await API.get(`quotes/${quotelocator}/all-documents`, query as Params);
  return data;
};

export const addDocumentService = async (
  reference_type: string,
  reference_locator: string,
  formData: FormData,
  onUploadProgress?: (progressEvent: any) => void,
  controller?: AbortController,
): Promise<QuoteDocumentCreate> => {
  const { data } = await API.post(
    `${reference_type}/${reference_locator}/quote-documents`,
    formData,
    {
      headers: {
        'Content-Type': 'multipart/form-data',
      },
      onUploadProgress,
      signal: controller?.signal,
    },
  );
  return data;
};

export const updateDocumentWithLocator = async (
  quoteId: string,
  documentLocator: string,
  body: QuoteEditDocuments,
): Promise<QuoteDocumentsRetrieve> => {
  const { data } = await API.patch(`quotes/${quoteId}/quote-documents/${documentLocator}`, body);
  return data;
};

export const deleteDocumentService = async (quoteId: string, documentLocator: string) => {
  const { data } = await API.delete(`quotes/${quoteId}/quote-documents/${documentLocator}`);
  return data;
};

export const quotesDocumentsWithLocator = async (
  quoteLocator: string,
  documentLocator: string,
): Promise<QuoteDocumentsRetrieve> => {
  const { data } = await API.get(`quotes/${quoteLocator}/all-documents/${documentLocator}`);
  return data;
};

export const getQuotesTasksWithLocator = async (
  quotelocator: string,
  query: ITaskPagination,
): Promise<PaginationResponse<Task[]>> => {
  const { data } = await API.get(`quotes/${quotelocator}/tasks`, query as Params);
  return data;
};

export const getQuotesInvoicesWithLocator = async (
  quotelocator: string,
  reference_type: string,
  query: Pagination,
): Promise<PaginationResponse<QuoteInvoicesList[]>> => {
  const newParams = { ...query, type: reference_type };
  const { data } = await API.get(`quotes/${quotelocator}/invoices`, newParams as Params);
  return data;
};

export const declineQuoteRequest = async (locator: string): Promise<QuoteDetailResponse> => {
  const { data } = await API.post(`quotes/${locator}/decline`);
  return data;
};

export const sendBackQuoteRequest = async (locator: string): Promise<QuoteDetailResponse> => {
  const { data } = await API.post(`quotes/${locator}/cancel-referral`);
  return data;
};

export const getQuoteProgressRequest = async (locator: string): Promise<QuoteProgress> => {
  const { data } = await API.get(`quotes/${locator}/aux-data/progress-data`);

  return data;
};

export const setQuoteProgressRequest = async (
  locator: string,
  body: ProgressData,
): Promise<ProgressData> => {
  const { data } = await API.put(`quotes/${locator}/aux-data/progress-data`, body);

  return data;
};

export const getQuotePrometrixResultsRequest = async (
  locator: string,
): Promise<IAuxDataPrometrix> => {
  const { data } = await API.get(`quotes/${locator}/aux-data/prometrix-data`);

  return data;
};

export const setQuotePrometrixResultsRequest = async (
  locator: string,
  body: IPrometrixData,
): Promise<IPrometrixData> => {
  const { data } = await API.put(`quotes/${locator}/aux-data/prometrix-data`, body);

  return data;
};

export const updateQuoteProducerRequest = async (
  locator: string,
  body: { producer: string },
): Promise<QuoteProducer> => {
  const { data } = await API.patch(`quotes/${locator}/update-owner`, body);

  return data;
};
