/* eslint-disable no-shadow */
import { KeyboardArrowDownRounded } from '@mui/icons-material';
import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogTitle,
  FormControl,
  FormHelperText,
  Grid,
  InputLabel,
  Skeleton,
  Typography,
} from '@mui/material';
import { createEndorsement, updateEndorsement } from 'api/services/PolicyEndorsement';
import CalendarSchedule from 'assets/images/CalendarSchedule.svg';
import {
  defaultDateFormat,
  policyDetailEndorsementTabs,
  THREE_EndorsementTypes,
  wcAuditSubTypes,
} from 'common/constants';
import CustomNativeSelect from 'components/CustomNativeSelect';
import DatePickerComponent from 'components/DatePickerComponent';
import { useFormik } from 'formik';
import displayBackendErrorMessage from 'helpers/displayBackendErrorMessage';
import {
  calendarIconStyle,
  primaryButtonStyle,
  secondaryButtonStyle,
} from 'helpers/MuiSharedStyles';
import ScrollToFormikError from 'helpers/ScrollToFormikError';
import { deleteFromQueryStrings } from 'helpers/Utils';
import useLoader from 'hooks/useLoader';
import usePolicyDetail from 'hooks/usePolicyDetail';
import React, { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory, useLocation, useParams } from 'react-router-dom';
import { ReactSVG } from 'react-svg';
import * as yup from 'yup';

interface ICreateWcAuditEndorsementProps {
  isModalOpen: boolean;
  setIsModalOpen: (state: boolean) => void;
}

export const CreateWcAuditEndorsement: FC<ICreateWcAuditEndorsementProps> = ({
  isModalOpen,
  setIsModalOpen,
}) => {
  const { t } = useTranslation();
  const HISTORY = useHistory();
  const LOCATION = useLocation();
  const { setLoading } = useLoader();
  const { id } = useParams<{ id: string }>();
  const { data, loading } = usePolicyDetail();

  const validationSchema = yup.object({
    audit_type: yup.string().required(t('This field may not be blank.')),
  });

  const formik = useFormik<any>({
    initialValues: {
      audit_type: '',
    },
    validationSchema,
    onSubmit: async (values) => {
      if (formik.isValid) {
        try {
          setLoading(true);

          const goToTab = policyDetailEndorsementTabs.WC_EXPOSURES.code;

          const endorsement = await createEndorsement(id, {
            endorsement_type: THREE_EndorsementTypes.WC_AUDIT,
            endorsement_start_at: data?.original_contract_start_time,
          });

          await updateEndorsement(
            id,
            endorsement.locator!,
            { data: { pol_wc_audit_type: values.audit_type } },
            { validate: 'pol_wc_audit_type' },
          );

          setIsModalOpen(false);

          HISTORY.push(
            `/policies/${endorsement.policy?.locator}/endorsement/${endorsement.locator}/overview?tab=${goToTab}`,
          );
        } catch (error) {
          displayBackendErrorMessage(error, t('An error occurred while creating WC Audit.'));
        } finally {
          setLoading(false);
        }
      }
    },
  });

  const handleClose = () => {
    HISTORY.push({
      search: deleteFromQueryStrings({
        locationSearch: LOCATION.search,
        omitKeys: ['createWcAudit'],
      }),
    });
    setIsModalOpen(false);
  };

  return (
    <Dialog
      open={isModalOpen}
      onClose={() => {
        handleClose();
      }}
      sx={{
        '& .MuiDialog-paper': {
          borderRadius: 0,
          px: 7.5,
          py: 7.5,
          minWidth: { xs: '400px', sm: '500px', md: '616px' },
          maxWidth: '616px',
        },
      }}
    >
      <ScrollToFormikError formik={formik} />
      <form
        id="createRenewalForm"
        name="createRenewalForm"
        noValidate
        onSubmit={formik.handleSubmit}
      >
        <Box sx={{ maxWidth: '616px' }}>
          <DialogTitle
            id="dialog-title"
            sx={{ p: 0, m: 0, mb: 3, display: 'flex', alignItems: 'flex-start' }}
          >
            <Typography
              sx={{
                fontSize: 20,
                fontWeight: 500,
                color: (theme) => theme.customColors.black,
              }}
            >
              {t('WC Audit')}
            </Typography>
          </DialogTitle>

          <Grid container gap={2} columnSpacing={2} mt={2} sx={{ display: 'grid' }}>
            <Grid item xs={12}>
              <FormControl size="small" variant="standard" fullWidth sx={{ ...calendarIconStyle }}>
                {loading ? (
                  <Skeleton animation="wave" width="100%" height={37} />
                ) : (
                  <DatePickerComponent
                    format={defaultDateFormat}
                    onChange={(_newValue) => {}}
                    value={data?.original_contract_start_time}
                    label={t('Effective Date')}
                    disabled
                    slots={{
                      openPickerIcon: () => (
                        <ReactSVG className="calendar-icon" src={CalendarSchedule} />
                      ),
                    }}
                    slotProps={{
                      textField: {
                        autoComplete: 'off',
                        inputProps: {
                          autoComplete: 'new-password',
                        },
                        error:
                          formik.touched.endorsement_start_at &&
                          Boolean(formik.errors.endorsement_start_at),
                        helperText:
                          formik.touched.endorsement_start_at && formik.errors.endorsement_start_at,
                        size: 'small',
                      },
                    }}
                  />
                )}
              </FormControl>
            </Grid>
            <Grid item xs={12}>
              <FormControl required size="small" fullWidth>
                <>
                  <InputLabel
                    error={formik.touched.audit_type && Boolean(formik.errors.audit_type)}
                  >
                    {t('Type of Audit')}
                  </InputLabel>
                  <CustomNativeSelect
                    label={t('Type of Audit')}
                    variant="outlined"
                    name="audit_type"
                    value={formik.values.audit_type}
                    error={formik.touched.audit_type && Boolean(formik.errors.audit_type)}
                    onChange={formik.handleChange}
                    IconComponent={KeyboardArrowDownRounded}
                  >
                    {Object.values(wcAuditSubTypes)?.map((type) => (
                      <option key={type.code} value={type.code}>
                        {type.name}
                      </option>
                    ))}
                  </CustomNativeSelect>
                </>
                {formik.touched.audit_type && formik.errors.audit_type && (
                  <FormHelperText
                    sx={{
                      mt: 1,
                      fontSize: '12px',
                      lineHeight: '14px',
                      color: (theme) => theme.customColors.alert,
                    }}
                  >
                    {formik.errors.audit_type}
                  </FormHelperText>
                )}
              </FormControl>
            </Grid>
          </Grid>

          <DialogActions sx={{ padding: (theme) => theme.spacing(2, 0, 0, 1), m: 0 }}>
            <Button onClick={handleClose} sx={{ ...secondaryButtonStyle }}>
              {t('Cancel')}
            </Button>

            <Button
              disabled={loading}
              type="submit"
              form="createRenewalForm"
              color="dialogInfo"
              sx={{ ...primaryButtonStyle, ml: '0 !important' }}
            >
              {t('Continue')}
            </Button>
          </DialogActions>
        </Box>
      </form>
    </Dialog>
  );
};
