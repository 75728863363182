import ApiService, { Params } from 'api/helpers/Sender';
import {
  IPolicyHoldersPagination,
  PolicyholderCreateRequest,
  PolicyholderPageResponse,
  PolicyholderResponse,
} from 'api/models/Policyholder/policyholder.model';

const API = new ApiService();

export const updatePolicyholderWithLocator = async (
  body: PolicyholderCreateRequest,
  policyholderlocator: string,
): Promise<PolicyholderResponse> => {
  const { data } = await API.put(`policyholders/${policyholderlocator}`, body);
  return data;
};

export const getPolicyholders = async (
  query: IPolicyHoldersPagination,
): Promise<PolicyholderPageResponse> => {
  const { data } = await API.get('policyholders', query as Params);
  return data;
};
