import { Box, Button, Typography, useTheme } from '@mui/material';
import { GridColDef } from '@mui/x-data-grid';
import Download from 'assets/images/icon_dropdowndownload.svg';
import { userRoles } from 'common/constants';
import DataTable from 'components/DataTable';
import { currencyFormat, getNestedValueFromObject, updateQueryStrings } from 'helpers/Utils';
import useConfig from 'hooks/useConfig';
import usePolicyDetail from 'hooks/usePolicyDetail';
import useUser from 'hooks/useUser/useUser';
import { isEmpty, startCase } from 'lodash-es';
import React, { FC, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory, useLocation } from 'react-router-dom';
import { IColumns } from 'routes/Policies/PolicyDetail/Billings/Billings';

interface IBillingTableProps {
  activeChip: string;
}

const InvoicesTable: FC<IBillingTableProps> = ({ activeChip }) => {
  const { t } = useTranslation();
  const themeHook = useTheme();
  const { invoices, groupedInvoices, billingPaymentInfo } = usePolicyDetail();
  const { formatDateInTimeZone } = useConfig();
  const HISTORY = useHistory();
  const LOCATION = useLocation();

  const { data: user } = useUser();

  const isUnderwriter = useMemo(() => user?.role?.code === userRoles.UNDERWRITER.code, [user]);

  const renderRows = () => {
    if (activeChip === 'all invoices') {
      return invoices?.data;
    } else if (
      groupedInvoices &&
      !isEmpty(groupedInvoices) &&
      groupedInvoices[`${activeChip}`]?.length
    ) {
      return groupedInvoices[`${activeChip}`];
    }

    return [];
  };

  const openWaiveDialog = (invoiceId: string) => {
    HISTORY.push({
      search: updateQueryStrings({
        locationSearch: LOCATION.search,
        newQueries: { waive: invoiceId },
      }),
    });
  };

  const columns: IColumns[] = [
    {
      name: 'display_id',
      headerName: t('Invoice No'),
      minWidth: 100,
      flex: 1,
      type: 'string',
      is_hidden: false,
      is_sortable: false,
      is_link: false,
      link_type: null,
      align: 'left',
      headerAlign: 'left',
    },
    {
      name: 'document_download',
      headerName: t(''),
      minWidth: 100,
      flex: 0.1,
      type: 'action',
      is_hidden: false,
      is_sortable: false,
      is_link: false,
      link_type: null,
      align: 'right',
      headerAlign: 'right',
    },
    {
      name: 'invoice_type',
      headerName: t('Invoice Type'),
      minWidth: 110,
      flex: 1,
      type: 'string',
      is_hidden: false,
      is_sortable: true,
      is_link: false,
      link_type: null,
      align: 'left',
      headerAlign: 'left',
    },
    {
      name: 'issue_time',
      headerName: t('Date Created'),
      minWidth: 110,
      flex: 1,
      type: 'date',
      is_hidden: false,
      is_sortable: true,
      is_link: false,
      link_type: null,
      align: 'left',
      headerAlign: 'left',
    },
    {
      name: 'due_time',
      headerName: t('Due Date'),
      minWidth: 110,
      flex: 1,
      type: 'date',
      is_hidden: false,
      is_sortable: true,
      is_link: false,
      link_type: null,
      align: 'left',
      headerAlign: 'left',
    },
    {
      name: 'premium',
      headerName: t('Premium'),
      minWidth: 110,
      flex: 1,
      is_hidden: false,
      is_sortable: false,
      is_link: false,
      link_type: null,
      align: 'right',
      headerAlign: 'right',
      type: 'number',
    },
    {
      name: 'fee',
      headerName: t('Fees'),
      minWidth: 110,
      flex: 1,
      is_hidden: false,
      is_sortable: false,
      is_link: false,
      link_type: null,
      align: 'right',
      headerAlign: 'right',
      type: 'number',
    },
    {
      name: 'total_due',
      headerName: t('Total'),
      minWidth: 110,
      flex: 1,
      is_hidden: false,
      is_sortable: false,
      is_link: false,
      link_type: null,
      align: 'right',
      headerAlign: 'right',
      type: 'number',
    },
    {
      name: 'settlement_status',
      headerName: t('Status'),
      minWidth: 330,
      flex: 2,
      is_hidden: false,
      is_sortable: false,
      is_link: false,
      link_type: null,
      align: 'left',
      headerAlign: 'left',
      type: 'string',
    },
  ];

  const editedColumns: GridColDef[] = columns.map((column) => ({
    field: column.name,
    headerName: column.headerName,
    flex: column.flex,
    minWidth: column.minWidth,
    sortable: column.is_sortable,
    align: column.align,
    headerAlign: column.headerAlign,
    renderCell: (params) => {
      const fieldValue = getNestedValueFromObject(params.row, column.name);
      const isFeeInvoice = params.row.invoice_type === 'other';
      const isFutureInvoice = params.row.future_invoice;

      if (column.name === 'document_download') {
        const document = getNestedValueFromObject(params.row, 'document');
        const { url = undefined } = document?.[0] ?? {};

        return url ? (
          <Box
            component="img"
            src={Download}
            sx={{ width: 24, height: 24, '&:hover': { cursor: 'pointer' } }}
            onClick={() => window.open(url, '_blank')}
          />
        ) : null;
      } else if (column.name === 'issue_time') {
        if (params.row.display_id === 'Scheduled') {
          return '-';
        }
        return formatDateInTimeZone(fieldValue);
      } else if (column.type === 'date') {
        return formatDateInTimeZone(fieldValue);
      } else if (column.type === 'number') {
        if (column.name === 'premium') {
          if (isFeeInvoice) {
            return '-';
          }
          return currencyFormat(undefined, fieldValue).merged || '-';
        }
        if (column.name === 'fee') {
          if (isFeeInvoice) {
            return currencyFormat(undefined, params.row.total_due).merged || '-';
          }
          if (isFutureInvoice) {
            return currencyFormat(undefined, params.row.fee).merged || '-';
          }
          return '-';
        }
        return currencyFormat(undefined, fieldValue).merged || '-';
      } else if (column.name === 'invoice_type') {
        const invoiceLocator: string = getNestedValueFromObject(params.row, 'locator');
        const isPaidWaive = params.row.settlement_status?.key === 'paid';
        const isWaive = params.row.is_waive === true;
        const canWaive = isFeeInvoice && isUnderwriter && !isPaidWaive && !isWaive;

        return (
          <Box display="flex">
            {isFeeInvoice ? (
              <Typography>{t('Fee')}</Typography>
            ) : (
              <Typography>
                {fieldValue === '-' ? t('Installment') : startCase(fieldValue)}
              </Typography>
            )}
            {canWaive && (
              <Button
                disableRipple
                onClick={() => openWaiveDialog(invoiceLocator)}
                sx={{
                  color: (theme) => theme.customColors.copper,
                  m: 0,
                  p: 0,
                  fontSize: '12px',
                  '&:hover': { textDecoration: 'underline', backgroundColor: 'transparent' },
                }}
              >
                {t('Waive')}
              </Button>
            )}
          </Box>
        );
      } else if (column.name === 'settlement_status') {
        const stripePaymentStatuses = getNestedValueFromObject(
          params.row,
          'stripe_payment_statuses',
        );
        const isPaid = params.row.settlement_status?.key === 'paid';
        const isStripePaid = stripePaymentStatuses.some((s) => s.startsWith('Paid'));
        const isWaive = params.row.is_waive === true;

        return (
          <>
            {fieldValue &&
              (isPaid && isStripePaid ? null : (
                <Box
                  sx={{
                    mr: 1,
                    py: 0.5,
                    px: 1.5,
                    fontSize: '12px',
                    borderRadius: '4px',
                    color: 'white',
                    fontWeight: 500,
                    backgroundColor: () =>
                      themeHook.customColors.invoiceStatusColor[params.row.status.key] ||
                      themeHook.customColors.black,
                  }}
                >
                  {params.row.status.value ?? ''}
                </Box>
              ))}
            {fieldValue && isWaive && (
              <Box
                sx={{
                  mr: 1,
                  py: 0.5,
                  px: 1.5,
                  fontSize: '12px',
                  borderRadius: '4px',
                  color: 'white',
                  fontWeight: 500,
                  backgroundColor: () => themeHook.customColors.invoiceStatusColor.waived,
                }}
              >
                {t('Waived')}
              </Box>
            )}

            {stripePaymentStatuses?.[0] ? (
              <Box
                sx={{
                  mr: 1,
                  py: 0.5,
                  px: 1.5,
                  fontSize: '12px',
                  borderRadius: '4px',
                  color: 'white',
                  fontWeight: 500,
                  backgroundColor: () =>
                    themeHook.customColors.invoiceStatusColor[isPaid ? 'paid' : 'chargeback'],
                }}
              >
                {stripePaymentStatuses[0]}
              </Box>
            ) : null}
          </>
        );
      } else {
        return fieldValue || '-';
      }
    },
  }));

  return (
    <Box sx={{ px: 3, py: 2 }}>
      <DataTable
        initialState={{
          sorting: {
            sortModel: [{ field: 'due_time', sort: 'asc' }],
          },
        }}
        rows={renderRows()}
        columns={editedColumns}
        loading={invoices?.loading && billingPaymentInfo.loading}
      />
    </Box>
  );
};

export default InvoicesTable;
