import { NotesContext } from 'providers/NotesProvider/NotesProviderContext';
import { INotesContextType } from 'providers/NotesProvider/types';
import { useContext } from 'react';

export default function useNotes(): INotesContextType {
  const context = useContext(NotesContext);

  /* istanbul ignore if */
  if (!context) {
    throw new Error('useNotes must be used within a NotesProvider');
  }

  return context;
}
