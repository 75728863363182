import { EndorsementDetailContext } from 'providers/EndorsementDetailProvider/EndorsementDetailProviderContext';
import { IEndorsementDetailContextType } from 'providers/EndorsementDetailProvider/types';
import { useContext } from 'react';

export default function useEndorsementDetail(): IEndorsementDetailContextType {
  const context = useContext(EndorsementDetailContext);

  /* istanbul ignore if */
  if (!context) {
    throw new Error('useEndorsementDetail must be used within a EndorsementDetailProvider');
  }

  return context;
}
