import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import WarningAmberIcon from '@mui/icons-material/WarningAmber';
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  List,
  ListItem,
  Typography,
} from '@mui/material';
import { PolicyFieldError } from 'api/models/Socotra/PolicyFieldErrorResponse/policyFieldError.model';
import { errorCodes, underWritingQuestionsPageNames } from 'common/constants';
import { primaryButtonStyle } from 'helpers/MuiSharedStyles';
import { groupBy, isEmpty } from 'lodash-es';
import React, { FC, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

interface ISocotraFieldErrorModalParser {
  errors: PolicyFieldError;
  open: boolean;
  setOpen: React.Dispatch<React.SetStateAction<boolean>>;
  title: string;
  errorCode?: string | number;
}

interface ParsedError {
  title?: string;
  errors?: Error[];
  childErrors?: ParsedError[];
}

interface Error {
  fieldName?: string;
  code?: string;
  message?: string;
}

export const SocotraFieldErrorModalParser: FC<ISocotraFieldErrorModalParser> = ({
  errors,
  open = false,
  setOpen,
  title,
  errorCode,
}) => {
  const { t } = useTranslation();

  const [parsedErrors, setParsedErrors] = useState<ParsedError[]>([]);

  const modifiedError = (ratingErrors: PolicyFieldError): PolicyFieldError => {
    const accordingToPagesRatingErrors = { ...ratingErrors };
    const fieldErrorsByConfigForm: { [configForm: string]: any[] } = {};

    accordingToPagesRatingErrors?.policy_field_errors?.field_group_errors!.forEach((groupError) => {
      groupError?.errors!.forEach((fieldError) => {
        const configForm = fieldError?.config_form;
        fieldErrorsByConfigForm[configForm!] = fieldErrorsByConfigForm[configForm!] || [];
        fieldErrorsByConfigForm[configForm!].push(fieldError);
      });
    });
    // sorting by page name
    const sortedConfigForms = Object.keys(fieldErrorsByConfigForm).sort();

    accordingToPagesRatingErrors.policy_field_errors!.field_group_errors = sortedConfigForms.map(
      (configForm) => ({
        field_name: underWritingQuestionsPageNames[configForm] ?? configForm,
        errors: fieldErrorsByConfigForm[configForm],
      }),
    );

    return accordingToPagesRatingErrors;
  };

  const [modifiedErrors, setModifiedErrors] = useState<PolicyFieldError>(
    !isEmpty(errors)
      ? errorCode === errorCodes.UNDERWRITING_QUESTIONS
        ? modifiedError(errors)
        : errors
      : errors,
  );

  useEffect(() => {
    const error = !isEmpty(errors)
      ? errorCode === errorCodes.UNDERWRITING_QUESTIONS
        ? modifiedError(errors)
        : errors
      : errors;
    setModifiedErrors(error);
  }, [errors]);

  const handleClose = () => {
    setOpen(false);
  };

  useEffect(() => {
    try {
      if (open && !isEmpty(errors)) {
        const parsedErrorsHolder: ParsedError[] = [];

        const { policy_field_errors, exposure_validation_errors } = modifiedErrors;

        if (!isEmpty(policy_field_errors)) {
          if (!isEmpty(policy_field_errors?.field_group_errors)) {
            const groupedErrors = groupBy(
              policy_field_errors?.field_group_errors,
              (e) => e.field_name,
            );

            Object.entries(groupedErrors).forEach(([key, value]) => {
              if (value.length > 1) {
                parsedErrorsHolder.push({
                  title: key,
                  childErrors: value.map((err, i) => ({
                    title: `${key} #${i + 1}`,
                    errors: err.errors,
                  })),
                });
              } else {
                parsedErrorsHolder.push({
                  title: key,
                  errors: value?.[0].errors,
                });
              }
            });
          }
        }

        if (!isEmpty(exposure_validation_errors)) {
          exposure_validation_errors?.forEach((exposureError) => {
            let expError: ParsedError = {
              title: `${exposureError?.exposure_name}`,
              errors: exposureError.exposure_field_errors?.errors ?? [],
            };

            const groupErrs: ParsedError[] = [];

            if (!isEmpty(exposureError.exposure_field_errors?.field_group_errors)) {
              const groupedErrors = groupBy(
                exposureError.exposure_field_errors?.field_group_errors,
                (e) => e.field_name,
              );

              Object.entries(groupedErrors).forEach(([key, value]) => {
                if (value.length > 1) {
                  groupErrs.push({
                    title: key,
                    childErrors: value.map((err, i) => ({
                      title: `${key} #${i + 1}`,
                      errors: err.errors,
                    })),
                  });
                } else {
                  groupErrs.push({
                    title: key,
                    errors: value?.[0].errors,
                  });
                }
              });

              expError = { ...expError, childErrors: groupErrs };
            }

            if (!isEmpty(exposureError.peril_validation_errors)) {
              const coverageErrs: ParsedError[] = [];

              const groupedErrors = groupBy(exposureError.peril_validation_errors, (e) => e.name);

              Object.entries(groupedErrors).forEach(([key, value]) => {
                if (value.length > 1) {
                  coverageErrs.push({
                    title: key,
                    childErrors: value.map((err, i) => ({
                      title: `${key} #${i + 1}`,
                      errors: err.field_errors?.errors,
                      childErrors: !isEmpty(err.field_errors?.field_group_errors)
                        ? Object.entries(
                            groupBy(err.field_errors?.field_group_errors, (ge) => ge.field_name),
                          ).map(([innerKey, innerValue]) => {
                            if (innerValue.length > 1) {
                              return {
                                title: innerKey,
                                childErrors: innerValue.map((innerErr, innerIndex) => ({
                                  title: `${innerKey} #${innerIndex + 1}`,
                                  errors: innerErr.errors,
                                })),
                              };
                            } else {
                              return {
                                title: innerKey,
                                errors: innerValue?.[0].errors,
                              };
                            }
                          })
                        : ([] as ParsedError[]),
                    })),
                  });
                } else {
                  coverageErrs.push({
                    title: key,
                    errors: value?.[0].field_errors?.errors,
                    childErrors: !isEmpty(value?.[0].field_errors?.field_group_errors)
                      ? Object.entries(
                          groupBy(
                            value?.[0].field_errors?.field_group_errors,
                            (ge) => ge.field_name,
                          ),
                        ).map(([innerKey, innerValue]) => {
                          if (innerValue.length > 1) {
                            return {
                              title: innerKey,
                              childErrors: innerValue.map((innerErr, innerIndex) => ({
                                title: `${innerKey} #${innerIndex + 1}`,
                                errors: innerErr.errors,
                              })),
                            };
                          } else {
                            return {
                              title: innerKey,
                              errors: innerValue?.[0].errors,
                            };
                          }
                        })
                      : ([] as ParsedError[]),
                  });
                }
              });

              groupErrs.push({
                title: t('Coverages'),
                childErrors: coverageErrs,
              });

              expError = { ...expError, childErrors: groupErrs };
            }

            parsedErrorsHolder.push({
              ...expError,
            });
          });
        }

        setParsedErrors(parsedErrorsHolder);
      }
      // eslint-disable-next-line no-empty
    } catch {}
  }, [open, modifiedErrors]);

  const accordion = (error: ParsedError, accIndex: number) => (
    <Accordion
      key={error.title}
      defaultExpanded
      onChange={(e, expanded) => {
        if (expanded) {
          e.currentTarget?.scrollIntoView({ behavior: 'smooth', block: 'start' });
        }
      }}
      sx={accIndex === 0 ? { mt: '1px' } : {}}
    >
      <AccordionSummary
        expandIcon={<ExpandMoreIcon />}
        aria-controls={error.title}
        id={error.title}
        sx={{
          '&.MuiAccordionSummary-root': {
            minHeight: 'unset',
          },
          '& .MuiAccordionSummary-content': {
            my: 1.5,
          },
        }}
      >
        <Typography
          sx={{
            fontWeight: 500,
            fontSize: 15,
          }}
        >
          {error.title}
        </Typography>
      </AccordionSummary>

      <AccordionDetails>
        {!isEmpty(error.errors) && (
          <List>
            {error.errors?.map((field, index) => (
              <ListItem key={index} sx={{ py: 1 / 2 }}>
                {field.message}
              </ListItem>
            ))}
          </List>
        )}

        {!isEmpty(error.childErrors) &&
          error.childErrors?.map((childError, i) => accordion(childError, i))}
      </AccordionDetails>
    </Accordion>
  );

  return (
    <Dialog open={open} onClose={handleClose}>
      <DialogTitle sx={{ display: 'flex', alignItems: 'flex-start' }}>
        <WarningAmberIcon
          sx={{
            color: (theme) => theme.customColors.alert,
            fontSize: '20px',
            mr: 1,
          }}
        />

        <Typography
          sx={{
            fontSize: 16,
            fontWeight: 500,
            color: (theme) => theme.customColors.black,
          }}
        >
          {title}
        </Typography>
      </DialogTitle>

      <DialogContent sx={{ minWidth: '500px' }}>
        {parsedErrors.map((error, i) => accordion(error, i))}
      </DialogContent>

      <DialogActions>
        <Button
          type="button"
          onClick={handleClose}
          color="info"
          sx={[primaryButtonStyle, { mr: 2, mb: 1 }]}
        >
          {t('Close')}
        </Button>
      </DialogActions>
    </Dialog>
  );
};
