import { IconButton, Tooltip } from '@mui/material';
import CopyIcon from 'assets/images/CopyIcon.svg';
import useCopyToClipboard from 'hooks/useCopyToClipboard';
import React, { FC, useState } from 'react';
import { ReactSVG } from 'react-svg';

interface CopyButtonProps {
  copyValue: string;
  isTooltipDisabled?: boolean;
}

const CopyButton: FC<CopyButtonProps> = ({ copyValue, isTooltipDisabled = false }) => {
  const [, copy] = useCopyToClipboard();
  const [isTooltipOpen, setIsTooltipOpen] = useState(false);

  const handleCopy = () => {
    copy(copyValue);
    setIsTooltipOpen(true);
  };

  const handleMouseOut = () => {
    setIsTooltipOpen(false);
  };

  return (
    <Tooltip
      title="Copied to clipboard"
      arrow
      open={isTooltipDisabled ? false : isTooltipOpen}
      PopperProps={{
        disablePortal: true,
        popperOptions: {
          modifiers: [
            {
              name: 'flip',
              enabled: false,
            },
          ],
        },
      }}
      disableFocusListener
      disableHoverListener
      disableTouchListener
    >
      <IconButton
        sx={{
          width: '32px',
          height: '32px',
          color: (theme) => theme.customColors.darkGrey,
          '&:hover': {
            color: (theme) => theme.customColors.primary.buttonBg,
          },
        }}
        onClick={handleCopy}
        onMouseOut={handleMouseOut}
      >
        <ReactSVG src={CopyIcon} />
      </IconButton>
    </Tooltip>
  );
};

export default CopyButton;
