import { Box, BoxProps, Typography } from '@mui/material';
import React, { FC } from 'react';

interface IStatusBoxProps {
  label: string;
  color: BoxProps['color'];
}

const StatusBox: FC<IStatusBoxProps> = ({ label, color }) => (
  <Box
    sx={{
      px: (theme) => theme.spacing(1.5),
      borderRadius: (theme) => theme.spacing(0.5),
      color: (theme) => theme.customColors.black,
      backgroundColor: color === undefined ? (theme) => theme.customColors.copper : color,
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
    }}
  >
    <Typography
      sx={{
        fontSize: (theme) => theme.typography.subtitle2.fontSize,
        color: (theme) => theme.customColors.white50,
        fontWeight: (theme) => theme.typography.subtitle2.fontWeight,
        whiteSpace: 'nowrap',
      }}
      variant="body1"
    >
      {label}
    </Typography>
  </Box>
);

export default StatusBox;
