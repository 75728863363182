import LocationSelectInput from 'components/QuotePolicyDetailEndorsement/Inputs/LocationSelectInput';
import { ILocationSelect } from 'components/QuotePolicyDetailEndorsement/Inputs/LocationSelectInput/types';
import useEndorsementDetail from 'hooks/useEndorsementDetail';
import React, { FC } from 'react';

const EndorsementLocationSelect: FC<ILocationSelect> = ({ ...props }) => {
  const { exposureList } = useEndorsementDetail();

  return <LocationSelectInput exposureList={exposureList} {...props} />;
};

export default EndorsementLocationSelect;
