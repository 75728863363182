import mitt, { Emitter } from 'mitt';

export type Events = {
  validateQuestionEngineInputs: keyof Events;
  submitCoverageSummary: keyof Events;
  questionEngineInputsValidated: boolean;
  declineQuestionEngineInputsValidated: boolean;
  sendBackQuestionEngineInputsValidated: boolean;
  referQuestionEngineInputsValidated: boolean;
  preRatingError: { error: any; defaultMessage: string };
  declineSubmissionEndorsement: keyof Events;
  sendBackSubmissionEndorsement: keyof Events;
};

const e: Emitter<Events> = mitt<Events>();

export const emitter = e;
