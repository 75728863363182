import { AdvancedSearchContext } from 'providers/AdvancedSearchProvider/AdvancedSearchProviderContext';
import { IAdvancedSearchContextType } from 'providers/AdvancedSearchProvider/types';
import { useContext } from 'react';

export default function useAdvancedSearch(): IAdvancedSearchContextType {
  const context = useContext(AdvancedSearchContext);

  /* istanbul ignore if */
  if (!context) {
    throw new Error('useAdvancedSearch must be used within a AdvancedSearchProvider');
  }

  return context;
}
