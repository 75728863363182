import { TasksContext } from 'providers/TasksProvider/TasksProviderContext';
import { ITasksContextType } from 'providers/TasksProvider/types';
import { useContext } from 'react';

export default function useTasks(): ITasksContextType {
  const context = useContext(TasksContext);

  /* istanbul ignore if */
  if (!context) {
    throw new Error('useTasks must be used within a TasksProvider');
  }

  return context;
}
