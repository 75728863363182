import { useLayoutEffect } from 'react';

const useEscape = (onEscape: () => void) => {
  useLayoutEffect(() => {
    const handleEsc = (event: KeyboardEvent) => {
      if (event.key === 'Escape') onEscape();
    };

    window.addEventListener('keydown', handleEsc);

    return () => {
      window.removeEventListener('keydown', handleEsc);
    };
  }, []);
};

export default useEscape;
