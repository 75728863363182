/* eslint-disable react/jsx-indent */
/* eslint-disable array-callback-return */
/* eslint-disable consistent-return */
/* eslint-disable react/no-unused-prop-types */
import { Cached, KeyboardArrowDownRounded } from '@mui/icons-material';
import {
  Box,
  Checkbox,
  FormControl,
  FormHelperText,
  Grid,
  IconButton,
  InputAdornment,
  InputLabel,
  Radio,
  Skeleton,
  Stack,
  SxProps,
  TextField,
  ToggleButton,
  ToggleButtonGroup,
  Typography,
} from '@mui/material';
import { Theme } from '@mui/system';
import { DynamicField } from 'api/models/DynamicFields/dynamicField.model';
import CalendarSchedule from 'assets/images/CalendarSchedule.svg';
import { dataFieldTypes, defaultDateFormat } from 'common/constants';
import CustomNativeSelect from 'components/CustomNativeSelect';
import DatePickerComponent from 'components/DatePickerComponent';
import NumberFormatComponent from 'components/NumberFormatComponent';
import { DollarPrefixedNumberFormatComponent } from 'components/NumberFormatComponent/DollarPrefixedNumberFormatComponent';
import { DetailDrawerLoader } from 'components/QuotePolicyDetailEndorsement/DrawerLoader';
import SmartyAutocompleteInput from 'components/SmartyAutocompleteInput';
import { IField } from 'components/SmartyAutocompleteInput/SmartyAutocompleteInput';
import {
  calendarIconStyle,
  checkboxStyle,
  readOnlyCheckboxStyle,
  readOnlyInputStyle,
  readOnlySelectInputStyle,
} from 'helpers/MuiSharedStyles';
import { currencyFormat } from 'helpers/Utils';
import useUser from 'hooks/useUser';
import { isEmpty, uniqBy } from 'lodash-es';
import React, { ChangeEvent, FC, useEffect } from 'react';
import InputMask from 'react-input-mask';
import { ReactSVG } from 'react-svg';

interface IProps {
  formik: any;
  isEdit?: boolean;
  showLoader?: boolean;
  fields?: DynamicField[];
  loaded?: boolean;
  splitSize?: number;
  state?: any;
  setState?: any;
  rowSpacing?: number;
  columnSpacing?: number;
  isReadOnly?: boolean;
  sx?: SxProps<Theme>;
  onChangeEmited?: ({ field, value }: { field: string; value: any }) => void;
  onSmartySelect?: <T>(newState: T) => void;
  onIntegrationFetch?: () => void;
}

export const ExpModFieldParser: FC<IProps> = ({
  isEdit = false,
  showLoader = false,
  isReadOnly = false,
  fields,
  splitSize = 2,
  state,
  setState,
  rowSpacing = 1.5,
  columnSpacing = 3,
  formik,
  sx,
  onChangeEmited,
  onSmartySelect,
  onIntegrationFetch,
}) => {
  const { data: user, loading: userLoading } = useUser();

  const handleFieldType = (field: DynamicField) => {
    const {
      loading,
      emitIsChangedEvent,
      currencyInput,
      showAsKeyValue,
      isReadOnlyForRoles,
      isMultiLine,
      isIntegrationFetcher,
    } = field?.additional_data ?? {};

    const isFieldReadOnly =
      field?.is_readonly ||
      isReadOnly ||
      !!isReadOnlyForRoles?.includes(user?.role?.code) ||
      field?.additional_data?.is_readonly;

    const checkEmitIsChanged = ({ fieldName, value }: { fieldName: string; value: any }) => {
      if (emitIsChangedEvent) {
        onChangeEmited?.({ field: fieldName, value });
      }
    };

    const handleInputChange = (event: ChangeEvent<HTMLInputElement>) => {
      const newVal = event.target.value;

      setState({ ...state, [event.target.name]: newVal });

      formik.setFieldValue([field.code], newVal);

      checkEmitIsChanged({ fieldName: event.target.name, value: newVal });
    };

    if (loading) {
      return (
        <FormControl variant="standard" sx={{ width: '100%' }} key={field.code}>
          <Skeleton sx={{ mr: '40%' }} animation="wave" width="100%" height={37} />
        </FormControl>
      );
    }

    if (showAsKeyValue) {
      return (
        <Box
          sx={{
            display: 'flex',
            justifyContent: 'space-between',
            flex: 1,
            mt: -rowSpacing,
            pt: '6px',
            pl: '12px',
          }}
          key={field.code}
        >
          {field?.additional_data?.loading ? (
            <>
              <Skeleton sx={{ mr: '40%' }} animation="wave" width="20%" height={20} />
              <Skeleton animation="wave" width="40%" height={20} />
            </>
          ) : (
            <>
              <Typography
                sx={{
                  fontSize: 14,
                  lineHeight: '21px',
                  fontWeight: 500,
                }}
              >
                {field?.name}
              </Typography>
              <Typography
                sx={{
                  fontSize: 14,
                  lineHeight: '21px',
                  fontWeight: 400,
                }}
              >
                {state[field.code] ?? ''}
              </Typography>
            </>
          )}
        </Box>
      );
    } else if (isMultiLine) {
      return (
        <FormControl variant="standard" sx={{ width: '100%' }} key={field.code}>
          <TextField
            multiline
            rows={6}
            id={field.code}
            name={field.code}
            label={field.name}
            size="small"
            required={!field.is_optional}
            onChange={handleInputChange}
            value={state[field.code] || ''}
            autoComplete="off"
            sx={isFieldReadOnly ? readOnlyInputStyle : {}}
            InputLabelProps={isFieldReadOnly ? { shrink: true } : {}}
            inputProps={{
              autoComplete: 'new-password',
              ...(isFieldReadOnly && { readOnly: true }),
            }}
            error={formik.touched[`${field.code}`] && Boolean(formik.errors[`${field.code}`])}
            helperText={formik.touched[`${field.code}`] && formik.errors[`${field.code}`]}
          />
        </FormControl>
      );
    } else if (isIntegrationFetcher) {
      return (
        <FormControl
          variant="standard"
          sx={{ width: '100%', ...calendarIconStyle }}
          key={field.code}
        >
          <DatePickerComponent
            disableFuture={field?.additional_data?.disableFuture}
            disablePast={field?.additional_data?.disablePast}
            format={defaultDateFormat}
            minDate={field?.minimum_date}
            maxDate={field?.maximum_date}
            label={field.name}
            readOnly={isFieldReadOnly}
            onChange={(newValue) => {
              setState({ ...state, [field.code!]: newValue! });
              formik.setFieldValue([field.code], newValue);
              checkEmitIsChanged({ fieldName: field.code, value: newValue });
            }}
            value={state?.[field.code] === '' ? null : state[field.code!] ?? null}
            slots={{
              openPickerIcon: () => <></>,
            }}
            slotProps={{
              textField: {
                size: 'small',
                required: !field.is_optional,
                id: field.code,
                name: field.code,
                sx: [isFieldReadOnly ? readOnlyInputStyle : {}],
                InputLabelProps: isFieldReadOnly ? { shrink: true } : {},
                autoComplete: 'off',
                inputProps: {
                  autoComplete: 'new-password',
                  ...(isFieldReadOnly && {
                    readOnly: true,
                  }),
                },
                InputProps: {
                  endAdornment:
                    !isEdit && isFieldReadOnly ? (
                      <ReactSVG className="calendar-icon" src={CalendarSchedule} />
                    ) : (
                      <InputAdornment position="end">
                        <IconButton
                          onClick={() => onIntegrationFetch?.()}
                          sx={{
                            '& svg': {
                              fontSize: '23px !important',
                            },
                          }}
                        >
                          <Cached />
                        </IconButton>
                      </InputAdornment>
                    ),
                },
                error: formik.touched[`${field.code}`] && Boolean(formik.errors[`${field.code}`]),
                helperText: formik.touched[`${field.code}`] && formik.errors[`${field.code}`],
              },
            }}
          />
        </FormControl>
      );
    }

    switch (field.type) {
      case dataFieldTypes.AUTOCOMPLETE:
        return (
          <FormControl variant="standard" sx={{ width: '100%' }} key={field.code}>
            {field.service_bridge?.name === 'smarty' && (
              <SmartyAutocompleteInput
                addressState={state}
                setAddressState={setState}
                formik={formik}
                field={field as IField}
                label={field.name}
                showLabel
                error={formik.touched[`${field.code}`] && Boolean(formik.errors[`${field.code}`])}
                helperText={formik.touched[`${field.code}`] && formik.errors[`${field.code}`]}
                makeInitialSearch={false}
                isReadOnly={isFieldReadOnly}
                onSelect={onSmartySelect}
              />
            )}
          </FormControl>
        );

      case dataFieldTypes.STRING:
        return (
          <FormControl variant="standard" sx={{ width: '100%' }} key={field.code}>
            <TextField
              id={field.code}
              name={field.code}
              label={field.name}
              size="small"
              required={!field.is_optional}
              onChange={handleInputChange}
              value={state[field.code] || ''}
              autoComplete="off"
              sx={isFieldReadOnly ? readOnlyInputStyle : {}}
              InputLabelProps={isFieldReadOnly ? { shrink: true } : {}}
              inputProps={{
                autoComplete: 'new-password',
                ...(isFieldReadOnly && { readOnly: true }),
              }}
              error={formik.touched[`${field.code}`] && Boolean(formik.errors[`${field.code}`])}
              helperText={formik.touched[`${field.code}`] && formik.errors[`${field.code}`]}
            />
          </FormControl>
        );

      case dataFieldTypes.PHONE:
        return (
          <FormControl
            variant="standard"
            sx={{ width: '100%' }}
            key={field.code}
            required={!field.is_optional}
          >
            <Stack
              sx={{
                '& .MuiFormControl-root': {
                  flex: 'auto',
                },
                py: 1,
                display: 'flex',
                flexDirection: 'row',
                alignItems: 'center',
                justifyContent: 'space-between',
              }}
            >
              <InputMask
                mask="(999) 999-9999"
                disabled={false}
                maskChar=" "
                onChange={handleInputChange}
                value={state[field.code] || ''}
              >
                {() => (
                  <TextField
                    sx={[{ flex: 'auto' }, isFieldReadOnly ? readOnlyInputStyle : {}]}
                    id={field.code}
                    name={field.code}
                    size="small"
                    required={!field.is_optional}
                    value={state[field.code] || ''}
                    inputProps={{
                      autoComplete: 'new-password',
                      ...(isFieldReadOnly && { readOnly: true }),
                    }}
                    error={
                      formik.touched[`${field.code}`] && Boolean(formik.errors[`${field.code}`])
                    }
                    helperText={formik.touched[`${field.code}`] && formik.errors[`${field.code}`]}
                  />
                )}
              </InputMask>
            </Stack>
          </FormControl>
        );

      case dataFieldTypes.NUMBER:
        return (
          <FormControl variant="standard" sx={{ width: '100%' }} key={field.code}>
            <TextField
              sx={[
                {
                  '& input::-webkit-outer-spin-button, & input::-webkit-inner-spin-button': {
                    display: 'none',
                  },
                  '& input[type=number]': {
                    MozAppearance: 'textfield',
                  },
                },
                isFieldReadOnly ? readOnlyInputStyle : {},
              ]}
              label={field.name}
              id={field.code}
              name={field.code}
              size="small"
              required={!field.is_optional}
              type={currencyInput ? 'text' : 'number'}
              onChange={handleInputChange}
              value={state[field.code] || ''}
              autoComplete="off"
              inputProps={{
                autoComplete: 'new-password',
                ...(isFieldReadOnly && {
                  readOnly: true,
                }),
              }}
              InputLabelProps={isFieldReadOnly ? { shrink: true } : {}}
              // eslint-disable-next-line react/jsx-no-duplicate-props
              InputProps={{
                autoComplete: 'new-password',
                inputComponent: currencyInput && (DollarPrefixedNumberFormatComponent as any),
              }}
              error={formik.touched[`${field.code}`] && Boolean(formik.errors[`${field.code}`])}
              helperText={formik.touched[`${field.code}`] && formik.errors[`${field.code}`]}
            />
          </FormControl>
        );

      case dataFieldTypes.EMAIL:
        return (
          <FormControl variant="standard" sx={{ width: '100%' }} key={field.code}>
            <TextField
              sx={[
                {
                  '& .MuiOutlinedInput-root': {
                    borderRadius: '4px',
                    '& .MuiInputBase-input': {
                      py: 1.5,
                    },
                    '&.Mui-focused fieldset': {
                      border: 2,
                    },
                  },
                },
                isFieldReadOnly ? readOnlyInputStyle : {},
              ]}
              id={field.code}
              name={field.code}
              size="small"
              type="email"
              required={!field.is_optional}
              // label={field.name}
              placeholder={`${field.name}${!field.is_optional ? ' *' : ''}`}
              onChange={handleInputChange}
              value={state[field.code] || ''}
              error={formik.touched[field.code] && Boolean(formik.errors[field.code])}
              helperText={formik.touched[field.code] && formik.errors[field.code]}
              autoComplete="off"
              inputProps={{
                autoComplete: 'new-password',
                ...(isFieldReadOnly && { readOnly: true }),
              }}
              InputLabelProps={isFieldReadOnly ? { shrink: true } : {}}
            />
          </FormControl>
        );

      case dataFieldTypes.DATE:
        return (
          <FormControl
            variant="standard"
            sx={{ width: '100%', ...calendarIconStyle }}
            key={field.code}
          >
            <DatePickerComponent
              disableFuture={field?.additional_data?.disableFuture}
              disablePast={field?.additional_data?.disablePast}
              format={defaultDateFormat}
              minDate={field?.minimum_date}
              maxDate={field?.maximum_date}
              label={field.name}
              readOnly={isFieldReadOnly}
              onChange={(newValue) => {
                setState({ ...state, [field.code]: newValue! });
                formik.setFieldValue([field.code], newValue);
                checkEmitIsChanged({ fieldName: field.code, value: newValue });
              }}
              value={state?.[field.code] === '' ? null : state[field.code] ?? null}
              slots={{
                openPickerIcon: () => <ReactSVG className="calendar-icon" src={CalendarSchedule} />,
              }}
              slotProps={{
                textField: {
                  size: 'small',
                  required: !field.is_optional,
                  id: field.code,
                  name: field.code,
                  autoComplete: 'off',
                  sx: [isFieldReadOnly ? readOnlyInputStyle : {}],
                  InputLabelProps: isFieldReadOnly ? { shrink: true } : {},
                  inputProps: {
                    autoComplete: 'new-password',
                    ...(isFieldReadOnly && {
                      readOnly: true,
                    }),
                  },
                  error: formik.touched[`${field.code}`] && Boolean(formik.errors[`${field.code}`]),
                  helperText: formik.touched[`${field.code}`] && formik.errors[`${field.code}`],
                },
              }}
            />
          </FormControl>
        );

      case dataFieldTypes.SELECT:
        return (
          <FormControl
            required={!field.is_optional}
            size="small"
            sx={[{ width: '100%' }, isFieldReadOnly ? readOnlySelectInputStyle : {}]}
            key={field.code}
            error={formik.touched[`${field.code}`] && Boolean(formik.errors[`${field.code}`])}
          >
            <Stack
              sx={{
                '& .MuiFormControl-root': {
                  flex: 'auto',
                },
              }}
            >
              <InputLabel
                required={!field.is_optional}
                id={`${field.code}_label`}
                shrink={isFieldReadOnly ? true : undefined}
              >
                {field.name}
              </InputLabel>
              <CustomNativeSelect
                required={!field.is_optional}
                id={field.code}
                labelId={`${field.code}_label`}
                label={field.name}
                name={field.code}
                IconComponent={KeyboardArrowDownRounded}
                error={formik.touched[`${field.code}`] && Boolean(formik.errors[`${field.code}`])}
                value={state[field.code] ?? ''}
                inputProps={{
                  autoComplete: 'off',
                  readOnly: isFieldReadOnly,
                }}
                sx={{
                  '& > .MuiSelect-select': { display: 'inline-block' },
                  width: '100%',
                }}
                MenuProps={{
                  sx: {
                    width: 100,
                    wordBreak: 'break-all',
                    whiteSpace: 'pre-wrap',
                    '& .MuiMenuItem-root': { whiteSpace: 'normal' },
                  },
                }}
                onChange={(event) => {
                  const { value } = event.target;
                  setState({ ...state, [field.code]: value });
                  formik.setFieldValue([field.code], value);
                  checkEmitIsChanged({ fieldName: field.code, value });
                }}
              >
                {field?.choices &&
                  uniqBy([...field.choices], 'code')?.map((type) => (
                    <option key={type.code} value={type.code}>
                      {type.name}
                    </option>
                  ))}
              </CustomNativeSelect>

              {formik.touched[`${field.code}`] && Boolean(formik.errors[`${field.code}`]) && (
                <FormHelperText
                  sx={{
                    mt: 1,
                    fontSize: '12px',
                    lineHeight: '14px',
                    color: (theme) => theme.customColors.alert,
                  }}
                >
                  {formik.errors[`${field.code}`]}
                </FormHelperText>
              )}
            </Stack>
          </FormControl>
        );

      case dataFieldTypes.SELECT_BUTTON:
        return (
          <FormControl size="small" sx={{ width: '100%', position: 'relative' }} key={field.code}>
            {field.name && (
              <Typography
                sx={{
                  fontWeight: 400,
                  fontSize: '12px',
                  lineHeight: '14px',
                  whiteSpace: 'nowrap',
                  mb: 1,
                }}
              >
                {field.name} {!field?.is_optional && '*'}
              </Typography>
            )}
            <ToggleButtonGroup
              exclusive
              value={state[field.code] ?? null}
              onChange={(event, newValue) => {
                if (newValue) {
                  setState({ ...state, [field.code]: newValue });
                  formik.setFieldValue([field.code], newValue);
                  checkEmitIsChanged({ fieldName: field.code, value: newValue });
                }
              }}
              sx={{ gap: 2 }}
              aria-label={field.name}
            >
              {field.choices?.map((choice) => (
                <ToggleButton
                  disabled={isFieldReadOnly}
                  sx={{
                    whiteSpace: 'nowrap',
                    backgroundColor: (theme) => theme.customColors.grey1150,
                    width: '120px',
                    height: '44px',
                    mb: 1,
                    '&.MuiToggleButton-root': {
                      py: 1.25,
                      px: 5,
                      color: (theme) => theme.customColors.gunMetal,
                      borderColor: (theme) => theme.customColors.gunMetal,
                      '&.MuiToggleButtonGroup-grouped:not(:first-of-type) ': {
                        borderLeft: '1px solid #2B2B2A',
                        ml: 0,
                        borderRadius: '2px',
                      },
                      '&.MuiToggleButtonGroup-grouped:not(:last-of-type) ': {
                        borderLeft: '1px solid #2B2B2A',
                        ml: 0,
                        borderRadius: '2px',
                      },
                      '&.Mui-selected': {
                        border: '2px solid !important',
                        borderColor: '#BB4F09 !important',
                      },
                    },
                  }}
                  value={choice.code}
                  key={choice.code}
                  aria-label={choice.name}
                >
                  <Radio
                    checked={state[field.code] === choice.code}
                    size="small"
                    disableRipple
                    sx={{
                      fontSize: 15,
                      width: 15,
                      height: 15,
                      mr: 1,
                      '&:hover': {
                        background: 'none',
                      },
                      color:
                        state[field.code] === choice.code
                          ? (theme) => theme.customColors.copper
                          : (theme) => theme.customColors.gunMetal,
                      '&.Mui-checked': {
                        color:
                          state[field.code] === choice.code
                            ? (theme) => theme.customColors.copper
                            : (theme) => theme.customColors.gunMetal,
                      },
                    }}
                  />

                  <Typography
                    sx={{
                      fontWeight: 500,
                      fontSize: '16px',
                      lineHeight: '24px',
                      textTransform: 'none',
                    }}
                  >
                    {Number.isNaN(Number(choice.name)) ? (
                      choice.name
                    ) : field.additional_data?.withDollarIcon ? (
                      `${currencyFormat('USD', choice.name!).merged}`
                    ) : (
                      <NumberFormatComponent displayType="text" value={choice.name} />
                    )}
                  </Typography>
                </ToggleButton>
              ))}
            </ToggleButtonGroup>
            <FormHelperText
              sx={{ position: 'absolute', bottom: '-24px', mb: 1 }}
              error={formik.touched[field.code] && Boolean(formik.errors[field.code])}
            >
              {formik.touched[field.code] && Boolean(formik.errors[field.code])
                ? formik.errors[field.code]
                : null}
            </FormHelperText>
          </FormControl>
        );

      case dataFieldTypes.LABEL:
        return (
          <Box
            sx={{
              display: 'flex',
              justifyContent: 'space-between',
              flex: 1,
            }}
            key={field.code}
          >
            <>
              <Typography
                sx={{
                  fontSize: 14,
                  lineHeight: '21px',
                  fontWeight: 500,
                }}
              >
                {field?.name}
              </Typography>
              <Typography
                sx={{
                  fontSize: 14,
                  lineHeight: '21px',
                  fontWeight: 400,
                }}
              >
                {state[field.code] ?? ''}
              </Typography>
            </>
          </Box>
        );

      case dataFieldTypes.CHECKBOX:
        return (
          <FormControl
            variant="standard"
            sx={{
              maxHeight: '21px',
              width: '100%',
              display: 'inline-flex',
              flexDirection: 'row',
              alignItems: 'center',
            }}
            key={field.code}
          >
            {field.additional_data?.asYesNo ? (
              <Checkbox
                sx={field?.is_readonly || isReadOnly ? readOnlyCheckboxStyle : checkboxStyle}
                id={field.name}
                name={field.name}
                checked={state[field.code] === 'Yes' ?? false}
                onChange={(event) => {
                  if (!field?.is_readonly && !isReadOnly) {
                    const value = event.target.checked ? 'Yes' : 'No';
                    setState?.({ ...state, [field.code]: value });
                    formik.setFieldValue([field.code], value);

                    checkEmitIsChanged({
                      fieldName: field.code,
                      value,
                    });
                  }
                }}
                inputProps={{
                  readOnly: isReadOnly,
                }}
              />
            ) : (
              <Checkbox
                sx={field?.is_readonly || isReadOnly ? readOnlyCheckboxStyle : checkboxStyle}
                id={field.name}
                name={field.name}
                checked={state[field.code] ?? false}
                onChange={(event) => {
                  if (!field?.is_readonly && !isReadOnly) {
                    const value = event.target.checked;

                    setState?.({ ...state, [field.code]: value });

                    formik.setFieldValue([field.code], value);

                    checkEmitIsChanged({
                      fieldName: field.code,
                      value,
                    });
                  }
                }}
                inputProps={{
                  readOnly: isReadOnly,
                }}
              />
            )}
            <Typography
              sx={{
                fontSize: 14,
                color: (theme) => theme.customColors.black,
                lineHeight: '21px',
                fontWeight: 400,
                ml: 2,
              }}
            >
              {field.name}
            </Typography>
          </FormControl>
        );

      default:
    }
  };

  const handleConditionalField = (field) => {
    const handleCondition = {
      select: (condition, fieldCode) => {
        const { value } = condition;
        return value.includes(state[fieldCode]);
      },
      number: (condition, fieldCode) => {
        const { value, operator } = condition;
        const operators = {
          '>': () => state[fieldCode] > +value,
          '<': () => state[fieldCode] < +value,
        };
        return operators[operator]();
      },
    };

    if (field?.condition && Object.keys(field.condition).length > 0) {
      const results: boolean[] = [];
      // eslint-disable-next-line no-restricted-syntax
      for (const [fieldCode, fieldConditions] of Object.entries(
        field.condition,
      ) as unknown as any[]) {
        const result = fieldConditions.every((condition) =>
          handleCondition[condition.type](condition, fieldCode),
        );
        results.push(result);
      }
      return results.every((result) => result);
    }
    return true;
  };

  useEffect(() => {
    fields?.map((f) => {
      if (f.type === dataFieldTypes.SELECT && f.choices?.length === 1) {
        setState({ ...state, [f.code!]: f.choices[0].code });
        formik.setFieldValue([f.code], f.choices[0].code);
      }
    });
  }, []);

  return (
    <>
      {showLoader || userLoading ? (
        <DetailDrawerLoader />
      ) : !isEmpty(fields) ? (
        <Grid
          sx={{ alignItems: 'flex-start' }}
          container
          rowSpacing={rowSpacing}
          columnSpacing={columnSpacing}
          columns={splitSize}
        >
          {fields?.map((field) => {
            const { showOnlyForRoles } = field?.additional_data ?? {};

            if (!isEmpty(showOnlyForRoles) && !showOnlyForRoles?.includes(user?.role?.code)) {
              return <React.Fragment key={`${field.code + field.type}__hidden`} />;
            }

            return (
              handleConditionalField(field) &&
              field &&
              !field?.is_hidden && (
                <React.Fragment key={field.code + field.type}>
                  {(field.heading ||
                    field.additional_data?.section_heading ||
                    field.newline ||
                    field.additional_data?.space_above) && (
                    <Grid
                      item
                      key={`${field.code}_header`}
                      xs={splitSize}
                      sx={[
                        field.newline ? { p: '0 !important' } : {},
                        field.additional_data?.space_above ? { mt: 1.5 } : {},
                      ]}
                    >
                      {field.heading && (
                        <Typography
                          sx={{
                            fontWeight: 500,
                            mt: 1.5,
                            fontSize: '16px',
                            lineHeight: 1.5,
                          }}
                        >
                          {field.heading}
                        </Typography>
                      )}
                      {field.additional_data?.section_heading && (
                        <Typography
                          sx={{
                            mt: 1,
                            mb: -0.5,
                            fontWeight: 500,
                            fontSize: '16px',
                            lineHeight: '24px',
                            color: (theme) => theme.customColors.black,
                          }}
                        >
                          {field.additional_data.section_heading}
                        </Typography>
                      )}
                    </Grid>
                  )}

                  <Grid
                    item
                    key={field.code + field.type}
                    xs={3}
                    sx={[...(Array.isArray(sx) ? sx : [sx])]}
                  >
                    {handleFieldType(field)}
                  </Grid>
                </React.Fragment>
              )
            );
          })}
        </Grid>
      ) : (
        <></>
      )}
    </>
  );
};
