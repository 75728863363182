import ApiService from 'api/helpers/Sender';
import { IVINtelligenceDecodeVinRequest } from 'api/models/Integrations/VINtelligence/IDecodeVin.model';
import { IVINtelligenceDecodeVinResponse } from 'api/models/Integrations/VINtelligence/IDecodeVinResponse.model';
import { Make } from 'api/models/Integrations/VINtelligence/IMakes.model';
import { IVINtelligenceModelsRequest } from 'api/models/Integrations/VINtelligence/IModelsRequest.model';
import { Model } from 'api/models/Integrations/VINtelligence/IModelsResponse.model';

const API = new ApiService();

export const getMakes = async (year: number): Promise<Make[]> => {
  const { data }: { data: Make[] } = await API.post('vintelligence/makes', { year });

  return data ?? [];
};

export const getModels = async (body: IVINtelligenceModelsRequest): Promise<Model[]> => {
  const { data }: { data: Model[] } = await API.post('vintelligence/models', body);

  return data ?? {};
};

export const decodeVin = async (
  body: IVINtelligenceDecodeVinRequest,
  controller?: AbortController,
): Promise<IVINtelligenceDecodeVinResponse[]> => {
  const { data }: { data: IVINtelligenceDecodeVinResponse[] } = await API.post(
    'vintelligence/decode-vin',
    body,
    {
      signal: controller?.signal,
    },
  );

  return data ?? [];
};
