/* eslint-disable no-case-declarations */
/* eslint-disable react/jsx-indent */
/* eslint-disable array-callback-return */
/* eslint-disable consistent-return */
import { KeyboardArrowDownRounded } from '@mui/icons-material';
import {
  Autocomplete,
  Box,
  FormControl,
  FormHelperText,
  FormLabel,
  Grid,
  InputLabel,
  Radio,
  Skeleton,
  Stack,
  SxProps,
  TextField,
  ToggleButton,
  ToggleButtonGroup,
  Typography,
} from '@mui/material';
import { Theme } from '@mui/system';
import { DynamicField } from 'api/models/DynamicFields/dynamicField.model';
import CalendarSchedule from 'assets/images/CalendarSchedule.svg';
import { dataFieldTypes, defaultDateFormat } from 'common/constants';
import CustomNativeSelect from 'components/CustomNativeSelect';
import DatePickerComponent from 'components/DatePickerComponent';
import NumberFormatComponent from 'components/NumberFormatComponent';
import { ILocationSelect } from 'components/QuotePolicyDetailEndorsement/Inputs/LocationSelectInput/types';
import {
  calendarIconStyle,
  readOnlyInputStyle,
  readOnlySelectInputStyle,
} from 'helpers/MuiSharedStyles';
import { currencyFormat } from 'helpers/Utils';
import { isEmpty, uniqBy } from 'lodash-es';
import React, { ChangeEvent, FC, useEffect } from 'react';
import InputMask from 'react-input-mask';
import { ReactSVG } from 'react-svg';

interface IProps {
  formik: any;
  isEdit?: boolean;
  showLoader?: boolean;
  fields?: DynamicField[];
  loaded?: boolean;
  splitSize?: number;
  state?: any;
  setState?: any;
  rowSpacing?: number;
  columnSpacing?: number;
  isReadOnly?: boolean;
  sx?: SxProps<Theme>;
  onChangeEmited?: ({ field, value }: { field: string; value: any }) => void;
  onBlur?: any;
  LocationSelect: FC<ILocationSelect>;
}

export const LiabilityExposurePerilDrawerFieldParser: FC<IProps> = ({
  isEdit = false,
  showLoader = false,
  isReadOnly = false,
  fields,
  loaded,
  splitSize = 2,
  state,
  setState,
  rowSpacing = 1.5,
  columnSpacing = 3,
  formik,
  sx,
  onChangeEmited,
  onBlur,
  LocationSelect,
}) => {
  const handleFieldType = (field: DynamicField) => {
    const { isLocationSelect, emitIsChangedEvent, currencyInput, hasBlurinInput } =
      field?.additional_data ?? {};

    const isFieldReadOnly =
      field?.is_read_only ||
      field?.is_readonly ||
      isReadOnly ||
      field?.additional_data?.is_readonly;

    const checkEmitIsChanged = ({ fieldName, value }: { fieldName: string; value: any }) => {
      if (emitIsChangedEvent) {
        onChangeEmited?.({ field: fieldName, value });
      }
    };

    const handleInputChange = (event: ChangeEvent<HTMLInputElement>) => {
      setState({ ...state, [event.target.name]: event.target.value });

      checkEmitIsChanged({ fieldName: event.target.name, value: event.target.value });
    };

    if (isLocationSelect) {
      return (
        <LocationSelect
          state={state}
          setState={setState}
          formik={formik}
          fieldConfig={field}
          onSelect={(value) => checkEmitIsChanged({ fieldName: field.code, value })}
          isReadOnly={isFieldReadOnly}
          isCodeLocator
          onBlur={hasBlurinInput && onBlur}
        />
      );
    }
    switch (field.type) {
      case dataFieldTypes.STRING:
        if (field?.additional_data?.isLabel) {
          const renderedFieldValue = () =>
            field?.additional_data?.currencyInput ? (
              <NumberFormatComponent value={state[field.code] ?? ''} displayType="text" />
            ) : (
              <Typography
                sx={{
                  fontSize: 14,
                  lineHeight: '21px',
                  fontWeight: 400,
                  textAlign: 'end',
                }}
              >
                {state[field.code] ?? ''}
              </Typography>
            );

          return (
            <Box
              sx={{
                display: 'flex',
                justifyContent: 'space-between',
                flex: 1,
                gap: 4,
                alignItems: field?.additional_data?.loading ? 'center' : 'flex-start',
              }}
              key={field.code}
            >
              <>
                <Typography
                  sx={{
                    fontSize: 14,
                    lineHeight: '21px',
                    fontWeight: 500,
                  }}
                >
                  {field?.name}
                </Typography>
                {field?.additional_data?.loading ? (
                  <Skeleton animation="wave" width="40%" height={27} />
                ) : (
                  renderedFieldValue()
                )}
              </>
            </Box>
          );
        }

        return (
          <FormControl variant="standard" sx={{ width: '100%' }} key={field.code}>
            {field.additional_data?.loading ? (
              <Skeleton sx={{ mr: '40%' }} animation="wave" width="100%" height={37} />
            ) : (
              <TextField
                id={field.code}
                name={field.code}
                label={field.name}
                size="small"
                required={!field.is_optional}
                onChange={handleInputChange}
                multiline
                value={state[field.code] ?? ''}
                sx={field?.is_readonly || isReadOnly ? readOnlyInputStyle : {}}
                InputLabelProps={
                  field?.is_readonly && !isReadOnly && !state[field.code]
                    ? { shrink: false }
                    : isReadOnly
                    ? { shrink: true }
                    : {}
                }
                inputProps={{
                  autoComplete: 'off',
                  ...((field?.is_readonly || isReadOnly) && { readOnly: true }),
                }}
                onBlur={hasBlurinInput && onBlur}
                error={formik.touched[`${field.code}`] && Boolean(formik.errors[`${field.code}`])}
                helperText={formik.touched[`${field.code}`] && formik.errors[`${field.code}`]}
              />
            )}
          </FormControl>
        );

      case dataFieldTypes.PHONE:
        return (
          <FormControl
            variant="standard"
            sx={{ width: '100%' }}
            key={field.code}
            required={!field.is_optional}
          >
            <Stack
              sx={{
                '& .MuiFormControl-root': {
                  flex: 'auto',
                },
                py: 1,
                display: 'flex',
                flexDirection: 'row',
                alignItems: 'center',
                justifyContent: 'space-between',
              }}
            >
              <InputMask
                mask="(999) 999-9999"
                disabled={false}
                maskChar=" "
                onChange={handleInputChange}
                value={state[field.code] || ''}
              >
                {() => (
                  <TextField
                    sx={[
                      { flex: 'auto' },
                      field?.is_readonly || isReadOnly ? readOnlyInputStyle : {},
                    ]}
                    id={field.code}
                    name={field.code}
                    size="small"
                    required={!field.is_optional}
                    value={state[field.code] || ''}
                    inputProps={{
                      autoComplete: 'off',
                      ...((field?.is_readonly || isReadOnly) && { readOnly: true }),
                    }}
                    error={
                      formik.touched[`${field.code}`] && Boolean(formik.errors[`${field.code}`])
                    }
                    helperText={formik.touched[`${field.code}`] && formik.errors[`${field.code}`]}
                  />
                )}
              </InputMask>
            </Stack>
          </FormControl>
        );

      case dataFieldTypes.NUMBER:
        return (
          <FormControl variant="standard" sx={{ width: '100%' }} key={field.code}>
            <TextField
              sx={[
                {
                  '& input::-webkit-outer-spin-button, & input::-webkit-inner-spin-button': {
                    display: 'none',
                  },
                  '& input[type=number]': {
                    MozAppearance: 'textfield',
                  },
                },
                isFieldReadOnly ? readOnlyInputStyle : {},
              ]}
              label={field.name}
              id={field.code}
              name={field.code}
              size="small"
              required={!field.is_optional}
              type={currencyInput ? 'text' : 'number'}
              onChange={handleInputChange}
              value={state[field.code] || ''}
              inputProps={{
                autoComplete: 'off',
                ...(isFieldReadOnly && {
                  readOnly: true,
                }),
              }}
              InputLabelProps={
                field?.is_readonly && !isReadOnly && !state[field.code]
                  ? { shrink: false }
                  : isFieldReadOnly
                  ? { shrink: true }
                  : {}
              }
              // eslint-disable-next-line react/jsx-no-duplicate-props
              InputProps={{
                autoComplete: 'off',
                inputComponent: currencyInput && (NumberFormatComponent as any),
              }}
              error={formik.touched[`${field.code}`] && Boolean(formik.errors[`${field.code}`])}
              helperText={formik.touched[`${field.code}`] && formik.errors[`${field.code}`]}
              onBlur={hasBlurinInput && onBlur}
            />
          </FormControl>
        );

      case dataFieldTypes.EMAIL:
        return (
          <FormControl variant="standard" sx={{ width: '100%' }} key={field.code}>
            <TextField
              sx={[
                {
                  '& .MuiOutlinedInput-root': {
                    borderRadius: '4px',
                    '& .MuiInputBase-input': {
                      py: 1.5,
                    },
                    '&.Mui-focused fieldset': {
                      border: 2,
                    },
                  },
                },
                field?.is_readonly || isReadOnly ? readOnlyInputStyle : {},
              ]}
              id={field.code}
              name={field.code}
              size="small"
              type="email"
              required={!field.is_optional}
              // label={field.name}
              placeholder={`${field.name}${!field.is_optional ? ' *' : ''}`}
              onChange={handleInputChange}
              value={state[field.code] || ''}
              error={formik.touched[field.code] && Boolean(formik.errors[field.code])}
              helperText={formik.touched[field.code] && formik.errors[field.code]}
              inputProps={{
                autoComplete: 'off',
                ...((field?.is_readonly || isReadOnly) && { readOnly: true }),
              }}
            />
          </FormControl>
        );

      case dataFieldTypes.DATE:
        return (
          <FormControl
            variant="standard"
            sx={{ width: '100%', ...calendarIconStyle }}
            key={field.code}
          >
            <Stack
              sx={{
                '& .MuiFormControl-root': {
                  flex: 'auto',
                },
                py: 1,
                display: 'flex',
                flexDirection: 'row',
                alignItems: 'center',
                justifyContent: 'space-between',
              }}
            >
              <FormLabel
                sx={{
                  fontSize: 12,
                  lineHeight: 1.5,
                  color: (theme) => theme.customColors.black,
                  mr: (theme) => theme.spacing(1.25),
                  minWidth: '142px',
                  maxWidth: '142px',
                }}
                required={!field.is_optional}
              >
                {field.name}
              </FormLabel>
              <DatePickerComponent
                format={defaultDateFormat}
                minDate={field?.minimum_date}
                maxDate={field?.maximum_date}
                onChange={(newValue) => {
                  setState({ ...state, [field.code]: newValue! });
                }}
                value={state[field.code] ?? null}
                slots={{
                  openPickerIcon: () => (
                    <ReactSVG className="calendar-icon" src={CalendarSchedule} />
                  ),
                }}
                slotProps={{
                  textField: {
                    size: 'small',
                    required: !field.is_optional,
                    id: field.code,
                    name: field.code,
                    inputProps: {
                      autoComplete: 'off',
                    },
                    error:
                      formik.touched[`${field.code}`] && Boolean(formik.errors[`${field.code}`]),
                    helperText: formik.touched[`${field.code}`] && formik.errors[`${field.code}`],
                  },
                }}
              />
            </Stack>
          </FormControl>
        );

      case dataFieldTypes.SELECT:
        return (
          <FormControl
            required={!field.is_optional}
            size="small"
            sx={[
              { width: '100%' },
              isReadOnly || field?.is_readonly ? readOnlySelectInputStyle : {},
            ]}
            key={field.code}
            error={formik.touched[`${field.code}`] && Boolean(formik.errors[`${field.code}`])}
          >
            {field.additional_data?.loading ? (
              <Skeleton sx={{ mr: '40%' }} animation="wave" width="100%" height={37} />
            ) : (
              <Stack
                sx={{
                  '& .MuiFormControl-root': {
                    flex: 'auto',
                  },
                }}
              >
                <InputLabel
                  required={!field.is_optional}
                  id={`${field.code}_label`}
                  shrink={isFieldReadOnly ? true : undefined}
                  error={formik.touched[`${field.code}`] && Boolean(formik.errors[`${field.code}`])}
                >
                  {field.name}
                </InputLabel>
                <CustomNativeSelect
                  required={!field.is_optional}
                  id={field.code}
                  labelId={`${field.code}_label`}
                  label={field.name}
                  name={field.code}
                  IconComponent={KeyboardArrowDownRounded}
                  error={formik.touched[`${field.code}`] && Boolean(formik.errors[`${field.code}`])}
                  value={state[field.code] ?? ''}
                  onBlur={hasBlurinInput && onBlur}
                  inputProps={{
                    autoComplete: 'off',
                    readOnly: field?.is_readonly || isReadOnly,
                  }}
                  sx={{
                    '& > .MuiSelect-select': { display: 'flex', alignItems: 'center' },
                    width: '100%',
                  }}
                  onChange={(event) => {
                    setState({ ...state, [field.code]: event.target.value });
                  }}
                >
                  {field.choices &&
                    uniqBy([...field.choices!], 'code')?.map((type) => (
                      <option key={type.code} value={type.code}>
                        {type.name}
                      </option>
                    ))}
                </CustomNativeSelect>

                {formik.touched[`${field.code}`] && Boolean(formik.errors[`${field.code}`]) && (
                  <FormHelperText
                    sx={{
                      mt: 1,
                      fontSize: '12px',
                      lineHeight: '14px',
                      color: (theme) => theme.customColors.alert,
                    }}
                  >
                    {formik.errors[`${field.code}`]}
                  </FormHelperText>
                )}
              </Stack>
            )}
          </FormControl>
        );

      case dataFieldTypes.SELECT_BUTTON:
        return (
          <FormControl size="small" sx={{ width: '100%', position: 'relative' }} key={field.code}>
            {field.name && (
              <Typography
                sx={{
                  fontWeight: 400,
                  fontSize: '12px',
                  lineHeight: '14px',
                  whiteSpace: 'nowrap',
                  mb: 1,
                }}
              >
                {field.name} {!field?.is_optional && '*'}
              </Typography>
            )}
            <ToggleButtonGroup
              exclusive
              value={state[field.code] ?? null}
              onChange={(event, newValue) => {
                if (newValue) {
                  setState({ ...state, [field.code]: newValue });
                  formik.setFieldValue([field.code], newValue);
                }
              }}
              sx={{ gap: 2 }}
              aria-label={field.name}
            >
              {field.choices?.map((choice) => (
                <ToggleButton
                  disabled={field?.is_readonly || isReadOnly}
                  sx={{
                    whiteSpace: 'nowrap',
                    backgroundColor: (theme) => theme.customColors.grey1150,
                    width: '120px',
                    height: '44px',
                    mb: 1,
                    '&.MuiToggleButton-root': {
                      py: 1.25,
                      px: 5,
                      color: (theme) => theme.customColors.gunMetal,
                      borderColor: (theme) => theme.customColors.gunMetal,
                      '&.MuiToggleButtonGroup-grouped:not(:first-of-type) ': {
                        borderLeft: '1px solid #2B2B2A',
                        ml: 0,
                        borderRadius: '2px',
                      },
                      '&.MuiToggleButtonGroup-grouped:not(:last-of-type) ': {
                        borderLeft: '1px solid #2B2B2A',
                        ml: 0,
                        borderRadius: '2px',
                      },
                      '&.Mui-selected': {
                        border: '2px solid !important',
                        borderColor: '#BB4F09 !important',
                      },
                    },
                  }}
                  value={choice.code}
                  key={choice.code}
                  aria-label={choice.name}
                >
                  <Radio
                    checked={state[field.code] === choice.code}
                    size="small"
                    disableRipple
                    sx={{
                      fontSize: 15,
                      width: 15,
                      height: 15,
                      mr: 1,
                      '&:hover': {
                        background: 'none',
                      },
                      color:
                        state[field.code] === choice.code
                          ? (theme) => theme.customColors.copper
                          : (theme) => theme.customColors.gunMetal,
                      '&.Mui-checked': {
                        color:
                          state[field.code] === choice.code
                            ? (theme) => theme.customColors.copper
                            : (theme) => theme.customColors.gunMetal,
                      },
                    }}
                  />

                  <Typography
                    sx={{
                      fontWeight: 500,
                      fontSize: '16px',
                      lineHeight: '24px',
                      textTransform: 'none',
                    }}
                  >
                    {Number.isNaN(Number(choice.name)) ? (
                      choice.name
                    ) : field.additional_data?.withDollarIcon ? (
                      `${currencyFormat('USD', choice.name!).merged}`
                    ) : (
                      <NumberFormatComponent displayType="text" value={choice.name} />
                    )}
                  </Typography>
                </ToggleButton>
              ))}
            </ToggleButtonGroup>
            <FormHelperText
              sx={{ position: 'absolute', bottom: '-24px' }}
              error={formik.touched[field.code] && Boolean(formik.errors[field.code])}
            >
              {formik.touched[field.code] && Boolean(formik.errors[field.code])
                ? formik.errors[field.code]
                : null}
            </FormHelperText>
          </FormControl>
        );

      case dataFieldTypes.AUTOCOMPLETE: {
        const autocompleteValue = field?.choices?.find(
          (o) => o.code === `${state[`${field.code}`]}`,
        ) || {
          code: '',
          name: '',
        };

        return (
          <FormControl
            required={!field.is_optional}
            size="small"
            sx={[
              { width: '100%' },
              isReadOnly || field?.is_readonly ? readOnlySelectInputStyle : {},
            ]}
            key={field.code}
          >
            <Stack
              sx={[
                {
                  '& .MuiFormControl-root': {
                    flex: 'auto',
                  },
                },
                ...(Array.isArray(sx) ? sx : [sx]),
              ]}
            >
              <Autocomplete
                sx={{ width: '100%' }}
                disableClearable
                id={field.code}
                readOnly={isReadOnly}
                getOptionLabel={(option) => (option ? option.code : option)}
                options={field.choices ?? []}
                value={autocompleteValue}
                filterOptions={(options, stateValue) =>
                  options.filter(
                    (option) =>
                      option.code.includes(stateValue.inputValue) ||
                      (option.name as string)
                        .toLowerCase()
                        .includes(stateValue.inputValue.toLowerCase()),
                  )
                }
                onChange={(e, selectedOption) => {
                  const newVal = selectedOption?.code;
                  setState?.({ ...state, [field.code!]: newVal });
                  formik.setFieldValue([field.code], newVal);
                }}
                isOptionEqualToValue={(option, v) => option.code === (v as any)}
                renderInput={(params) => (
                  <TextField
                    required={field!.is_optional ? !field!.is_optional : true}
                    {...params}
                    name={field.code}
                    size="small"
                    label={field.name}
                    error={formik.touched?.[field.code!] && Boolean(formik.errors?.[field.code!])}
                    helperText={formik.touched?.[field.code!] && formik.errors?.[field.code!]}
                    placeholder={isReadOnly ? '' : 'Start typing and select'}
                    InputLabelProps={isReadOnly ? { shrink: true } : {}}
                    onBlur={hasBlurinInput && onBlur}
                    InputProps={{
                      ...params.InputProps,
                      endAdornment: null,
                    }}
                  />
                )}
                renderOption={(props, option) => (
                  <li {...props} key={option.code}>
                    {`${option.code} - ${option.name}`}
                  </li>
                )}
              />
            </Stack>
          </FormControl>
        );
      }

      default:
    }
  };

  const handleConditionalField = (field) => {
    const handleCondition = {
      select: (condition, fieldCode) => {
        const { value } = condition;
        const { isDisplayedWhenPerilSelected } = field?.additional_data ?? {};

        if (isDisplayedWhenPerilSelected) return value.includes(state[fieldCode]);

        return isEmpty(state[fieldCode]) || value.includes(state[fieldCode]);
      },
      number: (condition, fieldCode) => {
        const { value, operator } = condition;
        const operators = {
          '>': () => state[fieldCode] > +value,
          '<': () => state[fieldCode] < +value,
        };
        return operators[operator]();
      },
      string: (condition, fieldCode) => {
        const { value } = condition;

        return value === state[fieldCode];
      },
    };

    if (field?.condition && Object.keys(field.condition).length > 0) {
      const results: boolean[] = [];
      // eslint-disable-next-line no-restricted-syntax
      for (const [fieldCode, fieldConditions] of Object.entries(
        field.condition,
      ) as unknown as any[]) {
        const result = fieldConditions.every((condition) =>
          handleCondition[condition.type](condition, fieldCode),
        );
        results.push(result);
      }
      return results.every((result) => result);
    }
    return true;
  };

  useEffect(() => {
    fields?.map((f) => {
      if (f.type === dataFieldTypes.SELECT && f.choices?.length === 1) {
        setState({ ...state, [f.code!]: f.choices[0].code });
      }
    });
  }, []);

  return (
    <>
      {showLoader ? (
        [...Array(4).keys()].map((v) => (
          <Stack
            key={`skel${v}`}
            gap={2}
            sx={{
              width: 1,
              flexDirection: {
                xs: 'column',
                sm: 'row',
              },
            }}
          >
            <FormControl variant="standard" sx={{ flex: 1 }}>
              <Skeleton animation="wave" width="100%" height={37} />
            </FormControl>
          </Stack>
        ))
      ) : (!isEdit ? loaded : !isEmpty(fields)) ? (
        <Grid
          sx={{ alignItems: 'flex-start' }}
          container
          rowSpacing={rowSpacing}
          columnSpacing={columnSpacing}
          columns={splitSize}
        >
          {fields?.map(
            (field) =>
              handleConditionalField(field) &&
              field &&
              !field?.is_hidden && (
                <React.Fragment key={field.code + field.type}>
                  {(field.heading ||
                    field.additional_data?.section_heading ||
                    field.newline ||
                    field.additional_data?.space_above) && (
                    <Grid
                      item
                      key={`${field.code}_header`}
                      xs={splitSize}
                      sx={[
                        field.newline ? { p: '0 !important' } : {},
                        field.additional_data?.space_above ? { mt: 2.25 } : {},
                      ]}
                    >
                      {field.heading && (
                        <Typography
                          sx={{
                            fontWeight: 500,
                            mt: 1.5,
                            fontSize: '16px',
                            lineHeight: 1.5,
                          }}
                        >
                          {field.heading}
                        </Typography>
                      )}
                      {field.additional_data?.section_heading && (
                        <Typography
                          sx={{
                            mt: 6,
                            fontWeight: 500,
                            fontSize: '20px',
                            lineHeight: '26px',
                            color: '#2B2B2A',
                          }}
                        >
                          {field.additional_data.section_heading}
                        </Typography>
                      )}
                    </Grid>
                  )}

                  <Grid
                    item
                    key={field.code + field.type}
                    xs={3}
                    sx={
                      Array.isArray(sx)
                        ? [
                            ...sx,
                            {
                              pt: field?.additional_data?.isLabel && '6px !important',
                              pl: field?.additional_data?.isLabel && '12px !important',
                            },
                          ]
                        : [
                            sx,
                            {
                              pt: field?.additional_data?.isLabel && '6px !important',
                              pl: field?.additional_data?.isLabel && '12px !important',
                            },
                          ]
                    }
                  >
                    {handleFieldType(field)}
                  </Grid>
                </React.Fragment>
              ),
          )}
        </Grid>
      ) : (
        <></>
      )}
    </>
  );
};
