import ApiService, { Params } from 'api/helpers/Sender';
import { AgentDetail } from 'api/models/Agents/AgentDetail.model';
import { AgentList } from 'api/models/Agents/agentList.model';
import { Pagination } from '../../types/Pagination';

const API = new ApiService();

export const getAgents = async (body: Pagination): Promise<AgentList[]> => {
  const { data } = await API.get('agents', body as Params);
  return data;
};

export const getAgentWithId = async (id: string): Promise<AgentDetail> => {
  const { data } = await API.get(`agents/${id}`);
  return data;
};
