import { Switch } from '@mui/material';
import GreenMark from 'assets/images/green-mark.svg';
import RedCross from 'assets/images/red-cross.svg';
import React, { FC, SyntheticEvent } from 'react';

interface WCExclusionToogleProps {
  excluded: boolean;
  handleChange?: (_e: SyntheticEvent<EventTarget>, v: boolean) => Promise<void>;
  canEdit?: boolean;
}

const WCExclusionToogle: FC<WCExclusionToogleProps> = ({
  excluded,
  handleChange,
  canEdit = false,
}) => (
  <Switch
    checked={!excluded}
    onChange={handleChange}
    color="default"
    disabled={!canEdit}
    sx={{
      width: 91,
      height: 28,
      padding: 0,
      '& .MuiSwitch-switchBase': {
        padding: 0,
        transform: 'translate(2.5px, 2px)',
        '&.Mui-checked': {
          transform: 'translate(65px, 2px)',

          color: (theme) => theme.customColors.white50,
          '& + .MuiSwitch-track': {
            opacity: 1,
          },
        },
        '&.Mui-disabled + .MuiSwitch-track': {
          opacity: 1,
        },
      },
      '& .MuiSwitch-thumb': {
        boxSizing: 'border-box',
        width: 24,
        height: 24,
        textAlign: 'center',
        transitionDuration: '300ms',
        boxShadow: 'none',
        '&::before': {
          content: "''",
          position: 'absolute',
          width: '100%',
          height: '100%',
          left: 0,
          top: 0,
          backgroundRepeat: 'no-repeat',
          backgroundPosition: 'center',
          backgroundImage: `url(${excluded ? RedCross : GreenMark})`,
        },
      },
      '& .MuiSwitch-track': {
        fontSize: (theme) => theme.typography.subtitle2.fontSize,
        fontWeight: (theme) => theme.typography.body2.fontWeight,
        color: (theme) => theme.customColors.white50,
        borderRadius: 28 / 2,
        backgroundColor: (theme) =>
          excluded
            ? theme.customColors.IndividualOwnersOfficersStatusColor.excludedColor
            : theme.customColors.IndividualOwnersOfficersStatusColor.coveredColor,
        opacity: 1,
        '&::before, &::after': {
          content: '""',
          position: 'absolute',
          top: '50%',
          transform: 'translateY(-50%)',
        },
        ...(excluded
          ? {
              '&::before': {
                content: '"Excluded"',
                right: 8,
              },
            }
          : {
              '&::after': {
                content: '"Covered"',
                left: 9,
              },
            }),
      },
    }}
  />
);

export default WCExclusionToogle;
