import { policiesListStatuses } from 'common/constants';
import { createContext } from 'react';

/// types
import { IPoliciesContextType, IPoliciesStore } from './types';

export const initialPoliciesData: IPoliciesStore = {
  data: [],
  loading: true,
  loaded: false,
  selectedPolicyRow: undefined,
  activePolicy: undefined,
  pagination: {
    status: policiesListStatuses.ACTIVE,
    ordering: '',
    page: 1,
    page_size: 20,
    search: '',
    total_count: 0,
  },
  filterListArray: {
    data: [],
    loading: true,
    loaded: false,
  },
};

export const policiesContextDefault: IPoliciesContextType = {
  ...initialPoliciesData,
  fetch: () => new Promise(() => {}),
  resetThreePolicyState: () => {},
};

export const PoliciesContext = createContext<IPoliciesContextType>(null!);
