import { Stack, TextField, useTheme } from '@mui/material';
import NumberFormatComponent from 'components/NumberFormatComponent';
import React, { FC, useRef, useState } from 'react';

interface IPercentageTextfieldProps {
  initialValue?: string;
  onChange: (value: string) => void;
}

const PercentageTextfield: FC<IPercentageTextfieldProps> = ({ initialValue, onChange }) => {
  const [textValue, setTextValue] = useState<string>(initialValue ?? '');
  const themeHook = useTheme();

  const textfieldRef = useRef<any>();

  const onCommentChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const text = event.target.value;
    if (Number(text) > 99) {
      setTextValue('99');
      onChange('99');
    } else if (Number(text) < -99) {
      setTextValue('-99');
      onChange('-99');
    } else {
      setTextValue(text);
      onChange(text);
    }
  };

  const getTextColor = (value: number) => {
    if (value < 0) {
      return themeHook.customColors.successGreen;
    } else if (value > 0) {
      return themeHook.customColors.errorRed;
    }

    return themeHook.customColors.gunMetal;
  };

  return (
    <Stack direction="row" justifyContent="center" alignItems="center">
      <div style={{ width: `100%` }}>
        <TextField
          fullWidth
          inputRef={textfieldRef}
          variant="standard"
          value={textValue}
          onChange={onCommentChange}
          InputProps={{
            disableUnderline: true,
            inputComponent: NumberFormatComponent as any,
            inputProps: {
              isAllowed: (values) => {
                const { floatValue } = values;
                return (floatValue < 100 && floatValue > -100) || floatValue === undefined;
              },
              allowNegative: true,
              decimalScale: 0,
              suffix: '%',
              style: {
                textAlign: 'center',
                fontWeight: '500',
                color: getTextColor(Number(textValue)),
              },
            },
          }}
          sx={{
            padding: '0 8px',
            width: '100%',
            '& input::-webkit-outer-spin-button, & input::-webkit-inner-spin-button': {
              display: 'none',
            },
            '& input[type=number]': {
              MozAppearance: 'textfield',
            },
          }}
        />
      </div>
    </Stack>
  );
};

export default PercentageTextfield;
