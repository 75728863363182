import { ShowCondition } from 'api/models/QuestionEngine/IShowConditions.model';
import { isEmpty } from 'lodash-es';
import { addValidationsToFields, fieldHasValue } from './Utils';

/**
 * Check the question's show conditions and determine,
 * whether it should be shown or not.
 */
export const handleShowCondition = (field, state) => {
  try {
    /**
     * Calculate additional fields for show conditions,
     * Calculated aliases are dependant on other uw questions or policy data.
     * They should be updated whenever dependant data changes.
     * Also new calculated aliases should be defined and calculated below.
     * Initial task: https://dev.azure.com/radity-gmbh/THREE-insurance/_workitems/edit/15651/
     */
    const calculatedAliases = {
      yearsInBusiness: state?.effective_date?.getFullYear() - state?.yearFounded,
    };

    const stateWithCalculatedAliases = { ...state, ...calculatedAliases };

    const handleCondition = {
      boolean: (reference: string, operator: string, value: boolean) => {
        const v = Boolean(value);
        const plainRef = stateWithCalculatedAliases[reference];
        const reff = Boolean(plainRef);

        const operators = {
          '==': () => reff === v,
          '!=': () => reff !== v,
        };

        return fieldHasValue(plainRef) ? operators[operator]() : false;
      },
      string: (reference: string, operator: string, value: string) => {
        const v = `${value}`;
        const plainRef = stateWithCalculatedAliases[reference];
        const reff = `${plainRef}`;

        const operators = {
          '**': () => reff?.includes(v),
          '!*': () => !reff?.includes(v),
          '==': () => reff === v,
          '!=': () => reff !== v,
        };

        return fieldHasValue(plainRef) ? operators[operator]() : false;
      },
      number: (reference: string, operator: string, value: number) => {
        const v = +value;
        const plainRef = stateWithCalculatedAliases[reference];
        const reff = +plainRef;

        const operators = {
          '>': () => reff > v,
          '<': () => reff < v,
          '<=': () => reff <= v,
          '>=': () => reff >= v,
          '==': () => reff === v,
          '!=': () => reff !== v,
        };

        return fieldHasValue(plainRef, [NaN]) ? operators[operator]() : false;
      },
      float: (reference: string | any, operator: string, value: string | any) => {
        // value might be a reference or a value in different cases
        // if value is a reference then get the value of reference from the state object otherwise use the value
        const valueRef = stateWithCalculatedAliases[value];
        let v = valueRef ? parseFloat(valueRef) : parseFloat(value);

        // reference might be a reference or a value in different cases
        // if reference is a reference then get the value of reference from the state object otherwise use the reference as value
        const plainRef = stateWithCalculatedAliases[reference];
        let reff = plainRef ? parseFloat(plainRef) : parseFloat(reference);

        // if there is an reference or value is recursive assume that there is an aritmetic operation
        if (reference.reference || value.value) {
          const recursiveRef = reference.reference
            ? handleCondition[reference.value_type](
                reference.reference,
                reference.relational_operator,
                reference.value,
              )
            : reference;
          reff = parseFloat(recursiveRef);
        }
        if (value.value) {
          const recursiveV = value.value
            ? handleCondition[value.value_type](
                value.reference,
                value.relational_operator,
                value.value,
              )
            : value;
          v = parseFloat(recursiveV);
        }

        const operators = {
          '>': () => reff > v,
          '<': () => reff < v,
          '<=': () => reff <= v,
          '>=': () => reff >= v,
          '==': () => reff === v,
          '!=': () => reff !== v,
          // Artihmetic operators
          '+': () => reff + v,
          '-': () => reff - v,
          '*': () => reff * v,
          '/': () => reff / v,
          '%': () => reff % v,
        };

        return fieldHasValue(reff, [NaN]) ? operators[operator]() : false;
      },
      array: (reference: string, operator: string, value: any[]) => {
        const reff = stateWithCalculatedAliases[reference];

        const operators = {
          in: () => value?.includes(reff),
          '!in': () => !value?.includes(reff),
        };

        return fieldHasValue(reff) ? operators[operator]() : false;
      },
    };

    const checkShowCondition = (SC: ShowCondition): boolean => {
      if (SC) {
        const isLogicalCondition = !isEmpty(SC.logical_operator);

        if (!isLogicalCondition) {
          return handleCondition[SC.value_type!](SC.reference, SC.relational_operator, SC.value);
        } else {
          const { logical_operator: LO, operations } = SC;

          if (LO === 'and') {
            const results: boolean[] = operations?.map((condition) =>
              checkShowCondition(condition),
            ) ?? [true];

            return results.every((R) => R);
          } else if (LO === 'or') {
            const results: boolean[] = operations?.map((condition) =>
              checkShowCondition(condition),
            ) ?? [true];

            return results.some((R) => R);
          } else {
            return true;
          }
        }
      }

      return true;
    };

    const SC = field?.show_condition;

    const showField = checkShowCondition(SC);

    return showField;
  } catch (_error) {
    // if there is an error checking the conditions do not show the field
    return false;
    // TODO: send error to sentry
  }
};

/**
 * Check questions show conditions and get every reference key used in there
 * Unique them and combine them at the section level as well
 *
 * These `relatedFields` will be used in question engine parser to only re-render,
 * specific section if its related keys are changed.
 */
export const modifyUnderwritingQuestions = (data) =>
  data?.map((page) => ({
    ...page,
    fields: page?.fields?.map((f) => {
      const groupRelatedFields: any[][] = [];
      const groupValidations: any[] = [];

      addValidationsToFields(f.nested_fields ?? [], false);

      return {
        ...f,
        nested_fields: (f.nested_fields ?? []).map((q) => {
          groupValidations.push({
            code: q.code,
            validationType: q.validationType,
            validations: q.validations,
          });

          const relatedFields = [q.code];

          // traverse `show_condition` and get the `references`
          const traverseShowCondition = (SC: ShowCondition): void => {
            if (SC) {
              const isLogicalCondition = !isEmpty(SC.logical_operator);

              if (!isLogicalCondition) {
                relatedFields.push(SC.reference);
              } else {
                const { logical_operator: LO, operations } = SC;

                if (LO === 'and' || LO === 'or') {
                  operations?.forEach((condition) => traverseShowCondition(condition));
                }
              }
            }
          };

          const SC = (q as any)?.show_condition;
          traverseShowCondition(SC);

          const uniqReleatedFields = [...new Set(relatedFields)] as string[];
          groupRelatedFields.push(uniqReleatedFields);

          return { ...q, relatedFields: uniqReleatedFields };
        }),
        relatedFields: [...new Set(groupRelatedFields.flat())],
        validations: groupValidations,
      };
    }),
  }));

export const maskedInputsConfig = {
  ssn: {
    format: '###-##-####',
    mask: '_',
  },
  fein: {
    format: '##-#######',
    mask: '_',
  },
};
