/* eslint-disable react/jsx-indent */
/* eslint-disable array-callback-return */
/* eslint-disable consistent-return */
/* eslint-disable react/no-unused-prop-types */
import { KeyboardArrowDownRounded } from '@mui/icons-material';
import {
  Box,
  Button,
  FormControl,
  FormHelperText,
  FormLabel,
  Grid,
  InputLabel,
  Radio,
  Skeleton,
  Stack,
  SxProps,
  TextField,
  ToggleButton,
  ToggleButtonGroup,
  Tooltip,
  Typography,
} from '@mui/material';
import { Theme } from '@mui/system';
import { DynamicField } from 'api/models/DynamicFields/dynamicField.model';
import CalendarSchedule from 'assets/images/CalendarSchedule.svg';
import { dataFieldTypes, defaultDateFormat } from 'common/constants';
import CustomNativeSelect from 'components/CustomNativeSelect';
import DatePickerComponent from 'components/DatePickerComponent';
import NumberFormatComponent from 'components/NumberFormatComponent';
import { DollarPrefixedNumberFormatComponent } from 'components/NumberFormatComponent/DollarPrefixedNumberFormatComponent';
import { DetailDrawerLoader } from 'components/QuotePolicyDetailEndorsement/DrawerLoader';
import { ILocationSelect } from 'components/QuotePolicyDetailEndorsement/Inputs/LocationSelectInput/types';
import {
  calendarIconStyle,
  readOnlyInputStyle,
  readOnlySelectInputStyle,
} from 'helpers/MuiSharedStyles';
import { currencyFormat, preventCharactersOnInputChange } from 'helpers/Utils';
import useUser from 'hooks/useUser';
import { isEmpty, uniqBy } from 'lodash-es';
import React, { ChangeEvent, FC, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import InputMask from 'react-input-mask';
import { ReactSVG } from 'react-svg';

interface IProps {
  formik: any;
  isEdit?: boolean;
  showLoader?: boolean;
  fields?: DynamicField[];
  loaded?: boolean;
  splitSize?: number;
  state?: any;
  setState?: any;
  rowSpacing?: number;
  columnSpacing?: number;
  isReadOnly?: boolean;
  sx?: SxProps<Theme>;
  onChangeEmited?: ({ field, value }: { field: string; value: any }) => void;
  handleVerifyVINClick?: () => void;
  LocationSelect: FC<ILocationSelect>;
}

export const VehicleDrawerFieldParser: FC<IProps> = ({
  isEdit = false,
  showLoader = false,
  isReadOnly = false,
  fields,
  splitSize = 2,
  state,
  setState,
  rowSpacing = 1.5,
  columnSpacing = 3,
  formik,
  sx,
  onChangeEmited,
  handleVerifyVINClick,
  LocationSelect,
}) => {
  const { t } = useTranslation();
  const { data: user, loading: userLoading } = useUser();

  const handleFieldType = (field: DynamicField) => {
    const testPrefix = `${field.code}`;

    const {
      loading,
      isLocationSelect,
      emitIsChangedEvent,
      currencyInput,
      showAsKeyValue,
      isReadOnlyForRoles,
    } = field?.additional_data ?? {};

    const isFieldReadOnly =
      field?.is_readonly ||
      isReadOnly ||
      !!isReadOnlyForRoles?.includes(user?.role?.code) ||
      field?.additional_data?.is_readonly;

    const isVerifyVINButtonDisabled = isEmpty(state.veh_vin);

    const checkEmitIsChanged = ({ fieldName, value }: { fieldName: string; value: any }) => {
      if (emitIsChangedEvent) {
        onChangeEmited?.({ field: fieldName, value });
      }
    };

    const handleInputChange = (
      event: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>,
      inputField?: DynamicField,
    ) => {
      let inputValue = event.target.value;
      const inputName = event.target.name;

      if (inputField) {
        inputValue = preventCharactersOnInputChange({ inputValue, field: inputField });
      }

      setState({ ...state, [inputName]: inputValue });

      checkEmitIsChanged({ fieldName: inputName, value: inputValue });
    };

    if (loading) {
      return (
        <FormControl variant="standard" sx={{ width: '380px' }} key={`${field.code}l`}>
          <Skeleton sx={{ mr: '40%' }} animation="wave" width="100%" height={37} />
        </FormControl>
      );
    }

    if (isLocationSelect) {
      return (
        <LocationSelect
          state={state}
          setState={setState}
          formik={formik}
          fieldConfig={field}
          onSelect={(value) => checkEmitIsChanged({ fieldName: field.code, value })}
          isReadOnly={isFieldReadOnly}
          sx={{ width: '380px' }}
        />
      );
    } else if (showAsKeyValue) {
      return (
        <Box
          sx={{
            display: 'flex',
            justifyContent: 'space-between',
            flex: 1,
            mt: -rowSpacing,
            pt: '6px',
            pl: '12px',
          }}
          key={field.code}
        >
          {field?.additional_data?.loading ? (
            <>
              <Skeleton sx={{ mr: '40%' }} animation="wave" width="20%" height={20} />
              <Skeleton animation="wave" width="40%" height={20} />
            </>
          ) : (
            <>
              <Typography
                sx={{
                  fontSize: 14,
                  lineHeight: '21px',
                  fontWeight: 500,
                }}
              >
                {field?.name}
              </Typography>
              <Typography
                sx={{
                  fontSize: 14,
                  lineHeight: '21px',
                  fontWeight: 400,
                }}
              >
                {state[field.code] ?? ''}
              </Typography>
            </>
          )}
        </Box>
      );
    }

    switch (field.type) {
      case dataFieldTypes.STRING:
        return (
          <Box sx={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
            <FormControl variant="standard" sx={{ width: '380px' }} key={field.code}>
              <TextField
                id={field.code}
                name={field.code}
                label={field.name}
                size="small"
                required={!field.is_optional}
                onChange={(event) => handleInputChange(event, field)}
                value={state[field.code] ?? ''}
                sx={isFieldReadOnly ? readOnlyInputStyle : {}}
                InputLabelProps={isFieldReadOnly ? { shrink: true } : {}}
                inputProps={{
                  autoComplete: 'off',
                  ...(isFieldReadOnly && { readOnly: true }),
                  'data-test': testPrefix,
                }}
                error={formik.touched[`${field.code}`] && Boolean(formik.errors[`${field.code}`])}
                helperText={formik.touched[`${field.code}`] && formik.errors[`${field.code}`]}
              />
            </FormControl>

            {field.additional_data?.show_verify_vin_button && isEdit && (
              <FormControl variant="standard" sx={{ width: '58px' }} key={`${field.code}a`}>
                {state.veh_vin_isvalid !== 'Yes' ? (
                  <Tooltip
                    arrow
                    title={isVerifyVINButtonDisabled ? 'Enter VIN number to verify.' : ''}
                  >
                    <Box component="span">
                      <Button
                        data-test="verify-vin"
                        onClick={handleVerifyVINClick}
                        variant="text"
                        disabled={isVerifyVINButtonDisabled}
                        disableRipple
                        sx={{
                          width: '58px',
                          p: 0,
                          ml: 1,
                          fontSize: '12px',
                          color: (theme) => theme.customColors.copper,
                          '&:hover': {
                            textDecoration: 'underline',
                            background: 'transparent',
                          },
                          '&:active': {
                            background: 'transparent',
                          },
                        }}
                      >
                        {t('Verify VIN')}
                      </Button>
                    </Box>
                  </Tooltip>
                ) : (
                  <Typography
                    sx={{
                      width: '58px',
                      p: 0,
                      ml: 1,
                      fontSize: '12px',
                      whiteSpace: 'nowrap',
                      color: (theme) => theme.customColors.successGreen,
                    }}
                  >
                    {t('VIN Verified')}
                  </Typography>
                )}
              </FormControl>
            )}
          </Box>
        );

      case dataFieldTypes.PHONE:
        return (
          <FormControl
            variant="standard"
            sx={{ width: '380px' }}
            key={field.code}
            required={!field.is_optional}
          >
            <Stack
              sx={{
                '& .MuiFormControl-root': {
                  flex: 'auto',
                },
                py: 1,
                display: 'flex',
                flexDirection: 'row',
                alignItems: 'center',
                justifyContent: 'space-between',
              }}
            >
              <InputMask
                mask="(999) 999-9999"
                disabled={false}
                maskChar=" "
                onChange={handleInputChange}
                value={state[field.code] || ''}
              >
                {() => (
                  <TextField
                    sx={[{ flex: 'auto' }, isFieldReadOnly ? readOnlyInputStyle : {}]}
                    id={field.code}
                    name={field.code}
                    size="small"
                    required={!field.is_optional}
                    value={state[field.code] || ''}
                    inputProps={{
                      autoComplete: 'off',
                      ...(isFieldReadOnly && { readOnly: true }),
                      'data-test': testPrefix,
                    }}
                    error={
                      formik.touched[`${field.code}`] && Boolean(formik.errors[`${field.code}`])
                    }
                    helperText={formik.touched[`${field.code}`] && formik.errors[`${field.code}`]}
                  />
                )}
              </InputMask>
            </Stack>
          </FormControl>
        );

      case dataFieldTypes.NUMBER:
        return (
          <FormControl variant="standard" sx={{ width: '380px' }} key={field.code}>
            <TextField
              sx={[
                {
                  '& input::-webkit-outer-spin-button, & input::-webkit-inner-spin-button': {
                    display: 'none',
                  },
                  '& input[type=number]': {
                    MozAppearance: 'textfield',
                  },
                },
                isFieldReadOnly ? readOnlyInputStyle : {},
              ]}
              label={field.name}
              id={field.code}
              name={field.code}
              size="small"
              required={!field.is_optional}
              type={currencyInput ? 'text' : 'number'}
              onChange={handleInputChange}
              value={state[field.code] || ''}
              inputProps={{
                autoComplete: 'off',
                ...(isFieldReadOnly && {
                  readOnly: true,
                }),
                'data-test': testPrefix,
              }}
              InputLabelProps={isFieldReadOnly ? { shrink: true } : {}}
              // eslint-disable-next-line react/jsx-no-duplicate-props
              InputProps={{
                autoComplete: 'new-password',
                inputComponent: currencyInput && (DollarPrefixedNumberFormatComponent as any),
              }}
              error={formik.touched[`${field.code}`] && Boolean(formik.errors[`${field.code}`])}
              helperText={formik.touched[`${field.code}`] && formik.errors[`${field.code}`]}
            />
          </FormControl>
        );

      case dataFieldTypes.EMAIL:
        return (
          <FormControl variant="standard" sx={{ width: '380px' }} key={field.code}>
            <TextField
              sx={[
                {
                  '& .MuiOutlinedInput-root': {
                    borderRadius: '4px',
                    '& .MuiInputBase-input': {
                      py: 1.5,
                    },
                    '&.Mui-focused fieldset': {
                      border: 2,
                    },
                  },
                },
                isFieldReadOnly ? readOnlyInputStyle : {},
              ]}
              id={field.code}
              name={field.code}
              size="small"
              type="email"
              required={!field.is_optional}
              // label={field.name}
              placeholder={`${field.name}${!field.is_optional ? ' *' : ''}`}
              onChange={handleInputChange}
              value={state[field.code] || ''}
              error={formik.touched[field.code] && Boolean(formik.errors[field.code])}
              helperText={formik.touched[field.code] && formik.errors[field.code]}
              inputProps={{
                autoComplete: 'off',
                ...(isFieldReadOnly && { readOnly: true }),
                'data-test': testPrefix,
              }}
            />
          </FormControl>
        );

      case dataFieldTypes.DATE:
        return (
          <FormControl
            variant="standard"
            sx={{ width: '380px', ...calendarIconStyle }}
            key={field.code}
          >
            <Stack
              sx={{
                '& .MuiFormControl-root': {
                  flex: 'auto',
                },
                py: 1,
                display: 'flex',
                flexDirection: 'column',
                // alignItems: 'center',
                justifyContent: 'center',
              }}
            >
              <FormLabel
                sx={{
                  fontSize: 12,
                  lineHeight: 1.5,
                  color: (theme) => theme.customColors.black,
                  mr: (theme) => theme.spacing(1.25),
                  minWidth: '142px',
                  maxWidth: '142px',
                }}
                required={!field.is_optional}
              >
                {field.name}
              </FormLabel>
              <DatePickerComponent
                format={defaultDateFormat}
                minDate={field?.minimum_date}
                maxDate={field?.maximum_date}
                onChange={(newValue) => {
                  setState({ ...state, [field.code]: newValue! });
                  checkEmitIsChanged({ fieldName: field.code, value: newValue });
                }}
                value={state[field.code] ?? null}
                slots={{
                  openPickerIcon: () => (
                    <ReactSVG className="calendar-icon" src={CalendarSchedule} />
                  ),
                }}
                slotProps={{
                  textField: {
                    size: 'small',
                    required: !field.is_optional,
                    id: field.code,
                    name: field.code,
                    inputProps: {
                      autoComplete: 'off',
                      'data-test': testPrefix,
                    },
                    error:
                      formik.touched[`${field.code}`] && Boolean(formik.errors[`${field.code}`]),
                    helperText: formik.touched[`${field.code}`] && formik.errors[`${field.code}`],
                  },
                }}
              />
            </Stack>
          </FormControl>
        );

      case dataFieldTypes.SELECT:
        return (
          <FormControl
            required={!field.is_optional}
            size="small"
            sx={[{ width: '380px' }, isFieldReadOnly ? readOnlySelectInputStyle : {}]}
            key={field.code}
            error={formik.touched[`${field.code}`] && Boolean(formik.errors[`${field.code}`])}
          >
            <Stack
              sx={{
                '& .MuiFormControl-root': {
                  flex: 'auto',
                  border: '1px solid',
                },
              }}
            >
              <InputLabel
                required={!field.is_optional}
                id={`${field.code}_label`}
                shrink={isFieldReadOnly ? true : undefined}
                error={formik.touched[`${field.code}`] && Boolean(formik.errors[`${field.code}`])}
              >
                {field.name}
              </InputLabel>
              <CustomNativeSelect
                required={!field.is_optional}
                id={field.code}
                labelId={`${field.code}_label`}
                label={field.name}
                name={field.code}
                IconComponent={KeyboardArrowDownRounded}
                error={formik.touched[`${field.code}`] && Boolean(formik.errors[`${field.code}`])}
                value={state[field.code] ?? ''}
                inputProps={{
                  autoComplete: 'off',
                  readOnly: isFieldReadOnly,
                  'data-test': testPrefix,
                }}
                sx={{
                  '& > .MuiSelect-select': { display: 'inline-block' },
                  width: '380px',
                }}
                MenuProps={{
                  sx: {
                    width: 100,
                    wordBreak: 'break-all',
                    whiteSpace: 'pre-wrap',
                    '& .MuiMenuItem-root': { whiteSpace: 'normal' },
                  },
                }}
                onChange={(event) => {
                  setState({ ...state, [field.code]: event.target.value });
                  checkEmitIsChanged({ fieldName: field.code, value: event.target.value });
                }}
              >
                {uniqBy([...(field.choices ?? [])], 'code')?.map((type) => (
                  <option key={type.code} value={type.code}>
                    {type.name}
                  </option>
                ))}
              </CustomNativeSelect>

              {formik.touched[`${field.code}`] && Boolean(formik.errors[`${field.code}`]) && (
                <FormHelperText
                  sx={{
                    mt: 1,
                    fontSize: '12px',
                    lineHeight: '14px',
                    color: (theme) => theme.customColors.alert,
                  }}
                >
                  {formik.errors[`${field.code}`]}
                </FormHelperText>
              )}
            </Stack>
          </FormControl>
        );

      case dataFieldTypes.SELECT_BUTTON:
        return (
          <FormControl size="small" sx={{ width: '380px', position: 'relative' }} key={field.code}>
            {field.name && (
              <Typography
                sx={{
                  fontWeight: 400,
                  fontSize: '12px',
                  lineHeight: '14px',
                  whiteSpace: 'nowrap',
                  mb: 1,
                }}
              >
                {field.name} {!field?.is_optional && '*'}
              </Typography>
            )}
            <ToggleButtonGroup
              exclusive
              value={state[field.code] ?? null}
              onChange={(event, newValue) => {
                if (newValue) {
                  setState({ ...state, [field.code]: newValue });
                  formik.setFieldValue([field.code], newValue);
                  checkEmitIsChanged({ fieldName: field.code, value: newValue });
                }
              }}
              sx={{ gap: 2 }}
              aria-label={field.name}
            >
              {field.choices?.map((choice) => (
                <ToggleButton
                  data-test={`${testPrefix}_${choice.code}`}
                  disabled={isFieldReadOnly}
                  sx={{
                    whiteSpace: 'nowrap',
                    backgroundColor: (theme) => theme.customColors.grey1150,
                    minWidth: '120px',
                    height: '44px',
                    mb: 1,
                    '&.MuiToggleButton-root': {
                      py: 1.25,
                      px: 5,
                      color: (theme) => theme.customColors.gunMetal,
                      borderColor: (theme) => theme.customColors.gunMetal,
                      '&.MuiToggleButtonGroup-grouped:not(:first-of-type) ': {
                        borderLeft: '1px solid #2B2B2A',
                        ml: 0,
                        borderRadius: '2px',
                      },
                      '&.MuiToggleButtonGroup-grouped:not(:last-of-type) ': {
                        borderLeft: '1px solid #2B2B2A',
                        ml: 0,
                        borderRadius: '2px',
                      },
                      '&.Mui-selected': {
                        border: '2px solid !important',
                        borderColor: '#BB4F09 !important',
                      },
                    },
                  }}
                  value={choice.code}
                  key={choice.code}
                  aria-label={choice.name}
                >
                  <Radio
                    checked={state[field.code] === choice.code}
                    size="small"
                    disableRipple
                    sx={{
                      fontSize: 15,
                      width: 15,
                      height: 15,
                      mr: 1,
                      '&:hover': {
                        background: 'none',
                      },
                      color:
                        state[field.code] === choice.code
                          ? (theme) => theme.customColors.copper
                          : (theme) => theme.customColors.gunMetal,
                      '&.Mui-checked': {
                        color:
                          state[field.code] === choice.code
                            ? (theme) => theme.customColors.copper
                            : (theme) => theme.customColors.gunMetal,
                      },
                    }}
                  />

                  <Typography
                    sx={{
                      fontWeight: 500,
                      fontSize: '16px',
                      lineHeight: '24px',
                      textTransform: 'none',
                    }}
                  >
                    {Number.isNaN(Number(choice.name)) ? (
                      choice.name
                    ) : field.additional_data?.withDollarIcon ? (
                      `${currencyFormat('USD', choice.name!).merged}`
                    ) : (
                      <NumberFormatComponent displayType="text" value={choice.name} />
                    )}
                  </Typography>
                </ToggleButton>
              ))}
            </ToggleButtonGroup>
            <FormHelperText
              sx={{ position: 'absolute', bottom: '-24px', mb: 1 }}
              error={formik.touched[field.code] && Boolean(formik.errors[field.code])}
            >
              {formik.touched[field.code] && Boolean(formik.errors[field.code])
                ? formik.errors[field.code]
                : null}
            </FormHelperText>
          </FormControl>
        );

      case dataFieldTypes.LABEL:
        return (
          <Box
            sx={{
              display: 'flex',
              justifyContent: 'space-between',
              flex: 1,
            }}
            key={field.code}
          >
            <>
              <Typography
                sx={{
                  fontSize: 14,
                  lineHeight: '21px',
                  fontWeight: 500,
                }}
              >
                {field?.name}
              </Typography>
              <Typography
                sx={{
                  fontSize: 14,
                  lineHeight: '21px',
                  fontWeight: 400,
                }}
              >
                {state[field.code] ?? ''}
              </Typography>
            </>
          </Box>
        );

      default:
    }
  };

  const handleConditionalField = (field) => {
    const handleCondition = {
      select: (condition, fieldCode) => {
        const { value } = condition;
        return value.includes(state[fieldCode]);
      },
      number: (condition, fieldCode) => {
        const { value, operator } = condition;
        const operators = {
          '>': () => state[fieldCode] > +value,
          '<': () => state[fieldCode] < +value,
        };
        return operators[operator]();
      },
    };

    if (field?.condition && Object.keys(field.condition).length > 0) {
      const results: boolean[] = [];
      // eslint-disable-next-line no-restricted-syntax
      for (const [fieldCode, fieldConditions] of Object.entries(
        field.condition,
      ) as unknown as any[]) {
        const result = fieldConditions.every((condition) =>
          handleCondition[condition.type](condition, fieldCode),
        );
        results.push(result);
      }
      return results.every((result) => result);
    }
    return true;
  };

  useEffect(() => {
    fields?.map((f) => {
      if (f.type === dataFieldTypes.SELECT && f.choices?.length === 1) {
        setState((prevState) => ({ ...prevState, [f.code!]: f.choices?.[0].code }));
      }
    });
  }, []);

  return (
    <>
      {showLoader || userLoading ? (
        <DetailDrawerLoader />
      ) : !isEmpty(fields) ? (
        <Grid
          sx={{ alignItems: 'flex-start' }}
          container
          rowSpacing={rowSpacing}
          columnSpacing={columnSpacing}
          columns={splitSize}
        >
          {fields?.map((field) => {
            const { showOnlyForRoles } = field?.additional_data ?? {};

            if (!isEmpty(showOnlyForRoles) && !showOnlyForRoles?.includes(user?.role?.code)) {
              return <React.Fragment key={`${field.code + field.type}__hidden`} />;
            }

            return (
              handleConditionalField(field) &&
              field &&
              !field?.is_hidden && (
                <React.Fragment key={field.code + field.type}>
                  {(field.heading ||
                    field.additional_data?.section_heading ||
                    field.newline ||
                    field.additional_data?.space_above) && (
                    <Grid
                      item
                      key={`${field.code}_header`}
                      xs={splitSize}
                      sx={[
                        field.newline ? { p: '0 !important' } : {},
                        field.additional_data?.space_above ? { mt: 1.5 } : {},
                      ]}
                    >
                      {field.heading && (
                        <Typography
                          sx={{
                            fontWeight: 500,
                            mt: 1.5,
                            fontSize: '16px',
                            lineHeight: 1.5,
                          }}
                        >
                          {field.heading}
                        </Typography>
                      )}
                      {field.additional_data?.section_heading && (
                        <Typography
                          sx={{
                            mt: 4,
                            fontWeight: 500,
                            fontSize: '20px',
                            lineHeight: '26px',
                            color: '#2B2B2A',
                          }}
                        >
                          {field.additional_data.section_heading}
                        </Typography>
                      )}
                    </Grid>
                  )}

                  <Grid
                    item
                    key={field.code + field.type}
                    xs={3}
                    sx={[...(Array.isArray(sx) ? sx : [sx])]}
                  >
                    {handleFieldType(field)}
                  </Grid>
                </React.Fragment>
              )
            );
          })}
        </Grid>
      ) : (
        <></>
      )}
    </>
  );
};
