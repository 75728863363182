import { Skeleton, SxProps, Theme } from '@mui/material';
import React, { FC } from 'react';

interface IProps {
  count: number;
  width: string | number;
  height: string | number;
  sx: SxProps<Theme>;
}

const SkeletonList: FC<IProps> = ({ count, width, height, sx }) => (
  <>
    {[...Array(count).keys()].map((_, i) => (
      <Skeleton
        animation="wave"
        width={width}
        height={height}
        sx={[...(Array.isArray(sx) ? sx : [sx])]}
        key={i}
      />
    ))}
  </>
);

export default SkeletonList;
