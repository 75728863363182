import { createContext } from 'react';

/// types
import { IDocumentsContextType, IDocumentsStore } from './types';

export const initialDocumentsData: IDocumentsStore = {
  data: [],
  loading: true,
  loaded: false,
  selectedDocumentRow: undefined,
  activeDocument: {
    data: {},
    loading: true,
    loaded: false,
  },
  pagination: {
    ordering: '',
    page: 1,
    page_size: 20,
    search: '',
    total_count: 0,
  },
};

export const documentsContextDefault: IDocumentsContextType = {
  ...initialDocumentsData,
  resetDocumentsState: () => {},
  fetch: () => new Promise(() => {}),
  fetchDocument: () => new Promise(() => {}),
  createDocument: () => new Promise(() => {}),
  deleteDocument: () => new Promise(() => {}),
  reFetchData: () => new Promise(() => {}),
  updateDocument: () => new Promise(() => {}),
};

export const DocumentsContext = createContext<IDocumentsContextType>(null!);
