/* eslint-disable react/require-default-props, react/jsx-indent */
import { Box, Button, Skeleton, Stack, Typography } from '@mui/material';
import { defaultDateTimeFormat } from 'common/constants';
import DrawerComponent from 'components/DrawerComponent';
import {
  drawerFooterPrimaryButtonStyle,
  drawerFooterSecondaryButtonStyle,
  showHiddenScroll,
} from 'helpers/MuiSharedStyles';
import { deleteFromQueryStrings } from 'helpers/Utils';
import useConfig from 'hooks/useConfig';
import useNotes from 'hooks/useNotes';
import { isEmpty } from 'lodash-es';
import qs from 'query-string';
import React, { FC, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory, useLocation } from 'react-router-dom';

export interface NoteDetailProps {
  isDrawerOpen: boolean;
  isRelatedToInfoVisible?: boolean;
}

const NoteDetailDrawer: FC<NoteDetailProps> = ({ isDrawerOpen, isRelatedToInfoVisible = true }) => {
  const { t } = useTranslation();
  const { formatDateInTimeZone } = useConfig();
  const HISTORY = useHistory();
  const LOCATION = useLocation();
  const { note } = qs.parse(LOCATION.search);

  const { activeNote, fetchNote } = useNotes();

  useEffect(() => {
    fetchNote(note as string);
  }, []);

  const handleQuery = () => {
    HISTORY.push({
      search: deleteFromQueryStrings({
        locationSearch: LOCATION.search,
        omitKeys: ['note'],
      }),
    });
  };

  const handleEdit = () => {
    HISTORY.push({
      search: `?edit_note_id=${note}`,
      state: {
        prevPath: `?note=${note}`,
      },
    });
  };

  return (
    <DrawerComponent
      isDrawerOpen={isDrawerOpen}
      onClose={() => handleQuery()}
      header={
        <Typography
          sx={{
            fontSize: 16,
            fontWeight: 500,
            lineHeight: '24px',
            color: (theme) => theme.customColors.drawer.header,
            wordBreak: 'break-word',
          }}
        >
          {!activeNote?.loading ? (
            <>{`${activeNote.data?.subject}`}</>
          ) : (
            <Skeleton animation="wave" width="60%" height={25} />
          )}
        </Typography>
      }
      content={
        <Stack gap={3}>
          {isRelatedToInfoVisible && (
            <Box sx={{ mt: 1.5 }}>
              <Typography
                variant="body1"
                sx={{ color: (theme) => theme.customColors.grey800, fontWeight: 600 }}
              >
                {t('Related to')}
              </Typography>
              <Typography
                sx={{
                  py: 1,
                  boxShadow: (theme) => theme.customShadows.shadow7,
                  fontWeight: 400,
                  color: (theme) => theme.palette.primary.main,
                }}
              >
                {!activeNote?.loading ? (
                  <>{activeNote?.data?.reference ?? '-'}</>
                ) : (
                  <Skeleton animation="wave" width="60%" height={20} />
                )}
              </Typography>
            </Box>
          )}
          <Box>
            <Typography
              variant="body1"
              sx={{ color: (theme) => theme.customColors.grey800, fontWeight: 600 }}
            >
              {t('Entered by')}
            </Typography>
            <Typography
              sx={{
                py: 1,
                boxShadow: (theme) => theme.customShadows.shadow7,
                fontWeight: 400,
                color: (theme) => theme.palette.primary.main,
              }}
            >
              {!activeNote?.loading ? (
                <>{activeNote?.data?.created_by ?? '-'}</>
              ) : (
                <Skeleton animation="wave" width="60%" height={20} />
              )}
            </Typography>
          </Box>

          <Box>
            <Typography
              variant="body1"
              sx={{ color: (theme) => theme.customColors.grey800, fontWeight: 600 }}
            >
              {t('Date Created')}
            </Typography>
            <Typography
              sx={{
                py: 1,
                boxShadow: (theme) => theme.customShadows.shadow7,
                fontWeight: 400,
                color: (theme) => theme.palette.primary.main,
              }}
            >
              {!activeNote?.loading ? (
                <>
                  {formatDateInTimeZone(activeNote?.data?.created_at as string)}{' '}
                  {formatDateInTimeZone(
                    activeNote?.data?.created_at as string,
                    defaultDateTimeFormat,
                  )}
                </>
              ) : (
                <Skeleton animation="wave" width="60%" height={20} />
              )}
            </Typography>
          </Box>
          <Box>
            <Typography
              variant="body1"
              sx={{ color: (theme) => theme.customColors.grey800, fontWeight: 600 }}
            >
              {t('Description')}
            </Typography>
            <Typography
              sx={{
                py: 1,
                fontWeight: 400,
                color: (theme) => theme.palette.primary.main,
              }}
            >
              {!activeNote?.loading ? (
                <Box
                  sx={{
                    ...showHiddenScroll,
                    whiteSpace: 'pre-wrap',
                    wordBreak: 'break-word',
                    maxHeight: 320,
                    overflowY: 'auto',
                  }}
                >
                  {!isEmpty(activeNote?.data?.note!) ? activeNote?.data?.note! : '-'}
                </Box>
              ) : (
                <Skeleton animation="wave" width="60%" height={20} />
              )}
            </Typography>
          </Box>
        </Stack>
      }
      footer={
        <Box>
          <Button onClick={handleQuery} sx={[drawerFooterSecondaryButtonStyle]}>
            {t('Close')}
          </Button>
          <Button onClick={handleEdit} sx={[drawerFooterPrimaryButtonStyle]}>
            {t('Edit')}
          </Button>
        </Box>
      }
    />
  );
};

export default NoteDetailDrawer;
