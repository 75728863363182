import { createContext } from 'react';

/// types
import { IRouterPromptContextType, IRouterPromptStore } from './types';

export const initialRouterPromptData: IRouterPromptStore = {
  when: false,
  bypassRouteBlock: false,
};

export const routerPromptContextDefault: IRouterPromptContextType = {
  ...initialRouterPromptData,
  resetRouterState: () => {},
  setWhen: () => false,
  setBypassRouteBlock: () => {},
};

export const RouterPromptContext = createContext<IRouterPromptContextType>(null!);
