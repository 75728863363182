import { GridColDef } from '@mui/x-data-grid';

export const TEAMS_TABLE_CONFIG: GridColDef[] = [
  {
    field: 'name',
    headerName: 'Team Name',
    flex: 2,
    sortable: true,
  },
  {
    field: 'email',
    headerName: 'Email',
    flex: 2,
    sortable: true,
  },
  {
    field: 'state',
    headerName: 'State',
    flex: 1,
    sortable: true,
  },
  {
    field: 'customer_service_phone_number',
    headerName: 'Phone Number',
    flex: 1,
    sortable: true,
  },
];

export const POLICY_TABLE_CONFIG: GridColDef[] = [
  {
    field: 'three_policy_number',
    headerName: 'Policy No.',
    type: 'string',
    sortable: true,
    minWidth: 120,
    flex: 1,
  },
  {
    field: 'product__name',
    headerName: 'Product',
    sortable: true,
    minWidth: 150,
    flex: 1,
  },
  {
    field: 'policyholder__name',
    headerName: 'Named Insured',
    sortable: true,
    minWidth: 150,
    flex: 1,
  },
  {
    field: 'owner_name',
    headerName: 'Producer',
    sortable: true,
    minWidth: 150,
    flex: 1,
  },
  {
    field: 'original_contract_start_time',
    headerName: 'Effective Date',
    type: 'date',
    sortable: true,
    flex: 0.1,
  },
  {
    field: 'status__value',
    headerName: 'Status',
    sortable: true,
    flex: 0.1,
  },
];
