import { policyInvoiceStatuses, threeExposureNames, threePolicyGroupNames } from 'common/constants';
import { createContext } from 'react';

/// types
import { IPolicyDetailContextType, IPolicyDetailStore, TGroups } from './types';

export const initialPolicyDetailData: IPolicyDetailStore = {
  data: undefined,
  activePolicy: undefined,
  exposures: {
    data: [],
    loading: true,
    loaded: false,
    pagination: {
      ordering: '',
      page: 1,
      page_size: 10000,
      search: '',
      total_count: 0,
    },
  },
  exposureList: Object.values(threeExposureNames).reduce(
    (a, curr) => ({
      ...a,
      [`${curr}`]: {
        data: [],
        loading: true,
        loaded: false,
        pagination: {
          ordering: '',
          page: 1,
          page_size: 10000,
          search: '',
          total_count: 0,
        },
      },
    }),
    {},
  ),
  groups: Object.values(threePolicyGroupNames).reduce(
    (a, curr) => ({
      ...a,
      [`${curr}`]: {
        data: [],
        loading: true,
        loaded: false,
      },
    }),
    {},
  ) as TGroups,
  perils: {
    data: [],
    loading: true,
    loaded: false,
    pagination: {
      ordering: '',
      page: 1,
      page_size: 20,
      search: '',
      total_count: 0,
    },
  },
  invoices: {
    data: [],
    loading: true,
    loaded: false,
    pagination: {
      ordering: 'due_time',
      page: 1,
      page_size: 10000,
      search: '',
      total_count: 10000,
    },
  },
  groupedInvoices: Object.values(policyInvoiceStatuses).reduce(
    (a, curr) => ({
      ...a,
      [`${curr}`]: {
        data: [],
        loading: true,
        loaded: false,
      },
    }),
    {},
  ) as TGroups,
  endorsements: {
    data: [],
    loading: true,
    loaded: false,
  },
  cancellations: {
    data: [],
    loading: true,
    loaded: false,
  },

  exposureDetail: {
    data: { data: {} },
    loading: true,
    loaded: false,
  },
  loading: true,
  loaded: false,
  tasks: {
    data: [],
    loading: true,
    loaded: false,
    pagination: {
      ordering: '',
      page: 1,
      page_size: 20,
      search: '',
      total_count: 0,
    },
  },
  gracePeriods: {
    data: [],
    loading: true,
    loaded: false,
  },
  transactionHistory: {
    data: [],
    loading: true,
    loaded: false,
    pagination: {
      ordering: '',
      page: 1,
      page_size: 10000,
      search: '',
      total_count: 0,
    },
  },
  price: { data: undefined, loading: true, loaded: false },
  fields: {
    policy: {
      data: [],
      loading: true,
      loaded: false,
    },
    exposure: {
      data: [],
      loading: true,
      loaded: false,
    },
  },
  cancellationDocuments: {
    data: [],
    loading: true,
    loaded: false,
  },
  cancellationPrice: {
    data: {},
    loading: true,
    loaded: false,
  },
  cancellationDetailList: {
    data: {},
    loading: true,
    loaded: false,
  },
  documentTypes: {
    loading: true,
    loaded: false,
    data: [],
  },
  billingPaymentAmounts: {
    totalPremium: 0,
    totalFees: 0,
    total: 0, // grand total
    paidToDate: 0,
    pastDue: 0,
    currentDue: 0,
    totalDue: 0,
  },
  billingPaymentInfo: {
    data: {},
    loading: true,
    loaded: false,
  },
  underwritingQuestionsState: {},
  underwritingQuestions: {},
  ruleEngineResults: {
    data: [],
    loading: true,
    loaded: false,
  },
};

export const policyDetailContextDefault: IPolicyDetailContextType = {
  ...initialPolicyDetailData,
  resetPolicyDetailState: () => {},
  resetTasks: () => {},
  fetch: () => new Promise(() => {}),
  getExposures: () => new Promise(() => {}),
  getPerils: () => new Promise(() => {}),
  getBillingData: () => new Promise(() => {}),
  updatePaymentInfo: () => new Promise(() => {}),
  getEndorsements: () => new Promise(() => {}),
  getUnderwritingQuestions: () => new Promise(() => {}),
  getCancellationDetails: () => new Promise(() => {}),
  resetPolicyEndorsements: () => {},
  getCancellations: () => new Promise(() => {}),
  resetPolicyCancellations: () => {},
  resetPolicyInvoices: () => {},
  getExposure: () => new Promise(() => {}),
  getTasks: () => new Promise(() => {}),
  getPolicyPrice: () => new Promise(() => {}),
  getFieldConfig: () => new Promise(() => {}),
  getGracePeriods: () => new Promise(() => {}),
  getTransactionHistory: () => new Promise(() => {}),
  resetTransactionHistory: () => {},
  resetPolicyExposures: () => {},
  resetPolicyPerils: () => {},
  getRuleEngineResults: () => new Promise(() => {}),
  setCancellationDetails: () => {},
  resetCancellationDetails: () => {},
  getPolicyDocumentTypes: () => new Promise(() => {}),
};

export const PolicyDetailContext = createContext<IPolicyDetailContextType>(null!);
