import React, { FC } from 'react';

interface IToastifyMessageProps {
  type?: string;
  message?: string | React.ReactNode;
}

const ToastifyMessage: FC<IToastifyMessageProps> = ({ type, message }) => (
  <div>
    <div className="toastify__content-title" data-test="toast-title">
      {type}
    </div>
    <div className="toastify__content" data-test="toast-content">
      {message}
    </div>
  </div>
);

export default ToastifyMessage;
