import { fieldHasValue } from 'helpers/Utils';
import React from 'react';
import { InputAttributes, NumericFormat, NumericFormatProps } from 'react-number-format';

interface CustomProps {
  onChange?: (event: { target: { name?: string; value: string } }) => void;
  name?: string;
  displayType?: string;
  value?: string;
  prefix?: string;
  suffix?: string;
  allowDecimal?: boolean;
  thousandSeparator?: boolean;
  allowLeadingZeros?: boolean;
}

const NumberFormatComponent = React.forwardRef<NumericFormatProps<InputAttributes>, CustomProps>(
  ({ displayType = 'input', ...props }, ref) => {
    const {
      onChange,
      value,
      thousandSeparator = true,
      allowDecimal = true,
      allowLeadingZeros = false,
      ...other
    } = props;

    const truncatedValue = value?.toString().split('.')[0];
    const fieldValue = !allowDecimal && fieldHasValue(value, ['-']) ? truncatedValue : value;

    if (displayType === 'input') {
      return (
        <NumericFormat
          value={fieldValue}
          allowLeadingZeros={allowLeadingZeros}
          allowNegative={false}
          decimalScale={allowDecimal ? 2 : 0}
          {...other}
          getInputRef={ref}
          onValueChange={(values, sourceInfo) => {
            if (sourceInfo.source !== 'prop') {
              onChange?.({
                target: {
                  name: props.name,
                  value: values.value,
                },
              });
            }
          }}
          thousandSeparator={thousandSeparator}
          valueIsNumericString
        />
      );
    } else {
      return (
        <NumericFormat
          value={fieldValue}
          allowLeadingZeros={allowLeadingZeros}
          allowNegative={false}
          decimalScale={allowDecimal ? 2 : 0}
          {...other}
          displayType="text"
          thousandSeparator={thousandSeparator}
          valueIsNumericString
        />
      );
    }
  },
);

export default NumberFormatComponent;
