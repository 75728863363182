import { ArrowBack } from '@mui/icons-material';
import { Box, IconButton } from '@mui/material';
import { LocationParams } from 'components/LinkTab/LinkTab';
import React, { FC, ReactNode } from 'react';
import { useHistory, useLocation } from 'react-router-dom';

export interface TopActionBarProps {
  hasBack?: boolean;
  children?: ReactNode;
}

const TopActionBar: FC<TopActionBarProps> = ({ children, hasBack = false }) => {
  const HISTORY = useHistory();
  const LOCATION = useLocation<LocationParams>();

  return (
    <Box
      sx={[
        {
          backgroundColor: (theme) => theme.palette.background.default,
          boxShadow: (theme) => theme.customShadows.shadow1,
          display: 'flex',
          height: (theme) => theme.actionBarHeight,
          position: 'fixed',
          top: (theme) => theme.appBarHeight,
          width: () => ({
            xs: '100%',
            sm: '100%',
            md: '100%',
            lg: '100%',
          }),
          zIndex: (theme) => theme.zIndex.appBar - 1,
          overflowX: 'auto',
          overflowY: 'hidden',
        },
      ]}
    >
      {hasBack && (
        <IconButton
          onClick={() => {
            if (LOCATION.key) {
              if (LOCATION.state?.prevPath) {
                HISTORY.push(LOCATION.state.prevPath);
              } else if (!LOCATION.state?.prevPath && LOCATION.state?.isTab) {
                const mainPathname = LOCATION.pathname.split('/')[1];
                HISTORY.push(`/${mainPathname}`);
              } else {
                HISTORY.goBack();
              }
            }
          }}
          disabled={!LOCATION.key}
          sx={{
            width: 48,
            height: 48,
            borderRadius: 0,
            boxShadow: (theme) => theme.customShadows.shadow3,
            py: 2,
            px: 2,
          }}
        >
          <ArrowBack
            sx={{
              color: (theme) => (!LOCATION.key ? 'inherit' : theme.palette.primary.light),
            }}
          />
        </IconButton>
      )}
      {children}
    </Box>
  );
};

export default TopActionBar;
