import ApiService, { Params } from 'api/helpers/Sender';
import {
  IAuxDataPrometrix,
  IPrometrixData,
} from 'api/models/Integrations/Prometrix/addressRequest.model';
import { CreateEndorsementExposure } from 'api/models/Policy/Endorsements/createEndorsementExposure.model';
import { CreateEndorsementRequest } from 'api/models/Policy/Endorsements/createEndorsementRequest.model';
import { CreateEndorsementResponse } from 'api/models/Policy/Endorsements/createEndorsementResponse.model';
import { EndorsementDetail } from 'api/models/Policy/Endorsements/endorsementDetail.model';
import { EndorsementExposures } from 'api/models/Policy/Endorsements/endorsementExposures.model';
import { EndorsementInvoice } from 'api/models/Policy/Endorsements/endorsementInvoice.model';
import { EndorsementPriceChange } from 'api/models/Policy/Endorsements/endorsementPriceChange.model';
import { EndorsementType } from 'api/models/Policy/Endorsements/endorsementType.model';
import { PolicyChanges } from 'api/models/Policy/policyChanges.model';
import { PolicyPriceChangeBreakdown } from 'api/models/Policy/policyPriceChangeBreakdown.model';
import { IPolicyholder } from 'api/models/Policyholder/policyholder.model';
import { PaginationResponse } from 'types/Pagination';
import { updatePolicyholderWithLocator } from './Policyholders';

const API = new ApiService();

export const createEndorsement = async (
  policyLocator: string,
  body: CreateEndorsementRequest,
): Promise<CreateEndorsementResponse> => {
  const { data } = await API.post(`policies/${policyLocator}/endorsements`, body);
  return data;
};

export const getEndorsementDetail = async (
  policyLocator: string,
  endorsementLocator: string,
): Promise<EndorsementDetail> => {
  const { data } = await API.get(`policies/${policyLocator}/endorsements/${endorsementLocator}`);
  return data;
};

export const getEndorsementPriceChanges = async (
  policyLocator: string,
  endorsementLocator: string,
): Promise<EndorsementPriceChange | PolicyPriceChangeBreakdown> => {
  const { data } = await API.get(
    `policies/${policyLocator}/endorsements/${endorsementLocator}/price`,
  );
  return data;
};

export const getEndorsementInvoices = async (
  policyLocator: string,
  endorsementLocator: string,
): Promise<PaginationResponse<EndorsementInvoice[]>> => {
  const { data } = await API.get(
    `policies/${policyLocator}/endorsements/${endorsementLocator}/invoices/?page_size=10000`,
  );
  return data;
};

export const getEndorsementChanges = async (
  policyLocator: string,
  endorsementLocator: string,
): Promise<PolicyChanges> => {
  const { data } = await API.get(
    `policies/${policyLocator}/endorsements/${endorsementLocator}/changes`,
  );
  return data;
};

// #region Endorsement actions
export const discardEndorsement = async (
  policyLocator: string,
  endorsementLocator: string,
): Promise<any> => {
  const { data } = await API.post(
    `policies/${policyLocator}/endorsements/${endorsementLocator}/discard`,
  );
  return data;
};

export const quoteEndorsement = async (
  policyLocator: string,
  endorsementLocator: string,
): Promise<EndorsementDetail> => {
  const { data } = await API.post(
    `policies/${policyLocator}/endorsements/${endorsementLocator}/quote`,
  );
  return data;
};

export const referEndorsementRequest = async (
  policyLocator: string,
  endorsementLocator: string,
): Promise<EndorsementDetail> => {
  const { data } = await API.post(
    `policies/${policyLocator}/endorsements/${endorsementLocator}/refer`,
  );
  return data;
};

export const acceptEndorsement = async (
  policyLocator: string,
  endorsementLocator: string,
): Promise<EndorsementDetail> => {
  const { data } = await API.post(
    `policies/${policyLocator}/endorsements/${endorsementLocator}/accept`,
  );
  return data;
};

export const invalidateEndorsement = async (
  policyLocator: string,
  endorsementLocator: string,
): Promise<EndorsementDetail> => {
  const { data } = await API.post(
    `policies/${policyLocator}/endorsements/${endorsementLocator}/invalidate`,
  );
  return data;
};

export const issueEndorsement = async (
  policyLocator: string,
  endorsementLocator: string,
): Promise<EndorsementDetail> => {
  const { data } = await API.post(
    `policies/${policyLocator}/endorsements/${endorsementLocator}/issue`,
  );
  return data;
};
// #endregion

export const updateEndorsement = async (
  policyLocator: string,
  endorsementLocator: string,
  body: any,
  query?: Params,
  hasBusinessNameChanged?: { changed: boolean; newName: any; policyholder: IPolicyholder },
): Promise<EndorsementDetail> => {
  // Sync policyholder name with `businessName` #11455
  if (hasBusinessNameChanged?.changed) {
    const { newName, policyholder } = hasBusinessNameChanged;

    await updatePolicyholderWithLocator(
      {
        data: {
          ...(policyholder.data ?? {}),
          business_name: newName,
        },
        broker_of_record: null,
      },
      policyholder.locator,
    );
  }

  const { data } = await API.patch(
    `policies/${policyLocator}/endorsements/${endorsementLocator}`,
    body,
    {},
    query,
  );

  return data;
};

export const getEndorsementExposures = async (
  policyLocator: string,
  endorsementLocator: string,
): Promise<EndorsementExposures[]> => {
  const { data } = await API.get(
    `policies/${policyLocator}/endorsements/${endorsementLocator}/exposures`,
  );
  return data;
};

export const addNewEndorsementExposure = async (
  policyLocator: string,
  endorsementLocator: string,
  body: CreateEndorsementExposure,
): Promise<any[]> => {
  const { data } = await API.post(
    `policies/${policyLocator}/endorsements/${endorsementLocator}/exposures`,
    body,
  );
  return data;
};

export const updateEndorsementExposure = async (
  policyLocator: string,
  endorsementLocator: string,
  exposureLocator: string | number,
  body: any,
  query?: Params,
): Promise<any[]> => {
  const { data } = await API.patch(
    `policies/${policyLocator}/endorsements/${endorsementLocator}/exposures/${exposureLocator}`,
    body,
    {},
    query,
  );
  return data;
};

export const updateEndorsementNewlyAddedExposure = async (
  policyLocator: string,
  endorsementLocator: string,
  exposureIndex: string | number,
  body: any,
  query?: Params,
): Promise<any[]> => {
  const { data } = await API.patch(
    `policies/${policyLocator}/endorsements/${endorsementLocator}/exposures/added-${exposureIndex}`,
    body,
    {},
    query,
  );
  return data;
};

export const deleteEndorsementExposure = async (
  policyLocator: string,
  endorsementLocator: string,
  exposureLocator: string | number,
  query?: Params,
): Promise<any[]> => {
  const { data } = await API.delete(
    `policies/${policyLocator}/endorsements/${endorsementLocator}/exposures/${exposureLocator}`,
    {},
    query,
  );
  return data;
};

export const deleteEndorsementNewlyAddedExposure = async (
  policyLocator: string,
  endorsementLocator: string,
  exposureIndex: string | number,
  query?: Params,
): Promise<any[]> => {
  const { data } = await API.delete(
    `policies/${policyLocator}/endorsements/${endorsementLocator}/exposures/added-${exposureIndex}`,
    {},
    query,
  );
  return data;
};

export const bulkExposureUpdateEndorsement = async (
  policyLocator: string,
  endorsementLocator: string,
  body: any,
): Promise<any[]> => {
  const { data } = await API.post(
    `policies/${policyLocator}/endorsements/${endorsementLocator}/bulk-exposure-update`,
    body,
  );
  return data;
};

export const getProductEndorsementTypes = async (
  product: string,
  query?: Params,
): Promise<EndorsementType[]> => {
  const { data } = await API.get(`products/${product}/endorsement-types`, query ?? {});
  return data;
};

export const declineEndorsementRequest = async (
  policyLocator: string,
  endorsementLocator: string,
): Promise<EndorsementDetail> => {
  const { data } = await API.post(
    `policies/${policyLocator}/endorsements/${endorsementLocator}/decline`,
  );
  return data;
};

export const sendBackEndorsementRequest = async (
  policyLocator: string,
  endorsementLocator: string,
): Promise<EndorsementDetail> => {
  const { data } = await API.post(
    `policies/${policyLocator}/endorsements/${endorsementLocator}/cancel-referral`,
  );
  return data;
};

export const getEndorsementPrometrixResultsRequest = async (
  policyLocator: string,
  endorsementLocator: string,
): Promise<IAuxDataPrometrix> => {
  const { data } = await API.get(
    `policies/${policyLocator}/endorsements/${endorsementLocator}/aux-data/prometrix-data`,
  );

  return data;
};

export const setEndorsementPrometrixResultsRequest = async (
  policyLocator: string,
  endorsementLocator: string,
  body: IPrometrixData,
): Promise<IPrometrixData> => {
  const { data } = await API.put(
    `policies/${policyLocator}/endorsements/${endorsementLocator}/aux-data/prometrix-data`,
    body,
  );

  return data;
};
