import { LoaderContext } from 'providers/LoaderProvider/LoaderProviderContext';
import { ILoaderContextType } from 'providers/LoaderProvider/types';
import { useContext } from 'react';

export default function useLoader(): ILoaderContextType {
  const context = useContext(LoaderContext);

  /* istanbul ignore if */
  if (!context) {
    throw new Error('useLoader must be used within a LoaderProvider');
  }

  return context;
}
