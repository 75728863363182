import { Box, Drawer, SxProps, Theme } from '@mui/material';
import React, { FC } from 'react';
import { useHistory } from 'react-router-dom';

interface DrawerComponentProps {
  sx?: SxProps<Theme>;
  isDrawerOpen: boolean;
  header: React.ReactNode;
  footer?: React.ReactNode;
  content: React.ReactNode;
  headerSx?: SxProps<Theme>;
  drawerSx?: SxProps<Theme>;
  transitionDuration?: number;
  setIsDrawerOpen?: (state: boolean) => void;
  contentSx?: SxProps<Theme>;
  footerSx?: SxProps<Theme>;
  width?: string;
  isBackdropHidden?: boolean;
  onClose?: () => void;
  boxShadow?: string;
  isContentScrollable?: boolean;
  // reason:number;
}

const DrawerComponent: FC<DrawerComponentProps> = ({
  header,
  footer,
  content,
  isDrawerOpen,
  setIsDrawerOpen,
  transitionDuration = 800,
  sx = [],
  headerSx = [],
  contentSx = [],
  footerSx = [],
  width = '476px',
  isBackdropHidden = false,
  onClose,
  boxShadow,
  isContentScrollable = false,
  // reason,
  drawerSx = [],
}) => {
  const HISTORY = useHistory();

  const handleClose = () => {
    if (onClose) {
      onClose();
    } else {
      setIsDrawerOpen!(false);
      HISTORY.replace({
        search: '',
      });
    }
  };

  return (
    <Drawer
      transitionDuration={transitionDuration}
      anchor="right"
      open={isDrawerOpen}
      disableEnforceFocus
      disableScrollLock
      hideBackdrop={isBackdropHidden}
      onClose={handleClose}
      SlideProps={{
        appear: true,
      }}
      sx={{
        [`& .MuiDrawer-paper`]: {
          width,
          boxSizing: 'border-box',
          position: 'absolute',
          left: `calc(100vw - ${width})`,
          boxShadow,
          // top: (theme) => `${theme.appBarHeight}px`,
          // height: (theme) => `calc(100% - ${theme.appBarHeight}px)`,
        },
        ...(Array.isArray(drawerSx) ? drawerSx : [drawerSx]),
      }}
    >
      <Box
        sx={[
          {
            height: '100%',
            width: '100%',
            display: 'flex',
            flexDirection: 'column',
            color: (theme) => theme.palette.primary.main,
          },
          ...(Array.isArray(sx) ? sx : [sx]),
        ]}
      >
        <Box
          sx={[
            {
              mt: '36px',
              mb: 3,
              px: 6,
              flexBasis: 3,
            },
            ...(Array.isArray(headerSx) ? headerSx : [headerSx]),
          ]}
        >
          {header}
        </Box>
        <Box
          sx={[
            {
              px: 6,
              display: 'flex',
              flexDirection: 'column',
              flexGrow: 1,
              ml: 0,
              ...(isContentScrollable && { overflowY: 'auto' }),
            },
            ...(Array.isArray(contentSx) ? contentSx : [contentSx]),
          ]}
        >
          {content}
        </Box>
        {footer && (
          <Box
            sx={[
              {
                mt: 'auto',
                width: '100%',
                display: 'flex',
                flexBasis: '64px',
                py: 2,
                px: 6,
                maxHeight: '64px',
                boxShadow: (theme) => theme.customShadows.shadow6,
                justifyContent: 'right',
              },
              ...(Array.isArray(footerSx) ? footerSx : [footerSx]),
            ]}
          >
            {footer}
          </Box>
        )}
      </Box>
    </Drawer>
  );
};

export default DrawerComponent;
