import React, { useEffect, useState } from 'react';
import { Prompt, useHistory } from 'react-router-dom';
// eslint-disable-next-line import/no-extraneous-dependencies
import { Location } from 'history';
import useDialog from 'hooks/useDialog';
import useRouterPrompt from 'hooks/useRouterPrompt';
import { TDialogs } from 'providers/DialogProvider/types';

interface Props {
  when?: boolean | undefined;
  onLeave?: () => void;
  dialog?: TDialogs;
}

const RouterPrompt = ({ when, onLeave, dialog = 'CHANGE_UNSAVEDPAGE' }: Props) => {
  const { setDialogOpen } = useDialog();
  const [lastLocation, setLastLocation] = useState<Location | null>(null);
  const [confirmedNavigation, setConfirmedNavigation] = useState(false);
  const HISTORY = useHistory();
  const { resetRouterState } = useRouterPrompt();

  const handleConfirmNavigationClick = (): Promise<void> => {
    // setModalVisible(false);
    setConfirmedNavigation(true);
    setDialogOpen({
      dialog,
      isOpen: false,
    });
    resetRouterState();
    onLeave?.();
    return Promise.resolve();
  };

  const handleBlockedNavigation = (nextLocation: Location): boolean => {
    if (!confirmedNavigation && when) {
      // setModalVisible(true);
      setDialogOpen({
        dialog,
        isOpen: true,
        onAccept: () => handleConfirmNavigationClick(),
      });
      setLastLocation(nextLocation);
      return false;
    }
    return true;
  };

  useEffect(() => {
    if (confirmedNavigation && lastLocation) {
      // Navigate to the previous blocked location with your navigate function
      setTimeout(() => {
        HISTORY.push(lastLocation.pathname);
      }, 100);
    }
  }, [confirmedNavigation, lastLocation]);

  return <Prompt when={when} message={handleBlockedNavigation} />;
};

export default RouterPrompt;
