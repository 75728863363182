import { createContext } from 'react';

/// types
import { IAgenciesContextType, IAgenciesStore } from './types';

export const initialAgenciesData: IAgenciesStore = {
  data: [],
  loading: true,
  loaded: false,
  pagination: {
    ordering: '',
    page: 1,
    page_size: 20,
    search: '',
    total_count: 0,
  },
};

export const agenciesContextDefault: IAgenciesContextType = {
  ...initialAgenciesData,
  resetAgenciesState: () => {},
  fetch: () => new Promise(() => {}),
};

export const AgenciesContext = createContext<IAgenciesContextType>(null!);
