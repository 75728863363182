import { FormControlLabel, Radio, Stack, Typography } from '@mui/material';
import { AddressDifferenceInfo } from 'api/models/Locations/AddressDifferenceInfo.model';
import React, { FC } from 'react';
import { useTranslation } from 'react-i18next';

interface OriginalSuggestedLocationBoxesProps {
  isSuggestedLocation?: boolean;
  address: {};
  isChecked?: boolean;
  value: string;
  inputProps: any;
}

const OriginalSuggestedLocationBox: FC<OriginalSuggestedLocationBoxesProps> = ({
  isSuggestedLocation = false,
  address,
  isChecked = false,
  value,
  inputProps,
}) => {
  const { t } = useTranslation();

  const getDifferentVerifiedValues = (obj: AddressDifferenceInfo): string[] =>
    Object.values(obj ?? {})
      .filter((item) => item.isDifferent && item.verified)
      .map((item) => item.verified!);

  const highlightMatchingValues = (str: string, values: string[]): React.ReactNode => {
    const items = str.split(',');
    const highlightedItems = items.map((item, index, array) => {
      const trimmedItem = item.trim();
      if (values.includes(trimmedItem)) {
        return (
          <span key={index} style={{ backgroundColor: 'rgba(187, 79, 9, 0.2)' }}>
            {trimmedItem}
            {index !== array.length - 1 && ','}
          </span>
        );
      }
      return (
        <React.Fragment key={index}>
          {trimmedItem}
          {index !== array.length - 1 && ','}
        </React.Fragment>
      );
    });
    return highlightedItems;
  };

  const buildRadioButton = () => (
    <Radio
      size="small"
      disableRipple
      inputProps={inputProps}
      sx={{
        fontSize: 15,
        width: 15,
        height: 15,
        mr: 1,
        '&:hover': {
          background: 'none',
        },
        color: (theme) => theme.customColors.copper,

        '&.Mui-checked': {
          color: (theme) => theme.customColors.copper,
        },
      }}
    />
  );

  return (
    <FormControlLabel
      value={value}
      control={buildRadioButton()}
      className={isChecked ? 'radio-button-checked' : 'radio-button-not-checked'}
      label={
        <Stack gap={1} sx={{ pr: 2 }}>
          <Typography
            sx={{
              fontSize: 16,
              fontWeight: (theme) => theme.typography.h6.fontWeight,
              lineHeight: (theme) => theme.typography.subtitle1.lineHeight,
            }}
          >
            {isSuggestedLocation ? t('Verified Address') : t('Original Address')}
          </Typography>
          <Typography
            sx={{
              fontSize: 16,
              fontWeight: (theme) => theme.typography.body1.fontWeight,
              lineHeight: (theme) => theme.typography.body1.lineHeight,
              wordSpacing: isSuggestedLocation ? '1px' : '0px',
            }}
          >
            {isSuggestedLocation
              ? highlightMatchingValues(value, getDifferentVerifiedValues(address))
              : value}
          </Typography>
        </Stack>
      }
    />
  );
};

export default OriginalSuggestedLocationBox;
