import { createContext } from 'react';

/// types
import { IQuotesContextType, IQuotesStore } from './types';

export const initialQuotesData: IQuotesStore = {
  data: [],
  loading: true,
  loaded: false,
  pagination: {
    ordering: '-created_at',
    page: 1,
    page_size: 20,
    search: '',
    total_count: 0,
  },
  filterListArray: {
    data: [],
    loading: true,
    loaded: false,
  },
};

export const quotesContextDefault: IQuotesContextType = {
  ...initialQuotesData,
  fetch: () => new Promise(() => {}),
  resetThreeSubmissionsState: () => {},
};

export const QuotesContext = createContext<IQuotesContextType>(null!);
