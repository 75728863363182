import { createContext } from 'react';

/// types
import { IDialogContextType, IDialogStore } from './types';

export const initialDialogData: IDialogStore = {
  dialogs: {} as IDialogStore['dialogs'],
};

export const dialogContextDefault: IDialogContextType = {
  ...initialDialogData,
  resetDialogState: () => {},
  setDialogOpen: () => {},
};

export const DialogContext = createContext<IDialogContextType>(null!);
