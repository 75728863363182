import { KeyboardArrowDown } from '@mui/icons-material';
import {
  Box,
  Button,
  ClickAwayListener,
  MenuItem,
  Skeleton,
  Stack,
  Toolbar,
  Typography,
} from '@mui/material';
import SearchInput from 'components/Header/global-search-input';
import MenuComponent from 'components/MenuComponent';
import useAuth from 'hooks/useAuth';

import React, { FC, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';

import useUser from 'hooks/useUser';

const HeaderMobile: FC = () => {
  const HISTORY = useHistory();
  const { signOutSSO } = useAuth();
  const { t } = useTranslation();
  const accountSubMenu = [
    { key: 'settings', title: t('Settings'), route: '/settings' },
    { key: 'logout', title: t('Log out') },
  ];

  const { data: user, loading } = useUser();
  const handleAccountMenuLink = (route: string | undefined) => {
    if (route) {
      HISTORY.push(route);
    } else {
      signOutSSO();
    }
  };

  const [isclickeduser, setIsclickeduser] = useState(false);

  const handleClickAwayUser = () => {
    setIsclickeduser(false);
  };

  return (
    <Toolbar
      sx={{
        alignItems: 'center',
        width: 1,
        justifyContent: 'space-around',
        px: {
          xs: 0,
          md: 2,
        },
        backgroundColor: (theme) => theme.customColors.topNavBar.bg,
      }}
    >
      <Box sx={{ flex: 1, mx: 2 }}>
        <SearchInput />
      </Box>
      <Stack
        gap={1}
        direction="row"
        sx={{
          justifyContent: 'flex-end',
        }}
      >
        <MenuComponent
          renderDefaultIcon={false}
          titleMenu
          margintop="36px"
          width={120}
          anchor={
            <ClickAwayListener onClickAway={handleClickAwayUser}>
              <Button
                disableRipple
                variant="text"
                endIcon={
                  isclickeduser ? (
                    <KeyboardArrowDown
                      sx={{
                        pt: 0.3,
                        color: (theme) => theme.customColors.white50,
                        transform: 'rotateZ(180deg)',
                      }}
                    />
                  ) : (
                    <KeyboardArrowDown
                      sx={{
                        pt: 0.3,
                        color: (theme) => theme.customColors.white50,
                        justifySelf: 'flex-end',
                      }}
                    />
                  )
                }
                onClick={() => setIsclickeduser(!isclickeduser)}
                sx={{
                  color: (theme) => theme.palette.background.default,
                  display: {
                    lg: 'none',
                  },
                }}
              >
                <Typography
                  sx={{
                    color: (theme) => theme.customColors.white50,
                    fontSize: '16px',
                    maxWidth: (theme) => `${theme.appBarUserCharacterLimit}ch`,
                    whiteSpace: 'nowrap',
                    overflow: 'hidden',
                    textOverflow: 'ellipsis',
                    flex: 1,
                  }}
                >
                  {!loading ? (
                    user?.full_name
                  ) : (
                    <Skeleton
                      animation="wave"
                      variant="text"
                      width="11ch"
                      height={30}
                      sx={{ bgcolor: (theme) => theme.customColors.topNavBar.itemHover }}
                    />
                  )}
                </Typography>
              </Button>
            </ClickAwayListener>
          }
        >
          {accountSubMenu.map(({ key, title, route }) => (
            <MenuItem key={key} onClick={() => handleAccountMenuLink(route)}>
              <Typography textAlign="center">{title}</Typography>
            </MenuItem>
          ))}
        </MenuComponent>
      </Stack>
    </Toolbar>
  );
};

export default HeaderMobile;
