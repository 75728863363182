import { TabContext } from 'providers/TabProvider/TabContext';
import { ITabContextType } from 'providers/TabProvider/types';
import { useContext } from 'react';

export default function useTab(): ITabContextType {
  const context = useContext(TabContext);

  /* istanbul ignore if */
  if (!context) {
    throw new Error('useTab must be used within a TabProvider');
  }

  return context;
}
