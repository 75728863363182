import { Box } from '@mui/material';
import React, { FC } from 'react';

interface ITabPanelProps {
  index: number;
  value: number;
  label?: string;
  children?: React.ReactNode;
}

const TabPanel: FC<ITabPanelProps> = (props) => {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`vertical-tabpanel-${index}`}
      aria-labelledby={`vertical-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box
          sx={{
            overflowY: 'auto',
            overflowX: 'hidden',
          }}
        >
          <Box
            sx={{
              width: 400,
              display: 'flex',
              justifyContent: 'center',
              flexDirection: 'column',
            }}
          >
            {children}
          </Box>
        </Box>
      )}
    </div>
  );
};

export default TabPanel;
