/* eslint-disable react/jsx-indent */
import { Box, Divider, Skeleton, SxProps, Tab, Tabs, Theme } from '@mui/material';
import { tabsClasses } from '@mui/material/Tabs';
import useRouterPrompt from 'hooks/useRouterPrompt';
import useTab from 'hooks/useTab';
import React, { FC, useEffect } from 'react';
import { Link, useLocation } from 'react-router-dom';

export interface LinkTabProps {
  tabs: {
    id: number;
    label: string;
    route: string;
    disabled?: boolean;
    hidden?: boolean;
  }[];
  currentTab: number;
  sx?: SxProps<Theme>;
  loading?: boolean;
  maxWidth?: boolean;
}

export interface LocationParams {
  pathname: string;
  prevPath: string;
  isTab: boolean;
}

const LinkTab: FC<LinkTabProps> = ({
  tabs,
  currentTab,
  sx = [],
  loading = false,
  maxWidth = true,
}) => {
  const { activeTab, setActiveTab } = useTab();

  const LOCATION = useLocation<LocationParams>();
  const { when } = useRouterPrompt();

  useEffect(() => {
    setActiveTab(currentTab);
  }, [currentTab]);

  const handleChange = (event, newValue: number) => {
    if (event?.ctrlKey) {
      return;
    }
    if (!when) {
      setTimeout(() => {
        setActiveTab(newValue);
      }, 100);
    }
  };

  return (
    <>
      <Box
        sx={[
          {
            width: 1,
            maxWidth: () => ({
              xs: '100vw',
              sm: '100vw',
              md: '100vw',
              lg: '100vw',
            }),
            display: 'flex',
          },
          ...(Array.isArray(sx) ? sx : [sx]),
        ]}
      >
        {loading ? (
          [...Array(5).keys()].map((tab, i) => (
            <Skeleton
              animation="wave"
              width="5%"
              height={28}
              sx={{ marginRight: 1.5, marginLeft: '24px' }}
              key={i}
            />
          ))
        ) : (
          <Tabs
            id="linkTab"
            sx={{
              width: 1,
              overflow: 'unset',
              minHeight: 'unset',
              boxShadow: '2px 4px 6px rgba(0, 0, 0, 0.06)',
              '& .MuiTabs-indicator': {
                background: loading ? 'transparent' : (theme) => theme.customColors.tabIndicator,
                height: 2,
              },
              '& .MuiTabs-scrollButtons': {
                display: 'flex',
                alignItems: 'center',
                position: 'relative',
                backgroundColor: 'transparent',
              },
              [`& .${tabsClasses.scrollButtons}`]: {
                '&.Mui-disabled': { opacity: 0.3 },
              },
            }}
            value={activeTab || 0}
            scrollButtons="auto"
            variant="scrollable"
            textColor="inherit"
            onChange={handleChange}
            TabScrollButtonProps={{ sx: { width: '30px' } }}
          >
            {tabs.map((tab, i) => (
              <Tab
                disabled={tab.disabled}
                key={tab.id}
                title={tab.label}
                component={Link}
                wrapped
                to={{
                  pathname: tab.route,
                  state: {
                    prevPath: LOCATION.state?.prevPath,
                    isTab: true,
                  },
                }}
                disableRipple={i === activeTab}
                sx={{
                  display: tab.hidden ? 'none' : 'inline-flex',
                  color: (theme) =>
                    i === activeTab ? theme.customColors.gunMetal : theme.customColors.darkGrey,
                  minHeight: 'unset',
                  minWidth: 'unset',
                  px: 3,
                  opacity: 1,
                  fontWeight: 500,
                  whiteSpace: 'nowrap',
                  maxWidth: !maxWidth ? 'unset' : 104,
                  textTransform: 'capitalize',
                  fontSize: 14,
                  lineHeight: '20px',
                  overflow: 'unset',
                  overflowWrap: 'anywhere',
                  '&::after': {
                    content: 'attr(title)',
                    height: 0,
                    overflow: 'hidden',
                    visibility: 'hidden',
                    fontWeight: 500,
                  },
                  '&:hover': {
                    backgroundColor: (theme) => theme.customColors.black1050,
                  },
                }}
                label={tab.label}
              />
            ))}
          </Tabs>
        )}
      </Box>
      <Divider />
    </>
  );
};

export default LinkTab;
