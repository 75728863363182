/* eslint-disable no-restricted-globals */
/* eslint-disable no-control-regex */
/* eslint-disable consistent-return */
/* eslint-disable array-callback-return */
/* eslint-disable no-shadow */
/* eslint-disable no-unused-expressions */
/* eslint-disable default-param-last */
/* eslint-disable no-restricted-syntax */
/* eslint-disable no-param-reassign */
import { DynamicField } from 'api/models/DynamicFields/dynamicField.model';
import { UnderwritingInformation } from 'api/models/Integrations/WCIRB/WCIRB.model';
import { AddressDifferenceInfo } from 'api/models/Locations/AddressDifferenceInfo.model';
import { AddressInfo } from 'api/models/Locations/AddressInfo.model';
import { Field, VehicleData } from 'api/models/NewQuote/productWorkFlow.model';
import { EndorsementDetail } from 'api/models/Policy/Endorsements/endorsementDetail.model';
import { Data } from 'api/models/Policy/policyExposures.model';
import { PropertyExposureField } from 'api/models/Quote/PropertyExposureField.model';
import { QuoteDetailResponse } from 'api/models/Quote/quoteDetailResponse.model';
import { SmartyValidateAddressResponse } from 'api/models/Smarty/SmartyValidateAddressResponse.model';
import { Filings } from 'api/models/THREEMappings/Filings/filings.model';
import {
  alphaNumericRegex,
  approvePropertyUWReportType,
  commaRegex,
  dataFieldTypes,
  defaultCurrency,
  defaultDateFormat,
  defaultTimezone,
  firstDayOfYear1900,
  firstDayOfYear2024,
  genericErrorMessage,
  nonAlphaNumericRegex,
  nonCommaRegex,
  paginationKeys,
  phoneNumberRegex,
  productCodes,
  propertyExposureScheduleBuildingOccupancies,
  submissionDetailInfoTabs,
  TAB_POSITIONS,
} from 'common/constants';
import { IColumns } from 'components/Policies/PolicyDetail/Info/Tabs/AdditionalInterests';
import {
  addDays,
  differenceInDays,
  endOfDay,
  isBefore,
  parse,
  parseISO,
  startOfDay,
} from 'date-fns';
import { utcToZonedTime, zonedTimeToUtc } from 'date-fns-tz';
import { t } from 'i18next';
import {
  capitalize,
  eq,
  filter,
  get,
  isArray,
  isEmpty,
  isString,
  keys,
  omit,
  toString,
  union,
} from 'lodash-es';
import qs from 'query-string';
import { CommonStreetAbbrs } from 'types/CommonStreetAbbrs';
import { DetailInfoTabsFieldsTypes } from 'types/DetailInfoTabsTypes';
import { IAxiosError } from 'types/ErrorResponseTypes';
import { v4 as uuidv4 } from 'uuid';
import * as yup from 'yup';
import { AnyObject } from 'yup/lib/types';
import { RadityError } from './AxiosInterceptor/types';
import displayToastMessage from './DisplayToastMessage';

export const getFirstValueIfArray = <T>(arg: T[] | T): T => (Array.isArray(arg) ? arg[0] : arg);

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const getNestedValueFromObject = <T>(object: T, key: string): any =>
  get(object, key.replace(/__/g, '.'), '-');

/**
 * Returns changed keys between two object
 * @param o1 Object
 * @param o2 Object
 * @returns
 */
export const changedKeys = (o1: { [x: string]: any }, o2: { [x: string]: any }) => {
  const keysArray = union(keys(o1), keys(o2));
  return filter(keysArray, (key) => !eq(toString(o1[key]), toString(o2[key])));
};
export const handleBackendErrorsWithFormik = <T>(error: T, formik: any) => {
  const e = error as unknown as RadityError<T>;
  if (e.isRadityError) {
    const tmpKeys: string[] = [];
    Object.entries(e.backendErrors!).forEach(([key, value]) => {
      if (value?.[0]) {
        tmpKeys.push(key);
        formik.setFieldError(key, value?.[0] ?? '');
      } else {
        Object.entries(value!).forEach(([childKey, childValue]) => {
          tmpKeys.push(childKey);
          formik.setFieldError(childKey, (childValue as any)?.[0] ?? '');
        });
      }
    });

    formik.setTouched(
      { ...formik.touched, ...tmpKeys.reduce((a, key) => ({ ...a, [`${key}`]: true }), {}) },
      false,
    );
  }
};
export const handleBackendMultiErrorsWithFormik = <T>(error: T, formik: any) => {
  const e = error as unknown as RadityError<T>;
  if (e.isRadityError) {
    const touchedFields = {};
    const touchedFieldsArray = [];
    Object.entries(e.backendErrors!).forEach(([key, value]) => {
      Object.entries(value!).forEach(([childKey, childValue]) => {
        if (!isEmpty(childValue)) {
          Object.entries(childValue!).forEach(([fieldKey, fieldValue]) => {
            const childValues = Object.keys(childValue!).reduce(
              (a, key) => ({ ...a, [`${key}`]: true }),
              {},
            );
            touchedFieldsArray[childKey] = childValues;
            formik.setFieldError(`${key}[${childKey}][${fieldKey}]`, fieldValue);
          });
        }
      });
      touchedFields[key] = touchedFieldsArray;
    });
    formik.setTouched({ ...formik.touched, ...touchedFields }, false);
  }
};

export const currencyFormat = (
  currency: string = defaultCurrency,
  value: string | number,
  withoutDecimals: boolean = false,
) => {
  let c = currency;

  if (isEmpty(c)) {
    c = defaultCurrency;
  }

  if (c && value && value !== '-') {
    try {
      if (isArray(value)) {
        throw new Error();
      }

      const merged = new Intl.NumberFormat('en-US', {
        style: 'currency',
        currency: c,
        ...(withoutDecimals && { maximumFractionDigits: 0, minimumFractionDigits: 0 }),
      }).format(parseFloat(value as string));

      const formatted = {
        sign: '',
        value: '',
        merged,
      };

      const parts = new Intl.NumberFormat('en-US', {
        style: 'currency',
        currency: c,
        ...(withoutDecimals && { maximumFractionDigits: 0, minimumFractionDigits: 0 }),
      }).formatToParts(parseFloat(value as string));

      formatted.sign = parts?.[0].value;

      const tmpParts = [...parts];
      tmpParts.shift();
      formatted.value = tmpParts?.map((part) => part.value).join('');

      return formatted;
    } catch (_error) {
      return {
        sign: '',
        value: '',
        merged: '',
      };
    }
  } else {
    return {
      sign: '',
      value: '',
      merged: '',
    };
  }
};

export const isDateOnlyString = (date: string) => {
  const regEx = /^\d{4}-\d{2}-\d{2}$/;
  return isString(date) && date.match(regEx) != null;
};

/**
 * @example Field config
 {
    id: "total",
    label: "Total People in Family",
    placeholder: "family members count",
    type: "text",
    validationType: "number",
    required: false,
    value: "1",
    validations: [
      {
        type: "required",
        params: ["this field is required"]
      },
      {
        type: "min",
        params: [1, "there should be atleast 1 family member"]
      },
      {
        type: "max",
        params: [5, "max family members can be 5"]
      }
    ]
  }
 * @param schema
 * @param config
 * @returns
 */

export const displayIntegrationErrorMessage = (
  error: any,
  defaultErrorMessage: string = genericErrorMessage(),
) => {
  const e = error as IAxiosError;
  const errorMessage = !isEmpty(e?.response?.data?.message)
    ? e.response?.data?.message
    : !isEmpty(e?.response?.data?.messages)
    ? e.response?.data?.messages?.[0]
    : defaultErrorMessage;

  displayToastMessage('ERROR', errorMessage);

  return errorMessage;
};

export const getRoleShortCode = (code: string): string => {
  switch (code) {
    case 'underwriters':
      return 'UW';
    case 'portal-admin':
      return 'AD';
    default:
      return '';
  }
};

export const decideValidationType = (type: string) => {
  switch (type) {
    case dataFieldTypes.NUMBER:
      return dataFieldTypes.NUMBER;
    case dataFieldTypes.PHONE:
      return dataFieldTypes.STRING;
    case dataFieldTypes.DATE:
      return dataFieldTypes.DATE;
    case dataFieldTypes.EMAIL:
      return dataFieldTypes.STRING;

    default:
      return dataFieldTypes.STRING;
  }
};

export const addValidationToField = (field: DynamicField, checkRequired = true) => {
  if (!field.is_optional && checkRequired) {
    field.validationType = decideValidationType(field.type!);
    field.validations = [
      ...(field.validations ?? []),
      {
        type: 'required',
        params: [t('This field cannot be left blank.')],
      },
    ];
  }

  if (field.type === dataFieldTypes.PHONE) {
    field.validationType = decideValidationType(field.type!);
    field.validations = [
      ...(field.validations ?? []),
      {
        type: 'matches',
        params: [phoneNumberRegex, t('Enter a valid phone')],
      },
    ];
  }

  if (field.type === dataFieldTypes.DATE) {
    field.validationType = decideValidationType(field.type!);
    field.validations = [
      ...(field.validations ?? []),
      {
        type: 'nullable',
        params: [],
      },
      {
        type: 'typeError',
        params: [
          t('Invalid date format, Must be in {{dateFormat}}', { dateFormat: defaultDateFormat }),
        ],
      },
    ];
  }

  if (field.type === dataFieldTypes.EMAIL) {
    field.validationType = decideValidationType(field.type!);
    field.validations = [
      ...(field.validations ?? []),
      {
        type: 'email',
        params: [t('Enter a valid email address.')],
      },
    ];
  }
  return field;
};
// missing test function
export const addValidationsToFields = (fields: DynamicField[], checkRequired = true) => {
  for (const field of fields) {
    addValidationToField(field, checkRequired);
  }
  return fields;
};

export const updateQueryStrings = ({
  locationSearch,
  newQueries,
}: {
  locationSearch: string;
  newQueries: any;
}) =>
  qs.stringify(
    {
      ...qs.parse(locationSearch),
      ...newQueries,
    },
    { sort: false },
  );

export const deleteFromQueryStrings = ({
  locationSearch,
  omitKeys,
}: {
  locationSearch: string;
  omitKeys: string[];
}) =>
  qs.stringify(
    omit(
      {
        ...qs.parse(locationSearch),
      },
      omitKeys,
    ),
    { sort: false },
  );

export const changeFieldsLoadingStatus = (
  fields: Field[] = [],
  mustChangeFields: string[] = [],
  loading = false,
) =>
  fields.map((f) => {
    const tmpField = { ...f };

    if (mustChangeFields.includes(f.code ?? '')) {
      if (isEmpty(tmpField.additional_data)) {
        tmpField.additional_data = {
          loading,
        };
      } else {
        tmpField.additional_data!.loading = loading;
      }
    }

    return tmpField;
  });

export const checkAndSetFieldsReadonlyState = (tmpField: Field, isReadonly: boolean) => {
  tmpField.is_readonly = isReadonly;

  if (isEmpty(tmpField.additional_data)) {
    tmpField.additional_data = {
      is_readonly: isReadonly,
    };
  } else {
    tmpField.additional_data!.is_readonly = isReadonly;
  }
  return tmpField;
};

export const changeFieldsHiddenStatus = (
  fields: Field[] = [],
  mustChangeFields: string[] = [],
  hidden = false,
) =>
  fields.map((f) => {
    const tmpField = { ...f };

    if (mustChangeFields.includes(f.code ?? '')) {
      tmpField.is_hidden = hidden;
    }

    return tmpField;
  });

export const makeFieldsReadonly = (
  fields: Field[] = [],
  readOnlyFields: string[] = [],
  isReadonly = true,
) =>
  fields.map((f) => {
    const tmpField = { ...f };

    if (readOnlyFields.includes(f.code ?? '')) {
      checkAndSetFieldsReadonlyState(tmpField, isReadonly);
    }

    return tmpField;
  });

export const makeFieldsRequired = (
  fields: Field[] = [],
  requiredFields: string[] = [],
  isOptional = false,
) =>
  fields.map((f) => {
    const tmpField = { ...f };

    if (requiredFields.includes(f.code ?? '')) {
      tmpField.is_optional = isOptional;
    }

    return tmpField;
  });

// There was a performance issue with constant variable with regex value
// So I couldn't store this regex value in constant variable, maybe change later.
// There are two exceptions for "-" and "'" characters.
// export const checkIfValueHasLetters = (value: string) => /^[a-zA-Z('\-)\s]*$/g.test(value);
export const checkIfValueHasLetters = (value: string) => /^[\p{L}('\-)\s]*$/gu.test(value);

export const checkIfNonAlphaNumericCharacter = (value: string) => /\W/gi.test(value);

// '111, 222, 333'
export const checkIfStrContainsOnlyCommaSeparatedNumbers = (value: string) =>
  /^(\d{5}(,\s*)?)*$/.test(value);

// https://dev.azure.com/radity-gmbh/THREE-insurance/_workitems/edit/8984/
export const formatLocation = (addressInfo: {
  addressLine1: string;
  addressLine2?: string;
  city?: string;
  state?: string;
  zip?: string;
}) => {
  const { addressLine1 = '', addressLine2 = '', city = '', state = '', zip = '' } = addressInfo;

  return {
    storing: addressLine1 ? [addressLine1, addressLine2, city, state, zip].join(',') : '-',
    showing: {
      tail: ` ${state} ${zip}`,
      head: addressLine1
        ? `${addressLine1},${addressLine2 ? ` ${addressLine2}, ` : ' '}${city}`
        : '-',
    },
  };
};

interface Team {
  addressLine1?: string;
  addressLine2?: string;
  city?: string;
  state?: string | null;
  zip?: string;
}

export const formatLocationWithOptionalFields = (location: Team) => {
  let result = '';

  if (location.addressLine1) {
    result += `${location.addressLine1}, `;
  }

  if (location.addressLine2) {
    result += `${location.addressLine2}, `;
  }

  if (location.city) {
    result += `${location.city} `;
  }

  if (location.state) {
    result += `${location.state} `;
  }

  if (location.zip) {
    result += location.zip;
  }

  return result.replace(/,\s*$/, '').trim(); // Remove unnecessary gaps at the end
};

/**
 * Parse location that stored as location formula
 *
 * https://dev.azure.com/radity-gmbh/THREE-insurance/_workitems/edit/8984/
 */
export const parseLocation = (location?: string) => {
  const [addressLine1 = '', addressLine2 = '', city = '', state = '', zip = ''] =
    location?.split(',') ?? [];

  return {
    addressLine1,
    addressLine2,
    city,
    state,
    zip,
  };
};

export const getGroupTypeFields = (fields: any) =>
  Object.entries(fields ?? {}).reduce((a, [key, value]) => {
    if (isArray(value)) {
      return { ...a, [`${key}`]: value };
    }

    return a;
  }, {});

export const getEndorsementGroupTypeFields = (fields: any) =>
  Object.entries(fields ?? {}).reduce((a, [key, value]) => {
    if (isArray(value)) {
      let addedIndex = 0;

      return {
        ...a,
        [`${key}`]: value.map((item) => {
          const tmp = {
            ...item,
            ...(!item.locator
              ? { isNewAddedOnEndorsement: true, locator: uuidv4(), index: addedIndex }
              : {}),
          };

          if (!item.locator) {
            addedIndex += 1;
          }

          return tmp;
        }),
      };
    }

    return a;
  }, {});

export const getSymmetricDiffrence = (arr1: any[], arr2: any[]) =>
  arr1.filter((x) => !arr2.includes(x)).concat(arr2.filter((x) => !arr1.includes(x)));

export const determineOwnerOfficerPositions = (entity: string) => {
  const type = {
    position: '',
    title: '',
    description: '',
  };

  switch (entity) {
    case 'SoleProprietor': {
      type.position = 'Owner';
      type.title = 'List your business owner.';
      type.description =
        'Only include individuals that perform work for your business or are involved in managing or directing day-to-day operations. Do not include outside or silent investors.';

      return type;
    }

    case 'LLC': {
      type.position = 'LLC Member';
      type.title = 'List all LLC members';
      type.description =
        'Only include individuals that perform work for your business or are involved in managing or directing day-to-day operations. Do not include outside or silent investors.';

      return type;
    }

    case 'Corporation':
    case 'CorporationSCorp':
    case 'CorporationOther':
    case 'NonprofitOrganization': {
      type.position = 'Officer';
      type.title = 'List all corporate officers.';
      type.description =
        'This includes President, Vice President, and/or other officer titles that were officially appointed or approved by ownership or the Board of Directors to manage or perform daily operations.';

      return type;
    }

    case 'Partnership': {
      type.position = 'Partner';
      type.title = 'List all partners.';
      type.description = 'Only include individual partners. Do not include non-person entities.';

      return type;
    }

    default:
      return type;
  }
};

/**
 * Checks field has value or not.
 *
 * By default it checks, `undifined`, `''`, and `null`
 *
 * With second paramether can add additionalCheks.
 * E.g: ['-'], will check `-` as well.
 *
 */
export const fieldHasValue = (value: any, additionalCheck?: any[]) =>
  [undefined, '', null, ...(additionalCheck ?? [])].every((condition) => condition !== value);

/**
 * Finds the correct filing set ID by looking at the `policyEffectiveDate` and the `state`
 *
 * By default or if not found a correct filing set ID returns `-1`
 */
export const findFilingSetId = (filings: Filings[], state: string, policyEffectiveDate: Date) => {
  let filingSetId = -1;

  // if we found a `filingSetId` do not continue to iterate `break`
  for (const filingRecord of filings) {
    if (filingRecord.State === state) {
      const { EffectiveDate, ExpirationDate } = filingRecord;

      if (EffectiveDate) {
        const parsedEffective = parse(EffectiveDate!, 'yyyy-MM-dd', new Date());

        if (!ExpirationDate) {
          if (policyEffectiveDate.getTime() >= parsedEffective.getTime()) {
            filingSetId = filingRecord.FilingSetID!;
            break;
          }
        } else {
          const parsedExpirationDate = parse(ExpirationDate!, 'yyyy-MM-dd', new Date());

          if (
            policyEffectiveDate.getTime() >= parsedEffective.getTime() &&
            policyEffectiveDate.getTime() <= parsedExpirationDate.getTime()
          ) {
            filingSetId = filingRecord.FilingSetID!;
            break;
          }
        }
      }
    }
  }

  return filingSetId;
};

export const sumTotalByKey = (array: any[], key: string) =>
  array?.reduce((acc, current) => {
    const value = parseFloat(current[key]) || 0;
    return acc + value;
  }, 0) ?? 0;

export const handleConditionalField = (field, state) => {
  const handleCondition = {
    select: (condition, fieldCode) => {
      const { value } = condition;
      return value.includes(state[fieldCode]);
    },
    number: (condition, fieldCode) => {
      const { value, operator } = condition;
      const operators = {
        '>': () => state[fieldCode] > +value,
        '<': () => state[fieldCode] < +value,
      };
      return operators[operator]();
    },
    string: (condition, fieldCode) => {
      const { value } = condition;

      return value === state[fieldCode];
    },
  };

  if (field?.condition && Object.keys(field.condition).length > 0) {
    const results: boolean[] = [];
    for (const [fieldCode, fieldConditions] of Object.entries(
      field.condition,
    ) as unknown as any[]) {
      const result = fieldConditions.every((condition) =>
        handleCondition[condition.type](condition, fieldCode),
      );
      results.push(result);
    }
    return results.every((result) => result);
  }
  return true;
};
export const addRequiredValidationToDynamicFields: any = (
  formField: { [key: string]: any },
  state: { [key: string]: any },
  userRole: string | null = null,
  additionalValidation?: {
    [key: string]: yup.StringSchema<string | undefined, AnyObject, string | undefined>;
  },
) => {
  const validateObj = {};
  const currentDateInUTC = zonedTimeToUtc(new Date(), defaultTimezone);
  const taxNumberFieldPattern = /(fein|ssn)/;
  const zipCodeFieldPattern = /zip/;

  formField
    ?.filter((f) => {
      if (userRole && f.additional_data?.showOnlyForRoles) {
        return !!f.additional_data?.showOnlyForRoles?.includes(userRole);
      }
      return true;
    })
    .map((field) => {
      if (field.type === dataFieldTypes.DATE) {
        !field.is_optional &&
          !field.condition &&
          Object.assign(validateObj, {
            [field.code]: yup.date().when([], () => {
              if (field?.additional_data?.disableFuture && state[field.code] > currentDateInUTC) {
                return yup.date().max(currentDateInUTC, t('Date must be earlier than today.'));
              }

              if (
                field?.additional_data?.disableDateBefore1900 &&
                state[field.code] < new Date(firstDayOfYear1900)
              ) {
                return yup
                  .date()
                  .min(new Date(firstDayOfYear1900), t('The date must be after 1900.'));
              }

              return yup
                .date()
                .nullable()
                .required(t('This field cannot be left blank.'))
                .typeError(
                  t('Invalid date format, Must be in {{dateFormat}}', {
                    dateFormat: defaultDateFormat,
                  }),
                );
            }),
          });
      } else if (field.type === dataFieldTypes.YEAR) {
        Object.assign(validateObj, {
          [field.code]: yup
            .number()
            .when([], {
              is: () => !field.is_optional && !field.condition,
              then: yup.number().required(t('This field cannot be left blank.')),
            })
            .when([], {
              is: () => field?.additional_data?.disableFuture,
              then: yup
                .number()
                .max(
                  currentDateInUTC.getFullYear(),
                  t(`The year can't be bigger then the current year.`),
                ),
            })
            .when([], {
              is: () => field?.additional_data?.disableDateBefore1900,
              then: yup
                .number()
                .min(
                  new Date(firstDayOfYear1900).getFullYear(),
                  t(`The year can't be less then 1900.`),
                ),
            })
            .when([], {
              is: () => field?.additional_data?.minLength,
              then: yup
                .number()
                .test(
                  'length',
                  `This field should be ${field.additional_data.minLength} digits.`,
                  (val) =>
                    !val?.toString()
                      ? true
                      : val.toString().length === field.additional_data.minLength,
                ),
            }),
        });
      } else if (field.type === dataFieldTypes.EMAIL) {
        ((!field.is_optional && !field.condition) || !isEmpty(state[field.code])) &&
          Object.assign(validateObj, {
            [field.code]: yup
              .string()
              .email(t('This field must be a valid email address.'))
              .when([], {
                is: () => !field.is_optional,
                then: yup.string().required(t('This field cannot be left blank.')),
              }),
          });
      } else if (field.type === dataFieldTypes.PHONE) {
        ((!field.is_optional && !field.condition) || !isEmpty(state[field.code])) &&
          Object.assign(validateObj, {
            [field.code]: yup
              .string()
              .matches(phoneNumberRegex, t('Enter a valid phone.'))
              .when([], {
                is: () => !field.is_optional,
                then: yup.string().required(t('This field cannot be left blank.')),
              }),
          });
      } else if (taxNumberFieldPattern.test(field.code)) {
        Object.assign(validateObj, {
          [field.code]: yup
            .string()
            .length(9, t('This field should be 9 digits.'))
            .when([], {
              is: () => !field.is_optional,
              then: yup.string().required(t('This field cannot be left blank.')),
            }),
        });
      } else if (zipCodeFieldPattern.test(field.code) && handleConditionalField(field, state)) {
        Object.assign(validateObj, {
          [field.code]: yup.string().when([], {
            is: () => !field.is_optional && field.maximum,
            then: yup
              .string()
              .length(field.maximum, t('Please enter a valid 5-digit Zip Code.'))
              .required(t('This field cannot be left blank.')),
            otherwise: yup.string().when([], {
              is: () => field.maximum,
              then: yup.string().length(field.maximum, t('Please enter a valid 5-digit Zip Code.')),
            }),
          }),
        });
      } else if (field.code === 'pol_incidents_type') {
        !field.is_optional &&
          Object.assign(validateObj, {
            [field.code]: yup
              .string()
              .required(t('Please select at least one of the options below.')),
          });
      } else {
        !field.is_optional &&
          handleConditionalField(field, state) &&
          Object.assign(validateObj, {
            [field.code]: yup.string().required(t('This field cannot be left blank.')),
          });
      }
    });

  return { ...validateObj, ...additionalValidation };
};

export const renderAddress = (data?: Data | null) => {
  const formatted = formatLocation({
    addressLine1: data?.loc_address_line1,
    addressLine2: data?.loc_address_line2,
    city: data?.loc_address_city,
    state: data?.loc_address_state,
    zip: data?.loc_address_zip,
  });
  return formatted.storing;
};

/**
This function takes in an object and an array of keys and finds the first key in the object that includes any of the keys in the array.
If a matching key is found, the corresponding value is returned. Otherwise, null is returned.
@param {object} obj - The object to search through.
@param {array} keys - An array of keys to search for.
@returns  - Returns the value of the first matching key found in the object, or null if no match is found.
*/
export const findMatchingKey = (obj, keys: any[]) => {
  const objKeys = Object.keys(obj);
  const matchingKey = objKeys.find((key) => keys.some((k) => key.includes(k)));
  return matchingKey ? obj[matchingKey] : null;
};

export const hasDiffKeyValues = (obj1: object, obj2: object, ...extraKeys: string[]) => {
  if (!obj2) return true;

  const paginationKeys = ['ordering', 'page', 'page_size', 'search', 'state', 'status'];
  const keysToControl = [...paginationKeys, ...extraKeys];

  return keysToControl.filter((key) => obj1[key] !== obj2[key])?.length !== 0;
};

export const hasPaginationKeys = (location: string) =>
  paginationKeys.filter((key) => location.includes(key))?.length !== 0;

export const getMonthDifference = (startDate: Date, endDate: Date) => {
  const res =
    endDate.getMonth() -
    startDate.getMonth() +
    12 * (endDate.getFullYear() - startDate.getFullYear());
  return Math.abs(res);
};

export const getRelevantUnderwritingInformation = (
  underwritingInformation: UnderwritingInformation[],
  effectiveDate: string,
) => {
  const filteredExpModFactorsCodeE = underwritingInformation.filter((elem) => {
    if (elem.RatingTypeCode === 'E' && elem.RatingEffectiveDate) {
      const dateMonthDiff = getMonthDifference(
        new Date(effectiveDate),
        new Date(elem.RatingEffectiveDate),
      );

      const isSmallerRatingDate = new Date(elem.RatingEffectiveDate) <= new Date(effectiveDate);

      if (isSmallerRatingDate && dateMonthDiff <= 12) {
        return elem;
      }
    }

    return null;
  });

  if (filteredExpModFactorsCodeE.length) {
    const minDifferenceEntry = filteredExpModFactorsCodeE.reduce((minEntry, currentEntry) => {
      const currentDateDifference = Math.abs(
        +new Date(effectiveDate) - +new Date(currentEntry.RatingEffectiveDate!),
      );

      const minDateDifference = Math.abs(
        +new Date(effectiveDate) - +new Date(minEntry.RatingEffectiveDate!),
      );

      return currentDateDifference < minDateDifference ? currentEntry : minEntry;
    }, filteredExpModFactorsCodeE[0]);

    return minDifferenceEntry;
  }

  if (!filteredExpModFactorsCodeE.length) {
    const filteredExpModFactorsCode = underwritingInformation.filter((elem) => {
      if (!elem.RatingEffectiveDate) {
        if (elem?.RatingEffectiveYear! <= new Date(effectiveDate).getFullYear()) {
          return elem;
        }
      }
      return null;
    });

    if (filteredExpModFactorsCode.length) {
      const minFilteredExpModFactorsCode = filteredExpModFactorsCode.reduce(
        (minEntry, currentEntry) => {
          const currentDateDifference = Math.abs(
            new Date(effectiveDate).getFullYear() - currentEntry?.RatingEffectiveYear!,
          );

          const minDateDifference = Math.abs(
            new Date(effectiveDate).getFullYear() - minEntry?.RatingEffectiveYear!,
          );

          return currentDateDifference < minDateDifference ? currentEntry : minEntry;
        },
        filteredExpModFactorsCode[0],
      );

      return minFilteredExpModFactorsCode;
    }
  }

  return null;
};

export const splitTextByLine = (text: string): string[] => {
  const textArray: string[] = text.split('\n');
  return textArray;
};

/*
  Function to restrict the input of characters based on specified validation rules.
  These rules can originate from the validationRelatedFieldNames parameter or directly from the field's configuration data.
 */
export const preventCharactersOnInputChange = ({
  inputValue,
  field,
  validationRelatedFieldNames,
}: {
  inputValue: string;
  field: DynamicField;
  validationRelatedFieldNames?: {
    fields?: string[];
    inputCharactersAllowed?: string[];
  }[];
}) => {
  const regexes = {
    alphaNumeric: {
      test: alphaNumericRegex,
      replace: nonAlphaNumericRegex,
    },
    nonComma: {
      test: nonCommaRegex,
      replace: commaRegex,
    },
  };

  const charactersTest = (inputCharactersAllowed: string[], inputValue: string) => {
    let updatedValue = inputValue;

    inputCharactersAllowed.forEach((restriction) => {
      const testRegex = regexes[restriction].test;
      const replaceRegex = regexes[restriction].replace;

      if (!testRegex.test(inputValue)) {
        updatedValue = inputValue.replace(replaceRegex, '');
      }
    });

    return updatedValue;
  };

  if (validationRelatedFieldNames?.length) {
    const fieldToValidate = validationRelatedFieldNames.find(
      ({ fields, inputCharactersAllowed }) =>
        fields?.includes(field.code) && inputCharactersAllowed?.length,
    );

    if (fieldToValidate && fieldToValidate.inputCharactersAllowed) {
      return charactersTest(fieldToValidate.inputCharactersAllowed, inputValue);
    }
  }

  const inputCharactersAllowed = field?.additional_data?.inputCharactersAllowed;

  if (inputCharactersAllowed) {
    return charactersTest(inputCharactersAllowed, inputValue);
  }

  return inputValue;
};

export const cleanString = (text: string) => {
  // Replace whitespace characters and trim
  const cleanedText = text.replace(/\s/g, ' ').trim();
  return cleanedText;
};

export const redirectToProducerTab = ({
  currentTab,
  triggeredRules = false,
  productType,
  isDriversTabHidden,
}: {
  currentTab: DetailInfoTabsFieldsTypes;
  triggeredRules?: boolean;
  productType: string | undefined;
  isDriversTabHidden?: boolean;
}) => {
  const isProductCodeThreeWithWorkersCompensation =
    productType === productCodes.THREE_WITH_WORKERS_COMPENSATION;

  if (triggeredRules) {
    return currentTab.producer_NextTab;
  }

  if (!triggeredRules) {
    if (currentTab.nextTabWithNoDrivers && isDriversTabHidden) {
      return currentTab.nextTabWithNoDrivers;
    } else {
      return isProductCodeThreeWithWorkersCompensation
        ? currentTab.nextTabWithWC
        : currentTab.nextTabWithoutWC;
    }
  }
};

export const findPositionTabClicked = (
  currentTab: string,
  clickedTab: string,
  submissionTabs: { [key: string]: any },
) => {
  const currTabIndex = submissionTabs.findIndex((tab) => tab.code === currentTab);
  const clickedTabIndex = submissionTabs.findIndex((tab) => tab.code === clickedTab);

  return clickedTabIndex < currTabIndex ? TAB_POSITIONS.PREVIOUS : TAB_POSITIONS.NEXT;
};

export const setNextPreviousTabUWSections = (codeActiveTab: string) => {
  if (codeActiveTab === submissionDetailInfoTabs.OPERATIONS_UW.code) {
    return {
      previousTab: submissionDetailInfoTabs.OPERATIONS.code,
      nextTab: submissionDetailInfoTabs.LOCATIONS.code,
    };
  }

  return {
    previousTab: submissionDetailInfoTabs.ADDITIONAL_INTEREST.code,
    nextTab: submissionDetailInfoTabs.SUMMARY_PRICING.code,
  };
};

// Checks if the field is optional
export const findOptionalFieldWithFieldCode = (
  allFields: PropertyExposureField[],
  code: string,
): boolean | undefined => {
  const selectedFields = allFields.find((field) => field.code === code);
  return selectedFields ? selectedFields.is_optional : undefined;
};

// Check if the property has any of the required fields that affect the premium calculations
export const isAllRequiredFieldsFilled = (
  stateObj: QuoteDetailResponse,
  fieldsObj: PropertyExposureField[],
  properties: string[],
): boolean => {
  const requiredFields = fieldsObj
    .filter(
      (field) =>
        properties.includes(field.code as string) && !field.is_optional && !field.is_hidden,
    )
    .map((field) => field.code);

  const extractedValues = Object.fromEntries(
    requiredFields.map((requiredField) => [requiredField, stateObj[requiredField as string]]),
  );

  const isNotEmpty = (value: any) =>
    (typeof value === 'string' && value.trim() !== '') ||
    (typeof value === 'number' && !isNaN(value));

  return Object.values(extractedValues).some((value) => !isNotEmpty(value));
};

export const findAddressesDifference = (originalAddresss, verifiedAddress) => {
  const originalAddressPartsLine1 = originalAddresss?.loc_address_line1?.trim();
  const verifiedAddressPartsLine1 = verifiedAddress?.delivery_line_1?.trim();
  const originalAddressPartsLine2 = originalAddresss?.loc_address_line2?.trim();
  const verifiedAddressPartsLine2 = verifiedAddress?.delivery_line_2?.trim();
  const verifiedApartmentNumber = `${verifiedAddress?.components?.secondary_designator} ${verifiedAddress?.components?.secondary_number}`;

  const addressLine1Diff = verifiedAddressPartsLine1
    ?.split(' ')
    ?.filter((word) => !originalAddressPartsLine1?.split(' ')?.includes(word))
    ?.join(' ');

  // Should ignore difference in case correct apartment number displayed in addressLine2
  const shouldIgnoreDiff =
    !verifiedAddressPartsLine2 &&
    addressLine1Diff === originalAddressPartsLine2 &&
    verifiedApartmentNumber === originalAddressPartsLine2;

  const isAddressLine1Different = shouldIgnoreDiff
    ? false
    : originalAddressPartsLine1 !== verifiedAddressPartsLine1;

  const isAddressLine2Different =
    (isEmpty(originalAddressPartsLine2) && isEmpty(verifiedAddressPartsLine2)) || shouldIgnoreDiff
      ? false
      : originalAddressPartsLine2 !== verifiedAddressPartsLine2;

  const isCityNameDifferent =
    originalAddresss?.loc_address_city !== verifiedAddress?.components?.city_name;

  const isStateDifferent =
    originalAddresss?.loc_address_state !== verifiedAddress?.components?.state_abbreviation;

  const isZipCodeDifferent =
    originalAddresss?.loc_address_zip !== verifiedAddress?.components?.zipcode;

  return {
    differenceAddressParts: {
      addressLine1: {
        isDifferent: isAddressLine1Different,
        original: originalAddressPartsLine1,
        verified: verifiedAddressPartsLine1,
      },
      addressLine2: {
        isDifferent: isAddressLine2Different,
        original: originalAddressPartsLine2,
        verified: verifiedAddressPartsLine2,
      },
      city: {
        isDifferent: isCityNameDifferent,
        original: originalAddresss?.loc_address_city,
        verified: verifiedAddress?.components?.city_name,
      },
      state: {
        isDifferent: isStateDifferent,
        original: originalAddresss?.loc_address_state,
        verified: verifiedAddress?.components?.state_abbreviation,
      },
      zip: {
        isDifferent: isZipCodeDifferent,
        original: originalAddresss?.loc_address_zip,
        verified: verifiedAddress?.components?.zipcode,
      },
    },

    isDifferent:
      isAddressLine1Different ||
      isAddressLine2Different ||
      isCityNameDifferent ||
      isStateDifferent ||
      isZipCodeDifferent,
  };
};

export const createAddressInfo = (
  obj: {
    addressLine1: AddressDifferenceInfo;
    addressLine2: AddressDifferenceInfo;
    city: AddressDifferenceInfo;
    state: AddressDifferenceInfo;
    zip: AddressDifferenceInfo;
  },
  selectType: 'original' | 'verified',
) => {
  const addressInfo: AddressInfo = {
    addressLine1: obj?.addressLine1?.[selectType] ?? '',
    addressLine2: obj?.addressLine2?.[selectType] || undefined,
    city: obj?.city?.[selectType] || undefined,
    state: obj?.state?.[selectType] || undefined,
    zip: obj?.zip?.[selectType] || undefined,
  };

  return addressInfo;
};

export const formatStreetAbbr = (originalAddressLine: string) => {
  const replacements = {
    Avenue: CommonStreetAbbrs.AVENUE,
    'Ave.': CommonStreetAbbrs.AVENUE,
    Ave: CommonStreetAbbrs.AVENUE,
    Boulevard: CommonStreetAbbrs.BOULEVARD,
    'Blvd.': CommonStreetAbbrs.BOULEVARD,
    Blvd: CommonStreetAbbrs.BOULEVARD,
    Court: CommonStreetAbbrs.COURT,
    'Ct.': CommonStreetAbbrs.COURT,
    Ct: CommonStreetAbbrs.COURT,
    Drive: CommonStreetAbbrs.DRIVE,
    'Dr.': CommonStreetAbbrs.DRIVE,
    Dr: CommonStreetAbbrs.DRIVE,
    Lane: CommonStreetAbbrs.LANE,
    'Ln.': CommonStreetAbbrs.LANE,
    Ln: CommonStreetAbbrs.LANE,
    Place: CommonStreetAbbrs.PLACE,
    'Pl.': CommonStreetAbbrs.PLACE,
    Pl: CommonStreetAbbrs.PLACE,
    Road: CommonStreetAbbrs.ROAD,
    'Rd.': CommonStreetAbbrs.ROAD,
    Rd: CommonStreetAbbrs.ROAD,
    Street: CommonStreetAbbrs.STREET,
    'St.': CommonStreetAbbrs.STREET,
    St: CommonStreetAbbrs.STREET,
    Trail: CommonStreetAbbrs.TRAIL,
    'Trl.': CommonStreetAbbrs.TRAIL,
    Trl: CommonStreetAbbrs.TRAIL,
  };

  const formattedAddress = originalAddressLine
    .split(' ')
    .map((word) => (replacements[capitalize(word)] ? replacements[capitalize(word)] : word))
    .join(' ');

  return formattedAddress;
};

export const formatAddressCase = (originAddress: string, verifiedAddress: string) =>
  originAddress
    .split(' ')
    .map((originPart) => {
      const replacement = verifiedAddress
        .split(' ')
        .find((verifiedPart) => originPart.toLowerCase() === verifiedPart.toLowerCase());

      return replacement ?? originPart;
    })
    .join(' ');

export const formatLocationCase = (
  originLocation: any,
  verifiedLocation: SmartyValidateAddressResponse | undefined,
) => {
  const originalAddressLine1 = originLocation?.loc_address_line1 || '';
  const verifiedAddressLine1 = verifiedLocation?.delivery_line_1 || '';
  const formattedAddressLine1 = formatAddressCase(originalAddressLine1, verifiedAddressLine1);

  const originalAddressLine2 = originLocation?.loc_address_line2 || '';
  const verifiedApartmentNumber = `${verifiedLocation?.components?.secondary_designator} ${verifiedLocation?.components?.secondary_number}`;
  const verifiedAddressLine2 = verifiedLocation?.delivery_line_2 || verifiedApartmentNumber || '';
  const formattedAddressLine2 = formatAddressCase(originalAddressLine2, verifiedAddressLine2);

  const originalCity = originLocation?.loc_address_city || '';
  const verifiedCity = verifiedLocation?.components?.city_name || '';
  const formattedCity = formatAddressCase(originalCity, verifiedCity);

  return {
    ...originLocation,
    loc_address_line1: formattedAddressLine1,
    loc_address_line2: formattedAddressLine2,
    loc_address_city: formattedCity,
  };
};

/**
 * This function calculates product effective date range based on its effective date and the current date.
 * Used when the user creates a new submission and ensures that the effective date range conforms to business rules:
 * - starts tomorrow if the product is available now, otherwise starting on the first available day
 * - extends up to 90 days from the estimated minimum effective date.
 * @param productEffectiveDateString
 * @param timezone
 * @returns An object containing the formatted effective date, minimum effective date, and maximum effective date.
 */
export const getProductEffectiveDateRange = (
  productEffectiveDateString: string,
  timezone = defaultTimezone,
) => {
  const convertZonedTimeToUtc = (date: Date | string) => zonedTimeToUtc(new Date(date), timezone);
  const tomorrow = convertZonedTimeToUtc(addDays(startOfDay(new Date()), 1));
  const productEffectiveDate = convertZonedTimeToUtc(
    startOfDay(new Date(parseISO(productEffectiveDateString))),
  );

  // Minimum effective date logic:
  // Special case: if today is 12/31/2023, then the min effective date is 1/1/2024
  // else the min effective date is tomorrow
  const defaultMinEffectiveDate =
    differenceInDays(tomorrow, firstDayOfYear2024) < 0 ? new Date(firstDayOfYear2024) : tomorrow;

  const isProductAvailableNow = isBefore(productEffectiveDate, defaultMinEffectiveDate);

  // If product effective date later than tommorow we set min effective date to product effective date
  const minEffectiveDate = isProductAvailableNow ? defaultMinEffectiveDate : productEffectiveDate;

  // Maximum effective date logic: today or product effective date + 90 days
  const maxEffectiveDate = isProductAvailableNow
    ? convertZonedTimeToUtc(addDays(endOfDay(new Date()), 90))
    : convertZonedTimeToUtc(
        addDays(endOfDay(utcToZonedTime(minEffectiveDate, defaultTimezone)), 90),
      );

  const effectiveDateRange = {
    productEffectiveDate,
    minEffectiveDate,
    maxEffectiveDate,
  };

  return effectiveDateRange;
};

/**
 * This function manage the visibility of a specific form field, veh_stated_amt, based on a series of conditions related to a vehicle's attributes.
 * Used when the user add vehicle by press "Verify VIN" button
 * https://3pager.visualstudio.com/NLS-3Pager-Agent-Portal/_workitems/edit/143066/
 * @param values vehicle's attributes (vin, msrp, year, category, subcategory e.t.c)
 * @param setFields callback function which set fields for vehicle form
 */
export const updateVisibilityOfEstimatedCurrentValueField = (
  values: VehicleData,
  setFields: React.Dispatch<React.SetStateAction<Field[]>>,
) => {
  const existedSubcategories = ['Ice Cream Truck', 'Food Truck', 'Stretched Limousine (Car/SUV)'];
  const categoryIsTrailer = values.veh_body_category === 'Trailer';
  const subcategoryIsExist = existedSubcategories.some((e) => e === values.veh_body_subcategory);
  const MSRP = values.veh_msrp;
  const vehicleAge = new Date().getFullYear() - values.veh_year;
  const estimatedCurrentValueFieldCode = 'veh_stated_amt';

  switch (true) {
    case !categoryIsTrailer && !subcategoryIsExist && MSRP > 0 && vehicleAge <= 15:
      setFields((prevFields) =>
        changeFieldsHiddenStatus(prevFields, [estimatedCurrentValueFieldCode], true),
      );
      break;

    case categoryIsTrailer:
      setFields((prevFields) =>
        changeFieldsHiddenStatus(prevFields, [estimatedCurrentValueFieldCode], false),
      );
      break;

    case !categoryIsTrailer && subcategoryIsExist:
      setFields((prevFields) =>
        changeFieldsHiddenStatus(prevFields, [estimatedCurrentValueFieldCode], false),
      );
      break;

    case !categoryIsTrailer && !subcategoryIsExist && MSRP <= 0:
      setFields((prevFields) =>
        changeFieldsHiddenStatus(prevFields, [estimatedCurrentValueFieldCode], false),
      );
      break;

    case !categoryIsTrailer && !subcategoryIsExist && MSRP > 0 && vehicleAge > 15:
      setFields((prevFields) =>
        changeFieldsHiddenStatus(prevFields, [estimatedCurrentValueFieldCode], false),
      );
      break;
    default:
  }
};

/**
 * This function checks if policy has uw result that requires attention by checking the extra status of policy.
 * Used when deciding to show provisional UW results tab.
 * @param extraStatuses An constant containing status codes.
 * @param underwritingQuestionsResponse
 * @returns a boolean value indicating whether the extra_status is one of these two specific statuses.
 */
export const isUWQuestionsResponseExtraStatusReferOrDecline = (
  extraStatuses,
  underwritingQuestionsResponse?: QuoteDetailResponse | EndorsementDetail,
) =>
  underwritingQuestionsResponse?.extra_status === extraStatuses.HAS_DECLINE.code ||
  underwritingQuestionsResponse?.extra_status === extraStatuses.HAS_REFER.code;

/**
 * This function manage validation type(required) depends on hidden status of Estimated Current Value Field(veh_stated_amt)
 * Used in commonValidations for vehicle form
 * https://3pager.visualstudio.com/NLS-3Pager-Agent-Portal/_workitems/edit/143066/
 * @param values vehicle's attributes (vin, msrp, year, category, subcategory e.t.c)
 * @param fields vehicle's form fields
 */
export const handleEstimatedCurrentValueFieldValidation = (fields: Field[]) => {
  const field = fields.find((e) => e.code === 'veh_stated_amt');

  return field?.is_hidden
    ? { veh_stated_amt: yup.string() }
    : { veh_stated_amt: yup.string().required(t('This field cannot be left blank.')) };
};

/**
 * Updates the `bdg_prometrix_hit` field in the state based on specific conditions.
 *
 * This function sets `bdg_prometrix_hit` only for schedule building occupancies.
 * If `isIntegrationResultsChanged` is true, it sets the `bdg_prometrix_hit` field
 * to 'No' and then updates it to 'Yes' if the `isIntegrationResultsChanged` flag is false
 * and either `bdg_erc_building` matches the `initial_integration_result.bdg_erc_building` or
 * `bdg_uw_report_type` is included in the `approvePropertyUWReportType` array.
 *
 * @param {Data} state - The current state object.
 * @param {React.Dispatch<React.SetStateAction<Data>>} setState - The state update function.
 * @param {boolean} isIntegrationResultsChanged - Flag indicating if the user changed inittial integration results.
 */
export const setBdgPrometrixHitValue = (
  state: Data,
  setState: React.Dispatch<React.SetStateAction<Data>>,
  isIntegrationResultsChanged: boolean,
) => {
  if (
    state?.bdg_occupancy &&
    propertyExposureScheduleBuildingOccupancies.includes(state?.bdg_occupancy)
  ) {
    let bdg_prometrix_hit = 'No';

    if (!isIntegrationResultsChanged) {
      if (
        (state?.bdg_erc_building &&
          String(state?.bdg_erc_building) ===
            String(state?.initial_integration_result?.bdg_erc_building)) ||
        approvePropertyUWReportType.includes(state?.bdg_uw_report_type)
      ) {
        bdg_prometrix_hit = 'Yes';
      }
    }

    setState((prevState) => ({
      ...prevState,
      bdg_prometrix_hit,
    }));

    return bdg_prometrix_hit;
  } else {
    setState((prevState) => ({
      ...omit(prevState, 'bdg_prometrix_hit'),
    }));
  }
};

/**
 * Sorting rows by main status
 *
 * Using in WCExposures to sort rows for WC payroll data table by main status
 *
 * @param values - accept rows which uses in DataTable
 */
export const sortComputedWcRowsByMainStatus = (values) => {
  return [...values].sort((a, b) => (a.isMain === 'Yes' && b.isMain === 'No' ? -1 : 1));
};

/**
 * Creates a grid columns visibility model based on their hidden status.
 *
 * @param {IColumns[]} columns - An array of column objects. Each object represents a column and contains the following properties:
 *   @param {string} columns[].name - The name of the column.
 *   @param {boolean} [columns[].is_hidden] - A boolean indicating whether the column is hidden. If `true`, the column is hidden; otherwise, it is visible.
 *
 * @returns {object} - A model where the keys are the names of the hidden columns, and the values are all set to `false`, indicating that these columns are hidden.
 */
export const createColumnVisibilityModel = (columns: IColumns[]) => {
  const model = {};
  columns.forEach((field) => {
    if (field.is_hidden) {
      model[field.name] = false;
    }
  });
  return model;
};

// This function is used to determine if a promise was successfully fulfilled
export const isFulfilled = <T>(
  response: PromiseSettledResult<T>,
): response is PromiseFulfilledResult<T> => response.status === 'fulfilled';
