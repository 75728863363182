import { PolicyDetailContext } from 'providers/PolicyDetailProvider/PolicyDetailProviderContext';
import { IPolicyDetailContextType } from 'providers/PolicyDetailProvider/types';
import { useContext } from 'react';

export default function usePolicyDetail(): IPolicyDetailContextType {
  const context = useContext(PolicyDetailContext);

  /* istanbul ignore if */
  if (!context) {
    throw new Error('usePolicyDetail must be used within a PolicyDetailProvider');
  }

  return context;
}
