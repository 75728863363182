import { FormControl, Skeleton, Stack } from '@mui/material';
import React, { FC } from 'react';

export const DetailDrawerLoader: FC = () => (
  <>
    {[...Array(7).keys()].map((v) => (
      <Stack
        key={`skel${v}`}
        gap={2}
        sx={{
          width: 1,
          flexDirection: {
            xs: 'column',
            sm: 'row',
          },
          mb: 2,
        }}
      >
        {/* <FormControl variant="standard" sx={{ flex: 1 }}>
          <Skeleton animation="wave" width="100%" height={37} />
        </FormControl> */}
        <FormControl variant="standard" sx={{ flex: 1 }}>
          <Skeleton animation="wave" width="100%" height={37} />
        </FormControl>
      </Stack>
    ))}
  </>
);
