/* eslint-disable prefer-const */
/* eslint-disable no-restricted-syntax */
import { ISchedModTableRow } from 'components/ScheduleModTable/ScheduleModTable';
import { isEmpty } from 'lodash-es';

interface ITableRow {
  FilingSetID: number;
  Category: string;
  MaxCredit: number;
  MaxDebit: number;
}

interface IGroupField {
  pol_sched_mod_value_rule?: string;
  pol_sched_mod_line: string;
  pol_sched_mod_state: string;
  pol_sched_mod_category: string;
  pol_sched_mod_comment?: string;
  pol_sched_mod_value_uw?: string;
  locator: string;
}

export const calculateTotalOfRow = (rule: number, judgement: number, max: number, min: number) => {
  const totalOfRuleAndJudgement = rule + judgement;

  if (totalOfRuleAndJudgement >= 0) {
    return Math.min(totalOfRuleAndJudgement, max * 100);
  } else {
    return Math.max(totalOfRuleAndJudgement, min * 100);
  }
};

export const mergeFieldsAndValues = (groupFields: IGroupField[], tableValues: ITableRow[]) => {
  // create an object that maps category names to tableValues objects
  const categoryMap = {};
  for (const value of tableValues) {
    categoryMap[value.Category] = value;
  }

  // merge each element of groupFields with the corresponding tableValues object
  const result: any = [];
  for (const field of groupFields) {
    const category = field.pol_sched_mod_category;
    const value = categoryMap[category];
    if (value) {
      result.push({ ...field, ...value });
    }
  }

  return result;
};

export const decimalToPercentage = (decimalValue: number, withPercentageSign = true) => {
  // Convert decimal value to percentage
  const percentageValue = decimalValue * 100;

  // Round to the nearest integer
  const roundedValue = Math.round(percentageValue);

  if (!withPercentageSign) return roundedValue.toString();

  // Return as a string with a percent sign
  return `${roundedValue.toString()}%`;
};

export const getTotal = (arr: ISchedModTableRow[]) => arr.reduce((acc, obj) => acc + obj.total, 0);

export const calculateModification = (
  totalOfCategories: number,
  totalCategoriesValues: ITableRow,
) => {
  const maxModValue =
    totalOfCategories >= 0
      ? totalCategoriesValues?.MaxDebit * 100
      : totalCategoriesValues?.MaxCredit * 100;
  const totalOfTotalValue =
    totalOfCategories >= 0
      ? Math.min(totalOfCategories, maxModValue)
      : Math.max(totalOfCategories, maxModValue);

  return totalOfTotalValue;
};

export const calculateSchedMod = (groupFields: IGroupField[], table: ITableRow[]) => {
  if (isEmpty(groupFields) || isEmpty(table)) return null;
  const maximumModificationRow = table?.find((item) => item.Category === 'Maximum Modification');
  const rowsWithMinsAndMaxs = mergeFieldsAndValues(groupFields, table);

  const groupFieldsWithTotal = rowsWithMinsAndMaxs.map((groupField) => {
    let { pol_sched_mod_value_rule: rule, pol_sched_mod_value_uw: judgement } = groupField;
    const { MaxDebit: max, MaxCredit: min } = groupField;

    if (Number.isNaN(judgement) || isEmpty(judgement)) judgement = 0;
    if (Number.isNaN(rule) || isEmpty(rule)) rule = 0;

    rule = +(+rule * 100).toFixed();
    judgement = +(+judgement * 100).toFixed();

    const total = calculateTotalOfRow(rule, judgement, max, min);

    return {
      ...groupField,
      total,
    };
  });

  const totalOfGroups = getTotal(groupFieldsWithTotal);

  const schedMod = calculateModification(totalOfGroups, maximumModificationRow!);

  return (1 + schedMod / 100).toFixed(2);
};

// These are the names of the schedule modification values that are defined in socotra configuration
type scheduleModificationNameTypes =
  | 'BM7'
  | 'CA18'
  | 'CF15'
  | 'CY5'
  | 'DO8'
  | 'EP7'
  | 'F17'
  | 'GL5'
  | 'PL9'
  | 'FilingsScheduleMod';

// This map is used to transform socotra schedule modification value names to the names that are used on portal
const scheduleModificationNamesMap = {
  BM7: 'BM',
  CA18: 'AUTO',
  CF15: 'PROP',
  CY5: 'CY',
  DO8: 'DO',
  EP7: 'EPLI',
  F17: 'FIDU',
  GL5: 'GL',
  PL9: 'PL',
  FilingsScheduleMod: 'WC',
};

export interface ISocotraScheduleModificationTable {
  key: string;
  value: {
    [key in scheduleModificationNameTypes]: {
      [fillingSetId: string]: {
        [category: string]: {
          MaxCredit: string;
          MaxDebit: string;
        };
      };
    };
  };
  value_type: string;
}

// This function transforms socotra schedule modification table to the format that is used on portal
export const transformScheduleModificationTable = (table: ISocotraScheduleModificationTable) => {
  const transformedValue = {};

  Object.entries(scheduleModificationNamesMap).forEach(
    ([scheduleModName, newScheduleModificationName]) => {
      transformedValue[newScheduleModificationName] = Object.entries(table.value[scheduleModName])
        .map(([key, value]) => ({
          ...Object.entries(value!).map(([category, values]) => ({
            Category: category,
            ...values,
            FilingSetID: key,
          })),
        }))
        .reduce((acc, curr) => [...acc, ...Object.values(curr)], [] as any);
    },
  );

  return { ...table, value: transformedValue };
};
