import ApiService from 'api/helpers/Sender';
import { IGenericIntegrationResponse } from 'api/models/Integrations/IGenericResponse';
import { IGetByFEINRequest, IWCIRBResponse } from 'api/models/Integrations/WCIRB/WCIRB.model';

const API = new ApiService();

export const getModByFeinWcirb = async (payload: IGetByFEINRequest): Promise<IWCIRBResponse> => {
  const { data }: { data: IGenericIntegrationResponse<IWCIRBResponse> } = await API.post(
    `services/wcirb/get-by-fein`,
    payload,
  );

  return data?.data ?? {};
};
