import ApiService from 'api/helpers/Sender';
import { SmartyAutoCompleteResponse } from 'api/models/Smarty/SmartyAutoCompleteResponse.model';
import { SmartyValidateAddressRequest } from 'api/models/Smarty/SmartyValidateAddressRequest.model';
import { SmartyValidateAddressResponse } from 'api/models/Smarty/SmartyValidateAddressResponse.model';

const API = new ApiService();

export const getSmartyAddress = async (body: any): Promise<SmartyAutoCompleteResponse[]> => {
  const { data } = await API.post(`smarty/complete-address`, {
    ...body,
  });

  return data ?? [];
};

export const validateSmartyAddress = async (
  body: SmartyValidateAddressRequest,
): Promise<SmartyValidateAddressResponse> => {
  const { data } = await API.post(`smarty/validate-address`, {
    ...body,
  });

  return data ?? [];
};
