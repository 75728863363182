import { getRoleShortCode } from './Utils';

export interface IAssignToOption {
  id: number;
  full_name: string;
  action: boolean;
  email: string;
  role: Role;
}

interface Role {
  code: string;
  name: string;
}

export const getAssignToOptionLabel = (option: IAssignToOption): string => {
  let text = `${option.full_name} - `;
  if (getRoleShortCode(option.role?.code)) {
    text += `${getRoleShortCode(option.role?.code)} - `;
  }
  text += option.email;

  return text;
};

export const getAssociatedWithOptionLabel = (option, isDataCombined = false): string => {
  const type = isDataCombined ? option.reference_type : option.resource?.resource_type;
  const name = isDataCombined ? option.name : option?.associated_information?.name;
  const threeNumber = isDataCombined
    ? option.three_number ?? option.locator
    : option.three_number ?? option.associated_information?.locator;

  let text = `${type}`;

  if (name?.trim()) {
    text += ` - ${name}`;
  }
  text += ` - ${threeNumber}`;

  return text;
};
