import { AuthContext } from 'providers/AuthProvider/AuthContext';
import { IAuthContext } from 'providers/AuthProvider/types';
import { useContext } from 'react';

export default function useAuth(): IAuthContext {
  const context = useContext(AuthContext);

  /* istanbul ignore if */
  if (!context) {
    throw new Error('useAuth must be used within a AuthProvider');
  }

  return context;
}
