import { Box, Skeleton } from '@mui/material';
import { PolicyFieldError } from 'api/models/Socotra/PolicyFieldErrorResponse/policyFieldError.model';
import {
  declineEndorsementRequest,
  discardEndorsement,
  issueEndorsement,
  quoteEndorsement,
} from 'api/services/PolicyEndorsement';
import Cancel from 'assets/images/CancelIcon.svg';
import QuoteIcon from 'assets/images/file-blank-edit-pen.svg';
import AddNote from 'assets/images/icon_addnote.svg';
import AddDocumnet from 'assets/images/icon_attach_document.svg';
import DeclineIcon from 'assets/images/icon_decline.svg';
import SendBack from 'assets/images/SendBack.svg';
import DiscardIcon from 'assets/images/trash-delete-bin-2.svg';
import {
  endorsementExtraStatuses,
  errorCodes,
  globalSearchTypes,
  policyDetailEndorsementStatuses,
  policyDetailEndorsementTabs,
  THREE_EndorsementTypes,
  userRoles,
} from 'common/constants';
import AddDocumentDrawer from 'components/Documents/AddDocument';
import AddNoteDrawer from 'components/Notes/AddNote';
import { SocotraFieldErrorModalParser } from 'components/QuoteFieldErrorParser/FieldErrorModal';
import TopActionBar from 'components/TopActionBar';
import TopActionBarButton from 'components/TopActionBarButton';
import { TopActionBarButtonProps } from 'components/TopActionBarButton/TopActionBarButton';
import displayBackendErrorMessage from 'helpers/displayBackendErrorMessage';
import displayToastMessage from 'helpers/DisplayToastMessage';
import { emitter, Events } from 'helpers/EventBus';
import { updateQueryStrings } from 'helpers/Utils';
import useDialog from 'hooks/useDialog';
import useEndorsementDetail from 'hooks/useEndorsementDetail';
import useLoader from 'hooks/useLoader';
import useRouterPrompt from 'hooks/useRouterPrompt';
import useUser from 'hooks/useUser';
import { isEmpty } from 'lodash-es';
import qs from 'query-string';
import React, { FC, useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory, useLocation, useParams } from 'react-router-dom';
import { ReactSVG } from 'react-svg';

export interface TopActionBarProps {
  policyId: string;
  onNoteCreated?: () => void;
  onDocumentCreated?: () => void;
}

const PolicyEndorsementDetailTopActionBar: FC<TopActionBarProps> = ({
  policyId,
  onNoteCreated,
  onDocumentCreated,
}) => {
  const { t } = useTranslation();
  const HISTORY = useHistory();
  const LOCATION = useLocation();
  const url = qs.parse(LOCATION.search);
  const isAddNoteDrawerOpen = url?.addNote === 'true';
  const isAddDocumentDrawerOpen = url?.addDocument === 'true';
  const [isPreRatingErrored, setIsPreRatingErrored] = useState(false);
  const [preRatingErrors, setPreRatingErrors] = useState<PolicyFieldError>({});
  const [preRatingErrorsCode, setPreRatingErrorsCode] = useState<number | string>();
  const {
    data: endorsementDetail,
    fetch,
    loaded: endorsementLoaded,
    documentTypes,
    getEndorsementDocumentTypes,
    referEndorsement,
    sendBackEndorsement,
  } = useEndorsementDetail();
  const { data: user } = useUser();
  const { setLoading } = useLoader();
  const { setDialogOpen } = useDialog();
  const { tab } = qs.parse(HISTORY.location.search) as { tab: string };
  const { endorsementId } = useParams<{ id: string; endorsementId: string }>();
  const { setBypassRouteBlock } = useRouterPrompt();

  const endorsementStatus = endorsementDetail?.state?.key;

  const isUserRoleUnderwriter = user?.role?.code === userRoles.UNDERWRITER.code;
  const isUserRoleProducer = user?.role?.code === userRoles.AGENT.code;

  const isAdditionalInterestEndorsement =
    endorsementDetail?.endorsement_type?.code === THREE_EndorsementTypes.ADDITIONAL_INTEREST;

  const isApplicationStatus = endorsementStatus === policyDetailEndorsementStatuses.APPLICATION;
  const isReferredStatus = endorsementStatus === policyDetailEndorsementStatuses.REFERRED;
  const isQuotedStatus = endorsementStatus === policyDetailEndorsementStatuses.QUOTED;
  const isAcceptedStatus = endorsementStatus === policyDetailEndorsementStatuses.ACCEPTED;
  const isDeclinedStatus = endorsementStatus === policyDetailEndorsementStatuses.DECLINED;
  const isDeclinedByUWStatus = endorsementStatus === policyDetailEndorsementStatuses.DECLINED_BY_UW;
  const isIssueButtonVisible =
    (isUserRoleUnderwriter || (isUserRoleProducer && isAdditionalInterestEndorsement)) &&
    (isAcceptedStatus || isQuotedStatus);
  const isUWDeclineButtonVisible =
    isUserRoleUnderwriter && (isApplicationStatus || isReferredStatus);
  const isDiscardButtonVisible =
    isUserRoleUnderwriter &&
    (isApplicationStatus || isQuotedStatus || isDeclinedByUWStatus || isDeclinedStatus);
  const isMainActionButtonsVisible = isApplicationStatus || isReferredStatus || isDeclinedStatus;
  const isSendBackButtonVisible = isReferredStatus && isUserRoleUnderwriter;

  const showAddNoteButton = () => isUserRoleUnderwriter;

  const handleNewNote = () => {
    HISTORY.push({
      search: updateQueryStrings({
        locationSearch: LOCATION.search,
        newQueries: { addNote: true },
      }),
    });
  };

  const processPreRatingError = (e: any, defaultMessage: string) => {
    switch (e?.response?.data?.code) {
      case errorCodes.UNDERWRITING_QUESTIONS: {
        setIsPreRatingErrored(true);
        setPreRatingErrors(e.response.data?.field_errors);
        setPreRatingErrorsCode(e.response?.data.code);
        break;
      }

      default:
        displayBackendErrorMessage(e, defaultMessage);
        break;
    }
  };
  const handleQuestionEngineInputsValidatedRefer = async (
    isDataValid: Events['referQuestionEngineInputsValidated'],
  ) => {
    if (isDataValid) {
      try {
        setLoading(true);
        await referEndorsement(policyId, endorsementId);
        displayToastMessage('SUCCESS', t('The policy changes has been referred.'));
      } catch (error) {
        displayBackendErrorMessage(
          error,
          t('An error occurred while referring the policy changes.'),
        );
      } finally {
        setLoading(false);
      }
    }
  };

  const handleQuestionEngineInputsInputsValidated = async (
    isDataValid: Events['questionEngineInputsValidated'],
  ) => {
    if (isDataValid) {
      try {
        setLoading(true);
        await quoteEndorsement(policyId, endorsementId);
        await fetch(policyId, endorsementId);
        displayToastMessage('SUCCESS', t('Policy changes has been quoted.'));
      } catch (error) {
        const e = error as any;
        processPreRatingError(e, t('An error occurred while quoting the policy changes.'));
      } finally {
        setLoading(false);
      }
    }
  };

  const handleQuestionEngineInputsValidatedDecline = async (
    isDataValid: Events['declineQuestionEngineInputsValidated'],
  ) => {
    if (isDataValid) {
      try {
        setLoading(true);
        await declineEndorsementRequest(policyId as string, endorsementId as string);
        await fetch(policyId, endorsementId);
        displayToastMessage('SUCCESS', t('Policy changes has been decline.'));
      } catch (error) {
        displayBackendErrorMessage(
          error,
          t('An error occurred while declining the policy changes.'),
        );
      } finally {
        setLoading(false);
      }
    }
  };

  const handleQuestionEngineInputsValidatedSendBack = async (
    isDataValid: Events['sendBackQuestionEngineInputsValidated'],
  ) => {
    if (isDataValid) {
      try {
        setLoading(true);
        await sendBackEndorsement(policyId, endorsementId);
        displayToastMessage('SUCCESS', t('Endorsement has been sent back.'));
      } catch (error) {
        displayBackendErrorMessage(
          error,
          t('An error occurred while sending back the endorsement.'),
        );
      } finally {
        setLoading(false);
      }
    }
  };

  useEffect(() => {
    emitter.on('questionEngineInputsValidated', handleQuestionEngineInputsInputsValidated);
    emitter.on('referQuestionEngineInputsValidated', handleQuestionEngineInputsValidatedRefer);
    emitter.on('declineQuestionEngineInputsValidated', handleQuestionEngineInputsValidatedDecline);
    emitter.on(
      'sendBackQuestionEngineInputsValidated',
      handleQuestionEngineInputsValidatedSendBack,
    );

    const handlePreRatingError = ({ error, defaultMessage }: Events['preRatingError']) =>
      processPreRatingError(error, defaultMessage);
    emitter.on('preRatingError', handlePreRatingError);

    return () => {
      emitter.off('questionEngineInputsValidated', handleQuestionEngineInputsInputsValidated);
      emitter.off('referQuestionEngineInputsValidated', handleQuestionEngineInputsValidatedRefer);
      emitter.off(
        'declineQuestionEngineInputsValidated',
        handleQuestionEngineInputsValidatedDecline,
      );
      emitter.off(
        'sendBackQuestionEngineInputsValidated',
        handleQuestionEngineInputsValidatedSendBack,
      );
      emitter.off('preRatingError', handlePreRatingError);
    };
  }, []);

  const isQuestionEnginePage = useMemo(() => {
    switch (tab) {
      case policyDetailEndorsementTabs.BUSINESS_INFO.code:
        return true;

      case policyDetailEndorsementTabs.OPERATIONS.code:
        return true;

      default:
        return false;
    }
  }, [tab]);

  const isCoverageSummaryPage = useMemo(() => {
    switch (tab) {
      case policyDetailEndorsementTabs.SUMMARY_PRICING.code:
        return true;

      default:
        return false;
    }
  }, [tab]);

  const handleQuote = async () => {
    setDialogOpen({
      dialog: 'QUOTE_ENDORSEMENT',
      isOpen: false,
    });

    // emit event to page
    if (isQuestionEnginePage) {
      emitter.emit('validateQuestionEngineInputs', 'questionEngineInputsValidated');
    } else if (isCoverageSummaryPage) {
      emitter.emit('submitCoverageSummary', 'questionEngineInputsValidated');
    } else {
      handleQuestionEngineInputsInputsValidated(true);
    }
  };

  const handleReferEndorsement = async () => {
    setDialogOpen({
      dialog: isUserRoleUnderwriter ? 'REFER_ENDORSEMENT_UW' : 'REFER_ENDORSEMENT',
      isOpen: false,
    });

    if (isQuestionEnginePage) {
      emitter.emit('validateQuestionEngineInputs', 'referQuestionEngineInputsValidated');
    } else if (isCoverageSummaryPage) {
      emitter.emit('submitCoverageSummary', 'referQuestionEngineInputsValidated');
    } else {
      handleQuestionEngineInputsValidatedRefer(true);
    }
  };

  const LockEndorsementButton = (props?: TopActionBarButtonProps) => (
    <TopActionBarButton
      onClick={() => {
        setDialogOpen({
          dialog: 'QUOTE_ENDORSEMENT',
          isOpen: true,
          onAccept: () => handleQuote(),
        });
      }}
      startIcon={<Box component="img" src={QuoteIcon} sx={{ width: '24px', height: '24px' }} />}
      {...props}
    >
      {t('Quote')}
    </TopActionBarButton>
  );

  const ReferEndorsementButton = (props?: TopActionBarButtonProps) => (
    <TopActionBarButton
      onClick={() => {
        setDialogOpen({
          dialog: isUserRoleUnderwriter ? 'REFER_ENDORSEMENT_UW' : 'REFER_ENDORSEMENT',
          isOpen: true,
          onAccept: () => handleReferEndorsement(),
        });
      }}
      startIcon={<Box component="img" src={QuoteIcon} sx={{ width: '24px', height: '24px' }} />}
      {...props}
    >
      {t('Refer')}
    </TopActionBarButton>
  );

  const mainActions = useMemo(() => {
    const extraStatus = endorsementDetail?.extra_status;
    const endorsementType = endorsementDetail?.endorsement_type?.code;

    if (isUserRoleUnderwriter) {
      switch (extraStatus) {
        case endorsementExtraStatuses.OK.code:
          return <>{LockEndorsementButton()}</>;

        case endorsementExtraStatuses.HAS_DECLINE.code:
          return (
            <>
              {LockEndorsementButton({
                disabled: true,
                tooltip: endorsementExtraStatuses.HAS_DECLINE.tooltip,
              })}
            </>
          );

        case endorsementExtraStatuses.HAS_REFER.code:
          if (endorsementStatus === policyDetailEndorsementStatuses.REFERRED) {
            return (
              <>
                {LockEndorsementButton({
                  disabled: true,
                  tooltip: endorsementExtraStatuses.HAS_REFER.tooltip,
                })}
              </>
            );
          } else {
            return <>{ReferEndorsementButton()}</>;
          }

        default:
          return <>{LockEndorsementButton()}</>;
      }
    } else if (isUserRoleProducer) {
      if (endorsementType === THREE_EndorsementTypes.ADDITIONAL_INTEREST) {
        return <>{LockEndorsementButton({})}</>;
      }
      if (endorsementStatus === policyDetailEndorsementStatuses.REFERRED) {
        return (
          <>
            {LockEndorsementButton({
              disabled: true,
              tooltip: endorsementExtraStatuses.HAS_REFER.tooltip,
            })}
          </>
        );
      }

      switch (extraStatus) {
        case endorsementExtraStatuses.OK.code:
          return <>{ReferEndorsementButton()}</>;

        case endorsementExtraStatuses.HAS_DECLINE.code:
          return (
            <>
              {LockEndorsementButton({
                disabled: true,
                tooltip: endorsementExtraStatuses.HAS_DECLINE.tooltip,
              })}
            </>
          );

        case endorsementExtraStatuses.HAS_REFER.code:
          return <>{ReferEndorsementButton()}</>;

        default:
          return <></>;
      }
    } else {
      return <></>;
    }
  }, [endorsementDetail, isUserRoleUnderwriter, isQuestionEnginePage, isCoverageSummaryPage, tab]);

  const handleIssue = async () => {
    try {
      setLoading(true);
      setDialogOpen({
        dialog: 'ISSUE_ENDORSEMENT',
        isOpen: false,
      });
      await issueEndorsement(policyId as string, endorsementId as string);
      await fetch(policyId, endorsementId);
      displayToastMessage('SUCCESS', t('Policy changes has been issued.'));
    } catch (error) {
      displayBackendErrorMessage(error, t('An error occurred while issuing the policy changes.'));
    } finally {
      setLoading(false);
    }
  };

  const handleDecline = async () => {
    setDialogOpen({
      dialog: 'DECLINE_ENDORSEMENT',
      isOpen: false,
    });

    if (isCoverageSummaryPage || isQuestionEnginePage) {
      emitter.emit('declineSubmissionEndorsement', 'declineQuestionEngineInputsValidated');
    } else {
      handleQuestionEngineInputsValidatedDecline(true);
    }
  };

  const handleSendBack = async () => {
    setDialogOpen({
      dialog: 'SEND_BACK_ENDORSEMENT',
      isOpen: false,
    });

    if (isCoverageSummaryPage || isQuestionEnginePage) {
      emitter.emit('sendBackSubmissionEndorsement', 'sendBackQuestionEngineInputsValidated');
    } else {
      handleQuestionEngineInputsValidatedSendBack(true);
    }
  };

  const handleDiscard = async () => {
    try {
      setLoading(true);
      setDialogOpen({
        dialog: 'DISCARD_ENDORSEMENT',
        isOpen: false,
      });
      setBypassRouteBlock(true);
      await discardEndorsement(policyId, endorsementId);
      displayToastMessage('SUCCESS', t('Policy changes has been discarded.'));
      HISTORY.push(`/policies/${policyId}`);
    } catch (error) {
      setBypassRouteBlock(false);
      displayBackendErrorMessage(
        error,
        t('An error occurred while discarding the policy changes.'),
      );
    } finally {
      setLoading(false);
    }
  };

  const handleNewDocument = () => {
    HISTORY.push({
      search: updateQueryStrings({
        locationSearch: LOCATION.search,
        newQueries: { addDocument: true },
      }),
    });
  };



  const handleCreateNote = () => {
    const isOnNotePage = LOCATION.pathname.includes('notes');
    if (!isOnNotePage) {
      HISTORY.push({
        pathname: `/policies/${policyId}/notes`,
      });
    }
    onNoteCreated?.();
  };

  const handleCreateDocument = () => {
    const isDocumentPage = LOCATION.pathname.includes('documents');
    if (!isDocumentPage) {
      HISTORY.push({
        pathname: `/policies/${policyId}/documents`,
      });
    }
    onDocumentCreated?.();
  };

  return (
    <>
 

      {/* Error parser */}
      <SocotraFieldErrorModalParser
        open={isPreRatingErrored}
        setOpen={setIsPreRatingErrored}
        errors={!isEmpty(preRatingErrors) ? preRatingErrors : {}}
        errorCode={preRatingErrorsCode}
        title={t('Failed to Process')}
      />
      {/* Error parser */}

      {isAddNoteDrawerOpen && (
        <AddNoteDrawer
          isDrawerOpen={isAddNoteDrawerOpen}
          associated={{
            reference_type: globalSearchTypes.POLICY,
            reference_locator: policyId,
            three_number: endorsementDetail?.policy?.three_policy_number ?? policyId,
          }}
          additionalInfo={endorsementDetail?.policy?.policyholder?.name}
          onCreated={handleCreateNote}
        />
      )}

      {isAddDocumentDrawerOpen && (
        <AddDocumentDrawer
          isDrawerOpen={isAddDocumentDrawerOpen}
          associated={{
            reference_type: globalSearchTypes.POLICY,
            reference_locator: policyId,
          }}
          onCreated={handleCreateDocument}
          documentTypes={documentTypes}
          fetchDocumentTypes={getEndorsementDocumentTypes}
        />
      )}

      <TopActionBar>
        {!endorsementLoaded &&
          [...Array(5).keys()].map((_, i) => (
            <Skeleton
              animation="wave"
              width="5%"
              height={28}
              sx={{ marginY: 1 / 2, marginX: 1, alignSelf: 'center' }}
              key={i}
            />
          ))}

        {endorsementLoaded && (
          <>
            {isMainActionButtonsVisible && <>{mainActions}</>}

            {isIssueButtonVisible && (
              <TopActionBarButton
                onClick={() => {
                  setDialogOpen({
                    dialog: 'ISSUE_ENDORSEMENT',
                    isOpen: true,
                    onAccept: () => handleIssue(),
                  });
                }}
                startIcon={
                  <Box component="img" src={QuoteIcon} sx={{ width: '24px', height: '24px' }} />
                }
              >
                {t('Issue')}
              </TopActionBarButton>
            )}

            {isUWDeclineButtonVisible && (
              <TopActionBarButton
                onClick={() => {
                  setDialogOpen({
                    dialog: 'DECLINE_ENDORSEMENT',
                    isOpen: true,
                    onAccept: () => handleDecline(),
                  });
                }}
                startIcon={
                  <Box
                    sx={{
                      '& .decline-icon svg': {
                        stroke: (theme) => theme.customColors.iconGray,
                        width: '24px',
                        height: '24px',
                      },
                    }}
                  >
                    <ReactSVG src={DeclineIcon} className="decline-icon" />
                  </Box>
                }
              >
                {t('Decline')}
              </TopActionBarButton>
            )}

            {isSendBackButtonVisible && (
              <TopActionBarButton
                onClick={() => {
                  setDialogOpen({
                    dialog: 'SEND_BACK_ENDORSEMENT',
                    isOpen: true,
                    onAccept: () => handleSendBack(),
                  });
                }}
                startIcon={
                  <Box component="img" src={SendBack} sx={{ width: '24px', height: '24px' }} />
                }
              >
                {t('Send Back')}
              </TopActionBarButton>
            )}

            {isDiscardButtonVisible && (
              <TopActionBarButton
                onClick={() => {
                  setDialogOpen({
                    dialog: 'DISCARD_ENDORSEMENT',
                    isOpen: true,
                    onAccept: () => handleDiscard(),
                  });
                }}
                startIcon={
                  <Box component="img" src={DiscardIcon} sx={{ width: '24px', height: '24px' }} />
                }
              >
                {t('Discard')}
              </TopActionBarButton>
            )}

            <TopActionBarButton
              startIcon={
                <Box component="img" src={AddDocumnet} sx={{ width: '24px', height: '24px' }} />
              }
              onClick={handleNewDocument}
            >
              {t('Attach Document')}
            </TopActionBarButton>
            {showAddNoteButton() && (
              <TopActionBarButton
                startIcon={
                  <Box component="img" src={AddNote} sx={{ width: '24px', height: '24px' }} />
                }
                onClick={handleNewNote}
              >
                {t('Add Note')}
              </TopActionBarButton>
            )}

            <TopActionBarButton
              onClick={() => HISTORY.push(`/policies/${policyId}`)}
              startIcon={
                <Box component="img" src={Cancel} sx={{ width: '24px', height: '24px' }} />
              }
            >
              {t('Close')}
            </TopActionBarButton>
          </>
        )}
      </TopActionBar>
    </>
  );
};

export default PolicyEndorsementDetailTopActionBar;
