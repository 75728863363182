/* eslint-disable dot-notation */
/* eslint-disable guard-for-in */
/* eslint-disable no-undef */
/* eslint-disable no-restricted-syntax */
/* eslint-disable no-shadow */
/* eslint-disable default-case */
import { KeyboardArrowDownRounded } from '@mui/icons-material';
import {
  Autocomplete,
  AutocompleteChangeReason,
  Box,
  CircularProgress,
  ClickAwayListener,
  FormControl,
  InputAdornment,
  Skeleton,
  Stack,
  SxProps,
  TextField,
} from '@mui/material';
import { Theme } from '@mui/system';
import { DynamicField } from 'api/models/DynamicFields/dynamicField.model';
import { SmartyAutoCompleteResponse } from 'api/models/Smarty/SmartyAutoCompleteResponse.model';
import { getSmartyAddress } from 'api/services/SmartyAddress';
import { defaultDebounceDelay } from 'common/constants';
import {
  autocompleteArrowStyle,
  autocompleteTextFieldStyle2,
  readOnlySelectInputStyle,
} from 'helpers/MuiSharedStyles';
import { preventCharactersOnInputChange } from 'helpers/Utils';
import { debounce, isEmpty, isEqual, isNaN, omit } from 'lodash-es';
import { IContext } from 'providers/types';
import React, { SyntheticEvent, useCallback, useEffect, useState } from 'react';
import { SmartAddressTypes } from 'types/SmartyAddressTypes';

export interface IField {
  code: string;
  type: string;
  name: string;
  width: number;
  is_optional: boolean;
  heading?: string;
  additional_data: { relatedFields: { smartyFieldName: string; configFieldName: string }[] };
}

const addressLine = {
  code: 'address',
  type: 'smartyaddress_autocomplete',
  name: 'Property Address',
  width: 1,
  is_optional: false,
  heading: '',
  additional_data: {
    relatedFields: [
      {
        smartyFieldName: SmartAddressTypes.STREET_LINE,
        configFieldName: 'address_line_1',
      },
      {
        smartyFieldName: SmartAddressTypes.SECONDARY,
        configFieldName: 'address_line_2',
      },
      { smartyFieldName: SmartAddressTypes.CITY, configFieldName: 'city' },
      {
        smartyFieldName: SmartAddressTypes.STATE,
        configFieldName: 'state',
      },
      {
        smartyFieldName: SmartAddressTypes.ZIPCODE,
        configFieldName: 'zip_code',
      },
      { smartyFieldName: SmartAddressTypes.COUNTRY, configFieldName: 'country' },
    ],
  },
};

const initialSmartyAddressAutocompleteData = {
  data: [] as SmartyAutoCompleteResponse[],
  loading: false,
  loaded: false,
};

interface IProps {
  addressState?: any;
  setAddressState?: any;
  field?: IField;
  formik: any;
  singleFieldAddress?: boolean;
  showLabel?: boolean;
  oneField?: string;
  label?: string;
  error?: any;
  helperText?: any;
  sx?: SxProps<Theme>;
  makeInitialSearch?: boolean;
  isReadOnly?: boolean;
  onSelect?: <T>(newState: T) => void;
  omitFieldsOnInputChange?: string[];
  includedStates?: string[];
  preferStates?: string[];
  preferRatio?: number;
  removeCommasOnInputChange?: string[];
}

const SmartyAutocompleteInput: React.FC<IProps> = ({
  addressState,
  setAddressState,
  field = addressLine,
  formik,
  oneField,
  singleFieldAddress = false,
  showLabel = false,
  label = addressLine.name,
  error,
  helperText,
  sx = [],
  makeInitialSearch = true,
  isReadOnly = false,
  onSelect,
  omitFieldsOnInputChange,
  includedStates,
  preferStates,
  preferRatio,
  removeCommasOnInputChange,
}) => {
  const [loading, setLoading] = useState(makeInitialSearch);
  const [isSelectOpen, setIsSelectOpen] = useState(false);
  const [state, setState] = useState<IContext<SmartyAutoCompleteResponse[]>>(
    initialSmartyAddressAutocompleteData,
  );
  const [inputValue, setInputValue] = useState('');

  const addressFieldName = React.useMemo(
    () =>
      field.additional_data?.relatedFields?.find(
        (f) => f.smartyFieldName === SmartAddressTypes.STREET_LINE,
      )?.configFieldName ?? 'address_line_1',
    [field],
  );

  const getAddressSuggestion = async (search?: string, selected: string = '') => {
    try {
      setState((prevState) => ({
        ...prevState,
        loading: true,
      }));

      const res = await getSmartyAddress({
        search,
        selected,
        source: 'all',
        max_results: 10,
        ...(!isEmpty(includedStates) ? { state_filter: includedStates } : {}),
        ...(!isEmpty(preferStates) ? { prefer_states: preferStates } : {}),
        ...(!isNaN(preferRatio) ? { prefer_ratio: preferRatio } : {}),
      });

      setState((prevState) => ({
        ...prevState,
        loading: false,
        loaded: true,
        data: res,
      }));

      return res;
    } catch (e) {
      setState({ ...state, loading: false, loaded: true });
      throw e;
    }
  };

  const relatedAddressField = field?.additional_data?.relatedFields?.find(
    (item) => item.smartyFieldName === SmartAddressTypes.STREET_LINE,
  )?.configFieldName;

  const relatedSecondaryAddressField = field?.additional_data?.relatedFields?.find(
    (item) => item.smartyFieldName === SmartAddressTypes.SECONDARY,
  )?.configFieldName;

  const relatedStateField = field?.additional_data?.relatedFields?.find(
    (item) => item.smartyFieldName === SmartAddressTypes.STATE,
  )?.configFieldName;

  const relatedZipCodeField = field?.additional_data?.relatedFields?.find(
    (item) => item.smartyFieldName === SmartAddressTypes.ZIPCODE,
  )?.configFieldName;

  const relatedCityField = field?.additional_data?.relatedFields?.find(
    (item) => item.smartyFieldName === SmartAddressTypes.CITY,
  )?.configFieldName;

  const setPlaceDetails = (place: any) => {
    const addressObj: { [key: string]: string } = {};

    // eslint-disable-next-line prefer-const
    for (let key in place) {
      switch (key) {
        case SmartAddressTypes.STREET_LINE: {
          if (relatedAddressField) {
            addressObj[relatedAddressField] = `${place.street_line}`;
          }
          break;
        }

        case SmartAddressTypes.SECONDARY: {
          if (relatedSecondaryAddressField) {
            addressObj[relatedSecondaryAddressField] = `${place.secondary}`;
          }
          break;
        }

        case SmartAddressTypes.CITY: {
          if (relatedCityField) {
            addressObj[relatedCityField] = `${place.city}`;
          }
          break;
        }

        case SmartAddressTypes.STATE: {
          if (relatedStateField) {
            addressObj[relatedStateField] = `${place.state}`;
          }
          break;
        }

        case SmartAddressTypes.ZIPCODE: {
          if (relatedZipCodeField) {
            addressObj[relatedZipCodeField] = `${place.zipcode}`;
          }
          break;
        }
      }
    }

    addressObj['country'] = 'US';

    let newState = {};

    if (!oneField) {
      newState = {
        ...addressState,
        ...addressObj,
        address: `${addressObj[relatedAddressField!]} ${
          addressObj[relatedSecondaryAddressField!]
        } ${addressObj[relatedCityField!]}, ${addressObj[relatedStateField!]} ${
          addressObj[relatedZipCodeField!]
        }`,
        entries: place.entries,
      };
      setAddressState(newState);
      Object.entries(addressObj).forEach(([key, value]) => formik.setFieldValue([key], value));
      formik.setFieldValue(
        [field?.code],
        `${addressObj[relatedAddressField!]} ${addressObj[relatedSecondaryAddressField!]} ${
          addressObj[relatedCityField!]
        }, ${addressObj[relatedStateField!]} ${addressObj[relatedZipCodeField!]}`,
      );
      formik.setFieldValue(
        'address',
        `${addressObj[relatedAddressField!]} ${addressObj[relatedSecondaryAddressField!]} ${
          addressObj[relatedCityField!]
        }, ${addressObj[relatedStateField!]} ${addressObj[relatedZipCodeField!]}`,
      );
    } else {
      newState = {
        ...addressState,
        [`${oneField}`]: `${addressObj[relatedAddressField!]} ${
          addressObj[relatedSecondaryAddressField!]
        } ${addressObj[relatedCityField!]}, ${addressObj[relatedStateField!]} ${
          addressObj[relatedZipCodeField!]
        }`,
      };
      setAddressState();
      formik.setFieldValue(
        [`${oneField}`],
        `${addressObj[relatedAddressField!]} ${addressObj[relatedSecondaryAddressField!]} ${
          addressObj[relatedCityField!]
        }, ${addressObj[relatedStateField!]} ${addressObj[relatedZipCodeField!]}`,
      );
    }

    onSelect?.(newState);
  };

  const fetchAddressSuggestions = async (newVal, selected = '') => {
    await getAddressSuggestion(newVal, selected);
  };

  const handleClickAway = () => setIsSelectOpen(false);

  useEffect(() => {
    if (addressState?.[`${field.code}`] === '') {
      setState({ ...initialSmartyAddressAutocompleteData });
    }
  }, [addressState, field]);

  useEffect(() => {
    async function func() {
      if (makeInitialSearch) {
        try {
          if (oneField && addressState[`${oneField}`]) {
            if (addressState[`${oneField}`]) {
              const res = await getAddressSuggestion(`${addressState[`${oneField}`]}`);

              setPlaceDetails(res?.[0]);
              setLoading(false);
            } else {
              setLoading(false);
            }
          }
          if (
            (addressState?.[`${addressFieldName}`] || addressState?.address_line_2) &&
            addressState?.city &&
            addressState?.state &&
            addressState?.zip_code
          ) {
            // const res = await getAddressSuggestion(
            //   `${addressState?.address_line_1} ${addressState?.address_line_2 || ''} ${
            //     addressState?.city
            //   }, ${addressState?.state} ${addressState?.zip_code}`,
            // );
            const res = await getAddressSuggestion(`${addressState?.[`${addressFieldName}`]}`);

            setPlaceDetails(res?.[0]);
            setLoading(false);
          } else {
            setLoading(false);
          }
        } catch (_error) {
          setLoading(false);
        }
      }
    }

    func();
  }, []);

  const handleDebounce = useCallback(
    debounce((event, newInputValue) => {
      if (event?.type === 'change') {
        setIsSelectOpen(true);
        setAddressState((prevState) => {
          const newAddressState = omitFieldsOnInputChange
            ? omit(prevState, omitFieldsOnInputChange)
            : prevState;

          return { ...newAddressState, [field.code]: newInputValue };
        });

        if (newInputValue.trim().length >= 1) {
          fetchAddressSuggestions(newInputValue);
        }
      }
    }, defaultDebounceDelay),
    [],
  );

  const handleInputChange = (event: SyntheticEvent, newInputValue: string, field: DynamicField) => {
    let inputValue = newInputValue;

    inputValue = preventCharactersOnInputChange({
      inputValue,
      field,
      validationRelatedFieldNames: [
        {
          fields: removeCommasOnInputChange,
          inputCharactersAllowed: ['nonComma'],
        },
      ],
    });

    setInputValue(inputValue);
    handleDebounce(event, inputValue);
  };

  return loading ? (
    <Stack
      gap={3}
      sx={{
        width: 1,
        flexDirection: {
          xs: 'column',
          sm: 'row',
        },
      }}
    >
      <FormControl variant="standard" sx={{ flex: 1 }}>
        <Skeleton animation="wave" width="100%" height={37} />
      </FormControl>
    </Stack>
  ) : (
    <ClickAwayListener onClickAway={handleClickAway}>
      <Stack sx={[...(Array.isArray(sx) ? sx : [sx])]}>
        <Stack>
          <Autocomplete
            freeSolo
            data-test={field.code}
            disablePortal
            sx={isReadOnly ? readOnlySelectInputStyle : autocompleteArrowStyle}
            onBlur={handleClickAway}
            onFocus={() => setIsSelectOpen(true)}
            readOnly={isReadOnly}
            disableClearable
            size="small"
            id={field?.code}
            open={isSelectOpen}
            disableCloseOnSelect
            defaultValue={
              !oneField
                ? state?.data?.find(
                    (suggestion: any) =>
                      suggestion.street_line === addressState?.[`${addressFieldName}`],
                  ) ?? addressState?.[`${addressFieldName}`]
                : state?.data?.find((suggestion: any) =>
                    addressState[`${oneField}`].includes(suggestion.street_line),
                  )
            }
            value={addressState[field.code] ?? null}
            popupIcon={<KeyboardArrowDownRounded />}
            getOptionLabel={(option: any) => {
              return typeof option !== 'string'
                ? addressState[`${oneField}`] === ''
                  ? ''
                  : addressState?.[`${addressFieldName}`] && option.entries > 1
                  ? `${option?.street_line}`
                  : singleFieldAddress
                  ? `${option?.street_line} ${option?.secondary} ${option?.city}, ${option?.state} ${option?.zipcode}`
                  : `${option?.street_line}`
                : option;
            }}
            filterOptions={(x) => x}
            options={state?.data?.length! >= 1 && !state.loading ? state.data ?? [] : []}
            loading={state.loading}
            autoComplete
            isOptionEqualToValue={(option, value) => {
              return typeof option !== 'string' ? isEqual(option, value) : option === value;
            }}
            onChange={(
              event: React.ChangeEvent<{}>,
              newValue: any,
              reason: AutocompleteChangeReason,
            ) => {
              if (reason === 'selectOption') {
                event.preventDefault();
                if (newValue.entries > 1) {
                  fetchAddressSuggestions(
                    `${newValue?.street_line} ${newValue?.secondary}`,
                    `${newValue?.street_line} ${newValue?.secondary} (${newValue?.entries}) ${newValue?.city}, ${newValue?.state} ${newValue?.zipcode}`,
                  );
                  setIsSelectOpen(true);
                } else {
                  setIsSelectOpen(false);
                  setPlaceDetails(newValue);
                }
              }
            }}
            inputValue={inputValue}
            onInputChange={(event, inputValue) => handleInputChange(event, inputValue, field)}
            renderInput={(params) => {
              const newParams = {
                ...params,
                inputProps: { ...params.inputProps, autoComplete: 'nope' },
              };
              return (
                <TextField
                  sx={{
                    autocompleteTextFieldStyle2,
                  }}
                  {...newParams}
                  error={
                    error || (formik.touched[field.code] && Boolean(formik.errors[field.code]))
                  }
                  helperText={
                    helperText || (formik.touched[field.code] && formik.errors[field.code])
                  }
                  required={showLabel && !field.is_optional}
                  id={field.code}
                  name={field.name}
                  label={showLabel ? (label !== field.name ? label : field.name) : ''}
                  InputLabelProps={isReadOnly ? { shrink: true } : {}}
                  InputProps={{
                    ...params.InputProps,
                    readOnly: isReadOnly,
                    endAdornment: state.loading ? (
                      <InputAdornment position="end" sx={{ mr: 0.5 }}>
                        <CircularProgress color="inherit" size={20} />
                      </InputAdornment>
                    ) : isReadOnly ? (
                      <></>
                    ) : state?.data?.length! >= 1 ? (
                      <Box
                        sx={{
                          mt: '-28px',
                        }}
                        onClick={(e) => {
                          e.preventDefault();
                          e.stopPropagation();
                          setIsSelectOpen(!isSelectOpen);
                        }}
                      >
                        {params.InputProps.endAdornment}
                      </Box>
                    ) : (
                      <></>
                    ),
                  }}
                />
              );
            }}
            renderOption={(props, option: any) => {
              return (
                <li {...props} key={props.id}>
                  {`${option.street_line} ${option.secondary} ${
                    option?.entries < 2 ? '' : `(${option.entries} more entries)`
                  } ${option.city}, ${option.state} ${option.zipcode}`}
                </li>
              );
            }}
          />
        </Stack>
      </Stack>
    </ClickAwayListener>
  );
};

export default SmartyAutocompleteInput;
