import ApiService, { Params } from 'api/helpers/Sender';
import { Task } from 'api/models/Tasks/task.model';
import { TaskAssociate } from 'api/models/Tasks/taskAssociate.model';
import { TaskCreateUpdate } from 'api/models/Tasks/taskCreateUpdate.model';
import { UserResponse } from 'api/models/Users/userList.model';
import { ITaskAssignUsersPagination } from 'providers/TaskAssignUsersProvider/types';
import { ITaskPagination } from 'providers/TasksProvider/types';
import { Pagination, PaginationResponse } from 'types/Pagination';

const API = new ApiService();

export const getAllTasks = async (query: ITaskPagination): Promise<PaginationResponse<Task[]>> => {
  const { data } = await API.get(`tasks`, query as Params);

  return data;
};

export const getCurrentUserTasks = async (
  query: ITaskPagination,
): Promise<PaginationResponse<Task[]>> => {
  const { data } = await API.get(`tasks/me`, query as Params);

  return data;
};

export const getTask = async (taskId: string): Promise<Task> => {
  const { data } = await API.get(`tasks/${taskId}`);

  return data;
};

export const getTaskAssociatesList = async (query: Pagination): Promise<TaskAssociate[]> => {
  const { data } = await API.get(`reference-search`, query as Params);

  return data;
};

export const getAssignUsers = async (
  query: ITaskAssignUsersPagination,
): Promise<PaginationResponse<UserResponse[]>> => {
  const { data } = await API.get(`users`, query as unknown as Params);

  return data;
};


export const deleteTaskService = async (taskId: number): Promise<void> => {
  const { data } = await API.delete(`tasks/${taskId}`);

  return data;
};

export const updateTaskService = async (
  taskId: number | string,
  task: TaskCreateUpdate,
): Promise<Task> => {
  const { data } = await API.patch(`tasks/${taskId}`, task);

  return data;
};
