import { createContext } from 'react';

/// types
import { ITasksContextType, ITasksStore } from './types';

export const initialTasksData: ITasksStore = {
  data: [],
  loading: true,
  loaded: false,
  selectedTaskRow: undefined,
  activeTask: {
    data: {},
    loading: true,
    loaded: false,
  },
  pagination: {
    ordering: '',
    page: 1,
    page_size: 20,
    search: '',
    total_count: 0,
  },
};

export const tasksContextDefault: ITasksContextType = {
  ...initialTasksData,
  resetTasksState: () => {},
  updateSelectedTaskRow: () => {},
  fetch: () => new Promise(() => {}),
  fetchTask: () => new Promise(() => {}),
  deleteTask: () => new Promise(() => {}),
  reFetchData: () => new Promise(() => {}),
  updateTask: () => new Promise(() => {}),
  closeTask: () => new Promise(() => {}),
  addToQueue: () => new Promise(() => {}),
};

export const TasksContext = createContext<ITasksContextType>(null!);
