import {
  DatePicker,
  DatePickerProps,
  DateValidationError,
  LocalizationProvider,
  PickerChangeHandlerContext,
} from '@mui/x-date-pickers';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { defaultTimezone } from 'common/constants';
import { utcToZonedTime } from 'date-fns-tz';
import { isDateOnlyString } from 'helpers/Utils';
import useConfig from 'hooks/useConfig';
import React, { FC } from 'react';

export type DefaultDatePickerValue = Date | '';

interface IProps extends DatePickerProps<any, any> {
  // CustomInput?: any;
}

const isDateValid = (date: Date) => date && !Number.isNaN(date.getTime());

const DatepickerComponent: FC<IProps> = ({ ...rest }) => {
  const { value, onChange, shouldDisableDate } = rest;
  const { convertZonedTimeToUtc, timezoneConfig } = useConfig();

  const convertDate = (date: Date, context: PickerChangeHandlerContext<DateValidationError>) => {
    if (!onChange) {
      return;
    }
    if (isDateValid(date)) {
      const utcEffectiveDate = convertZonedTimeToUtc(date);
      onChange(utcEffectiveDate, context);
    } else {
      onChange(date, context);
    }
  };

  const convertShouldDisableDate = (date: Date): boolean => {
    if (shouldDisableDate) {
      if (isDateValid(date)) {
        const utcEffectiveDate = convertZonedTimeToUtc(date);
        return shouldDisableDate(utcEffectiveDate);
      } else {
        return shouldDisableDate('');
      }
    } else {
      return false;
    }
  };

  const createDateFromDateString = (date: string) => {
    const dateArr = date.split('-');
    const newDate = new Date(
      parseInt(dateArr[0], 10),
      parseInt(dateArr[1], 10) - 1,
      parseInt(dateArr[2], 10),
    );

    let utcEffectiveDate;
    if (isDateValid(newDate)) {
      utcEffectiveDate = convertZonedTimeToUtc(newDate);
    }
    return utcEffectiveDate;
  };

  const convertedDateFormat = (date: DefaultDatePickerValue) => {
    // date-only strings (aka policyholder date fields, ex: 1980-02-02), are considered to be in UTC time and not local
    // see 'Timestamp String' in Date docs: https://developer.mozilla.org/en-US/docs/Web/JavaScript/Reference/Global_Objects/Date/Date
    const newDate = date
      ? isDateOnlyString(date.toString())
        ? createDateFromDateString(date.toString())
        : new Date(date)
      : date;

    if (isDateValid(newDate)) {
      const zonedEffectiveDate = utcToZonedTime(
        newDate,
        timezoneConfig?.data?.code ?? defaultTimezone,
      );
      return zonedEffectiveDate;
    }

    return date;
  };

  return (
    <LocalizationProvider dateAdapter={AdapterDateFns}>
      <DatePicker
        {...rest}
        value={convertedDateFormat(value)}
        shouldDisableDate={convertShouldDisableDate}
        onChange={convertDate}
        slotProps={{
          textField: {
            ...rest?.slotProps?.textField,
            value: convertedDateFormat(value),
          },
        }}
        minDate={
          rest.minDate
            ? utcToZonedTime(rest.minDate, timezoneConfig?.data?.code ?? defaultTimezone)
            : undefined
        }
        maxDate={
          rest.maxDate
            ? utcToZonedTime(rest.maxDate, timezoneConfig?.data?.code ?? defaultTimezone)
            : undefined
        }
      />
    </LocalizationProvider>
  );
};

export default DatepickerComponent;
