import {
  Box,
  Button,
  ButtonProps,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Typography,
} from '@mui/material';

import ErrorOutline from '@mui/icons-material/ErrorOutline';
import { drawerFooterPrimaryButtonStyle, secondaryButtonStyle } from 'helpers/MuiSharedStyles';
import React, { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';

interface ICloneQuoteDialogProps {
  isOpen: boolean;
  setIsOpen: (isOpen: boolean) => void;
  setLocator: (url: string) => void;
  locator: string;
}
const CloneQuoteDialog: FC<ICloneQuoteDialogProps> = ({
  isOpen,
  setIsOpen,
  setLocator,
  locator,
}) => {
  const { t } = useTranslation();
  const HISTORY = useHistory();
  const handleClose = () => {
    setIsOpen(false);
    HISTORY.push(`/quotes/${locator}/details`);
  };

  const handleAccept = async () => {
    setIsOpen(false);
    window.open(`${window.location.origin}/quotes/${locator}/details`, '_blank');
    setLocator('');
  };
  return (
    <Dialog
      open={isOpen}
      onClose={handleClose}
      aria-labelledby="dialog-title"
      aria-describedby="dialog-description"
      sx={{ borderRadius: 0 }}
    >
      <Box
        sx={{
          m: 0,
          p: 1,
          maxWidth: '480px',
        }}
      >
        <Box
          sx={{
            m: 0,
            padding: (theme) => theme.spacing(2.5, 2),
          }}
        >
          <DialogTitle
            id="dialog-title"
            sx={{ p: 0, m: 0, mb: 1.5, display: 'flex', alignItems: 'center' }}
          >
            <ErrorOutline
              sx={{
                color: (theme) => theme.customColors.info,
                fontSize: '20px',
                mr: 1,
              }}
            />

            <Typography
              sx={{
                fontSize: 16,
                fontWeight: 500,
                color: (theme) => theme.customColors.black,
              }}
            >
              {t('Please choose an option to continue')}
            </Typography>
          </DialogTitle>

          <DialogContent sx={{ p: 0, m: 0 }}>
            <DialogContentText
              sx={{
                fontSize: 14,
                fontWeight: 400,
                color: (theme) => theme.customColors.black,
                whiteSpace: 'pre-line',
              }}
              id="dialog-description"
            >
              {t('Where do you want to use the cloned quote?')}
            </DialogContentText>
          </DialogContent>
        </Box>

        <DialogActions sx={{ padding: (theme) => theme.spacing(1, 1), m: 0 }}>
          <Button
            autoFocus
            onClick={handleClose}
            color={'dialogCancel' as ButtonProps['color']}
            sx={{
              fontSize: 14,
              fontWeight: 500,
              ...secondaryButtonStyle,
            }}
          >
            {t('Stay on the current tab')}
          </Button>

          <Button
            autoFocus
            onClick={handleAccept}
            color={'dialogAlert' as ButtonProps['color']}
            sx={{
              fontSize: 14,
              fontWeight: 500,
              ml: '0 !important',
              ...drawerFooterPrimaryButtonStyle,
            }}
          >
            {t('Open in a new tab')}
          </Button>
        </DialogActions>
      </Box>
    </Dialog>
  );
};

export default CloneQuoteDialog;
