import { getB505Score, getC212Score } from 'api/services/Integrations/LexisNexis';
import { defaultDateFormat, defaultTimezone, LEXIS_NEXIS_SCORE_TYPES } from 'common/constants';
import { formatInTimeZone } from 'date-fns-tz';
import displayToastMessage from 'helpers/DisplayToastMessage';
import { t } from 'i18next';
import { IAxiosError } from 'types/ErrorResponseTypes';

/**
 * Both B505 and NCF gets same request payload,
 * this helper is for shaping the owner data to fit the payload requirements
 */
export const prepareOwnerForLexisNexisRequest = (ownwer) => {
  const {
    pol_oo_name_first: First,
    pol_oo_name_last: Last,
    pol_oo_dob,
    pol_oo_address_line1: StreetAddress1,
    pol_oo_address_city: City,
    pol_oo_address_state: State,
    pol_oo_address_zip: Zip5,
  } = ownwer;

  const DOB = {
    Year: '',
    Month: '',
    Day: '',
  };

  // TODO: add state.timezoneConfig?.data?.code ?? defaultTimezone to second parameter
  const formatted = formatInTimeZone(new Date(pol_oo_dob), defaultTimezone, defaultDateFormat);
  if (formatted !== '-') {
    const [Month, Day, Year] = formatted.split('/');
    DOB.Month = Month;
    DOB.Day = Day;
    DOB.Year = Year;
  }

  return {
    Name: {
      First,
      Last,
    },
    DOB,
    CurrentAddress: {
      StreetAddress1,
      City,
      State,
      Zip5,
    },
  };
};

export const fetchLexisNexisScore = async (
  scoreType: string,
  state: any,
  isUnderwriter: boolean,
  setLoading: (isLoading: boolean) => void,
) => {
  const { pol_oo_isprimary } = state;
  let score = { success: false, score: undefined, errored: false };

  if (pol_oo_isprimary === 'Yes') {
    try {
      const response = await (scoreType === LEXIS_NEXIS_SCORE_TYPES.B505
        ? getB505Score
        : getC212Score)(prepareOwnerForLexisNexisRequest(state));
      score = { success: true, score: response[scoreType], errored: false };
    } catch (error) {
      if (isUnderwriter) {
        const e = error as IAxiosError;
        displayToastMessage(
          'ERROR',
          e.response?.data?.message ?? t('Service is not currently available.'),
        );
        score = { ...score, errored: true };
        setLoading(false);
      }
    }
  }

  return score;
};
