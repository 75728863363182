import { Box, FormHelperText, Typography, useTheme } from '@mui/material';
import convertFileSizeToBytes from 'api/helpers/convertFileSizeToBytes';
import { isEmpty } from 'lodash-es';
import React, { CSSProperties, Dispatch, FC, ReactNode, useMemo, useState } from 'react';
import { FileWithPath, useDropzone } from 'react-dropzone';
import { useTranslation } from 'react-i18next';
import CircularProgressWithLabel from '../CircularProgressWithLabel';

interface FileUploaderComponentProps {
  files: Array<{
    id: number;
    file: { name: string; size: number; status: 'uploading' | 'uploaded' | 'failed' | 'ready' };
  }>;
  setFiles: Dispatch<any>;
  progress: number;
  upload: boolean;
  setUpload: Dispatch<any>;
}

const DocumentFileUploader: FC<FileUploaderComponentProps> = ({ setFiles, progress, upload }) => {
  const { t } = useTranslation();

  const [errors, setErrors] = useState('');
  const themeHook = useTheme();

  const onDrop = (acceptedFiles) => {
    setFiles(acceptedFiles[0]);
    setErrors('');
  };

  const onError = (error) => {
    setErrors(error?.[0].errors?.[0]?.message);
  };

  const {
    acceptedFiles,
    getRootProps,
    getInputProps,
    isFocused,
    isDragAccept,
    isDragActive,
    fileRejections,
  } = useDropzone({
    accept: [
      '.pdf',
      '.html',
      '.jpg',
      '.jpeg',
      '.rar',
      '.zip',
      '.png',
      '.doc',
      '.docx',
      '.csv',
      '.xlsx',
      '.xls',
    ],
    onDrop,
    maxSize: 50000000,
    maxFiles: 1,
    onDropRejected: onError,
  });

  // eslint-disable-next-line no-shadow
  const fileList = (files: FileWithPath[]): ReactNode =>
    files.map((file) => (
      <Box
        sx={{
          fontSize: 14,
          color: (theme) => theme.customColors.black,
          fontWeight: 400,
        }}
        key={file.path}
      >
        <Typography>{file.path}</Typography>
        {!upload && <Typography>{convertFileSizeToBytes(file.size)}</Typography>}
      </Box>
    ));

  const focusedStyle = {
    borderColor: themeHook.customColors.activeField,
  };

  const activeStyle = {
    borderColor: themeHook.customColors.activeField,
  };

  const acceptStyle = {
    borderColor: themeHook.customColors.green250,
  };

  const rejectStyle = {
    borderColor: themeHook.customColors.red150,
  };

  const style = useMemo(
    () => ({
      ...({
        flex: 1,
        display: 'flex',
        width: '100%',
        maxWidth: '380px',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
        textAlign: 'center',
        padding: themeHook.spacing(1),
        height: '200px',
        borderWidth: 2,
        borderRadius: 2,
        borderColor: themeHook.customColors.grey100,
        borderStyle: 'dashed',
        backgroundColor: themeHook.customColors.white50,
        color: themeHook.customColors.black1050,
        outline: 'none',
        transition: 'border .24s ease-in-out',
        '& .dropzone': {
          backgroundColor: 'red !important',
        },
      } as CSSProperties),
      ...(isFocused ? focusedStyle : {}),
      ...(isDragActive ? activeStyle : {}),
      ...(isDragAccept ? acceptStyle : {}),
      ...(!isEmpty(fileRejections) ? rejectStyle : {}),
    }),
    [isFocused, isDragAccept, fileRejections, isDragActive],
  );

  return (
    <>
      {progress <= 100 && (
        <section className="container" style={{ marginBottom: themeHook.spacing(2) }}>
          <Box
            sx={{
              '&:hover': {
                backgroundColor: '#f5f5f5 !important',
                cursor: 'pointer',
              },
            }}
            {...getRootProps({ className: 'dropzone', style })}
          >
            <input {...getInputProps()} />
            {upload && (
              <>
                <CircularProgressWithLabel value={progress} />
                <Typography
                  sx={{
                    color: themeHook.customColors.blue50,
                    mt: themeHook.spacing(1),
                    mb: themeHook.spacing(2),
                  }}
                >
                  {t('Uploading...')}
                </Typography>
              </>
            )}
            {acceptedFiles.length === 0 && !upload && (
              <>
                <Box
                  sx={{
                    fontSize: 14,
                    color: (theme) => theme.customColors.black,
                    fontWeight: 500,
                  }}
                >
                  <Typography sx={{}}>
                    {t('Drag')} & {t('drop here')}
                  </Typography>
                  {t('or')}{' '}
                  <Typography sx={{ color: (theme) => theme.customColors.navigation }}>
                    {t('Browse files')}
                  </Typography>
                </Box>
                <Box sx={{ marginTop: 2, color: (theme) => theme.customColors.black }}>
                  <Typography
                    sx={{
                      fontSize: 12,
                      fontWeight: 300,
                      letterSpacing: 0.2,
                    }}
                  >
                    {t('Maximum  file size is 50MB')}
                  </Typography>
                  <Typography
                    sx={{
                      fontSize: 12,
                      fontWeight: 300,
                      letterSpacing: 0.2,
                    }}
                  >
                    {t(
                      'Accepted file types are pdf, html, jpg, jpeg, png, doc, docx, csv, rar, zip, xls and xlsx.',
                    )}
                  </Typography>
                </Box>
              </>
            )}
            {fileList(acceptedFiles)}
          </Box>
        </section>
      )}
      {errors && (
        <FormHelperText
          sx={{
            mt: 1,
            fontSize: '12px',
            lineHeight: '14px',
            color: (theme) => theme.customColors.alert,
          }}
        >
          {errors}
        </FormHelperText>
      )}

      {/* {progress === 100 && (
        <Box
          component="div"
          sx={{
            display: 'flex',
            lineHeight: '26px',
          }}
        >
          <FilePresentIcon
            sx={{
              fontSize: '24px',
              height: '24px',
              marginBottom: themeHook.spacing(1),
              color: (theme) => theme.customColors.blue300,
            }}
          />
          {fileList(acceptedFiles)}
        </Box>
      )} */}
    </>
  );
};

export default DocumentFileUploader;
