import CloseIcon from '@mui/icons-material/Close';
import { Autocomplete, Chip, FormControl, TextField } from '@mui/material';
import { DynamicField } from 'api/models/DynamicFields/dynamicField.model';
import { getAgencies, getSubAgencies } from 'api/services/Agencies';
import { getAgents } from 'api/services/Agents';
import { getPolicyholders } from 'api/services/Policyholders';
import Arrow from 'assets/images/Arrow.svg';
import { defaultDebounceDelay, stateList } from 'common/constants';
import displayBackendErrorMessage from 'helpers/displayBackendErrorMessage';
import { autocompleteArrowStyle, autocompleteTextFieldStyle2 } from 'helpers/MuiSharedStyles';
import useAdvancedSearch from 'hooks/useAdvancedSearch';
import { t } from 'i18next';
import { debounce, take } from 'lodash-es';
import React, { FC, useCallback, useEffect, useState } from 'react';
import { ReactSVG } from 'react-svg';

interface Option {
  code: string;
  name: string;
}

interface MultiselectProps {
  field: DynamicField;
  formik: any;
  defaultOptions: Option[];
  state: any;
  setState: any;
}

const MultiSelectChips: FC<MultiselectProps> = ({
  defaultOptions,
  field,
  state,
  setState,
  formik,
}) => {
  const { activeTabType } = useAdvancedSearch();

  const [options, setOptions] = useState(defaultOptions);
  const [loading, setLoading] = useState(false);
  const [inputText, setInputText] = useState('');

  const handleFieldCode = (fieldCode: string) => {
    switch (fieldCode) {
      case 'policyholder__title':
        return getPolicyholders;
      case 'info_agent_name':
        return getAgents;
      case 'info_agency_name':
        return getAgencies;
      case 'info_team_name':
        return getSubAgencies;
      default:
        return null;
    }
  };

  useEffect(() => {
    if (field.code === 'pol_state') {
      setOptions(stateList);
    }
  }, []);

  const handleSearch = async (newValue) => {
    const fetch = handleFieldCode(field.code);

    setLoading(true);
    if (fetch) {
      try {
        // Returns the encoded form of the string. test& -> test%26.
        const encodedSearch = encodeURIComponent(newValue);

        const res: any = await fetch({
          search: encodedSearch ?? '',
          ...(field.code === 'policyholder__title'
            ? {
                // The is_insured query parameter allows filtering policyholders by their policy status (policy = true, quote = false),
                // returning all policyholders when omitted.
                is_insured: activeTabType === 'policy',
              }
            : {}),
        });

        const results = res?.results?.length ? res.results : take(res || [], 20);

        let newOptions = results?.map((result) => ({
          code: `${result.id}`,
          name: result?.full_name || result?.name,
        }));

        if (field.code === 'info_team_name') {
          newOptions = newOptions?.map((option) => ({
            ...option,
            name: option.name.split('-')[0],
          }));
        }
        setOptions(newOptions ?? []);
      } catch (error) {
        displayBackendErrorMessage(error);
      } finally {
        setLoading(false);
      }
    }
  };

  const debounceSearch = useCallback(debounce(handleSearch, defaultDebounceDelay), [activeTabType]);

  const handleInputChange = (event, newValue, reason) => {
    if (!event && reason === 'reset') {
      return;
    } else if (event && event.type === 'blur') {
      setInputText('');
      return;
    }

    setInputText(newValue);
    debounceSearch(newValue);
  };

  const handleSelectChange = (_event, newValue: Option[]) => {
    setState((prevState) => ({
      ...prevState,
      [`${field.code}`]: newValue,
    }));
    formik.setFieldValue(field.code, newValue);
  };

  return (
    <FormControl size="small" sx={{ width: '100%', py: 1 }}>
      <Autocomplete
        multiple
        filterSelectedOptions
        limitTags={1}
        sx={[
          autocompleteArrowStyle,
          {
            '& .MuiAutocomplete-inputRoot': {},
          },
        ]}
        size="small"
        disableClearable
        clearOnBlur
        loading={field.code === 'pol_state' ? false : loading}
        popupIcon={<ReactSVG className="arrow-icon" src={Arrow} />}
        value={state?.[`${field.code}`] || []}
        inputValue={inputText}
        onInputChange={handleInputChange}
        onChange={handleSelectChange}
        placeholder={t(field.name)}
        options={options}
        getOptionLabel={(option) => option.name}
        renderInput={(params) => (
          <TextField
            {...params}
            id={field.code}
            name={field.code}
            inputProps={{
              ...params.inputProps,
              autoComplete: 'new-password',
            }}
            sx={[
              autocompleteTextFieldStyle2,
              {
                '& .MuiOutlinedInput-root': {
                  padding: '4px 6px !important',
                },
                '& input.MuiOutlinedInput-input': {
                  width: '0 !important',
                  flexGrow: '1 !important',
                  padding: '4px 6px !important',
                  height: '21px',
                },
              },
            ]}
            label={t(field.name)}
          />
        )}
        renderTags={(value: readonly Option[], getTagProps) =>
          value.map((option: Option, index: number) => {
            const { key, ...tagProps } = getTagProps({ index });
            return (
              <Chip
                variant="outlined"
                label={option.name}
                key={key}
                deleteIcon={<CloseIcon />}
                sx={{
                  borderRadius: '4px',
                  borderColor: (theme) => theme.customColors.paleSlate,
                  backgroundColor: (theme) => theme.customColors.grey1150,
                  fontSize: '14px',
                  height: '24px',
                  lineHeight: '20px',
                  '& span': {
                    padding: '0 4px 0 8px',
                  },
                  '& .MuiChip-deleteIcon': {
                    width: '16px',
                    height: '16px',
                    marginRight: '8px',
                    marginLeft: '0',
                    color: (theme) => theme.customColors.borderBottomLine,
                  },
                }}
                {...tagProps}
              />
            );
          })
        }
        renderOption={(props, option) => (
          <li {...props} key={option.code ?? 0}>
            {option.name === '' ? <span>&#8203;</span> : option.name}
          </li>
        )}
        isOptionEqualToValue={(option, value) => option.code === value.code}
      />
    </FormControl>
  );
};

export default MultiSelectChips;
