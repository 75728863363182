/* eslint-disable no-restricted-syntax */
import { Box, Skeleton } from '@mui/material';
import { PolicyFieldError } from 'api/models/Socotra/PolicyFieldErrorResponse/policyFieldError.model';
import { cloneQuoteRequest, declineQuoteRequest } from 'api/services/Quote';
import AddCloneQuote from 'assets/images/CloneQuote.svg';
import DiscardQuote from 'assets/images/discardQuote.svg';
import AddNote from 'assets/images/icon_addnote.svg';
import AddDocument from 'assets/images/icon_attach_document.svg';
import DeclineIcon from 'assets/images/icon_decline.svg';
import PolicyChange from 'assets/images/icon_policychange.svg';
import IssueQuote from 'assets/images/issueQuote.svg';
import LockQoute from 'assets/images/LockQoute.svg';
import SendBack from 'assets/images/SendBack.svg';
import {
  errorCodes,
  globalSearchTypes,
  productCodes,
  quoteExtraStatuses,
  quoteStatuses,
  submissionDetailInfoTabs,
  userRoles,
} from 'common/constants';
import AddNoteDrawer from 'components/Notes/AddNote';
import { SocotraFieldErrorModalParser } from 'components/QuoteFieldErrorParser/FieldErrorModal';
import CloneQuoteDialog from 'components/Quotes/CloneQuoteDialog';
import AddDocumentDrawer from 'components/Quotes/QuoteDetail/Documents/AddDocument';
import TopActionBar from 'components/TopActionBar';
import TopActionBarButton from 'components/TopActionBarButton';
import { TopActionBarButtonProps } from 'components/TopActionBarButton/TopActionBarButton';
import TopActionBarDivider from 'components/TopActionBarDivider';
import displayBackendErrorMessage from 'helpers/displayBackendErrorMessage';
import displayToastMessage from 'helpers/DisplayToastMessage';
import { emitter, Events } from 'helpers/EventBus';
import { updateQueryStrings } from 'helpers/Utils';
import useDialog from 'hooks/useDialog';
import useLoader from 'hooks/useLoader';
import useQuoteDetail from 'hooks/useQuoteDetail';
import useRouterPrompt from 'hooks/useRouterPrompt';
import useUser from 'hooks/useUser';
import { isEmpty } from 'lodash-es';
import qs from 'query-string';
import React, { FC, useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory, useLocation, useParams } from 'react-router-dom';
import { ReactSVG } from 'react-svg';

export interface QuoteDetailTopActionBarProps {
  onNoteCreated?: () => void;
  onDocumentCreated?: () => void;
}

const QuoteDetailTopActionBar: FC<QuoteDetailTopActionBarProps> = ({
  onNoteCreated,
  onDocumentCreated,
}) => {
  const { t } = useTranslation();
  const { id } = useParams<{ id: string }>();
  const { setDialogOpen } = useDialog();
  const LOCATION = useLocation();
  const [isPreRatingErrored, setIsPreRatingErrored] = useState(false);
  const [preRatingErrors, setPreRatingErrors] = useState<PolicyFieldError>({});
  const [preRatingErrorsCode, setPreRatingErrorsCode] = useState<number | string>();
  const [isCloneQuoteDialogOpen, setIsCloneQuoteDialogOpen] = useState<boolean>(false);
  const [cloneLocator, setCloneLocator] = useState<string>('');
  const {
    lockQuote,
    data: quoteDetail,
    acceptQuote,
    invalidateQuote,
    discardQuote,
    issueQuote,
    referQuote,
    sendBackQuote,
    fetch,
    loaded: quoteLoaded,
    price,
    progress: { data: progressData },
    isAllowedTopBarActions,
  } = useQuoteDetail();
  const { setLoading } = useLoader();
  const HISTORY = useHistory();
  const { data: user } = useUser();
  const { setBypassRouteBlock } = useRouterPrompt();

  const url = qs.parse(LOCATION.search);
  const isProductCodeThreeWithWorkersCompensation =
    quoteDetail?.product?.code === productCodes.THREE_WITH_WORKERS_COMPENSATION;

  const isProgressStepsCompleted = (obj: { [key: string]: any }): boolean => {
    const mappedProgressData = Object.entries(obj)
      .map(([key, value]) => ({
        key,
        ...value,
      }))
      // filter wc key from progressData for product without wc
      .filter(({ key }) =>
        !isProductCodeThreeWithWorkersCompensation ? !key.startsWith('wc') : true,
      );

    const mapped: { [key: string]: any } = {};
    mappedProgressData.forEach((el) => {
      const keyIncludesUWResults = el.key.endsWith('_uw_results');
      if (keyIncludesUWResults) {
        const keyWithoutUWResults = el.key.replace('_uw_results', '');
        if (!mapped[keyWithoutUWResults]) {
          mapped[keyWithoutUWResults] = el;
        }
      } else {
        mapped[el.key] = el;
      }
    });
    const isCompleted = Object.values(mapped).every((el) => el?.is_completed);

    return isCompleted;
  };

  const isQuoteIssued = quoteDetail?.characteristics?.length
    ? !!quoteDetail?.characteristics?.[0].issued_at
    : false;

  const { tab } = qs.parse(HISTORY.location.search) as { tab: string };

  // Check role of user
  const isUnderwriter = user?.role?.code === userRoles.UNDERWRITER.code;
  const isProducer = user?.role?.code === userRoles.AGENT.code;

  const quoteStatus = quoteDetail?.state?.code;
  const isDraftedStatus = quoteStatus === quoteStatuses.DRAFT;
  const isReferredStatus = quoteStatus === quoteStatuses.REFERRED;
  const isDeclinedStatus = quoteStatus === quoteStatuses.DECLINED;
  const isMoratoriumStatus = quoteStatus === quoteStatuses.IN_MORATORIUM;
  const isExpiredStatus = quoteDetail?.state?.code === quoteStatuses.EXPIRED;
  const isQuotedStatus = quoteDetail?.state?.code === quoteStatuses.QUOTED;

  const isCloneButtonVisible = isQuotedStatus || isExpiredStatus;
  const isFinalizeButtonVisible = isQuotedStatus;
  const isUWDeclineButtonVisible = isUnderwriter && (isReferredStatus || isDraftedStatus);
  const isMainActionButtonsVisible = isDraftedStatus || isReferredStatus || isDeclinedStatus;
  const isSendBackButtonVisible = isReferredStatus && isUnderwriter;
  const isDiscardButtonVisible =
    isUnderwriter &&
    (isDraftedStatus || isReferredStatus || isDeclinedStatus || isQuotedStatus || isExpiredStatus);

  const showAddNoteButton = isUnderwriter;
  const isAddNoteDrawerOpen = showAddNoteButton && url?.addNote === 'true';
  const isAddDocumentDrawerOpen = url?.addDocument === 'true';

  const handleAcceptQuote = async () => {
    try {
      setLoading(true);
      await acceptQuote(id, { exclude_price: true });
      displayToastMessage(
        'SUCCESS',
        t('The Quote has been finalized and is ready for down payment.'),
      );
    } catch (error) {
      displayBackendErrorMessage(error, t('An error occurred while finalizing the quote.'));
    } finally {
      setLoading(false);
    }
  };

  const handleIssueQuote = async () => {
    try {
      setLoading(true);
      setDialogOpen({
        dialog: 'ISSUE_QUOTE',
        isOpen: false,
      });
      await issueQuote(id);

      HISTORY.push(`/policies/${quoteDetail?.policy_locator}/overview`);
      displayToastMessage('SUCCESS', t('The quote has been issued.'));
    } catch (error) {
      displayBackendErrorMessage(error, t('An error occurred while issuing the quote.'));
    } finally {
      setLoading(false);
    }
  };

  const processPreRatingError = (e: any, defaultMessage: string) => {
    switch (e?.response?.data?.code) {
      case errorCodes.UNDERWRITING_QUESTIONS: {
        setIsPreRatingErrored(true);
        setPreRatingErrors(e.response.data?.field_errors);
        setPreRatingErrorsCode(e.response?.data.code);
        break;
      }

      default:
        displayBackendErrorMessage(e, defaultMessage);
        break;
    }
  };

  const handleQuestionEngineInputsValidated = async (
    isDataValid: Events['questionEngineInputsValidated'],
  ) => {
    if (isDataValid) {
      try {
        setLoading(true);
        await lockQuote(id, { exclude_price: true });
        displayToastMessage('SUCCESS', t('The quote has been quoted.'));
      } catch (error) {
        const e = error as any;
        processPreRatingError(e, t('An error occurred while quoting the quote.'));
      } finally {
        setLoading(false);
      }
    }
  };

  const isQuestionEnginePage = useMemo(() => {
    switch (tab) {
      case submissionDetailInfoTabs.BUSINESS_INFO.code:
        return true;

      case submissionDetailInfoTabs.OPERATIONS.code:
        return true;

      case submissionDetailInfoTabs.WC_EXPOSURES.code:
        return true;

      default:
        return false;
    }
  }, [tab]);

  const isCoverageSummaryPage = useMemo(() => {
    switch (tab) {
      case submissionDetailInfoTabs.SUMMARY_PRICING.code:
        return true;

      default:
        return false;
    }
  }, [tab]);

  const isPrice = price?.loaded && !isEmpty(price.data);

  const isQuoteOrReferButtonActive = useMemo(() => {
    if (isUnderwriter) {
      return isPrice;
    }

    if (isProducer) {
      return !isEmpty(progressData) ? isProgressStepsCompleted(progressData) : false;
    }

    return false;
  }, [isPrice, isCoverageSummaryPage, progressData]);

  const handleLockQuote = async () => {
    setDialogOpen({
      dialog: 'LOCK_QUOTE',
      isOpen: false,
    });

    // emit event to page
    if (isQuestionEnginePage) {
      emitter.emit('validateQuestionEngineInputs', 'questionEngineInputsValidated');
    } else if (isCoverageSummaryPage) {
      emitter.emit('submitCoverageSummary', 'questionEngineInputsValidated');
    } else {
      handleQuestionEngineInputsValidated(true);
    }
  };

  const handleQuestionEngineInputsValidatedRefer = async (
    isDataValid: Events['referQuestionEngineInputsValidated'],
  ) => {
    if (isDataValid) {
      try {
        setLoading(true);
        await referQuote(id, { exclude_price: true });
        displayToastMessage('SUCCESS', t('The quote has been referred.'));
      } catch (error) {
        displayBackendErrorMessage(error, t('An error occurred while referring the quote.'));
      } finally {
        setLoading(false);
      }
    }
  };

  const handleQuestionEngineInputsValidatedDecline = async (
    isDataValid: Events['declineQuestionEngineInputsValidated'],
  ) => {
    if (isDataValid) {
      try {
        setLoading(true);
        await declineQuoteRequest(id);
        await fetch(id);
        displayToastMessage('SUCCESS', t('Quote has been declined.'));
      } catch (error) {
        displayBackendErrorMessage(error, t('An error occurred while declining the quote.'));
      } finally {
        setLoading(false);
      }
    }
  };

  const handleQuestionEngineInputsValidatedSendBack = async (
    isDataValid: Events['sendBackQuestionEngineInputsValidated'],
  ) => {
    if (isDataValid) {
      try {
        setLoading(true);
        await sendBackQuote(id);
        displayToastMessage('SUCCESS', t('Quote has been sent back.'));
      } catch (error) {
        displayBackendErrorMessage(error, t('An error occurred while sending back the quote.'));
      } finally {
        setLoading(false);
      }
    }
  };

  useEffect(() => {
    emitter.on('questionEngineInputsValidated', handleQuestionEngineInputsValidated);
    emitter.on('referQuestionEngineInputsValidated', handleQuestionEngineInputsValidatedRefer);
    emitter.on('declineQuestionEngineInputsValidated', handleQuestionEngineInputsValidatedDecline);
    emitter.on(
      'sendBackQuestionEngineInputsValidated',
      handleQuestionEngineInputsValidatedSendBack,
    );

    const handlePreRatingError = ({ error, defaultMessage }: Events['preRatingError']) =>
      processPreRatingError(error, defaultMessage);
    emitter.on('preRatingError', handlePreRatingError);

    return () => {
      emitter.off('questionEngineInputsValidated', handleQuestionEngineInputsValidated);
      emitter.off('referQuestionEngineInputsValidated', handleQuestionEngineInputsValidatedRefer);
      emitter.off(
        'declineQuestionEngineInputsValidated',
        handleQuestionEngineInputsValidatedDecline,
      );
      emitter.off(
        'sendBackQuestionEngineInputsValidated',
        handleQuestionEngineInputsValidatedSendBack,
      );
      emitter.off('preRatingError', handlePreRatingError);
    };
  }, []);

  const handleInvalidate = async () => {
    try {
      setLoading(true);
      setDialogOpen({
        dialog: 'INVALIDATE_QUOTE',
        isOpen: false,
      });
      await invalidateQuote(id, { exclude_price: true });
      displayToastMessage('SUCCESS', t('The quote has been invalidated.'));
    } catch (error) {
      displayBackendErrorMessage(error, t('An error occurred while invalidating the quote.'));
    } finally {
      setLoading(false);
    }
  };

  const handleReferQuote = async () => {
    setDialogOpen({
      dialog: isUnderwriter ? 'REFER_QUOTE_UW' : 'REFER_QUOTE',
      isOpen: false,
    });

    // emit event to page
    if (isQuestionEnginePage) {
      emitter.emit('validateQuestionEngineInputs', 'referQuestionEngineInputsValidated');
    } else if (isCoverageSummaryPage) {
      emitter.emit('submitCoverageSummary', 'referQuestionEngineInputsValidated');
    } else {
      handleQuestionEngineInputsValidatedRefer(true);
    }
  };

  const handleClone = async () => {
    try {
      setLoading(true);
      const clonedQuote = await cloneQuoteRequest(id);
      const { locator } = clonedQuote;

      displayToastMessage('SUCCESS', t('The Quote has been cloned.'));
      const hasPopUpsAllowed = window.open(
        `${window.location.origin}/quotes/${locator}/details`,
        '_blank',
      );
      if (!hasPopUpsAllowed) {
        setCloneLocator(locator as string);
        setIsCloneQuoteDialogOpen(true);
      }
    } catch (error) {
      displayBackendErrorMessage(error, t('An error occurred while Cloning the Quote.'));
    } finally {
      setLoading(false);
    }
  };

  const handleDiscard = async () => {
    try {
      setLoading(true);
      setDialogOpen({
        dialog: 'DISCARD_QUOTE',
        isOpen: false,
      });
      setBypassRouteBlock(true);
      await discardQuote(id);
      displayToastMessage('SUCCESS', t('The quote has been discarded.'));
      HISTORY.push('/quotes');
    } catch (error) {
      setBypassRouteBlock(false);
      displayBackendErrorMessage(error, t('An error occurred while discarding the quote.'));
    } finally {
      setLoading(false);
    }
  };

  const handleCreateNote = () => {
    const isOnNotePage = LOCATION.pathname.includes('notes');
    if (!isOnNotePage) {
      HISTORY.push({
        pathname: `/quotes/${id}/notes`,
      });
    }
    onNoteCreated?.();
  };


  const handleNewNote = () => {
    HISTORY.push({
      search: updateQueryStrings({
        locationSearch: LOCATION.search,
        newQueries: { addNote: true },
      }),
    });
  };

  const handleNewDocument = () => {
    HISTORY.push({
      search: updateQueryStrings({
        locationSearch: LOCATION.search,
        newQueries: { addDocument: true },
      }),
    });
  };

  const handleCreateDocument = () => {
    const isDocumentPage = LOCATION.pathname.includes('documents');
    if (!isDocumentPage) {
      HISTORY.push({
        pathname: `/quotes/${id}/documents`,
      });
    }
    onDocumentCreated?.();
  };

  const LockQuoteButton = (props?: TopActionBarButtonProps) => (
    <TopActionBarButton
      disabled={!isQuoteOrReferButtonActive || !isAllowedTopBarActions}
      tooltip={
        !isQuoteOrReferButtonActive
          ? 'All steps should be completed and price indication should be fetched to quote this submission.'
          : ''
      }
      onClick={() => {
        setDialogOpen({
          dialog: 'LOCK_QUOTE',
          isOpen: true,
          onAccept: () => handleLockQuote(),
        });
      }}
      startIcon={<Box component="img" src={LockQoute} sx={{ width: '24px', height: '24px' }} />}
      {...props}
      data-test="lock-quote"
    >
      {t('Quote')}
    </TopActionBarButton>
  );

  const ReferQuoteButton = (props?: TopActionBarButtonProps) => (
    <TopActionBarButton
      disabled={!isQuoteOrReferButtonActive}
      tooltip={
        !isQuoteOrReferButtonActive
          ? 'All steps should be completed and price indication should be fetched to quote this submission.'
          : ''
      }
      onClick={() => {
        setDialogOpen({
          dialog: isUnderwriter ? 'REFER_QUOTE_UW' : 'REFER_QUOTE',
          isOpen: true,
          onAccept: () => handleReferQuote(),
        });
      }}
      startIcon={<Box component="img" src={LockQoute} sx={{ width: '24px', height: '24px' }} />}
      {...props}
    >
      {t('Refer')}
    </TopActionBarButton>
  );

  const onPayInvoice = () => {
    HISTORY.push({
      pathname: 'billings',
      search: `?payInvoice=true&step=1`,
    });
  };

  const mainActions = useMemo(() => {
    const extraStatus = quoteDetail?.extra_status;

    if (isUnderwriter) {
      switch (extraStatus) {
        case quoteExtraStatuses.OK.code:
          return <>{LockQuoteButton()}</>;

        case quoteExtraStatuses.HAS_DECLINE.code:
          return (
            <>
              {LockQuoteButton({
                disabled: true,
                tooltip: quoteExtraStatuses.HAS_DECLINE.tooltip,
              })}
            </>
          );

        case quoteExtraStatuses.HAS_REFER.code:
          if (quoteStatus === quoteStatuses.REFERRED) {
            return (
              <>
                {LockQuoteButton({ disabled: true, tooltip: quoteExtraStatuses.HAS_REFER.tooltip })}
              </>
            );
          } else {
            return <>{ReferQuoteButton()}</>;
          }

        default:
          return <></>;
      }
    } else {
      if (quoteStatus === quoteStatuses.REFERRED) {
        return (
          <>{LockQuoteButton({ disabled: true, tooltip: quoteExtraStatuses.HAS_REFER.tooltip })}</>
        );
      }

      switch (extraStatus) {
        case quoteExtraStatuses.OK.code:
          return <>{LockQuoteButton()}</>;

        case quoteExtraStatuses.HAS_DECLINE.code:
          return (
            <>
              {LockQuoteButton({
                disabled: true,
                tooltip: quoteExtraStatuses.HAS_DECLINE.tooltip,
              })}
            </>
          );

        case quoteExtraStatuses.HAS_REFER.code:
          return <>{ReferQuoteButton()}</>;

        default:
          return <></>;
      }
    }
  }, [
    quoteDetail,
    isUnderwriter,
    isQuestionEnginePage,
    isCoverageSummaryPage,
    tab,
    isQuoteOrReferButtonActive,
  ]);

  const handleDecline = async () => {
    setDialogOpen({
      dialog: 'DECLINE_QUOTE',
      isOpen: false,
    });

    if (isCoverageSummaryPage || isQuestionEnginePage) {
      emitter.emit('declineSubmissionEndorsement', 'declineQuestionEngineInputsValidated');
    } else {
      handleQuestionEngineInputsValidatedDecline(true);
    }
  };

  const handleSendBack = async () => {
    setDialogOpen({
      dialog: 'SEND_BACK_QUOTE',
      isOpen: false,
    });

    if (isCoverageSummaryPage || isQuestionEnginePage) {
      emitter.emit('sendBackSubmissionEndorsement', 'sendBackQuestionEngineInputsValidated');
    } else {
      handleQuestionEngineInputsValidatedSendBack(true);
    }
  };

  return (
    <>
      {isCloneQuoteDialogOpen && (
        <CloneQuoteDialog
          isOpen={isCloneQuoteDialogOpen}
          setIsOpen={setIsCloneQuoteDialogOpen}
          setLocator={setCloneLocator}
          locator={cloneLocator}
        />
      )}

      {isAddDocumentDrawerOpen && (
        <AddDocumentDrawer
          isDrawerOpen={isAddDocumentDrawerOpen}
          associated={{ reference_type: globalSearchTypes.QUOTE, reference_locator: id }}
          onCreated={handleCreateDocument}
        />
      )}

      {isAddNoteDrawerOpen && (
        <AddNoteDrawer
          isDrawerOpen={isAddNoteDrawerOpen}
          associated={{
            reference_type: globalSearchTypes.QUOTE,
            reference_locator: id,
            three_number: quoteDetail?.three_quote_number ?? id,
          }}
          additionalInfo={quoteDetail?.policyholder?.name}
          onCreated={handleCreateNote}
        />
      )}


      {/* Error parser */}
      <SocotraFieldErrorModalParser
        open={isPreRatingErrored}
        setOpen={setIsPreRatingErrored}
        errors={!isEmpty(preRatingErrors) ? preRatingErrors : {}}
        errorCode={preRatingErrorsCode}
        title={t('Failed to Process')}
      />
      {/* Error parser */}

      <TopActionBar>
        {!quoteLoaded &&
          [...Array(5).keys()].map((_, i) => (
            <Skeleton
              animation="wave"
              width="5%"
              height={28}
              sx={{ marginY: 1 / 2, marginX: 1, alignSelf: 'center' }}
              key={i}
            />
          ))}

        {quoteLoaded && (
          <>
            {isMainActionButtonsVisible && <>{mainActions} </>}

            {isUWDeclineButtonVisible && (
              <TopActionBarButton
                disabled={!isAllowedTopBarActions}
                onClick={() => {
                  setDialogOpen({
                    dialog: 'DECLINE_QUOTE',
                    isOpen: true,
                    onAccept: () => handleDecline(),
                  });
                }}
                startIcon={
                  <Box
                    sx={{
                      '& .decline-icon svg': {
                        stroke: (theme) => theme.customColors.iconGray,
                        width: '24px',
                        height: '24px',
                      },
                    }}
                  >
                    <ReactSVG src={DeclineIcon} className="decline-icon" />
                  </Box>
                }
              >
                {t('Decline')}
              </TopActionBarButton>
            )}

            {isSendBackButtonVisible && (
              <TopActionBarButton
                data-test="send-back-quote"
                onClick={() => {
                  setDialogOpen({
                    dialog: 'SEND_BACK_QUOTE',
                    isOpen: true,
                    onAccept: () => handleSendBack(),
                  });
                }}
                startIcon={
                  <Box component="img" src={SendBack} sx={{ width: '24px', height: '24px' }} />
                }
              >
                {t('Send Back')}
              </TopActionBarButton>
            )}

            {isFinalizeButtonVisible && (
              <TopActionBarButton
                onClick={handleAcceptQuote}
                disabled={!isAllowedTopBarActions}
                startIcon={
                  <Box component="img" src={LockQoute} sx={{ width: '24px', height: '24px' }} />
                }
                data-test="finalize-quote"
              >
                {t('Finalize')}
              </TopActionBarButton>
            )}

            {isCloneButtonVisible && (
              <TopActionBarButton
                startIcon={
                  <Box component="img" src={AddCloneQuote} sx={{ width: '24px', height: '24px' }} />
                }
                disabled={!isAllowedTopBarActions}
                onClick={() => {
                  setDialogOpen({
                    dialog: 'CLONE_QUOTE',
                    isOpen: true,
                    onAccept: () => handleClone(),
                  });
                }}
              >
                {t('Clone')}
              </TopActionBarButton>
            )}

            {isDiscardButtonVisible && (
              <TopActionBarButton
                data-test="discard-quote"
                onClick={() => {
                  setDialogOpen({
                    dialog: 'DISCARD_QUOTE',
                    isOpen: true,
                    onAccept: () => handleDiscard(),
                  });
                }}
                startIcon={
                  <Box component="img" src={DiscardQuote} sx={{ width: '24px', height: '24px' }} />
                }
              >
                {t('Discard')}
              </TopActionBarButton>
            )}

            {!isMoratoriumStatus && <TopActionBarDivider />}

            {isUnderwriter && quoteDetail?.state?.code === quoteStatuses.ACCEPTED && (
              <>
                {!isQuoteIssued && (
                  <TopActionBarButton
                    onClick={() =>
                      setDialogOpen({
                        dialog: 'ISSUE_QUOTE',
                        isOpen: true,
                        onAccept: () => handleIssueQuote(),
                      })
                    }
                    startIcon={
                      <Box
                        component="img"
                        src={IssueQuote}
                        sx={{ width: '24px', height: '24px' }}
                      />
                    }
                    data-test="issue-quote"
                  >
                    {t('Issue')}
                  </TopActionBarButton>
                )}

                <TopActionBarButton
                  onClick={() => {
                    setDialogOpen({
                      dialog: 'INVALIDATE_QUOTE',
                      isOpen: true,
                      onAccept: () => handleInvalidate(),
                    });
                  }}
                  startIcon={
                    <Box
                      component="img"
                      src={DiscardQuote}
                      sx={{ width: '24px', height: '24px' }}
                    />
                  }
                  data-test="invalidate-quote"
                >
                  {t('Invalidate')}
                </TopActionBarButton>
              </>
            )}

            {quoteDetail?.state?.code === quoteStatuses.ACCEPTED && (
              <TopActionBarButton
                startIcon={
                  <Box component="img" src={PolicyChange} sx={{ width: '24px', height: '24px' }} />
                }
                onClick={onPayInvoice}
                data-test="pay-invoice"
              >
                {t('Pay Invoice')}
              </TopActionBarButton>
            )}

            {!isMoratoriumStatus && <TopActionBarDivider />}

            <TopActionBarButton
              startIcon={
                <Box component="img" src={AddDocument} sx={{ width: '24px', height: '24px' }} />
              }
              onClick={handleNewDocument}
            >
              {t('Attach Document')}
            </TopActionBarButton>
            {showAddNoteButton && (
              <TopActionBarButton
                startIcon={
                  <Box component="img" src={AddNote} sx={{ width: '24px', height: '24px' }} />
                }
                onClick={handleNewNote}
              >
                {t('Add Note')}
              </TopActionBarButton>
            )}

          </>
        )}
      </TopActionBar>
    </>
  );
};

export default QuoteDetailTopActionBar;
