import { Tab, tabClasses, Tabs, tabsClasses } from '@mui/material';
import SkeletonList from 'components/SkeletonList';
import { isFunction } from 'lodash-es';
import React, { FC, ReactElement } from 'react';

interface VerticalTabsProps {
  activeTab: number;
  isProgressTabs?: boolean;
  progressLoading?: boolean;
  tabs: {
    code: string;
    name: string | (() => string);
    index: number;
    disabled?: boolean;
    icon?: ReactElement;
    subTab?: boolean;
  }[];
  onTabChange: (event: React.SyntheticEvent, newValue: number) => Promise<void> | void;
}

const VerticalTabs: FC<VerticalTabsProps> = ({
  activeTab,
  tabs,
  isProgressTabs,
  onTabChange,
  progressLoading,
}) =>
  progressLoading ? (
    <SkeletonList
      sx={{
        minWidth: isProgressTabs ? 200 : 160,
        maxWidth: isProgressTabs ? 200 : 160,
        marginY: 1 / 2,
        alignSelf: 'center',
      }}
      width="100%"
      count={tabs.length}
      height={36}
    />
  ) : (
    <Tabs
      orientation="vertical"
      variant="scrollable"
      value={activeTab}
      onChange={onTabChange}
      sx={{
        minWidth: isProgressTabs ? 200 : 160,
        maxWidth: isProgressTabs ? 200 : 160,
        borderRight: 0,
        borderColor: 'divider',
        '& .MuiTabs-indicator': {
          background: (theme) => theme.customColors.darkCopper,
          height: 2,
          width: 0,
        },
        '& .MuiTabs-scrollButtons': {
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'end',
          position: 'relative',
          backgroundColor: 'transparent',
        },
        [`& .${tabsClasses.scrollButtons}`]: {
          '&.Mui-disabled': { opacity: 0.3 },
        },
        [`& .${tabClasses.root}`]: {
          alignItems: 'center',
          textAlign: isProgressTabs ? 'center' : 'right',
          justifyContent: 'end',
        },
        [`& .${tabClasses.selected}`]: {
          color: (theme) => `${theme.customColors.white50} !important`,
          [`& .MuiTypography-root`]: {
            color: (theme) => theme.customColors.white50,
          },
        },
        [`& .${tabClasses.iconWrapper} svg`]: {
          mt: 0.8,
        },
      }}
    >
      {tabs.map((tab, i) => (
        <Tab
          data-test={`tab_${tab.code}`}
          key={tab.code}
          label={isFunction(tab.name) ? tab.name?.() : tab.name}
          title={isFunction(tab.name) ? tab.name?.() : tab.name}
          icon={tab.icon}
          iconPosition="end"
          disableRipple={i === activeTab}
          disabled={tab.disabled}
          sx={{
            backgroundColor: (theme) =>
              i === activeTab ? theme.customColors.darkCopper : theme.customColors.grey1150,
            color: (theme) =>
              i === activeTab ? theme.customColors.white50 : theme.customColors.gunMetal,
            py: 0.5,
            ml: tab.subTab ? 4 : i === activeTab && !isProgressTabs ? 0 : 1,
            minHeight: 36,
            px: 0.5,
            pr: tab.subTab ? 4.4 : isProgressTabs ? 2 : 1,
            opacity: 1,
            fontWeight: i === activeTab ? 500 : 400,
            whiteSpace: 'normal',
            minWidth: isProgressTabs ? 200 : 152,
            mb: i === tabs.length - 1 ? 1 : 0.5,
            textTransform: 'capitalize',
            fontSize: 14,
            lineHeight: '20px',
            overflow: 'unset',
            overflowWrap: 'anywhere',
            transition: 'background-color 250ms ease, color 250ms ease',
            transitionDelay: '50ms',
            boxShadow: '0px 2px 7px rgba(0, 0, 0, 0.12)',
            borderRight: (theme) =>
              `2px solid ${theme.customColors.darkCopper}${tab.disabled ? '30' : '80'}`,
            '&:hover': {
              backgroundColor: (theme) => theme.customColors.darkCopper,
              color: (theme) => theme.customColors.white50,
            },
            '&::after': {
              width: 0,
              content: 'attr(title)',
              height: 0,
              overflow: 'hidden',
              visibility: 'hidden',
              fontWeight: 700,
            },
          }}
        />
      ))}
    </Tabs>
  );

export default VerticalTabs;
