import { useDisclosure } from '@mantine/hooks';
import { Menu } from '@mui/icons-material';
import { AppBar, Box, Drawer, IconButton, Toolbar } from '@mui/material';
import HeaderMobile from 'components/Header/HeaderMobile';
import SidebarContent from 'components/Sidebar/SidebarContent';
import React from 'react';

interface MobileDrawerProps {
  /**
   * Injected by the documentation to work in an iframe.
   * You won't need it on your project.
   */
  window?: () => Window;
}

const MobileDrawer = ({ window }: MobileDrawerProps) => {
  const [isMobileDrawerOpen, mobileDrawerHandlers] = useDisclosure(false);

  const container = window !== undefined ? () => window().document.body : undefined;

  return (
    <>
      <AppBar
        position="fixed"
        sx={{
          width: (theme) => ({ lg: `calc(100% - ${theme.sidebarWidth}px)` }),
          ml: (theme) => ({ sm: `${theme.sidebarWidth}px` }),
          display: {
            lg: 'none',
          },
          backgroundColor: (theme) => theme.customColors.topNavBar.bg,
          boxShadow: 'none',
          maxHeight: (theme) => `${theme.appBarHeight}px`,
          '& .MuiToolbar-root': {
            minHeight: (theme) => `${theme.appBarHeight}px`,
            padding: 0,
          },
        }}
        id="headerMobile"
      >
        <Toolbar>
          <IconButton
            color="inherit"
            aria-label="open sidebar"
            edge="start"
            onClick={mobileDrawerHandlers.toggle}
            sx={{
              ml: 1.5,
              display: { lg: 'none' },
              color: (theme) => theme.palette.background.default,
            }}
          >
            <Menu />
          </IconButton>
          <HeaderMobile />
        </Toolbar>
      </AppBar>
      <Box
        component="nav"
        sx={{
          flexShrink: { sm: 0 },
          display: {
            lg: 'none',
          },
          backgroundColor: (theme) => theme.customColors.grey1150,
        }}
      >
        {/* The implementation can be swapped with js to avoid SEO duplication of links. */}
        <Drawer
          container={container}
          variant="temporary"
          open={isMobileDrawerOpen}
          onClose={mobileDrawerHandlers.toggle}
          ModalProps={{
            keepMounted: true, // Better open performance on mobile.
          }}
          sx={{
            display: { xs: 'block', md: 'block', lg: 'none' },
            '& .MuiDrawer-paper': {
              boxSizing: 'border-box',
              width: '240px',
              color: (theme) => theme.palette.background.default,
              backgroundColor: (theme) => theme.customColors.grey1150,
              justifyContent: 'space-between',
            },
          }}
          anchor="left"
        >
          <SidebarContent isExpanded isMobile closeDrawer={mobileDrawerHandlers.toggle} />
        </Drawer>
      </Box>
    </>
  );
};

export default MobileDrawer;
