import LocationSelectInput from 'components/QuotePolicyDetailEndorsement/Inputs/LocationSelectInput';
import { ILocationSelect } from 'components/QuotePolicyDetailEndorsement/Inputs/LocationSelectInput/types';
import useQuoteDetail from 'hooks/useQuoteDetail';
import React, { FC } from 'react';

const QuoteLocationSelect: FC<ILocationSelect> = ({ ...props }) => {
  const { exposureList } = useQuoteDetail();

  return <LocationSelectInput exposureList={exposureList} {...props} />;
};

export default QuoteLocationSelect;
