import { createContext } from 'react';

/// types
import { ITabContextType, ITabStore } from './types';

export const initialTabData: ITabStore = {
  activeTab: 0,
};

export const tabContextDefault: ITabContextType = {
  ...initialTabData,
  resetTabState: () => {},
  setActiveTab: () => 0,
};

export const TabContext = createContext<ITabContextType>(null!);
