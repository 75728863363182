import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Typography,
  useTheme,
} from '@mui/material';
import { GridColDef, GridRowSelectionModel } from '@mui/x-data-grid';
import { PoliciesInvoices } from 'api/models/Policy/policiesInvoices.model';
import Download from 'assets/images/icon_dropdowndownload.svg';
import DataTable from 'components/DataTable';
import {
  currencyFormat,
  deleteFromQueryStrings,
  getNestedValueFromObject,
  updateQueryStrings,
} from 'helpers/Utils';
import useConfig from 'hooks/useConfig';
import usePolicyDetail from 'hooks/usePolicyDetail';
import { startCase } from 'lodash-es';
import React, { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory, useLocation } from 'react-router-dom';
import { IColumns } from 'routes/Policies/PolicyDetail/Billings/Billings';

interface IPayInvoiceDialogProps {
  isOpen: boolean;
}

const PayInvoiceDialog: React.FC<IPayInvoiceDialogProps> = ({ isOpen }) => {
  const { t } = useTranslation();
  const themeHook = useTheme();
  const { formatDateInTimeZone } = useConfig();
  const { invoices } = usePolicyDetail();
  const LOCATION = useLocation();
  const HISTORY = useHistory();
  const outstandingInvoices = useMemo(
    () => invoices?.data?.filter((invoice) => invoice.settlement_status?.key === 'outstanding'),
    [invoices],
  );
  const [selectedInvoices, setSelectedInvoices] = React.useState<PoliciesInvoices[]>([]);

  const handleSelectedRows = (selectedRows: GridRowSelectionModel) => {
    const selectedInvoicesList = selectedRows?.map((selectedInvoice) =>
      invoices.data?.find((invoice) => invoice.display_id === selectedInvoice),
    ) as PoliciesInvoices[];
    setSelectedInvoices(selectedInvoicesList);
  };

  const handleOnClose = () => {
    HISTORY.push({
      search: deleteFromQueryStrings({
        locationSearch: LOCATION.search,
        omitKeys: ['payInvoice', 'invoice', 'step'],
      }),
    });
  };

  const columns: IColumns[] = [
    {
      name: 'display_id',
      headerName: t('Invoice No'),
      minWidth: 100,
      flex: 1,
      type: 'string',
      is_hidden: false,
      is_sortable: false,
      is_link: false,
      link_type: null,
      align: 'left',
      headerAlign: 'left',
    },
    {
      name: 'invoice_type',
      headerName: t('Invoice Type'),
      minWidth: 110,
      flex: 1,
      type: 'string',
      is_hidden: false,
      is_sortable: true,
      is_link: false,
      link_type: null,
      align: 'left',
      headerAlign: 'left',
    },
    {
      name: 'due_time',
      headerName: t('Due Date'),
      minWidth: 110,
      flex: 1,
      type: 'date',
      is_hidden: false,
      is_sortable: true,
      is_link: false,
      link_type: null,
      align: 'left',
      headerAlign: 'left',
    },
    {
      name: 'premium',
      headerName: t('Premium'),
      minWidth: 110,
      flex: 1,
      is_hidden: false,
      is_sortable: false,
      is_link: false,
      link_type: null,
      align: 'right',
      headerAlign: 'right',
      type: 'number',
    },
    {
      name: 'fee',
      headerName: t('Fees'),
      minWidth: 110,
      flex: 1,
      is_hidden: false,
      is_sortable: false,
      is_link: false,
      link_type: null,
      align: 'right',
      headerAlign: 'right',
      type: 'number',
    },
    {
      name: 'total_due',
      headerName: t('Total'),
      minWidth: 110,
      flex: 1,
      is_hidden: false,
      is_sortable: false,
      is_link: false,
      link_type: null,
      align: 'right',
      headerAlign: 'right',
      type: 'number',
    },
    {
      name: 'settlement_status',
      headerName: t('Status'),
      minWidth: 330,
      flex: 2,
      is_hidden: false,
      is_sortable: false,
      is_link: false,
      link_type: null,
      align: 'left',
      headerAlign: 'left',
      type: 'string',
    },
  ];

  const editedColumns: GridColDef[] = columns.map((column) => ({
    field: column.name,
    headerName: column.headerName,
    flex: column.flex,
    minWidth: column.minWidth,
    sortable: column.is_sortable,
    align: column.align,
    headerAlign: column.headerAlign,
    renderCell: (params) => {
      const fieldValue = getNestedValueFromObject(params.row, column.name);
      const isFeeInvoice = params.row.invoice_type === 'other';

      if (column.name === 'document_download') {
        const document = getNestedValueFromObject(params.row, 'document');
        const { url } = document[0];

        return (
          <Box
            component="img"
            src={Download}
            sx={{ width: 24, height: 24, '&:hover': { cursor: 'pointer' } }}
            onClick={() => window.open(url, '_blank')}
          />
        );
      } else if (column.type === 'date') {
        return formatDateInTimeZone(fieldValue);
      } else if (column.type === 'number') {
        if (column.name === 'premium') {
          if (isFeeInvoice) {
            return '-';
          }
          return currencyFormat(undefined, fieldValue).merged || '-';
        }
        if (column.name === 'fee') {
          if (isFeeInvoice) {
            return currencyFormat(undefined, params.row.total_due).merged || '-';
          }
          return '-';
        }
        return currencyFormat(undefined, fieldValue).merged || '-';
      } else if (column.name === 'invoice_type') {
        return (
          <Box display="flex">
            {isFeeInvoice ? (
              <Typography>{t('Fee')}</Typography>
            ) : (
              <Typography>{startCase(fieldValue)}</Typography>
            )}
          </Box>
        );
      } else if (column.name === 'settlement_status') {
        const stripePaymentStatuses = getNestedValueFromObject(
          params.row,
          'stripe_payment_statuses',
        );

        return (
          <>
            <Box
              key={fieldValue.value}
              sx={{
                mr: 1,
                px: 1.5,
                fontSize: '12px',
                borderRadius: '4px',
                color: 'white',
                fontWeight: 500,
                backgroundColor: () =>
                  themeHook.customColors.invoiceStatusColor[params.row.status.key] ||
                  themeHook.customColors.black,
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
              }}
            >
              <Typography
                sx={{
                  fontSize: (theme) => theme.typography.subtitle2.fontSize,
                  color: (theme) => theme.customColors.white50,
                  fontWeight: (theme) => theme.typography.subtitle2.fontWeight,
                }}
                variant="body1"
              >
                {params.row.status.value ?? ''}
              </Typography>
            </Box>

            {stripePaymentStatuses?.[0] ? (
              <Box
                sx={{
                  mr: 1,
                  display: 'flex',
                  justifyContent: 'center',
                  alignItems: 'center',
                  px: 1.5,
                  fontSize: '12px',
                  borderRadius: '4px',
                  color: 'white',
                  fontWeight: 500,
                  backgroundColor: () => themeHook.customColors.invoiceStatusColor.chargeback,
                }}
              >
                <Typography
                  sx={{
                    fontSize: (theme) => theme.typography.subtitle2.fontSize,
                    color: (theme) => theme.customColors.white50,
                    fontWeight: (theme) => theme.typography.subtitle2.fontWeight,
                  }}
                  variant="body1"
                >
                  {stripePaymentStatuses[0]}
                </Typography>
              </Box>
            ) : null}
          </>
        );
      } else {
        return fieldValue || '-';
      }
    },
  }));

  return (
    <Dialog open={isOpen} onClose={handleOnClose} maxWidth="xl">
      <Box
        sx={{
          backgroundColor: (theme) => theme.customColors.grey1150,
          width: {
            lg: 1024,
            md: 900,
            xs: 400,
          },
        }}
      >
        <DialogTitle>
          <Typography sx={{ mt: 2, ml: 2, fontSize: 20, fontWeight: 500 }}>
            {t('Select the invoices you would like to pay')}
          </Typography>
        </DialogTitle>

        <DialogContent>
          <Box sx={{ py: 2 }}>
            <DataTable
              rows={outstandingInvoices ?? []}
              columns={editedColumns}
              onSelect={(selectedRows: GridRowSelectionModel) => {
                handleSelectedRows(selectedRows);
              }}
              initialState={{
                sorting: {
                  sortModel: [{ field: 'due_time', sort: 'asc' }],
                },
              }}
              getRowId={(row) => row.display_id}
              checkboxSelection
              multipleSelect
              disableSelectionOnClick
              hideFooterPagination
              loading={invoices?.loading}
              wrapperSx={{
                mx: 3,
                backgroundColor: (theme) => theme.customColors.grey1150,
              }}
            />
          </Box>
        </DialogContent>

        <DialogActions>
          <Button
            variant="outlined"
            sx={{
              color: (theme) => theme.customColors.copper,
              borderColor: (theme) => theme.customColors.copper,
              py: 0.5,
              px: 3,
              m: 1,
              mr: 0,
              fontSize: 14,
              borderRadius: '2px',
            }}
            onClick={handleOnClose}
          >
            {t('Cancel')}
          </Button>

          <Button
            disabled={outstandingInvoices?.length === 0 || selectedInvoices.length === 0}
            variant="contained"
            sx={{
              py: 0.5,
              px: 3,
              m: 1,
              mr: 2,
              fontSize: 14,
              borderRadius: '2px',
            }}
            onClick={() => {
              HISTORY.push({
                search: updateQueryStrings({
                  locationSearch: LOCATION.search,
                  newQueries: { invoice: selectedInvoices.map((inv) => inv.display_id), step: '2' },
                }),
              });
            }}
          >
            {t('Pay')}
          </Button>
        </DialogActions>
      </Box>
    </Dialog>
  );
};

export default PayInvoiceDialog;
