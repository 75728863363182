import { Box, Checkbox, Stack, Typography } from '@mui/material';
import { GridAlignment, GridColDef } from '@mui/x-data-grid';
import {
  defaultRowVirtualization,
  quotePolicyEndorsementInfoTitlesDescriptions,
  submissionDetailInfoTabs,
  underwritingResultDecisions,
  userRoles,
} from 'common/constants';
import DataTable from 'components/DataTable';
import NavigationButtons from 'components/QuotePolicyDetailEndorsement/NavigationButtons';
import TextBadge from 'components/TextBadge/TextBadge';
import displayBackendErrorMessage from 'helpers/displayBackendErrorMessage';
import { tabTitleStyles } from 'helpers/MuiSharedStyles';
import {
  createColumnVisibilityModel,
  getNestedValueFromObject,
  updateQueryStrings,
} from 'helpers/Utils';
import useDialog from 'hooks/useDialog';
import useLoader from 'hooks/useLoader/useLoader';
import useQuoteDetail from 'hooks/useQuoteDetail';
import useUser from 'hooks/useUser';
import qs from 'query-string';
import React, { FC, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory, useLocation, useParams } from 'react-router-dom';

interface IUWResultsProps {
  previousNextTab?: {
    previousTab: string;
    nextTab: string;
  };
}

interface IColumns {
  name: string;
  display_name: string;
  type: string;
  is_hidden: boolean;
  is_sortable: boolean;
  is_link: boolean;
  link_type: null;
  align: GridAlignment;
  headerAlign: GridAlignment;
  flex: number;
  width?: number;
  minWidth?: number;
}

const determineStatusColor = (decision: string) => {
  switch (decision) {
    case underwritingResultDecisions.APPROVED.code:
      return 'green200';
    case underwritingResultDecisions.REFERRED.code:
      return 'darkGrey';
    case underwritingResultDecisions.DECLINED.code:
      return 'errorRed';

    default:
      return 'grey850';
  }
};

const UWResults: FC<IUWResultsProps> = ({ previousNextTab }) => {
  const { t } = useTranslation();
  const { setDialogOpen } = useDialog();
  const {
    getRuleEngineResults,
    ruleEngineResults,
    canEdit,
    fetch,
    updateQuoteProgress,
    updateRuleEngineDecisionStatus,
    progress: { data: progressData },
  } = useQuoteDetail();
  const { data: user } = useUser();
  const { id: locator } = useParams<{ id: string }>();
  const { setLoading } = useLoader();
  const HISTORY = useHistory();
  const LOCATION = useLocation();
  const { tab } = qs.parse(LOCATION.search) as { tab: string };

  const isUnderwriter = user?.role?.code === userRoles.UNDERWRITER.code;
  const isProducer = user?.role?.code === userRoles.AGENT.code;

  const canSeeNavigationButtons = canEdit && isProducer;
  const isUWResultsTabNotCompleted = !(submissionDetailInfoTabs.UW_RESULTS.code in progressData);

  useEffect(() => {
    try {
      getRuleEngineResults(locator);
    } catch (error) {
      displayBackendErrorMessage(error, t('An error occured while fetching rule engine results.'));
    }
  }, []);

  const columns: IColumns[] = [
    {
      name: 'underwriting_rule_number',
      display_name: t('Rule ID'),
      is_hidden: false,
      is_sortable: true,
      is_link: false,
      link_type: null,
      flex: 0.1,
      type: 'string',
      align: 'right',
      headerAlign: 'right',
    },
    {
      name: 'refer_decline_reason',
      display_name: t('Refer/Decline Reason'),
      is_hidden: false,
      is_sortable: false,
      is_link: false,
      link_type: null,
      flex: 1,
      type: 'string',
      minWidth: 220,
      align: 'left',
      headerAlign: 'left',
    },

    ...(isUnderwriter
      ? ([
          {
            name: 'referral_support',
            display_name: t('Referral Support'),
            type: 'string',
            is_hidden: false,
            is_sortable: false,
            is_link: false,
            link_type: null,
            align: 'left',
            minWidth: 260,
            flex: 1,
            headerAlign: 'left',
          },
          {
            name: 'uw_instruction',
            display_name: t('UW Instruction'),
            type: 'string',
            is_hidden: false,
            is_sortable: false,
            is_link: false,
            link_type: null,
            align: 'left',
            minWidth: 260,
            flex: 1,
            headerAlign: 'left',
          },
        ] as IColumns[])
      : []),
    {
      name: 'rule_engine_decision_result',
      display_name: t('Outcome'),
      is_hidden: false,
      is_sortable: true,
      is_link: false,
      link_type: null,
      flex: 0.1,
      type: 'string',
      minWidth: 100,
      align: 'left',
      headerAlign: 'left',
    },

    {
      name: 'approved',
      display_name: t('Mark Approved'),
      type: 'checkbox',
      is_hidden: false,
      is_sortable: false,
      is_link: false,
      link_type: null,
      flex: 0.1,
      minWidth: 100,
      align: 'center',
      headerAlign: 'center',
    },
  ];

  const updateApprovedStatus = async (
    id: number,
    isUnderwriterApproved: boolean,
    ruleEngineStatusCode: string,
  ) => {
    const oppositeUnderwriterDecision = !isUnderwriterApproved;

    const updateRuleStatus = async () => {
      try {
        setLoading(true);

        await updateRuleEngineDecisionStatus(id, oppositeUnderwriterDecision, false);
        await fetch(locator, { exclude_price: true }, false);
      } catch (error) {
        displayBackendErrorMessage(error, t('An error occured while marking.'));
      } finally {
        setLoading(false);
      }
    };

    if (
      oppositeUnderwriterDecision &&
      ruleEngineStatusCode === underwritingResultDecisions.DECLINED.code
    ) {
      setDialogOpen({
        dialog: 'OVERRIDE_DECLINE_RULE',
        isOpen: true,
        onAccept: () => updateRuleStatus(),
      });
    } else {
      updateRuleStatus();
    }
  };

  const handlePrevious = async () => {
    HISTORY.push({
      search: updateQueryStrings({
        locationSearch: HISTORY.location.search,
        newQueries: { tab: previousNextTab?.previousTab },
      }),
    });
  };

  const handleNext = async () => {
    if (tab === submissionDetailInfoTabs.UW_RESULTS.code && isUWResultsTabNotCompleted) {
      try {
        setLoading(true);
        await updateQuoteProgress({
          locator,
          currentTab: submissionDetailInfoTabs.UW_RESULTS.code,
          isCompleted: true,
        });
      } catch (error) {
        displayBackendErrorMessage(error);
      } finally {
        setLoading(false);
      }
    }

    HISTORY.push({
      search: updateQueryStrings({
        locationSearch: HISTORY.location.search,
        newQueries: { tab: previousNextTab?.nextTab },
      }),
    });
  };

  const uwResultsColumns: GridColDef[] = columns.map((field) => ({
    field: field.name,
    headerName: field.display_name,
    minWidth: field.minWidth,
    flex: field.flex ?? 1,
    align: field.align ?? 'left',
    headerAlign: field.headerAlign ?? 'left',
    sortable: field.is_sortable,
    valueGetter: (_value, row) => {
      if (field.name === 'rule_engine_decision_result') {
        return row.rule_engine_decision_result?.code;
      }

      return getNestedValueFromObject(row, field.name);
    },
    renderCell: (params) => {
      const fieldValue = getNestedValueFromObject(params.row, field.name);

      if (field.type === 'checkbox') {
        const { rule_engine_decision_result, is_underwriter_approved, id } = params.row;

        return (
          <Checkbox
            checked={is_underwriter_approved}
            onChange={() => {
              updateApprovedStatus(id, is_underwriter_approved, rule_engine_decision_result?.code);
            }}
            disabled={!canEdit || !isUnderwriter}
          />
        );
      } else if (field.name === 'rule_engine_decision_result') {
        const { rule_engine_decision_result } = params.row;
        const determined = determineStatusColor(rule_engine_decision_result?.code);

        return <TextBadge label={rule_engine_decision_result?.name} color={determined} />;
      } else if (field.name === 'reason') {
        return (
          <Box sx={{ display: 'flex', flexDirection: 'column', width: '100%' }}>
            <Typography
              sx={{
                lineHeight: (theme) => theme.typography.body1.lineHeight,
                fontSize: (theme) => theme.typography.body1.fontSize,
                color: (theme) => theme.customColors.black,
                fontWeight: (theme) => theme.typography.subtitle2.fontWeight,
                textOverflow: 'ellipsis',
                overflow: 'hidden',
                whiteSpace: 'nowrap',
              }}
              title={fieldValue}
            >
              {fieldValue.substring(0, 40) || '-'}
            </Typography>

            {fieldValue?.length > 40 && (
              <Typography
                sx={{
                  lineHeight: (theme) => theme.typography.body1.lineHeight,
                  fontSize: (theme) => theme.typography.body1.fontSize,
                  color: (theme) => theme.customColors.black,
                  fontWeight: (theme) => theme.typography.subtitle2.fontWeight,
                  textOverflow: 'ellipsis',
                  overflow: 'hidden',
                  whiteSpace: 'nowrap',
                }}
                title={fieldValue}
              >
                {fieldValue.substring(40) || '-'}
              </Typography>
            )}
          </Box>
        );
      } else {
        return (
          <Typography
            sx={{
              lineHeight: (theme) => theme.typography.body1.lineHeight,
              fontSize: (theme) => theme.typography.body1.fontSize,
              color: (theme) => theme.customColors.black,
              fontWeight: (theme) => theme.typography.subtitle2.fontWeight,
              textOverflow: 'ellipsis',
              overflow: 'hidden',
              whiteSpace: 'nowrap',
            }}
            title={fieldValue}
          >
            {fieldValue || '-'}
          </Typography>
        );
      }
    },
  }));

  const isTableLoading = ruleEngineResults.loading;

  return (
    <Stack flexDirection="column" justifyContent="space-between" height="100%">
      <Box>
        <Typography sx={[tabTitleStyles]}>
          {quotePolicyEndorsementInfoTitlesDescriptions.UW_RESULTS.title()}
        </Typography>

        <Box sx={{ mt: 3 }}>
          <DataTable
            getRowId={(row) => `${row.id}_${row.is_underwriter_approved ?? ''}`}
            autoRowCellHeight
            dynamicRowHeight
            rows={ruleEngineResults?.data ?? []}
            loading={isTableLoading}
            columns={uwResultsColumns}
            columnVisibilityModel={createColumnVisibilityModel(columns)}
            pageSize={defaultRowVirtualization}
            hideFooterPagination={ruleEngineResults?.data?.length! < defaultRowVirtualization}
            wrapperSx={{ maxWidth: '1400px' }}
          />
        </Box>
      </Box>

      {canSeeNavigationButtons && (
        <NavigationButtons handlePrevious={handlePrevious} handleNext={handleNext} />
      )}
    </Stack>
  );
};

export default UWResults;
