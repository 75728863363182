/* eslint-disable react/require-default-props */
import { Box, SxProps, Theme } from '@mui/material';
import React, { FC } from 'react';

interface PageHeaderProps {
  right?: React.ReactNode;
  left: React.ReactNode;
  sx?: SxProps<Theme>;
  containerSx?: SxProps<Theme>;
}

const PageHeader: FC<PageHeaderProps> = ({ left, right, sx = [], containerSx = [] }) => (
  <Box
    gap={1}
    sx={[
      {
        width: 1,
        display: 'flex',
        py: 2,
        px: 3,
        mt: (theme) => `${theme.actionBarHeight}px`,
        flexDirection: {
          xs: 'column',
          md: 'row',
        },
        justifyContent: 'space-between',
        alignItems: 'center',
        overflowX: 'auto',
      },
      ...(Array.isArray(sx) ? sx : [sx]),
      ...(Array.isArray(containerSx) ? containerSx : [containerSx]),
    ]}
  >
    <Box sx={{ flex: 1 }}>{left}</Box>
    {right && (
      <Box
        sx={[
          {
            display: 'flex',
            flex: 1,
            justifyContent: { md: 'right', xs: 'left' },
            paddingTop: '0px !important',
          },
          ...(Array.isArray(sx) ? sx : [sx]),
        ]}
      >
        {right}
      </Box>
    )}
  </Box>
);

export default PageHeader;
