import ApiService, { Params } from 'api/helpers/Sender';
import { IGenericIntegrationResponse } from 'api/models/Integrations/IGenericResponse';
import { IPrometrixByAddressSearch } from 'api/models/Integrations/Prometrix/addressRequest.model';
import { IEstimatedReplacementCostRequest } from 'api/models/Integrations/Prometrix/EstimatedReplacementCosts.model';

const API = new ApiService();

export const getIntegrationResults = async (
  query: IPrometrixByAddressSearch,
  controller?: AbortController,
): Promise<any> => {
  const { data }: { data: IGenericIntegrationResponse<any> } = await API.get(
    `services/prometrix/results`,
    query as unknown as Params,
    {
      signal: controller?.signal,
    },
  );

  const res = data?.data ?? {};

  if (!res.bdg_wind_symbol) {
    res.bdg_wind_symbol = 'B';
  }

  return res ?? {};
};

export const getEstimatedReplacementCost = async (
  body: IEstimatedReplacementCostRequest,
  controller?: AbortController,
): Promise<any> => {
  const { data }: { data: IGenericIntegrationResponse<any> } = await API.post(
    `services/prometrix/estimated-replacement-cost`,
    body,
    {
      signal: controller?.signal,
    },
  );

  return data?.data ?? {};
};
