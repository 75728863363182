import { KeyboardArrowDownRounded } from '@mui/icons-material';
import {
  Box,
  FormControl,
  FormHelperText,
  InputLabel,
  MenuItem,
  Select,
  Skeleton,
  Stack,
} from '@mui/material';
import { threeExposureNames } from 'common/constants';
import LocationShow from 'components/LocationShow';
import { readOnlySelectInputStyle } from 'helpers/MuiSharedStyles';
import { formatLocation } from 'helpers/Utils';
import { uniqBy } from 'lodash-es';
import React, { FC, useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { ILocationSelectInput } from './types';

const defaultFieldConfig = {
  code: 'location',
  type: 'select',
  name: 'Location',
  width: 1,
  is_optional: false,
  is_readonly: false,
  heading: '',
  choices: [],
};

const LocationSelectInput: FC<ILocationSelectInput> = ({
  fieldConfig = defaultFieldConfig,
  formik,
  sx = [],
  isReadOnly = false,
  onSelect,
  state,
  setState,
  isCodeLocator = false,
  onBlur,
  exposureList,
}) => {
  const { t } = useTranslation();
  const [field, setField] = useState(fieldConfig);

  const currentExposures = exposureList?.[`${threeExposureNames.LOCATION}`];

  useEffect(() => {
    setField((prevState) => ({
      ...prevState,
      choices: currentExposures?.data?.map((ex) => {
        const formatted = formatLocation({
          addressLine1: ex.data?.loc_address_line1,
          addressLine2: ex.data?.loc_address_line2,
          city: ex.data?.loc_address_city,
          state: ex.data?.loc_address_state,
          zip: ex.data?.loc_address_zip,
        });

        return {
          code: !isCodeLocator ? formatted.storing : ex.locator,
          name: `${formatted.showing.head}|${formatted.showing.tail}`,
        };
      }),
    }));
  }, [currentExposures]);

  const options = useMemo(() => uniqBy([...(field.choices ?? [])], 'code'), [field.choices]);

  return currentExposures?.loading ? (
    <Stack
      gap={3}
      sx={{
        width: 1,
        flexDirection: {
          xs: 'column',
          sm: 'row',
        },
      }}
    >
      <FormControl variant="standard" sx={{ flex: 1 }}>
        <Skeleton animation="wave" width="100%" height={37} />
      </FormControl>
    </Stack>
  ) : (
    <FormControl
      required={!field.is_optional}
      size="small"
      sx={[{ width: '100%' }, isReadOnly || field?.is_readonly ? readOnlySelectInputStyle : {}]}
      key={field.code}
    >
      <Stack
        sx={[
          {
            '& .MuiFormControl-root': {
              flex: 'auto',
            },
          },
          ...(Array.isArray(sx) ? sx : [sx]),
        ]}
      >
        <InputLabel
          required={!field.is_optional}
          shrink={field?.is_readonly || isReadOnly ? true : undefined}
          id={`${field.code}_label`}
          error={formik.touched[`${field.code}`] && Boolean(formik.errors[`${field.code}`])}
        >
          {field.name}
        </InputLabel>
        <Select
          required={!field.is_optional}
          id={field.code}
          labelId={`${field.code}_label`}
          label={field.name}
          name={field.code}
          IconComponent={KeyboardArrowDownRounded}
          error={formik.touched[`${field.code}`] && Boolean(formik.errors[`${field.code}`])}
          value={state[field.code!] ?? ''}
          inputProps={{
            autoComplete: 'off',
            readOnly: field?.is_readonly || isReadOnly,
            'data-test': field.code,
          }}
          sx={{
            width: '100%',
          }}
          MenuProps={{
            sx: {
              width: 100,
            },
          }}
          onChange={(event) => {
            const newVal = event.target.value;
            setState?.({ ...state, [field.code!]: newVal });
            formik.setFieldValue([field.code], newVal);
            onSelect?.(event.target.value);
          }}
          onBlur={onBlur}
        >
          {options?.length === 0 ? (
            <Box
              component="span"
              sx={{
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
                outline: 'none',
              }}
            >
              {t('No data')}
            </Box>
          ) : (
            options?.map((type, index) => {
              const splited = type.name?.split('|');

              return (
                <MenuItem
                  data-test={`location_${index}`}
                  key={type.code}
                  value={type.code}
                  sx={{ w: 1 }}
                >
                  <LocationShow location={{ head: splited?.[0], tail: splited?.[1] }} />
                </MenuItem>
              );
            })
          )}
        </Select>

        {formik.touched[`${field.code}`] && Boolean(formik.errors[`${field.code}`]) && (
          <FormHelperText
            sx={{
              mt: 1,
              fontSize: '12px',
              lineHeight: '14px',
              color: (theme) => theme.customColors.alert,
            }}
          >
            {formik.errors[`${field.code}`]}
          </FormHelperText>
        )}
      </Stack>
    </FormControl>
  );
};

export default LocationSelectInput;
