import { Tooltip } from '@mui/material';
import React, { FC, ReactElement } from 'react';

export interface ErrorTooltipProps {
  formik: any;
  fieldName: string;
  maxWidth?: number;
}

const ErrorTooltip: FC<ErrorTooltipProps> = ({ formik, fieldName, children, maxWidth }) => (
  <Tooltip
    arrow
    disableHoverListener
    disableFocusListener
    leaveDelay={0}
    leaveTouchDelay={0}
    placement="top"
    open={(formik.touched[fieldName] && Boolean(formik.errors[fieldName])) ?? false}
    title={(formik.touched[fieldName] && formik.errors[fieldName]) ?? ''}
    TransitionProps={{
      timeout: {
        appear: 300,
        enter: 300,
        exit: 0,
      },
    }}
    componentsProps={{
      tooltip: {
        sx: {
          bgcolor: 'error.main',
          maxWidth: maxWidth ? `${maxWidth - 8}px` : 'unset',
        },
      },
      arrow: {
        sx: {
          color: 'error.main',
        },
      },
    }}
  >
    {children as ReactElement}
  </Tooltip>
);

export default ErrorTooltip;
