import { ProductWorkFlow } from 'api/models/NewQuote/productWorkFlow.model';
import {
  genericErrorMessage,
  policyDetailInfoTabs,
  submissionDetailInfoTabs,
  userRoles,
} from 'common/constants';
import BusinessInfoSection from 'components/QuotePolicyDetailEndorsement/BusinessInfoSection';
import Eligibility from 'components/QuotePolicyDetailEndorsement/Eligibility';
import NavigationButtons from 'components/QuotePolicyDetailEndorsement/NavigationButtons';
import ScrollToTop from 'components/ScrollToTop';
import { useFormik } from 'formik';
import displayBackendErrorMessage from 'helpers/displayBackendErrorMessage';
import displayToastMessage from 'helpers/DisplayToastMessage';
import { emitter, Events } from 'helpers/EventBus';
import { PreQualQuestionsLoading } from 'helpers/PreQualQuestionsLoading';
import { handleShowCondition } from 'helpers/QuestionEngine';
import ScrollToFormikError from 'helpers/ScrollToFormikError';
import {
  addRequiredValidationToDynamicFields,
  redirectToProducerTab,
  updateQueryStrings,
} from 'helpers/Utils';
import useLoader from 'hooks/useLoader';
import useQuoteDetail from 'hooks/useQuoteDetail';
import useUser from 'hooks/useUser';
import { isEmpty } from 'lodash-es';
import React, {
  createRef,
  forwardRef,
  useEffect,
  useImperativeHandle,
  useMemo,
  useState,
} from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory, useParams } from 'react-router-dom';
import * as yup from 'yup';

const BusinessInfo = forwardRef<any, any>((_props, ref) => {
  const { t } = useTranslation();
  const { data: user } = useUser();
  const HISTORY = useHistory();
  const { id } = useParams<{ id: string }>();
  const {
    isPreQual,
    underwritingQuestions,
    underwritingQuestionsState,
    setUnderwritingQuestionsState,
    saveUnderwritingQuestionsState,
    handleKeywordChange,
    canEdit,
    getExposures,
    updateQuoteProgress,
    data: quoteDetails,
    progress: { data: progressData },
  } = useQuoteDetail();
  const { setLoading } = useLoader();

  const [state, setState] = useState<any>({ ...underwritingQuestionsState });
  const [isBusinessInfoUpdated, setIsBusinessInfoUpdated] = useState(false);
  const [isNextButtonClicked, setIsNextButtonClicked] = useState(false);
  const [canReInitialize, setCanReInitialize] = useState(true);

  const eligibilityRef = createRef<{ validateFormik: () => Promise<{}> }>();

  const isProducer = user?.role?.code === userRoles.AGENT.code;
  const isProgressTab = isProducer && canEdit;
  const isBusinessInfoTabNotCompleted = !(
    submissionDetailInfoTabs.BUSINESS_INFO.code in (progressData ?? {})
  );

  const businessInfoFields = useMemo(
    () =>
      (underwritingQuestions?.policy?.data as ProductWorkFlow[])?.find(
        (f) => f.code === policyDetailInfoTabs.BUSINESS_INFO.code,
      )?.fields ?? [],
    [underwritingQuestions],
  );

  const visibleFields = businessInfoFields
    .flatMap((i) => i.nested_fields)
    .filter((i) => i && handleShowCondition(i, state) && !i?.is_hidden);

  const initialValues = useMemo(
    () =>
      businessInfoFields
        .flatMap((i) => i.nested_fields)
        .reduce((acc, cur) => ({ ...acc, [cur?.code!]: '' }), {}),
    [businessInfoFields],
  );

  const commonValidations = {
    ...addRequiredValidationToDynamicFields(visibleFields, state, user?.role?.code),
  };

  const validationSchema = yup.lazy(() => {
    const shapes = {
      ...commonValidations,
    };
    return yup.object().shape(shapes);
  });

  const formik = useFormik({
    initialValues: {
      ...initialValues,
      ...state,
    },

    validationSchema,
    enableReinitialize: canReInitialize,
    onSubmit: async (_values) => {},
  });

  useEffect(() => {
    setUnderwritingQuestionsState({ ...state });
  }, [state]);

  // display previous values in case it was declined with blank fields
  useEffect(() => {
    if (!canEdit) {
      setState({ ...underwritingQuestionsState });
      formik.resetForm({
        errors: {},
        touched: {},
      });
    }
  }, [canEdit]);

  useEffect(() => {
    setCanReInitialize(true);

    formik.setValues({ ...initialValues, ...formik.values });
    setTimeout(() => {
      setCanReInitialize(false);
    }, 100);
  }, [JSON.stringify(underwritingQuestions)]);

  const replaceBack = () =>
    HISTORY.replace({
      search: updateQueryStrings({
        locationSearch: HISTORY.location.search,
        newQueries: { tab: submissionDetailInfoTabs.BUSINESS_INFO.code },
      }),
    });

  const inputsHasError = async () => {
    const errors = await eligibilityRef?.current?.validateFormik();

    // validate this page
    await formik.submitForm();
    const pageErrors = await formik.validateForm();

    await formik.setTouched(
      {
        ...formik.touched,
        ...Object.keys(commonValidations).reduce((a, key) => ({ ...a, [`${key}`]: true }), {}),
      },
      false,
    );
    return !isEmpty(errors) || !isEmpty(pageErrors);
  };

  const handleNext = async ({
    triggerHistoryChange,
    keyword,
    emitterTriggered,
  }: {
    triggerHistoryChange: boolean;
    keyword?: string;
    emitterTriggered?: boolean;
  }) => {
    if (await inputsHasError()) {
      replaceBack();
      throw new Error('Validation error');
    } else if (triggerHistoryChange) {
      if (isProgressTab) {
        HISTORY.push({
          search: updateQueryStrings({
            locationSearch: HISTORY.location.search,
            newQueries: {
              tab: redirectToProducerTab({
                currentTab: submissionDetailInfoTabs.BUSINESS_INFO,
                productType: quoteDetails?.product?.code,
              }),
            },
          }),
        });
      } else {
        HISTORY.push({
          search: updateQueryStrings({
            locationSearch: HISTORY.location.search,
            newQueries: { tab: submissionDetailInfoTabs.OPERATIONS.code },
          }),
        });
      }
    } else if (formik.dirty && (!isBusinessInfoUpdated || emitterTriggered)) {
      try {
        setLoading(true);
        await saveUnderwritingQuestionsState(keyword);
        setIsBusinessInfoUpdated(true);
        // rule engine will update the exposures, so we need to fetch them again
        if (!isPreQual) getExposures(id, { page_size: 10000 });

        if (isProgressTab) {
          if (isBusinessInfoTabNotCompleted) {
            await updateQuoteProgress({
              locator: id,
              currentTab: submissionDetailInfoTabs.BUSINESS_INFO.code,
              isCompleted: true,
            });
          }

          if (isNextButtonClicked) {
            HISTORY.push({
              search: updateQueryStrings({
                locationSearch: HISTORY.location.search,
                newQueries: {
                  tab: redirectToProducerTab({
                    currentTab: submissionDetailInfoTabs.BUSINESS_INFO,
                    productType: quoteDetails?.product?.code,
                  }),
                },
              }),
            });
          }
        }
      } catch (error) {
        displayBackendErrorMessage(error, t('An error occured while saving the data.'));
        replaceBack();
        throw error;
      } finally {
        setIsNextButtonClicked(false);
        setLoading(false);
      }
    } // when a submission clone is generated, it ought to trigger the updateQuoteProgress function if the data remains unchanged.
    else if (!formik.dirty && isProgressTab && isBusinessInfoTabNotCompleted) {
      try {
        setLoading(true);

        await updateQuoteProgress({
          locator: id,
          currentTab: submissionDetailInfoTabs.BUSINESS_INFO.code,
          isCompleted: true,
        });

        HISTORY.push({
          search: updateQueryStrings({
            locationSearch: HISTORY.location.search,
            newQueries: {
              tab: redirectToProducerTab({
                currentTab: submissionDetailInfoTabs.BUSINESS_INFO,
                productType: quoteDetails?.product?.code,
              }),
            },
          }),
        });
      } finally {
        setLoading(false);
      }
    }
  };

  const isFormsLoading = underwritingQuestions.policy?.loading ?? true;

  const isReadOnly = !canEdit;

  const updateKeyword = async (val: any, oldVal: any) => {
    try {
      setLoading(true);
      await handleKeywordChange(val);
      HISTORY.go(0);
    } catch (error) {
      setState((prevState) => ({ ...prevState, pol_tax_keyword: oldVal }));
      // The logic here is that we have written the error message specifically here because the old correct value comes in an incorrect selection.
      const e = error as unknown as any;
      const defaultErrorMessage: string = genericErrorMessage();
      if (Array.isArray(e.response?.data?.field_errors?.pol_tax_keyword)) {
        displayToastMessage(
          'ERROR',
          !isEmpty(e.response?.data?.field_errors?.pol_tax_keyword)
            ? e.response?.data?.field_errors?.pol_tax_keyword?.[0]
            : defaultErrorMessage,
        );
      }

      replaceBack();
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    const handleSubmit = async (emitterAction: keyof Events) => {
      // emit event to action bar
      if (!(await inputsHasError())) {
        if (!isPreQual) {
          await handleNext({ triggerHistoryChange: false, emitterTriggered: true });
        }
        emitter.emit(emitterAction, true);
      }
    };

    emitter.on('validateQuestionEngineInputs', handleSubmit);
    emitter.on('declineSubmissionEndorsement', handleSubmit);
    emitter.on('sendBackSubmissionEndorsement', handleSubmit);

    return () => {
      emitter.off('validateQuestionEngineInputs', handleSubmit);
      emitter.off('declineSubmissionEndorsement', handleSubmit);
      emitter.off('sendBackSubmissionEndorsement', handleSubmit);
    };
  }, [underwritingQuestionsState, isPreQual]);

  useImperativeHandle(ref, () => ({
    savePageInfo: async (triggerHistoryChange = true) => {
      if (isFormsLoading) {
        replaceBack();
        throw new Error('loading');
      }

      await handleNext({ triggerHistoryChange });
    },
    isDirty: () => formik.dirty,
  }));

  return isFormsLoading ? (
    <>{PreQualQuestionsLoading()}</>
  ) : (
    <>
      <ScrollToFormikError formik={formik} />
      <Eligibility
        ref={eligibilityRef}
        parentFormik={formik}
        state={state}
        setState={setState}
        isReadOnly={isReadOnly}
        onKeywordChange={(val, oldVal) => updateKeyword(val, oldVal)}
      />

      {businessInfoFields.map((f, i) => (
        <BusinessInfoSection
          key={f?.code}
          field={f}
          formik={formik}
          isFormsLoading={isFormsLoading}
          index={i}
          state={state}
          setState={setState}
          isReadOnly={isReadOnly}
        />
      ))}

      {canEdit && (
        <NavigationButtons
          isPreviousButtonVisible={false}
          handleNext={async () => {
            setIsNextButtonClicked(true);
            await handleNext({ triggerHistoryChange: true }).catch((e) => e);
          }}
        />
      )}

      <ScrollToTop />
    </>
  );
});

export default BusinessInfo;
