/* eslint-disable @typescript-eslint/no-explicit-any, no-param-reassign */

import { Auth } from 'api/models/Auth/auth.model';
import Axios, { AxiosRequestConfig, AxiosResponse } from 'axios';
import { buildWebStorage, CacheOptions, setupCache } from 'axios-cache-interceptor';
import { cachePrefix } from 'helpers/AxiosInterceptor/cache';
import TokenStorage from 'helpers/TokenStorage';
import { isEmpty, isNil, merge } from 'lodash-es';
import REQUEST_OPTIONS from './Options';

const envApiUrl = import.meta.env.REACT_APP_BACKEND_MAIN_API_URL;

export const axios = setupCache(Axios, {
  storage: buildWebStorage(localStorage, cachePrefix),
});

export interface IAxiosRequestConfig extends AxiosRequestConfig {
  id?: string;
  cache?: CacheOptions | boolean;
}

const apiUrl = envApiUrl;

export interface TokenRequest {
  email: string;
  password: string;
  otp_code?: string;
}

export interface Params {
  [key: string]: string | number | undefined;
}

const Timezone = Intl.DateTimeFormat().resolvedOptions().timeZone;

class ApiService {
  API_URL: string;

  URL: string;

  constructor(path = '') {
    this.API_URL = `${apiUrl}`;
    this.URL = `${this.API_URL}/${path}/`;
  }

  /**
   * Creates a generic get request for specified URL.
   *
   * @param  {string} url (eg: get /questions/types)
   * @return {Promise<AxiosResponse>}
   */
  get(url: string, params: Params = {}, options: IAxiosRequestConfig = {}): Promise<AxiosResponse> {
    const requestOptions: IAxiosRequestConfig = merge({}, REQUEST_OPTIONS.GET, options);

    let additionalParams = '';

    if (!isEmpty(params)) {
      additionalParams = `/?${this.parseParams(params)}`;
    }

    return this.send(`${this.API_URL}/${url}${additionalParams}`, requestOptions);
  }

  /**
   * Creates a generic post request for specified URL.
   *
   * @param  {string} url
   * @return {Promise<AxiosResponse>}
   */
  post(
    url: string,
    data: any = {},
    options: IAxiosRequestConfig = {},
    params: Params = {},
  ): Promise<AxiosResponse> {
    const requestOptions: IAxiosRequestConfig = merge({}, REQUEST_OPTIONS.POST, options, { data });
    let additionalParams = '';

    if (!isEmpty(params)) {
      additionalParams = `/?${this.parseParams(params)}`;
    }
    return this.send(`${this.API_URL}/${url}${additionalParams}`, requestOptions);
  }

  /**
   * Creates a generic patch request for specified URL.
   *
   * @param  {string} url
   * @return {Promise<AxiosResponse>}
   */
  patch(
    url: string,
    data: any = {},
    options: IAxiosRequestConfig = {},
    params: Params = {},
  ): Promise<AxiosResponse> {
    const requestOptions: IAxiosRequestConfig = merge({}, REQUEST_OPTIONS.PATCH, options, { data });
    let additionalParams = '';

    if (!isEmpty(params)) {
      additionalParams = `/?${this.parseParams(params)}`;
    }

    return this.send(`${this.API_URL}/${url}${additionalParams}`, requestOptions);
  }

  /**
   * Creates a generic put request for specified URL.
   *
   * @param  {string} url
   * @return {Promise<AxiosResponse>}
   */
  put(
    url: string,
    data: any = {},
    options: IAxiosRequestConfig = {},
    params: Params = {},
  ): Promise<AxiosResponse> {
    const requestOptions: IAxiosRequestConfig = merge({}, REQUEST_OPTIONS.PUT, options, { data });

    let additionalParams = '';
    if (!isEmpty(params)) {
      additionalParams = `/?${this.parseParams(params)}`;
    }

    return this.send(`${this.API_URL}/${url}${additionalParams}`, requestOptions);
  }

  /**
   * Creates a generic post request for specified URL.
   *
   * @param  {string} url
   * @return {Promise<AxiosResponse>}
   */
  delete(
    url: string,
    options: IAxiosRequestConfig = {},
    params: Params = {},
  ): Promise<AxiosResponse> {
    const requestOptions: IAxiosRequestConfig = merge({}, REQUEST_OPTIONS.DELETE, options);
    let additionalParams = '';

    if (!isEmpty(params)) {
      additionalParams = `/?${this.parseParams(params)}`;
    }
    return this.send(`${this.API_URL}/${url}${additionalParams}`, requestOptions);
  }

  /**
   * Creates login request for oauth2 api.
   *
   * @param  {TokenRequest} body
   * @return {Promise<AxiosResponse>}
   */
  getToken({ email, password, otp_code }: TokenRequest): Promise<AxiosResponse> {
    const data = {
      email,
      password,
      otp_code,
    };

    const requestOptions: IAxiosRequestConfig = merge({}, REQUEST_OPTIONS.POST, {
      data,
      headers: {
        'Content-Type': 'application/json',
      },
    });

    return this.send(`${this.API_URL}/auth/token`, requestOptions);
  }

  /**
   * Creates a refresh token request for oauth2 api.
   *
   * @return {Promise<AxiosResponse>}
   */
  refreshToken(): Promise<AxiosResponse> | any {
    const auth = TokenStorage.get();
    if (auth?.refresh) {
      const data = {
        refresh: auth.refresh,
      };

      const requestOptions: IAxiosRequestConfig = merge({}, REQUEST_OPTIONS.POST, {
        data,
        headers: {
          'Content-Type': 'application/json',
        },
      });
      return this.send(`${this.API_URL}/auth/token/refresh`, requestOptions);
    } else {
      return null;
    }
  }

  /**
   * Revokes authentication
   * @returns {Promise<AxiosResponse>}
   */
  revokeToken(options: IAxiosRequestConfig = {}): Promise<AxiosResponse> {
    const requestOptions: IAxiosRequestConfig = merge({}, REQUEST_OPTIONS.POST, options);
    return this.send(`${this.API_URL}/revokeToken`, requestOptions);
  }

  /**
   * Creates a get request for list of resources.
   *
   * @return {Promise<AxiosResponse>}
   */
  index(params: Params = {}, options: IAxiosRequestConfig = {}): Promise<AxiosResponse> {
    const requestOptions: IAxiosRequestConfig = merge({}, REQUEST_OPTIONS.GET, options);
    let additionalParams = '';

    if (!isEmpty(params)) {
      additionalParams = `?${this.parseParams(params)}`;
    }

    return this.send(`${this.URL}${additionalParams}`, requestOptions);
  }

  /**
   * Creates a get request for a single resource.
   *
   * @param  {string | number} id
   * @return {Promise<AxiosResponse>}
   */
  show(id: string | number, options: IAxiosRequestConfig = {}): Promise<AxiosResponse> {
    const requestOptions: IAxiosRequestConfig = merge({}, REQUEST_OPTIONS.GET, options);
    return this.send(`${this.URL}/${id}`, requestOptions);
  }

  /**
   * Creates a post request for resource creation.
   *
   * @param  {object} data
   * @return {Promise<AxiosResponse>}
   */
  create(data: Record<string, unknown>, options = {}): Promise<AxiosResponse> {
    const requestOptions = merge({}, REQUEST_OPTIONS.POST, options, {
      data,
    });
    return this.send(this.URL, requestOptions);
  }

  /**
   * Creates a put request for resource updates.
   *
   * @param  {string | number} id
   * @param  {Record<string, unknown>} data
   * @return {Promise<AxiosResponse>}
   */
  update(id: string | number, data: Record<string, unknown>, options = {}): Promise<AxiosResponse> {
    const requestOptions = merge({}, REQUEST_OPTIONS.PUT, options, {
      data,
    });

    return this.send(`${this.URL}/${id}`, requestOptions);
  }

  /**
   * Creates a delete request for single resource.
   *
   * @param  {string | number} id
   * @return {Promise<AxiosResponse>}
   */
  // TODO rename to 'delete' kinds of name
  // if you change this name remember change all api.destroy calls name with the new name
  destroy(
    id: string | number,
    options: IAxiosRequestConfig = {},
    params: Params = {},
  ): Promise<AxiosResponse> {
    const requestOptions: IAxiosRequestConfig = merge({}, REQUEST_OPTIONS.DELETE, options);
    // return this.send(`${this.URL}/${id}`, requestOptions); // Old api return

    /**
     * additionalParams needs for #486
     */
    let additionalParams = '';

    if (!isEmpty(params)) {
      additionalParams = `?${this.parseParams(params)}`;
    }
    return this.send(`${this.URL}/${id}${additionalParams}`, requestOptions);
  }

  /**
   * Adds access token to request if any token exist on storage.
   *
   * @param  {object} request options
   * @return {object} modified options
   */
  // eslint-disable-next-line class-methods-use-this
  addTokenToRequest = (options: IAxiosRequestConfig): IAxiosRequestConfig => {
    const auth: Auth | null = TokenStorage.get();

    if (auth?.access) {
      options.headers!.Authorization = `Bearer ${auth.access}`;
    }

    options.headers!['X-Timezone'] = Timezone;

    return options;
  };

  /**
   * Creates form encoded body.
   *
   * @param  {any} params
   * @return {string}
   */
  // eslint-disable-next-line class-methods-use-this
  parseParams = (params: Params = {}): string =>
    Object.keys(params)
      .map((key) => `${key}=${params[key]}`)
      .join('&');

  /**
   * Generates a promise which sends request to server with given paremeters.
   *
   * @param  {string} url
   * @param  {IAxiosRequestConfig} options
   * @return {Promise<AxiosResponse>}
   */
  send(url: string, options: IAxiosRequestConfig): Promise<AxiosResponse> {
    const fullOptions: IAxiosRequestConfig = this.addTokenToRequest(options);
    fullOptions.url = `${url}${url.includes('?') ? '' : '/'}`;

    fullOptions.headers = {
      ...fullOptions.headers,
    };

    // By default make cache false for every request
    fullOptions.cache = isNil(fullOptions.cache) ? false : fullOptions.cache;

    return new Promise((resolve, reject) =>
      // eslint-disable-next-line no-promise-executor-return
      axios(fullOptions)
        .then((res) => {
          if (res?.status < 400) {
            resolve(res);
          } else {
            reject(res);
          }
        })
        .catch((err) => {
          reject(err);
        }),
    );
  }
}

export default ApiService;
