import { DocumentsContext } from 'providers/DocumentsProvider/DocumentsProviderContext';
import { IDocumentsContextType } from 'providers/DocumentsProvider/types';
import { useContext } from 'react';

export default function useDocuments(): IDocumentsContextType {
  const context = useContext(DocumentsContext);

  /* istanbul ignore if */
  if (!context) {
    throw new Error('useDocuments must be used within a DocumentsProvider');
  }

  return context;
}
