/* eslint-disable consistent-return */
/* eslint-disable react/no-unused-prop-types */
import { Info, KeyboardArrowDownRounded } from '@mui/icons-material';
import {
  Box,
  Checkbox,
  FormControl,
  FormHelperText,
  Grid,
  InputLabel,
  Radio,
  Skeleton,
  Stack,
  SxProps,
  TextField,
  ToggleButton,
  ToggleButtonGroup,
  Tooltip,
  Typography,
} from '@mui/material';
import { Theme } from '@mui/system';
import { DynamicField } from 'api/models/DynamicFields/dynamicField.model';
import CalendarSchedule from 'assets/images/CalendarSchedule.svg';
import { dataFieldTypes, defaultDateFormat } from 'common/constants';
import CustomNativeSelect from 'components/CustomNativeSelect';
import DatePickerComponent from 'components/DatePickerComponent';
import MultiselectChekboxes from 'components/MultiselectChekboxes';
import NumberFormatComponent from 'components/NumberFormatComponent';
import {
  calendarIconStyle,
  checkboxStyle,
  readOnlyCheckboxStyle,
  readOnlyInputStyle,
  readOnlySelectInputStyle,
} from 'helpers/MuiSharedStyles';
import { usePreviousValue } from 'helpers/PreviousValue';
import { handleShowCondition, maskedInputsConfig } from 'helpers/QuestionEngine';
import { cleanString, currencyFormat, getSymmetricDiffrence } from 'helpers/Utils';
import { isEmpty, omit, pick, uniqBy } from 'lodash-es';
import React, {
  ChangeEvent,
  forwardRef,
  memo,
  useEffect,
  useImperativeHandle,
  useMemo,
} from 'react';
import InputMask from 'react-input-mask';
import { PatternFormat } from 'react-number-format';
import { ReactSVG } from 'react-svg';
import UwQuestionsPercentageInput from './CustomInputs/Percentage';

interface IProps {
  formik: any;
  showLoader?: boolean;
  fields?: DynamicField[];
  loaded?: boolean;
  splitSize?: number;
  state?: any;
  setState?: any;
  rowSpacing?: number;
  columnSpacing?: number;
  isReadOnly?: boolean;
  sx?: SxProps<Theme>;
  onChangeEmited?: ({ field, value }: { field: string; value: any }) => void;
  onSmartySelect?: <T>(newState: T) => void;
  relationalFields?: any;
}

const QuestionEngineFieldParser = forwardRef<any, IProps>(
  (
    {
      showLoader = false,
      isReadOnly = false,
      fields,
      splitSize = 2,
      state,
      setState,
      rowSpacing = 1.5,
      columnSpacing = 3,
      formik,
      sx,
      onChangeEmited,
      relationalFields,
    },
    ref,
  ) => {
    const relations = useMemo(() => pick(state, relationalFields ?? []), [JSON.stringify(state)]);

    const relationalError = useMemo(
      () =>
        relationalFields
          ?.map((f) =>
            Object.hasOwn(formik?.errors, f) && Object.hasOwn(formik?.touched, f)
              ? formik?.errors[`${f}`]
              : '',
          )
          .join('|'),
      [JSON.stringify(formik?.errors), JSON.stringify(formik?.touched)],
    );

    // only update fields if their `relationalFields` are changed
    const visibleFields = useMemo(
      () =>
        fields?.filter(
          (field) => field && handleShowCondition(field, state) && !field?.is_hidden,
        ) ?? [],
      [JSON.stringify(relations), JSON.stringify(fields)],
    );

    const visibleFieldCodes: string[] = useMemo(
      () => visibleFields.map((f) => f.code),
      [JSON.stringify(visibleFields)],
    );

    const nonVisibleFieldCodes = useMemo(
      () =>
        fields?.reduce(
          (c: string[], f) => (!visibleFieldCodes.includes(f.code) ? [...c, f.code] : c),
          [],
        ) ?? [],
      [JSON.stringify(visibleFieldCodes)],
    );

    const prevNonVisibleFieldCodes = usePreviousValue(nonVisibleFieldCodes);

    useImperativeHandle(ref, () => ({
      renderedQuestions: () => visibleFields,
    }));

    const handleFieldType = (field: DynamicField) => {
      const testPrefix = `uw_${field.code}`;
      const { loading, emitIsChangedEvent } = field?.additional_data ?? {};

      const isFieldReadOnly = field?.is_readonly || isReadOnly;
      const isFieldRequired = Boolean(field.name) && !field.is_optional;

      const checkEmitIsChanged = ({ fieldName, value }: { fieldName: string; value: any }) => {
        if (emitIsChangedEvent) {
          onChangeEmited?.({ field: fieldName, value });
        }
      };

      const updateState = (name: string, value: any) => {
        setState((prevState) => ({ ...prevState, [name]: value }));

        formik.setFieldValue([field.code], value);

        checkEmitIsChanged({ fieldName: name, value });
      };

      const handleInputChange = (event: ChangeEvent<HTMLInputElement>) => {
        const { name, value } = event.target;

        updateState(name, value);
      };

      const handlePatternFormatInputChange = (values, sourceInfo) => {
        const name = sourceInfo?.event?.target?.name ?? '';
        const { value = '' } = values;

        updateState(name, value);
      };

      if (loading) {
        return (
          <FormControl variant="standard" sx={{ width: '100%' }} key={field.code}>
            <Skeleton sx={{ mr: '40%' }} animation="wave" width="100%" height={37} />
          </FormControl>
        );
      } else if (Object.keys(maskedInputsConfig).includes(field.code)) {
        const { format, mask } = maskedInputsConfig[`${field.code}`];

        return (
          <FormControl
            variant="standard"
            sx={{ width: '100%', maxWidth: '480px' }}
            key={field.code}
          >
            <PatternFormat
              format={format}
              customInput={TextField}
              valueIsNumericString
              mask={mask}
              id={field.code}
              name={field.code}
              label={field.name}
              size="small"
              required={isFieldRequired}
              value={state[field.code] || ''}
              onValueChange={handlePatternFormatInputChange}
              sx={isFieldReadOnly ? readOnlyInputStyle : {}}
              InputLabelProps={isFieldReadOnly ? { shrink: true } : {}}
              inputProps={{
                autoComplete: 'nope',
                ...(isFieldReadOnly && { readOnly: true }),
                'data-test': testPrefix,
                // This CSS Class will automatically mask the Full Story field.
                ...(field.code === 'ssn' && { className: 'fs-mask' }),
              }}
              error={formik.touched[`${field.code}`] && Boolean(formik.errors[`${field.code}`])}
              helperText={formik.touched[`${field.code}`] && formik.errors[`${field.code}`]}
            />
          </FormControl>
        );
      }

      switch (field.type) {
        case dataFieldTypes.STRING:
          return (
            <FormControl
              variant="standard"
              sx={{ width: '100%', maxWidth: '480px' }}
              key={field.code}
            >
              <TextField
                id={field.code}
                name={field.code}
                label={field.name}
                size="small"
                required={isFieldRequired}
                onChange={handleInputChange}
                onBlur={(event) => {
                  const cleanedStringValue = cleanString(event.target.value);
                  formik.setFieldValue([field.code], cleanedStringValue);
                  setState((prevState) => ({ ...prevState, [field.code]: cleanedStringValue! }));
                }}
                value={state[field.code] || ''}
                sx={isFieldReadOnly ? readOnlyInputStyle : {}}
                InputLabelProps={isFieldReadOnly ? { shrink: true } : {}}
                inputProps={{
                  autoComplete: 'nope',
                  ...(isFieldReadOnly && { readOnly: true }),
                  'data-test': testPrefix,
                }}
                error={formik.touched[`${field.code}`] && Boolean(formik.errors[`${field.code}`])}
                helperText={formik.touched[`${field.code}`] && formik.errors[`${field.code}`]}
              />
            </FormControl>
          );

        case dataFieldTypes.PHONE:
          return (
            <FormControl
              variant="standard"
              sx={{ width: '100%', maxWidth: '480px' }}
              key={field.code}
              required={isFieldRequired}
            >
              <Stack
                sx={{
                  '& .MuiFormControl-root': {
                    flex: 'auto',
                  },
                  py: 1,
                  display: 'flex',
                  flexDirection: 'row',
                  alignItems: 'center',
                  justifyContent: 'space-between',
                }}
              >
                <InputMask
                  mask="(999) 999-9999"
                  disabled={false}
                  maskChar=" "
                  onChange={handleInputChange}
                  value={state[field.code] || ''}
                >
                  {() => (
                    <TextField
                      data-test={`${testPrefix}Field`}
                      sx={[{ flex: 'auto' }, isFieldReadOnly ? readOnlyInputStyle : {}]}
                      id={field.code}
                      name={field.code}
                      size="small"
                      required={isFieldRequired}
                      label={field.name}
                      value={state[field.code] || ''}
                      InputLabelProps={isFieldReadOnly ? { shrink: true } : {}}
                      inputProps={{
                        autoComplete: 'nope',
                        ...(isFieldReadOnly && { readOnly: true }),
                        'data-test': testPrefix,
                      }}
                      error={
                        formik.touched[`${field.code}`] && Boolean(formik.errors[`${field.code}`])
                      }
                      helperText={formik.touched[`${field.code}`] && formik.errors[`${field.code}`]}
                    />
                  )}
                </InputMask>
              </Stack>
            </FormControl>
          );

        case dataFieldTypes.YEAR:
          return (
            <FormControl
              variant="standard"
              sx={{ width: '100%', maxWidth: '480px' }}
              key={field.code}
            >
              <PatternFormat
                format="####"
                customInput={TextField}
                valueIsNumericString
                id={field.code}
                name={field.code}
                label={field.name}
                size="small"
                required={isFieldRequired}
                value={state[field.code] || ''}
                onValueChange={handlePatternFormatInputChange}
                sx={isFieldReadOnly ? readOnlyInputStyle : {}}
                InputLabelProps={isFieldReadOnly ? { shrink: true } : {}}
                inputProps={{
                  autoComplete: 'nope',
                  ...(isFieldReadOnly && { readOnly: true }),
                  'data-test': testPrefix,
                }}
                error={formik.touched[`${field.code}`] && Boolean(formik.errors[`${field.code}`])}
                helperText={formik.touched[`${field.code}`] && formik.errors[`${field.code}`]}
              />
            </FormControl>
          );

        case dataFieldTypes.CURRENCY:
        case dataFieldTypes.NUMBER:
          return (
            <FormControl
              variant="standard"
              sx={{ width: '100%', maxWidth: '480px' }}
              key={field.code}
            >
              <TextField
                sx={[
                  {
                    '& input::-webkit-outer-spin-button, & input::-webkit-inner-spin-button': {
                      display: 'none',
                    },
                    '& input[type=number]': {
                      MozAppearance: 'textfield',
                    },
                  },
                  isFieldReadOnly ? readOnlyInputStyle : {},
                ]}
                label={field.name}
                id={field.code}
                name={field.code}
                size="small"
                required={isFieldRequired}
                // UW questions don't have currencyInput additionalData. So type is set to text for displaying formatted number
                type="text"
                // type={currencyInput ? 'text' : 'number'}
                onChange={handleInputChange}
                value={state[field.code] || ''}
                inputProps={{
                  autoComplete: 'off',
                  ...(isFieldReadOnly && {
                    readOnly: true,
                  }),
                  'data-test': testPrefix,
                }}
                InputLabelProps={isFieldReadOnly ? { shrink: true } : {}}
                // eslint-disable-next-line react/jsx-no-duplicate-props
                InputProps={{
                  autoComplete: 'new-password',
                  inputComponent: NumberFormatComponent as any,
                  ...(typeof field?.additional_data?.allowDecimal === 'boolean'
                    ? {
                        inputProps: {
                          allowDecimal: field?.additional_data?.allowDecimal,
                        },
                      }
                    : {}),
                }}
                error={formik.touched[`${field.code}`] && Boolean(formik.errors[`${field.code}`])}
                helperText={formik.touched[`${field.code}`] && formik.errors[`${field.code}`]}
              />
            </FormControl>
          );

        case dataFieldTypes.PERCENTAGE:
          // TODO: implement correct percentage input
          return (
            <FormControl
              variant="standard"
              sx={{ width: '100%', maxWidth: '480px' }}
              key={field.code}
            >
              <UwQuestionsPercentageInput
                // @ts-ignore
                customInput={TextField}
                id={field.code}
                name={field.code}
                label={field.name}
                // @ts-ignore
                size="small"
                required={isFieldRequired}
                initialValue={state[field.code] || ''}
                onInputChange={(val) => updateState(field.code, val)}
                sx={isFieldReadOnly ? readOnlyInputStyle : {}}
                InputLabelProps={isFieldReadOnly ? { shrink: true } : {}}
                inputProps={{
                  autoComplete: 'off',
                  ...(isFieldReadOnly && { readOnly: true }),
                  'data-test': testPrefix,
                }}
                error={formik.touched[`${field.code}`] && Boolean(formik.errors[`${field.code}`])}
                helperText={formik.touched[`${field.code}`] && formik.errors[`${field.code}`]}
              />
            </FormControl>
          );

        case dataFieldTypes.EMAIL:
          return (
            <FormControl
              variant="standard"
              sx={{ width: '100%', maxWidth: '480px' }}
              key={field.code}
            >
              <TextField
                sx={[isFieldReadOnly ? readOnlyInputStyle : {}]}
                id={field.code}
                name={field.code}
                size="small"
                type="email"
                required={isFieldRequired}
                label={field.name}
                onChange={handleInputChange}
                value={state[field.code] || ''}
                error={formik.touched[field.code] && Boolean(formik.errors[field.code])}
                helperText={formik.touched[field.code] && formik.errors[field.code]}
                inputProps={{
                  autoComplete: 'nope',
                  ...(isFieldReadOnly && { readOnly: true }),
                  'data-test': testPrefix,
                }}
                InputLabelProps={isFieldReadOnly ? { shrink: true } : {}}
              />
            </FormControl>
          );

        case dataFieldTypes.DATE:
          return (
            <FormControl
              variant="standard"
              sx={{ width: '100%', maxWidth: '480px', ...calendarIconStyle }}
              key={field.code}
            >
              <DatePickerComponent
                format={defaultDateFormat}
                minDate={field?.minimum_date}
                maxDate={field?.maximum_date}
                label={field.name}
                readOnly={isFieldReadOnly}
                onChange={(newValue) => {
                  setState((prevState) => ({ ...prevState, [field.code]: newValue! }));
                  formik.setFieldValue([field.code], newValue);
                  checkEmitIsChanged({ fieldName: field.code, value: newValue });
                }}
                value={state?.[field.code] === '' ? null : state[field.code] ?? null}
                slots={{
                  openPickerIcon: () => (
                    <ReactSVG className="calendar-icon" src={CalendarSchedule} />
                  ),
                }}
                slotProps={{
                  textField: {
                    size: 'small',
                    required: isFieldRequired,
                    id: field.code,
                    name: field.code,
                    sx: [isFieldReadOnly ? readOnlyInputStyle : {}],
                    InputLabelProps: isFieldReadOnly ? { shrink: true } : {},
                    inputProps: {
                      autoComplete: 'nope',
                      ...(isFieldReadOnly && {
                        readOnly: true,
                      }),
                      'data-test': testPrefix,
                    },
                    error:
                      formik.touched[`${field.code}`] && Boolean(formik.errors[`${field.code}`]),
                    helperText: formik.touched[`${field.code}`] && formik.errors[`${field.code}`],
                  },
                }}
              />
            </FormControl>
          );

        case dataFieldTypes.SELECT:
          return (
            <FormControl
              required={isFieldRequired}
              size="small"
              sx={[
                { width: '100%', maxWidth: '480px' },
                isFieldReadOnly ? readOnlySelectInputStyle : {},
              ]}
              key={field.code}
              error={formik.touched[`${field.code}`] && Boolean(formik.errors[`${field.code}`])}
            >
              <Stack
                sx={{
                  '& .MuiFormControl-root': {
                    flex: 'auto',
                  },
                }}
              >
                <InputLabel
                  required={isFieldRequired}
                  id={`${field.code}_label`}
                  shrink={isFieldReadOnly ? true : undefined}
                >
                  {field.name}
                </InputLabel>
                <CustomNativeSelect
                  required={isFieldRequired}
                  id={field.code}
                  labelId={`${field.code}_label`}
                  label={field.name}
                  name={field.code}
                  IconComponent={KeyboardArrowDownRounded}
                  error={formik.touched[`${field.code}`] && Boolean(formik.errors[`${field.code}`])}
                  value={state[field.code] ?? ''}
                  inputProps={{
                    autoComplete: 'nope',
                    readOnly: isFieldReadOnly,
                    'data-test': testPrefix,
                  }}
                  sx={{
                    '& > .MuiSelect-select': { display: 'inline-block' },
                    width: '100%',
                  }}
                  MenuProps={{
                    sx: {
                      width: 100,
                      wordBreak: 'break-all',
                      whiteSpace: 'pre-wrap',
                      '& .MuiMenuItem-root': { whiteSpace: 'normal' },
                    },
                  }}
                  onChange={(event) => {
                    const { value } = event.target;
                    setState((prevState) => ({ ...prevState, [field.code]: value }));
                    formik.setFieldValue([field.code], value);
                    checkEmitIsChanged({ fieldName: field.code, value });
                  }}
                >
                  {field?.choices &&
                    uniqBy([...field.choices], 'code')?.map((type) => (
                      <option key={type.code} value={type.code}>
                        {type.name}
                      </option>
                    ))}
                </CustomNativeSelect>

                {formik.touched[`${field.code}`] && Boolean(formik.errors[`${field.code}`]) && (
                  <FormHelperText
                    sx={{
                      mt: 1,
                      fontSize: '12px',
                      lineHeight: '14px',
                      color: (theme) => theme.customColors.alert,
                    }}
                  >
                    {formik.errors[`${field.code}`]}
                  </FormHelperText>
                )}
              </Stack>
            </FormControl>
          );

        case dataFieldTypes.SELECT_BUTTON:
          return (
            <FormControl
              size="small"
              sx={{ width: '100%', maxWidth: '480px', position: 'relative' }}
              key={field.code}
            >
              {field.name && (
                <Typography
                  sx={{
                    fontWeight: 400,
                    fontSize: '12px',
                    lineHeight: '14px',
                    whiteSpace: 'nowrap',
                    mb: 1,
                  }}
                >
                  {field.name} {!field?.is_optional && '*'}
                </Typography>
              )}
              <ToggleButtonGroup
                exclusive
                value={state[field.code] ?? null}
                onChange={(event, newValue) => {
                  if (newValue) {
                    setState((prevState) => ({ ...prevState, [field.code]: newValue }));
                    formik.setFieldValue([field.code], newValue);
                    checkEmitIsChanged({ fieldName: field.code, value: newValue });
                  }
                }}
                sx={{ gap: 2 }}
                aria-label={field.name}
              >
                {field.choices?.map((choice) => (
                  <ToggleButton
                    data-test={`${testPrefix}_${choice.code}`}
                    disabled={isFieldReadOnly}
                    sx={{
                      whiteSpace: 'nowrap',
                      backgroundColor: (theme) => theme.customColors.grey1150,
                      width: '120px',
                      height: '44px',
                      mb: 1,
                      '&.MuiToggleButton-root': {
                        py: 1.25,
                        px: 5,
                        color: (theme) => theme.customColors.gunMetal,
                        borderColor: (theme) => theme.customColors.gunMetal,
                        '&.MuiToggleButtonGroup-grouped:not(:first-of-type) ': {
                          borderLeft: (theme) => `1px solid ${theme.customColors.gunMetal}`,
                          ml: 0,
                          borderRadius: '2px',
                        },
                        '&.MuiToggleButtonGroup-grouped:not(:last-of-type) ': {
                          borderLeft: (theme) => `1px solid ${theme.customColors.gunMetal}`,
                          ml: 0,
                          borderRadius: '2px',
                        },
                        '&.Mui-selected': {
                          border: '1px solid !important',
                          borderColor: (theme) => ` ${theme.customColors.activeCheckbox}!important`,
                        },
                      },
                    }}
                    value={choice.code}
                    key={choice.code}
                    aria-label={choice.name}
                  >
                    <Radio
                      checked={state[field.code] === choice.code}
                      size="small"
                      disableRipple
                      sx={{
                        fontSize: 15,
                        width: 15,
                        height: 15,
                        mr: 1,
                        '&:hover': {
                          background: 'none',
                        },
                        color:
                          state[field.code] === choice.code
                            ? (theme) => theme.customColors.copper
                            : (theme) => theme.customColors.gunMetal,
                        '&.Mui-checked': {
                          color:
                            state[field.code] === choice.code
                              ? (theme) => theme.customColors.copper
                              : (theme) => theme.customColors.gunMetal,
                        },
                      }}
                    />

                    <Typography
                      sx={{
                        fontWeight: 500,
                        fontSize: '16px',
                        lineHeight: '24px',
                        textTransform: 'none',
                      }}
                    >
                      {Number.isNaN(Number(choice.name)) ? (
                        choice.name
                      ) : field.additional_data?.withDollarIcon ? (
                        `${currencyFormat('USD', choice.name!).merged}`
                      ) : (
                        <NumberFormatComponent displayType="text" value={choice.name} />
                      )}
                    </Typography>
                  </ToggleButton>
                ))}
              </ToggleButtonGroup>
              <FormHelperText
                sx={{
                  fontSize: '12px',
                  lineHeight: '14px',
                  color: (theme) => theme.customColors.alert,
                }}
                error={formik.touched[field.code] && Boolean(formik.errors[field.code])}
              >
                {formik.touched[field.code] && Boolean(formik.errors[field.code])
                  ? formik.errors[field.code]
                  : null}
              </FormHelperText>
            </FormControl>
          );

        case dataFieldTypes.LABEL:
          return (
            <Box
              sx={{
                display: 'flex',
                justifyContent: 'space-between',
                flex: 1,
              }}
              key={field.code}
            >
              <>
                <Typography
                  sx={{
                    fontSize: 14,
                    lineHeight: '21px',
                    fontWeight: 500,
                  }}
                >
                  {field?.name}
                </Typography>
                <Typography
                  sx={{
                    fontSize: 14,
                    lineHeight: '21px',
                    fontWeight: 400,
                  }}
                >
                  {state[field.code] ?? ''}
                </Typography>
              </>
            </Box>
          );

        case dataFieldTypes.CHECKBOX:
          return (
            <FormControl
              variant="standard"
              sx={{
                maxHeight: '21px',
                width: '100%',
                maxWidth: '480px',
                display: 'inline-flex',
                flexDirection: 'row',
                alignItems: 'center',
              }}
              key={field.code}
            >
              {field.additional_data?.asYesNo ? (
                <Checkbox
                  data-test={`${testPrefix}`}
                  sx={field?.is_readonly || isReadOnly ? readOnlyCheckboxStyle : checkboxStyle}
                  id={field.name}
                  name={field.name}
                  checked={state[field.code] === 'Yes' ?? false}
                  onChange={(event) => {
                    if (!field?.is_readonly && !isReadOnly) {
                      const value = event.target.checked ? 'Yes' : 'No';
                      setState?.((prevState) => ({ ...prevState, [field.code]: value }));
                      formik.setFieldValue([field.code], value);

                      checkEmitIsChanged({
                        fieldName: field.code,
                        value,
                      });
                    }
                  }}
                  inputProps={{
                    readOnly: isReadOnly,
                  }}
                />
              ) : (
                <Checkbox
                  data-test={`${testPrefix}`}
                  sx={field?.is_readonly || isReadOnly ? readOnlyCheckboxStyle : checkboxStyle}
                  id={field.name}
                  name={field.name}
                  checked={state[field.code] ?? false}
                  onChange={(event) => {
                    if (!field?.is_readonly && !isReadOnly) {
                      const value = event.target.checked;

                      setState?.((prevState) => ({ ...prevState, [field.code]: value }));

                      formik.setFieldValue([field.code], value);

                      checkEmitIsChanged({
                        fieldName: field.code,
                        value,
                      });
                    }
                  }}
                  inputProps={{
                    readOnly: isReadOnly,
                  }}
                />
              )}
              <Typography
                sx={{
                  fontSize: 14,
                  color: (theme) => theme.customColors.black,
                  lineHeight: '21px',
                  fontWeight: 400,
                  ml: 2,
                }}
              >
                {field.name}
              </Typography>
            </FormControl>
          );

        case dataFieldTypes.MULTISELECT:
          return (
            <MultiselectChekboxes
              setState={setState}
              options={field.choices!}
              field={field}
              state={state}
              formik={formik}
              isReadOnly={isFieldReadOnly}
            />
          );

        default:
      }
    };

    const renderField = (field) => (
      <React.Fragment key={field.code + field.type}>
        {/* Heading */}
        {(field.heading ||
          field.additional_data?.section_heading ||
          field.newline ||
          field.additional_data?.space_above) && (
          <Grid
            item
            key={`${field.code}_header`}
            xs={splitSize}
            sx={[
              field.newline ? { p: '0 !important' } : {},
              field.additional_data?.space_above ? { mt: 1.5 } : {},
            ]}
          >
            {field.heading && (
              <Typography
                sx={{
                  fontWeight: 700,
                  mt: 1.5,
                  fontSize: '20px',
                  lineHeight: 1.5,
                }}
              >
                {field.heading}
              </Typography>
            )}

            {field.additional_data?.section_heading && (
              <Typography
                sx={{
                  mt: 1,
                  mb: -0.5,
                  fontWeight: 700,
                  fontSize: '20px',
                  lineHeight: 1.5,
                  color: (theme) => theme.customColors.black,
                }}
              >
                {field.additional_data.section_heading}
              </Typography>
            )}
          </Grid>
        )}

        {field?.question_text && (
          <Grid item xs={3}>
            <Typography
              sx={{
                position: 'relative',
                mb: -0.5,
                fontWeight: 400,
                fontSize: '14px',
                lineHeight: '21px',
                color: (theme) => theme.customColors.black,
                wordBreak: 'break-word',
                maxWidth: '760px',
              }}
            >
              {field?.question_text} {!field?.is_optional && '*'}
              {field.tooltip && (
                <Tooltip arrow title={field?.tooltip} placement="bottom" enterTouchDelay={0}>
                  <Info
                    sx={{
                      cursor: 'help',
                      fontSize: '24px',
                      alignSelf: 'end',
                      position: 'absolute',
                      // top: 0,
                      bottom: 0,
                      ml: 0.5,
                      mt: '-6px',
                      color: (theme) => theme.customColors.primary.buttonBg,
                    }}
                  />
                </Tooltip>
              )}
            </Typography>
          </Grid>
        )}

        <Grid item key={field.code + field.type} xs={3} sx={[...(Array.isArray(sx) ? sx : [sx])]}>
          {handleFieldType(field)}
        </Grid>
      </React.Fragment>
    );

    useEffect(() => {
      const diff = getSymmetricDiffrence(nonVisibleFieldCodes, prevNonVisibleFieldCodes ?? []);

      if (diff?.length) {
        setState?.((prevState) => omit(prevState, nonVisibleFieldCodes));
        formik?.setValues(omit(formik?.values, nonVisibleFieldCodes));
      }
    }, [JSON.stringify(nonVisibleFieldCodes)]);

    /**
     * Only re-render if relational fields got updated via
     * State change, or error, or its readonly changed
     */
    const memoizedRender = useMemo(
      () => visibleFields.map((field) => renderField(field)),
      [visibleFields, relationalError, isReadOnly],
    );

    return (
      <>
        {showLoader ? (
          [...Array(7).keys()].map((v) => (
            <Stack
              key={`skel${v}`}
              gap={2}
              sx={{
                width: 1,
                flexDirection: {
                  xs: 'column',
                  sm: 'row',
                },
                mb: 2,
              }}
            >
              <FormControl variant="standard" sx={{ flex: 1, maxWidth: '760px' }}>
                <Skeleton animation="wave" width="100%" height={37} />
              </FormControl>
            </Stack>
          ))
        ) : !isEmpty(fields) ? (
          <Grid
            sx={{ alignItems: 'flex-start', maxWidth: '100%' }}
            container
            rowSpacing={rowSpacing}
            columnSpacing={columnSpacing}
            columns={splitSize}
          >
            {memoizedRender}
          </Grid>
        ) : (
          <></>
        )}
      </>
    );
  },
);

export default memo(QuestionEngineFieldParser);
