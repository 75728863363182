import { IUserContextType } from 'providers/UserProvider/types';
import { UserContext } from 'providers/UserProvider/UserProviderContext';
import { useContext } from 'react';

export default function useUser(): IUserContextType {
  const context = useContext(UserContext);

  /* istanbul ignore if */
  if (!context) {
    throw new Error('useUser must be used within a UserProvider');
  }

  return context;
}
