/* eslint-disable react/no-unused-prop-types */
import { KeyboardArrowDownRounded } from '@mui/icons-material';
import {
  FormControl,
  FormHelperText,
  InputLabel,
  Skeleton,
  Stack,
  TextField,
  Typography,
} from '@mui/material';
import CalendarSchedule from 'assets/images/CalendarSchedule.svg';
import { defaultDateFormat, userRoles } from 'common/constants';
import CustomNativeSelect from 'components/CustomNativeSelect';
import DatePickerComponent from 'components/DatePickerComponent';
import PrimaryIndustrySearchInput from 'components/PrimaryIndustrySearchInput';
import { addDays, isBefore, isSameDay, parse, startOfDay, subDays } from 'date-fns';
import { useFormik } from 'formik';
import { findStartAndEndDateOfAFiling } from 'helpers/FilingSetId';
import {
  calendarIconStyle,
  readOnlyInputStyle,
  readOnlySelectInputStyle,
  tabTitleStyles,
} from 'helpers/MuiSharedStyles';
import ScrollToFormikError from 'helpers/ScrollToFormikError';
import { fieldHasValue, getProductEffectiveDateRange } from 'helpers/Utils';
import useConfig from 'hooks/useConfig';
import useUser from 'hooks/useUser/useUser';
import React, { forwardRef, useEffect, useImperativeHandle, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { ReactSVG } from 'react-svg';
import * as yup from 'yup';

interface IProps {
  parentFormik: any;
  showLoader?: boolean;
  state?: any;
  setState?: any;
  isReadOnly?: boolean;
  mustNotDisableSelectedDate?: boolean;
  isEndorsement?: boolean;
  onKeywordChange?: (val: any, oldVal: any) => void;
}

const today = new Date();

const Eligibility = forwardRef<any, IProps>(
  (
    {
      parentFormik,
      state,
      setState,
      isReadOnly = false,
      mustNotDisableSelectedDate = false,
      isEndorsement = false,
      onKeywordChange,
    },
    ref,
  ) => {
    const { t } = useTranslation();
    const {
      convertZonedTimeToUtc,
      products,
      locFilingIdTable,
      formatDateInTimeZone,
      timezoneConfig,
    } = useConfig();

    const { data: user } = useUser();
    const timezone = timezoneConfig?.data?.code;
    const isProducer = user?.role?.code === userRoles.AGENT.code;
    const isUnderwriter = user?.role?.code === userRoles.UNDERWRITER.code;

    // Is defaulted to tomorrow (today + 1 day)
    const tomorrow = convertZonedTimeToUtc(addDays(startOfDay(today), 1));
    const selectedProduct = useMemo(
      () => products?.data?.find((p) => p.code === state?.product) ?? {},
      [state.product, products],
    );
    const selectedProductState = selectedProduct.states?.find((st) => st.code === state.pol_state);

    const [effectiveDateRange, setEffectiveDateRange] = useState({
      minEffectiveDate: new Date(),
      maxEffectiveDate: new Date(),
      productEffectiveDate: new Date(),
    });

    const {
      minEffectiveDate,
      maxEffectiveDate: primaryMaxEffectiveDate,
      productEffectiveDate,
    } = effectiveDateRange;

    const [secondaryMaxEffectiveDate, setSecondaryMaxEffectiveDate] = useState<any>(undefined);

    // compare primaryMaxEffectiveDate and secondaryMaxEffectiveDate and return the earliest date
    const earliestMaxEffectiveDate = secondaryMaxEffectiveDate
      ? isBefore(primaryMaxEffectiveDate, secondaryMaxEffectiveDate)
        ? primaryMaxEffectiveDate
        : secondaryMaxEffectiveDate
      : primaryMaxEffectiveDate;

    useEffect(() => {
      if (selectedProductState?.effective_date) {
        setEffectiveDateRange(
          getProductEffectiveDateRange(selectedProductState.effective_date, timezone),
        );
      }
    }, [selectedProductState?.effective_date]);

    useEffect(() => {
      const ExpirationDate = findStartAndEndDateOfAFiling(
        locFilingIdTable?.data!,
        state.pol_state,
        state?.effective_date,
      )?.ExpirationDate;

      setSecondaryMaxEffectiveDate(
        fieldHasValue(ExpirationDate) && !mustNotDisableSelectedDate
          ? convertZonedTimeToUtc(subDays(parse(ExpirationDate!, 'yyyy-MM-dd', new Date()), 1))
          : undefined,
      );
    }, []);

    const validationSchema = yup.object({
      product: yup.string().required('This field cannot be blank.'),
      pol_state: yup.string().required('This field cannot be blank.'),
      pol_tax_keyword: yup.string().required('This field cannot be blank.'),
      effective_date: yup
        .date()
        .nullable()
        .min(
          productEffectiveDate,
          t('The Policy Effective Date cannot be earlier than {{formattedMinEffectiveDate}}.', {
            formattedMinEffectiveDate: formatDateInTimeZone(productEffectiveDate),
          }),
        )
        .when({
          is: () => !isEndorsement && isProducer,
          then: yup
            .date()
            .min(
              minEffectiveDate,
              t('The Policy Effective Date cannot be earlier than {{formattedMinEffectiveDate}}.', {
                formattedMinEffectiveDate: formatDateInTimeZone(minEffectiveDate),
              }),
            )
            .max(
              earliestMaxEffectiveDate,
              t('The Policy Effective Date cannot be later than {{dateFieldName1}}.', {
                dateFieldName1: formatDateInTimeZone(earliestMaxEffectiveDate),
              }),
            ),
        })
        .typeError(t('Invalid date format.'))
        .required('This field cannot be blank.'),
    });

    const formik = useFormik({
      initialValues: {
        product: '',
        pol_state: '',
        effective_date: tomorrow,
        pol_tax_keyword: '',
        ...state,
      },
      validationSchema,
      onSubmit: (_values) => {},
    });

    useEffect(() => {
      formik.setValues({ ...(formik.values ?? {}), ...(state ?? {}) });
    }, [state]);

    useImperativeHandle(ref, () => ({
      validateFormik: async () => {
        await formik.submitForm();
        const errors = await formik.validateForm();

        return errors;
      },
    }));

    const isEffectiveDisabled = isReadOnly || isEndorsement;

    return (
      <Stack display="flex" flexDirection="column" gap={1.5} sx={{ maxWidth: '480px', mb: 3 }}>
        <ScrollToFormikError formik={formik} />

        <Typography sx={[tabTitleStyles]}>{t('Eligibility')}</Typography>

        <FormControl size="small" variant="standard" fullWidth sx={{ ...calendarIconStyle }}>
          <DatePickerComponent
            format={defaultDateFormat}
            onChange={(newValue) => {
              formik.setFieldValue('effective_date', newValue);
              parentFormik.setFieldValue('effective_date', newValue);

              setState?.((prevState) => ({ ...prevState, effective_date: newValue }));
            }}
            value={state.effective_date}
            label={t('Policy Effective Date')}
            shouldDisableDate={(date) => {
              let disable = false;
              if (isProducer && isBefore(new Date(date!), startOfDay(tomorrow))) {
                disable = true;
              }

              // Disable the selected date if it is before the minEffectiveDate
              if (isProducer && isBefore(new Date(date!), minEffectiveDate)) {
                disable = true;
              }

              if (
                mustNotDisableSelectedDate &&
                isSameDay(new Date(date!), new Date(state?.effective_date!))
              ) {
                disable = false;
              }

              return disable;
            }}
            maxDate={isProducer && earliestMaxEffectiveDate}
            readOnly={isEffectiveDisabled}
            slots={{
              openPickerIcon: () => <ReactSVG className="calendar-icon" src={CalendarSchedule} />,
            }}
            slotProps={{
              textField: {
                autoComplete: 'off',
                required: true,
                sx: [isEffectiveDisabled ? readOnlyInputStyle : {}],
                InputLabelProps: isEffectiveDisabled ? { shrink: true } : {},
                inputProps: {
                  autoComplete: 'new-password',
                  ...(isEffectiveDisabled && {
                    readOnly: true,
                  }),
                },
                error: formik.touched.effective_date && Boolean(formik.errors.effective_date),
                helperText: formik.touched.effective_date && formik.errors.effective_date,
                size: 'small',
              },
            }}
          />
        </FormControl>

        <FormControl required size="small" fullWidth>
          <PrimaryIndustrySearchInput
            fieldName="pol_tax_keyword"
            formik={formik}
            state={state}
            setState={setState}
            isReadOnly={isReadOnly}
            onChange={onKeywordChange}
          />
        </FormControl>

        {isUnderwriter && (
          <FormControl required size="small" fullWidth>
            <TextField
              autoComplete="off"
              required
              value={state.pol_tax_naics}
              name="pol_tax_naics"
              sx={readOnlyInputStyle}
              label={t('NAICS Code')}
              inputProps={{
                autoComplete: 'new-password',
                readOnly: true,
              }}
              error={formik.touched.pol_tax_naics && Boolean(formik.errors.pol_tax_naics)}
              helperText={formik.touched.pol_tax_naics && formik.errors.pol_tax_naics}
              size="small"
            />
          </FormControl>
        )}

        <FormControl
          error={formik.touched.pol_state && Boolean(formik.errors.pol_state)}
          required
          size="small"
          fullWidth
          sx={[readOnlySelectInputStyle]}
        >
          {products.loading ? (
            <Skeleton animation="wave" width="100%" height={37} />
          ) : (
            <>
              <InputLabel id="policy-issue-state" shrink>
                {t('Policy Issue State')}
              </InputLabel>

              <CustomNativeSelect
                value={state.pol_state ?? ''}
                label="Policy Issue State"
                name="pol_state"
                error={formik.touched.pol_state && Boolean(formik.errors.pol_state)}
                onChange={(e) => {
                  formik.handleChange(e);
                  parentFormik.handleChange(e);

                  setState?.((prevState) => ({ ...prevState, pol_state: e?.target?.value }));
                }}
                IconComponent={KeyboardArrowDownRounded}
                inputProps={{
                  autoComplete: 'off',
                  readOnly: true,
                }}
              >
                {selectedProduct.states?.map((s) => (
                  <option key={s.code} value={s.code}>
                    {s.name}
                  </option>
                ))}
              </CustomNativeSelect>

              {formik.touched.pol_state && formik.errors.pol_state && (
                <FormHelperText
                  sx={{
                    mt: 1,
                    fontSize: '12px',
                    lineHeight: '14px',
                    color: (theme) => theme.customColors.alert,
                  }}
                >
                  {formik.errors.pol_state}
                </FormHelperText>
              )}
            </>
          )}
        </FormControl>
      </Stack>
    );
  },
);

export default Eligibility;
