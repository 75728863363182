import ApiService from 'api/helpers/Sender';
import { IGenericIntegrationResponse } from 'api/models/Integrations/IGenericResponse';
import { INCCIResponse } from 'api/models/Integrations/NCCI/INCCIResponse.model';
import { IGetModFeinQueries } from 'api/models/Integrations/NCCI/NCCI.model';

const API = new ApiService();

export const getModByFeinNcci = async (query: IGetModFeinQueries): Promise<INCCIResponse> => {
  const { data }: { data: IGenericIntegrationResponse<INCCIResponse> } = await API.get(
    `services/ncci/get-mod-fein`,
    query as any,
  );

  return data?.data ?? {};
};
