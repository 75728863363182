import { IAxiosRequestConfig } from 'api/helpers/Sender';
import { CacheOptions } from 'axios-cache-interceptor';

export interface ICacheOptions {
  axiosOptions?: IAxiosRequestConfig;
  cache?: CacheOptions;
  id?: string;
}

export const cachePrefix = 'axios-cache:';

export const cacheIds = {
  userInfo: 'userInfo',
  taskQueueList: 'taskQueueList',
};

export const prepareCacheOptions = (options?: ICacheOptions) => ({
  ...(options?.axiosOptions ?? {}),
  id: options?.id,
  cache: {
    methods: options?.cache?.methods ?? ['get'],
    ttl: options?.cache?.ttl ?? 86400000, // 24 hours default
  },
});
