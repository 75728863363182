import useRouterPrompt from 'hooks/useRouterPrompt';
import { useEffect, useRef } from 'react';
import { useHistory } from 'react-router-dom';

/*
 * Return truthy if you wish to block. Empty return or false will not block
 */
export const useBlockRoute = (func: any) => {
  const { block, push, location } = useHistory();
  const { setBypassRouteBlock, bypassRouteBlock } = useRouterPrompt();
  const lastLocation: any = useRef();

  const funcRef: any = useRef();
  funcRef.current = func;

  useEffect(() => () => setBypassRouteBlock(false), []);

  useEffect(() => {
    if ((location === lastLocation.current || !funcRef.current) && !bypassRouteBlock) return;

    lastLocation.current = location;

    const unblock = block((l, action) => {
      const doBlock = async () => {
        if (bypassRouteBlock || !(await funcRef.current?.(l, action))) {
          unblock();
          push(l);
        }
      };

      doBlock();
      return false;
    });
  }, [location, bypassRouteBlock]);
};
