import ApiService, { Params } from 'api/helpers/Sender';
import { IGenericIntegrationResponse } from 'api/models/Integrations/IGenericResponse';
import { IGetModFeinQueries } from 'api/models/Integrations/PCRB/PCRB.model';
import { PCRBResponse } from 'api/models/Integrations/PCRB/PCRBResponse.model';

const API = new ApiService();

export const getModByFeinPcrb = async (query: IGetModFeinQueries): Promise<PCRBResponse> => {
  const { data }: { data: IGenericIntegrationResponse<PCRBResponse> } = await API.post(
    'pcrb/get-by-fein',
    query as unknown as Params,
  );

  return data?.data ?? {};
};
