/* eslint-disable no-shadow */

/* see: https://pe.usps.com/text/pub28/28apc_002.htm */

export enum CommonStreetAbbrs {
  AVENUE = 'Ave',
  BOULEVARD = 'Blvd',
  COURT = 'Ct',
  DRIVE = 'Dr',
  LANE = 'Ln',
  PLACE = 'Pl',
  ROAD = 'Rd',
  STREET = 'St',
  TRAIL = 'Trl',
}
