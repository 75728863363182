import { IColumns } from 'api/models/DynamicTableColum/IColumns.model';
import { t } from 'i18next';

export const requiredEnvVars = [
  'REACT_APP_BACKEND_MAIN_API_URL',
  'REACT_APP_AUTH0_DOMAIN',
  'REACT_APP_AUTH0_CLIENT_ID',
];

export const environmentChips = {
  development: 'DEV',
  qa: 'TEST',
  uat: 'UAT',
};

export const dataFieldTypes = {
  DATE: 'date',
  EMAIL: 'email',
  MEDIA: 'media',
  NUMBER: 'number',
  STRING: 'string',
  BOOLEAN: 'boolean',
  SELECT: 'select',
  GROUP: 'group',
  CURRENCY: 'currency',
  ACTION: 'action_menu',
  AUTOCOMPLETE: 'autocomplete',
  SMARTY: 'smarty',
  PHONE: 'phone',
  SELECT_BUTTON: 'select_button',
  LABEL: 'label',
  CHECKBOX: 'checkbox',
  CHECKBOX_GROUP: 'checkbox_group',
  MULTISELECT: 'multiselect',
  PERCENTAGE: 'percentage',
  YEAR: 'year',
  RADIO_BUTTON: 'radio',
} as const;

export const errorCodes = {
  UNDERWRITING_QUESTIONS: 1090,
  MISSING_EXPOSURES_OR_FIELD_GROUPS: 1080,
  SUBMISSION_HAS_ALREADY_FINALIZED: 1016,
};

export const timelineItemStatus = {
  DONE: 'done',
  EMPTY: 'empty',
  ACTIVE: 'active',
} as const;

export const timelineItems = {
  PRELIMINARY_INFORMATION: 'preliminary-information',
  POLICY_INFORMATION: 'policy-information',
  EXPOSURE_INFORMATION: 'exposure-information',
  COVERAGES: 'coverages',
  QUOTE_SUMMARY: 'quote-summary',
} as const;

export const notificationTypes = {
  ERROR: 'ERROR' as const,
  SUCCESS: 'SUCCESS' as const,
  INFO: 'INFO' as const,
  WARNING: 'WARNING' as const,
};

export const underWritingQuestionsPageNames = {
  Business_Info: 'Business Info',
  Operations: 'Operations',
  'Vehicle Details': 'Vehicle Details',
};

export const globalSearchTypes = {
  POLICY: 'policy',
  QUOTE: 'quote',
} as const;

export const advancedSearchTypes = {
  ACCOUNT: 'account',
  AGENCIES: 'agencies',
  AGENCY: 'agency',
  AGENT: 'agent',
  AGENTS: 'agents',
  CLAIM: 'claim',
  POLICY: 'policy',
  POLICYHOLDER: 'policyholder',
  SUBMISSIONS: 'submission',
  USER: 'user',
  QUOTE: 'quote',
} as const;

export const supportedLocales = ['en'];
export const defaultCurrency = 'USD';

export const userRoles = {
  AGENT: {
    id: 1,
    code: 'agent',
    name: (): string => t('Producer'),
  },
  UNDERWRITER: {
    id: 8,
    code: 'underwriter',
    name: (): string => t('Underwriter'),
  },
};

export const taskStatuses = {
  OPEN: {
    key: 'open',
    title: (): string => t('Open'),
  },
  CLOSED: {
    key: 'closed',
    title: (): string => t('Closed'),
  },
};

export const taskPriorities = {
  NORMAL: {
    key: '20',
    title: (): string => t('Normal'),
  },
  QUALIFIED_RUSH: {
    key: '30',
    title: (): string => t('Qualified Rush'),
  },
};

export const passwordRules = [
  (): string => t('Password must have at least {{charLimit}} characters', { charLimit: 8 }),
  (): string => t('Include at least one lowercase letter (a-z)'),
  (): string => t('Include at least one uppercase letter (A-Z)'),
  (): string => t('Include at least one number (0-9)'),
];

export const quoteStatuses = {
  DRAFT: 'draft',
  QUOTED: 'quoted',
  ACCEPTED: 'accepted',
  DECLINED: 'declined',
  DISCARDED: 'discarded',
  REFERRED: 'referred',
  EXPIRED: 'expired',
  DECLINED_BY_UW: 'declined_by_uw',
  IN_MORATORIUM: 'in_moratorium',
} as const;

export const policiesListStatuses = {
  ACTIVE: 'active',
  INACTIVE: 'inactive',
  CANCELLED: 'cancelled',
  IN_CANCELLATION: 'in_cancellation',
} as const;

export const defaultDateFormat = 'MM/dd/yyyy';
export const defaultDateTimeFormat = 'hh:mm:ss aaa';
export const defaultTimezone = 'America/New_York';

export const defaultDateFormatMask = '__/__/____';

export const cancellationReasons = {
  INSUREDS_REQUEST: 'insureds_request',
  NON_COMPLIANCE: 'non_compliance',
  NON_PAYMENT: 'non_payment',
};

export const policyDetailStatuses = {
  NON_ISSUED: 'non_issued',
  ACTIVE: 'active', // active policy may have a modifier status of 'in_cancellation'
  INACTIVE: 'inactive',
  CANCELLED: 'cancelled',
  EXPIRED: 'expired',
  IN_CANCELLATION: 'in_cancellation',
} as const;

export const policyInvoiceStatuses = {
  SETTLED: 'settled',
  OUTSTANDING: 'outstanding',
  PAID: 'paid',
  WRITTEN_OFF: 'writtenOff',
  ZERO_DUE: 'zeroDue',
  INVALIDATED: 'invalidated',
  CARRIED_FORWARD: 'carriedForward',
} as const;

export const policyDetailEndorsementStatuses = {
  ISSUED: 'issued',
  INVALIDATED: 'invalidated',
  ACCEPTED: 'accepted',
  QUOTED: 'quoted',
  APPLICATION: 'application',
  DISCARDED: 'discarded',
  REFERRED: 'referred',
  DECLINED_BY_UW: 'declined_by_uw',
  DECLINED: 'declined',
} as const;

export const phoneNumberRegex = /^(\+\d{1,2}\s)?\(?\d{3}\)?[\s.-]\d{3}[\s.-]\d{4}$/;

export const validURLRegex =
  /((https?):\/\/)?(www.)?[a-z0-9]+(\.[a-z]{2,}){1,3}(#?\/?[a-zA-Z0-9#]+)*\/?(\?[a-zA-Z0-9-_]+=[a-zA-Z0-9-%]+&?)?$/;

export const commaRegex = /,/g;

export const nonCommaRegex = /^[^,]+$/g;

export const alphaNumericRegex = /^[a-zA-Z0-9]+$/g;

export const nonAlphaNumericRegex = /[^a-zA-Z0-9]/g;

export const titleCharacterLimit = 255;

export const descriptionCharacterLimit = 4000;

export const defaultRowVirtualization = 100;

export const defaultRowTransactionList = 5;

export const submissionDetailInfoTabs = {
  BUSINESS_INFO: {
    code: 'business_info',
    name: () => t('Business Info'),
    index: 0,
    disabled: false,
    hidden: false,
    nextTabWithWC: 'operations',
    nextTabWithoutWC: 'operations',
  },
  OPERATIONS: {
    code: 'operations',
    name: () => t('Operations'),
    index: 1,
    disabled: false,
    hidden: false,
    prevTab: 'business_info',
    nextTabWithWC: 'locations',
    nextTabWithoutWC: 'locations',
    producer_NextTab: 'operations_uw_results',
  },
  OPERATIONS_UW: {
    code: 'operations_uw_results',
    name: () => t('UW Results'),
    index: 2,
    disabled: false,
    hidden: true,
  },
  LOCATIONS: {
    code: 'locations',
    name: () => t('Locations'),
    index: 3,
    disabled: false,
    hidden: false,
    prevTab: 'operations',
    nextTabWithWC: 'owners_members',
    nextTabWithoutWC: 'owners_members',
  },
  OWNERS_MEMBERS: {
    code: 'owners_members',
    name: () => t('Owners/Members'),
    index: 4,
    disabled: false,
    hidden: false,
    prevTab: 'locations',
    nextTabWithWC: 'property_exposures',
    nextTabWithoutWC: 'property_exposures',
  },
  LIABILITY_EXPOSURES: {
    code: 'liability_exposures',
    name: () => t('Liability Exposures'),
    index: 5,
    disabled: false,
    hidden: true,
  },
  PROPERTY_EXPOSURES: {
    code: 'property_exposures',
    name: () => t('Premises'),
    index: 6,
    disabled: false,
    hidden: false,
    prevTab: 'owners_members',
    nextTabWithWC: 'wc_exposures',
    nextTabWithoutWC: 'auto_exposures',
  },
  WC_EXPOSURES: {
    code: 'wc_exposures',
    name: () => t('Workers’ Comp'),
    index: 7,
    disabled: false,
    hidden: true,
    prevTab: 'property_exposures',
    nextTabWithWC: 'auto_exposures',
    nextTabWithoutWC: 'auto_exposures',
  },
  AUTO_EXPOSURES: {
    code: 'auto_exposures',
    name: () => t('Vehicles'),
    index: 8,
    disabled: false,
    hidden: false,
    prevTabWithWC: 'wc_exposures',
    prevTab: 'property_exposures',
    nextTabWithWC: 'drivers',
    nextTabWithoutWC: 'drivers',
    nextTabWithNoDrivers: 'claims_history',
  },
  DRIVERS: {
    code: 'drivers',
    name: () => t('Drivers'),
    index: 9,
    disabled: false,
    hidden: false,
    prevTab: 'auto_exposures',
    nextTabWithWC: 'claims_history',
    nextTabWithoutWC: 'claims_history',
  },
  CLAIMS_HISTORY: {
    code: 'claims_history',
    name: () => t('Claims History'),
    index: 10,
    disabled: false,
    hidden: false,
    prevTab: 'drivers',
    prevTabWithNoDrivers: 'auto_exposures',
    nextTabWithWC: 'additional_interests',
    nextTabWithoutWC: 'additional_interests',
  },
  ADDITIONAL_INTEREST: {
    code: 'additional_interests',
    name: () => t('Additional Interests'),
    index: 11,
    disabled: false,
    hidden: false,
    prevTab: 'claims_history',
    nextTabWithWC: 'uw_results',
    nextTabWithoutWC: 'uw_results',
  },
  UW_RESULTS: {
    code: 'uw_results',
    name: () => t('UW Results'),
    index: 12,
    disabled: false,
    hidden: false,
    prevTab: 'additional_interests',
    nextTabWithWC: 'summary_pricing',
    nextTabWithoutWC: 'summary_pricing',
  },
  SUMMARY_PRICING: {
    code: 'summary_pricing',
    name: () => t('Coverage Summary'),
    index: 13,
    disabled: false,
    hidden: false,
    prevTab: 'uw_results',
    nextTabWithWC: 'pricing_breakdown',
    nextTabWithoutWC: 'pricing_breakdown',
  },
  PRICING_BREAKDOWN: {
    code: 'pricing_breakdown',
    name: () => t('Pricing Breakdown'),
    index: 14,
    disabled: false,
    hidden: false,
  },
};

export const subTabsList = [submissionDetailInfoTabs.OPERATIONS_UW.code];

export const quotePolicyEndorsementInfoTitlesDescriptions = {
  OWNERS_MEMBERS: {
    description: () =>
      t(
        'This includes President, Vice President, and/or other officer titles that were officially appointed or approved by ownership or the Board of Directors to manage or perform daily operations.',
      ),
  },
  PROPERTY_EXPOSURES: {
    title: () => t('List all premises where business operates'),
    description: () =>
      t(
        'Record insured values at every premises where the business operates. You may record more than one Building/Premises at each location.',
      ),
  },
  WC_EXPOSURES: {
    title: () => t('Workers Compensation Class Codes'),
    description: () => t('Allocate payroll across class codes.'),
  },
  INDIVIDUAL_OWNERS_OFFICERS: {
    title: () => t('Individual Owners/Officers'),
    description: () =>
      t(
        'Select each owner/officer and assign to payroll classification or mark as excluded from Workers Compensation coverage.',
      ),
  },
  AUTO_EXPOSURES: {
    title: () => t('Vehicles'),
    description: () => t('Record all vehicles owned, leased, or regularly used by the business.'),
  },
  DRIVERS: {
    title: () => t('Drivers'),
    description: () =>
      t(
        'List all individuals (including owners, employees and subcontractors) that will be driving vehicles scheduled on this policy.',
      ),
  },
  DRIVING_INCIDENTS: {
    title: () => t('Driving Incidents'),
    description: () =>
      t(
        'For each driver, list all accidents and violations within the last 3 years. Do not include parking violations.',
      ),
  },
  CLAIMS_HISTORY: {
    title: () => t('3-Year Claims History'),
    description: () => t('List all claims filed within the last 3 years.'),
  },
  ADDITIONAL_INTEREST: {
    title: () => t('Additional Interests'),
  },
  UW_RESULTS: {
    title: () => t('Underwriting Results'),
  },
  SUMMARY_PRICING: {
    title: () => t('Summary'),
  },
  PRICING_BREAKDOWN: {
    title: () => t('Pricing Breakdown'),
  },
};

export const policyDetailInfoTabs = {
  ...submissionDetailInfoTabs,
};

export const policyDetailEndorsementTabs = {
  ...submissionDetailInfoTabs,
};

export const THREE_EndorsementTypes = {
  GENERIC: 'generic',
  ADDITIONAL_INTEREST: 'change.additionalInterest',
  WC_AUDIT: 'wc_audit',
} as const;

export const threeExposureNames = {
  LOCATION: 'location',
  BUILDING: 'building',
  VEHICLE: 'vehicle',
  EMPLOYER: 'employer',
  POLICY_INFORMATION: 'policyInformation',
} as const;

export const expModWorkFlowName = 'expMod';

export const threeEmployerExposurePerilNames = {
  MANUAL_PREMIUM: 'manual_premium',
  SMALL_DEDUCTIBLE_PROGRAM: 'small_deductible_program',
} as const;

export const LIABILITY_PERILS = 'liability_perils';

export const staticGenerateCertificateDrawerFields = [
  {
    code: 'certificate_holder_name',
    name: 'Certificate Holder Name',
    is_optional: false,
    is_hidden: false,
    is_readonly: false,
    newline: false,
    type: 'string',
  },
  {
    code: 'loc_address_line1',
    name: 'Address Line 1',
    is_optional: false,
    is_hidden: false,
    is_readonly: false,
    newline: false,
    type: 'autocomplete',
    additional_data: {
      relatedFields: [
        {
          configFieldName: 'loc_address_line1',
          smartyFieldName: 'street_line',
        },
        {
          configFieldName: 'loc_address_line2',
          smartyFieldName: 'secondary',
        },
        {
          configFieldName: 'loc_address_city',
          smartyFieldName: 'city',
        },
        {
          configFieldName: 'loc_address_state',
          smartyFieldName: 'state',
        },
        {
          configFieldName: 'loc_address_zip',
          smartyFieldName: 'zipcode',
        },
      ],
    },
    service_bridge: {
      name: 'smarty',
    },
  },
  {
    code: 'loc_address_isvalidated',
    name: 'Adress is validated',
    is_optional: true,
    is_hidden: true,
    is_readonly: false,
    newline: false,
    type: 'select',
    choices: [
      {
        code: 'Yes',
        name: 'Yes',
      },
      {
        code: 'No',
        name: 'No',
      },
    ],
  },
  {
    code: 'loc_address_line2',
    name: 'Address Line 2',
    is_optional: true,
    is_hidden: false,
    is_readonly: false,
    newline: false,
    type: 'string',
  },
  {
    code: 'loc_address_city',
    name: 'City',
    is_optional: true,
    is_hidden: false,
    is_readonly: false,
    newline: false,
    type: 'string',
  },
  {
    code: 'loc_address_state',
    name: 'State',
    is_optional: true,
    is_hidden: false,
    is_readonly: false,
    newline: false,
    type: 'string',
  },
  {
    code: 'loc_address_zip',
    name: 'Zip Code',
    is_optional: true,
    is_hidden: false,
    is_readonly: false,
    newline: false,
    type: 'string',
  },
  {
    code: 'loc_address_lat',
    name: 'Address Latitude',
    is_optional: true,
    is_hidden: true,
    is_readonly: false,
    newline: false,
    type: 'string',
  },
];

export const threePolicyGroupNames = {
  OWNER: 'pol_oo',
  DRIVERS: 'pol_drivers',
  INCIDENTS: 'pol_incidents',
  ADDITIONAL_INTEREST: 'info_ai',
  CLAIMS: 'pol_claims',
  SCHEDULE_MODIFICATION: 'pol_sched_mod',
} as const;

export const vehicleExposureVinRelatedFields = ['veh_year', 'veh_make', 'veh_model', 'veh_trim'];

export const keyValueTables = {
  SCHED_MOD_TABLE: 'schedule_modification_tables',
  LOC_FILINGS_TABLE: 'loc_filings',
  WC_CLASS_CODE: 'workers_compensation_class_code',
};

export const vehicleExposureRelatedMaps = {
  BODY_CATEGORY: 'vehicleBodyCategory',
  BODY_STYLE_TO_CATEGORY: 'vehicleBodyStyleToCategory',
  MAKE_CD_MAKE_NM: 'vehicleMakeCdmakeNmMapping',
  GCW: 'gcwMapping',
  TBL_GCW: 'tblGCW',
  SCHED_MOD_TABLES: keyValueTables.SCHED_MOD_TABLE,
  TBL_DEPRECIATIOn: 'tblDepreciation',
};

export const vehicleExposureRelatedMappings = Object.values(vehicleExposureRelatedMaps);

export const propertyExposureRelatedKeyValues = {
  CLASS_CODES: 'class-codes',
  SCHED_MOD_TABLES: keyValueTables.SCHED_MOD_TABLE,
};

export const propertyExposureRelatedKeyValuesMap = Object.values(propertyExposureRelatedKeyValues);

export const liabilityExposureRelatedKeyValues = {
  LIABILITY_PERILS: 'liabilityPerilsMapping',
  SCHED_MOD_LINES_PERILS: 'schedModLinesOfPerils',
  SCHED_MOD_TABLES: keyValueTables.SCHED_MOD_TABLE,
  LIABILITY_PERILS_CLASS_CODES_AND_DESCRIPTION:
    'liability_perils_class_code_to_description_mapping',
};

export const liabilityExposureRelatedKeyValuesMap = Object.values(
  liabilityExposureRelatedKeyValues,
);

export const wcExposureRelatedKeyValues = {
  WC_CLASS_CODES: keyValueTables.WC_CLASS_CODE,
  SCHED_MOD_TABLES: keyValueTables.SCHED_MOD_TABLE,
};

export const wcExposureRelatedKeyValuesMap = Object.values(wcExposureRelatedKeyValues);

export const propertyExposureScheduleBuildingOccupanciesKeyValue = {
  BUILDING_LEASE: `Building I lease and am required to insure (e.g. 'Triple Net Lease')`,
  BUILDING_OWN_RUN: 'Building I own and run my business out of',
  BUILDING_OWN_MANAGE: 'Building I own/manage and lease to others',
};

export const propertyExposureScheduleBuildingOccupancies = Object.values(
  propertyExposureScheduleBuildingOccupanciesKeyValue,
);

export const propertyExposureBuildingRelatedFields = [
  'bdg_construction_code',
  'bdg_wind_symbol',
  'bdg_year_built',
  'bdg_sqft',
  'bdg_num_stories',
  'bdg_issprinklered',
  'bdg_protection_class',
];

export const propertyExposureScheduleBuildingValidationsFields = [
  'bdg_erc_building',
  'bdg_rrc_building',
  'bdg_tiv_building',
  'bdg_year_built',
];

export const propertyExposureAffectPremiumCalculationsFields = [
  'bdg_construction_code',
  'bdg_wind_symbol',
  'bdg_year_built',
  'bdg_sqft',
  'bdg_num_stories',
  'bdg_issprinklered',
];

export const propertyExposureScheduleBuildingFields = [
  'bdg_erc_building',
  'bdg_rrc_building',
  'bdg_tiv_building',
];

export const approvePropertyUWReportType = ['BUR', 'PDR', 'BFUR'];

export const genericErrorMessage = (): string =>
  t('Could not complete your request. If the problem persists, contact your support team.');

export const underwritingResultDecisions = {
  APPROVED: { code: 'approved', name: 'Approved' },
  REFERRED: { code: 'referred', name: 'Referred' },
  DECLINED: { code: 'declined', name: 'Declined' },
};

export const vehiclesUwQuestionCode = 'vehicle_details';
export const uwQuestionGroupName = 'underwriting_question';

export const liabilityExposureBasises = {
  'Employee Count': ['employment_practices_liability', 'fiduciary_liability'],
  'Cost of Hire': [
    'cargo_liability',
    'cost_of_hire_physical_damage_collision',
    'cost_of_hire_physical_damage_comprehensive',
    'cost_of_hire_liability',
  ],
  Revenue: [
    'cyber_liability',
    'commercial_auto_terrorism',
    'directors_officers_liability',
    'garagekeepers_liability',
    'general_liability_terrorism',
    'professional_liability',
  ],
  'Liquor Sales': ['general_liability_liquor_liability'],
};

export const liabilityPerilsNamesWithCustomExposureBasis = [
  'general_liability_premises_operations',
  'general_liability_products_completed_operations',
];

export const exposureTypeFields = {
  'Cost of Hire': 'cost_of_hire',
  Revenue: 'revenue',
  'Employee Count': 'ee_count',
  'Liquor Sales': 'alcohol_sales',
  'Lawyer FTE Count': 'lawyer_fte',
};

export const fixedPremiumChargePeril = 'fixed_premium_charge';

export const lookupTableTypes = {
  TERRITORY_LOOKUP: 'territory_lookup',
  LIABILITY_PERILS: 'liability_perils',
};

export const liabilityExposurePerils = {
  commercialAutoTerrorism: {
    code: 'commercial_auto_terrorism',
    title: 'Commercial Auto - Terrorism',
  },
  cyberLiability: {
    code: 'cyber_liability',
    title: 'Cyber Liability',
  },
  directorsOfficersLiability: {
    code: 'directors_officers_liability',
    title: 'Directors & Officers Liability',
  },
  employmentPracticesLiability: {
    code: 'employment_practices_liability',
    title: 'Employment Practices Liability',
  },
  fiduciaryLiability: {
    code: 'fiduciary_liability',
    title: 'Fiduciary Liability',
  },
  garagekeepersLiability: {
    code: 'garagekeepers_liability',
    title: "Garagekeeper's Liability",
  },
  generalLiabilityLiquorLiability: {
    code: 'general_liability_liquor_liability',
    title: 'General Liability - Liquor Liability',
  },
  generalLiabilityTerrorism: {
    code: 'general_liability_terrorism',
    title: 'General Liability - Terrorism',
  },
  glpo: {
    code: 'general_liability_premises_operations',
    name: 'General Liability - Premises/Operations',
  },
  glpcOperations: {
    code: 'general_liability_products_completed_operations',
    title: 'General Liability - Products/Completed Operations',
  },
  professionalLiability: {
    code: 'professional_liability',
    title: 'Professional Liability',
  },
  trailerInterchange: {
    code: 'trailer_interchange',
    title: 'Trailer Interchange',
  },
};

export const professionalLiabilityPerilExposureBasis = {
  REVENUE: 'Revenue',
  LAWYER_FTE_COUNT: 'Lawyer FTE Count',
};

export const liabilityExposuresFieldCodes = {
  perilType: 'peril_type',
  location: 'location',
  classCode: 'class_code',
  classCodeDescription: 'class_code_description',
  hazardGroup: 'hazard_group',
  basis: 'basis',
  divider: 'divider',
  rate: 'rate',
  exposureAmount: 'exposure_amount',
  exposureBasis: 'exposure_basis',
  classCodeOverride: 'class_code_override',
  exposureAmountOverride: 'exposure_amount_override',
  glpoOoCount: 'glpo_oo_count',
  fiduIndicator: 'fidu_indicator',
  fiduEeCount: 'fidu_ee_count',
  cyberLiabilityRevenueCount: 'cy_revenue',
  cyberLiabilityhazardGroup: 'cy_hazard',
  glpcOoCount: 'glpc_oo_count',
  doIndicator: 'do_indicator',
  epliIndicator: 'epli_indicator',
  liqIndicator: 'liq_indicator',
  plIndicator: 'pl_indicator',
  plContractQuality: 'pl_contract_quality',
  plLawyerFte: 'pl_lawyer_fte',
  plLawyerPte: 'pl_lawyer_pte',
};

export const hazardRelatedFieldCodes = [
  'hazard_group',
  'cy_hazard_user',
  'do_hazard_user',
  'epli_hazard_user',
  'pl_hazardgroup_user',
];

export const dividerRateFieldsHiddenPerilCodes = [
  'cyber_liability',
  'employment_practices_liability',
  'professional_liability',
  'directors_officers_liability',
];

export const quoteExtraStatuses = {
  OK: { code: 'ok', tooltip: '' },
  HAS_DECLINE: {
    code: 'has_decline',
    tooltip: 'One or more rules were declined, therefore, Locking the quote is not possible',
  },
  HAS_REFER: { code: 'has_refer', tooltip: 'Underwriting review is pending' },
} as const;

export const endorsementExtraStatuses = {
  OK: { code: 'ok', tooltip: '' },
  HAS_DECLINE: {
    code: 'has_decline',
    tooltip:
      'One or more rules were declined, therefore, Locking the policy changes is not possible',
  },
  HAS_REFER: { code: 'has_refer', tooltip: 'Underwriting review is pending' },
} as const;

export const smodLines = {
  PROPERTY: 'PROP',
  AUTO: 'AUTO',
  EMPLOYER: 'WC',
};

export const MAXIMUM_MODIFICATION = 'Maximum Modification';

export const empXratedOptions = {
  NO_REQUEST: 'No Request',
  SUCCESS_E: '0 Success - E',
  SUCCESS_E_OLD: '0 Success - E - old',
  SUCCESS_D: '0 Success - D',
  SUCCESS_C: '0 Success - C',
  SUCCESS_W: '0 Success - W',
  NOT_FOUND: '1 Data Not Found',
  ERROR_IN_PARAMS: '2 Error In Parameters',
};

export const DOCUMENT_TYPES_FROM = {
  POLICY: 'policy',
  QUOTE: 'quote',
};

export const paginationKeys = ['ordering', 'page', 'page_size', 'search'];

export const uwQuestionAliases = {
  businessName: 'businessName',
  yearFounded: 'yearFounded',
  taxIDType: 'taxIDType',
  fein: 'fein',
  ssn: 'ssn',
};

export const uwTaxOptions = {
  FEIN: 'FEIN',
  SOCIAL_SECURITY_NUMBER: 'Social Security Number',
};

export const acceptedBdgOccupancy = [
  "Building I lease and am required to insure (e.g. 'Triple Net Lease')",
  'Building I own and run my business out of',
  'Building I own/manage and lease to others',
];

export const wcAuditSubTypes = {
  ESTIMATED: { code: 'Estimated', name: 'Estimated' },
  ACTUAL: { code: 'Actual', name: 'Actual' },
  UNCOOPERATIVE: { code: 'Uncooperative', name: 'Uncooperative' },
};

export const wcAuditPolOoFields = {
  FINAL: ['pol_oo_wc_payroll_audit_final'],
  ESTIMATED: ['pol_oo_wc_payroll_audit_est'],
  ORIGINALS: ['pol_oo_wc_payroll'],
};

export const wcAuditEmployerFields = {
  FINAL: [
    'man_payroll_w2_audit_final',
    'man_payroll_sub_audit_final',
    'man_capita_audit_final',
    'owner_payroll_audit_final',
    'total_rated_payroll_audit_final',
  ],
  ESTIMATED: [
    'man_payroll_w2_audit_est',
    'man_payroll_sub_audit_est',
    'man_capita_audit_est',
    'owner_payroll_audit_est',
    'total_rated_payroll_audit_est',
  ],
  ORIGINALS: ['man_payroll_w2', 'man_payroll_sub', 'man_capita'],
};

export const employerManCapitaRealatedFields = [
  'man_capita',
  'man_capita_audit_est',
  'man_capita_audit_final',
];

export const manIsancillaryFieldCode = 'man_isancillary';

export const firstDayOfYear1900 = 'Tue Jan 01 1900 07:00:00 GMT+0300 (GMT+03:00)';

export const LOCATION_DEFAULT_PERIL = 'Location Default Peril';

export const defaultDebounceDelay = 600;

export const firstDayOfYear2024 = 1704085200000;

export const zipCodeLength = 5;

export const billingChipCategories = [
  {
    code: 'all invoices',
    label: 'All Invoices',
  },
  {
    code: 'outstanding',
    label: 'Outstanding',
  },
  {
    code: 'paid',
    label: 'Paid',
  },
  {
    code: 'invalidated',
    label: 'Invalidated',
  },
  {
    code: 'pastDue',
    label: 'Past Due',
  },
  {
    code: 'currentDue',
    label: 'Current Due',
  },
  {
    code: 'scheduled',
    label: 'Scheduled',
  },
  {
    code: 'zeroDue',
    label: 'Zero Due',
  },
  {
    code: 'carriedForward',
    label: 'Carried Forward',
  },
  {
    code: 'writtenOff',
    label: 'Written Off',
  },
];

export const selectionChoices = {
  INCLUDED: 'Included',
  FLAT: 'Flat',
  NO: 'No',
  YES: 'Yes',
};

export const productCodes = {
  THREE_WITH_WORKERS_COMPENSATION: 'three_with_workers_compensation',
  THREE_WITHOUT_WORKERS_COMPENSATION: 'three_without_workers_compensation',
};

export const TAB_POSITIONS = {
  PREVIOUS: 'previous',
  NEXT: 'next',
};

export const LEXIS_NEXIS_SCORE_TYPES = {
  B505: 'B505',
  C212: 'C212',
};

export const states = {
  AK: 'AK',
  AL: 'AL',
  AR: 'AR',
  AZ: 'AZ',
  CA: 'CA',
  CO: 'CO',
  CT: 'CT',
  DC: 'DC',
  DE: 'DE',
  FL: 'FL',
  GA: 'GA',
  HI: 'HI',
  IA: 'IA',
  ID: 'ID',
  IL: 'IL',
  IN: 'IN',
  KS: 'KS',
  KY: 'KY',
  LA: 'LA',
  MA: 'MA',
  MD: 'MD',
  ME: 'ME',
  MI: 'MI',
  MN: 'MN',
  MO: 'MO',
  MS: 'MS',
  MT: 'MT',
  NC: 'NC',
  ND: 'ND',
  NE: 'NE',
  NH: 'NH',
  NJ: 'NJ',
  NM: 'NM',
  NV: 'NV',
  NY: 'NY',
  OH: 'OH',
  OK: 'OK',
  OR: 'OR',
  PA: 'PA',
  RI: 'RI',
  SC: 'SC',
  SD: 'SD',
  TN: 'TN',
  TX: 'TX',
  UT: 'UT',
  VA: 'VA',
  VT: 'VT',
  WA: 'WA',
  WI: 'WI',
  WV: 'WV',
  WY: 'WY',
};

export const stateList = Object.values(states).map((state) => ({ code: state, name: state }));

export const COLUMNS: IColumns = {
  AGENCY_COLUMNS: [
    {
      code: 'name',
      name: 'Agency Name',
      type: 'string',
      is_hidden: false,
      is_sortable: true,
      is_searchable: true,
      is_link: true,
      link_type: 'agency',
      additional_data: {
        minWidth: 160,
      },
    },
    {
      code: 'email',
      name: 'Contact Email',
      type: 'string',
      is_hidden: false,
      is_sortable: true,
      is_searchable: false,
      is_link: false,
      link_type: '',
      additional_data: {
        headerAlign: 'center',
      },
    },
    {
      code: 'customer_service_phone_number',
      name: 'Phone Number',
      type: 'string',
      is_hidden: false,
      is_sortable: true,
      is_searchable: false,
      is_link: false,
      link_type: '',
      additional_data: {
        headerAlign: 'center',
      },
    },
    {
      code: 'state',
      name: 'State',
      type: 'string',
      is_hidden: false,
      is_sortable: true,
      is_searchable: false,
      is_link: false,
      link_type: '',
      additional_data: {
        minWidth: 120,
      },
    },
  ],
  POLICY_COLUMNS: [
    {
      code: 'locator',
      name: 'Locator',
      type: 'number',
      is_hidden: true,
      is_sortable: true,
      is_searchable: true,
      is_link: false,
      link_type: '',
      additional_data: undefined,
    },
    {
      code: 'three_policy_number',
      name: 'Policy No.',
      type: 'number',
      is_hidden: false,
      is_sortable: true,
      is_searchable: true,
      is_link: true,
      link_type: 'policy',
      additional_data: {
        minWidth: 120,
      },
    },
    {
      code: 'pol_named_insured',
      name: 'Named Insured',
      type: 'string',
      is_hidden: false,
      is_sortable: true,
      is_searchable: true,
      is_link: true,
      link_type: 'policyholder',
      additional_data: {
        flex: 1.1,
        minWidth: 160,
      },
    },
    {
      code: 'pol_business_phone_number',
      name: 'Business Phone',
      type: 'phone',
      is_hidden: true,
      is_sortable: true,
      is_searchable: true,
      is_link: false,
      link_type: '',
      additional_data: undefined,
    },
    {
      code: 'pol_business_email',
      name: 'Business Email',
      type: 'email',
      is_hidden: true,
      is_sortable: true,
      is_searchable: true,
      is_link: false,
      link_type: '',
      additional_data: undefined,
    },
    {
      code: 'owner_name',
      name: 'Producer',
      type: 'string',
      is_hidden: false,
      is_sortable: true,
      is_searchable: false,
      is_link: false,
      link_type: '',
      additional_data: {
        flex: 1.2,
        minWidth: 180,
      },
    },
    {
      code: 'team',
      name: 'Team',
      type: 'string',
      is_hidden: false,
      is_sortable: true,
      is_searchable: false,
      is_link: false,
      link_type: '',
      additional_data: {
        flex: 1.4,
        minWidth: 140,
      },
    },
    {
      code: 'agency',
      name: 'Agency',
      type: 'string',
      is_hidden: false,
      is_sortable: true,
      is_searchable: false,
      is_link: false,
      link_type: '',
      additional_data: {
        flex: 1.4,
        minWidth: 140,
      },
    },
    {
      code: 'original_contract_start_time',
      name: 'Effective Date',
      type: 'date',
      is_hidden: false,
      is_sortable: true,
      is_searchable: false,
      is_link: false,
      link_type: '',
      additional_data: {
        minWidth: 130,
      },
    },
    {
      code: 'effective_contract_end_time',
      name: 'Expiration Date',
      type: 'date',
      is_hidden: true,
      is_sortable: true,
      is_searchable: false,
      is_link: false,
      link_type: '',
      additional_data: undefined,
    },
    {
      code: 'status__value',
      name: 'Status',
      type: 'string',
      is_hidden: false,
      is_sortable: false,
      is_searchable: false,
      is_link: false,
      link_type: '',
      additional_data: {
        minWidth: 140,
        headerAlign: 'center',
      },
    },
  ],
  QUOTE_COLUMNS: [
    {
      code: 'locator',
      name: 'Locator',
      type: 'number',
      is_hidden: true,
      is_sortable: true,
      is_searchable: true,
      is_link: false,
      link_type: '',
      additional_data: undefined,
    },
    {
      code: 'three_quote_number',
      name: 'Submission',
      type: 'string',
      is_hidden: false,
      is_sortable: true,
      is_searchable: true,
      is_link: true,
      link_type: 'quote',
      additional_data: {
        minWidth: 110,
      },
    },
    {
      code: 'version',
      name: 'Version',
      type: 'number',
      is_hidden: false,
      is_sortable: true,
      is_searchable: true,
      is_link: false,
      link_type: '',
      additional_data: {
        maxWidth: 80,
      },
    },
    {
      code: 'policyholder__name',
      name: 'Named Insured',
      type: 'string',
      is_hidden: false,
      is_sortable: true,
      is_searchable: true,
      is_link: false,
      link_type: '',
      additional_data: {
        minWidth: 120,
      },
    },
    {
      code: 'pol_business_phone_number',
      name: 'Business Phone',
      type: 'phone',
      is_hidden: true,
      is_sortable: true,
      is_searchable: true,
      is_link: false,
      link_type: '',
      additional_data: undefined,
    },
    {
      code: 'pol_business_email',
      name: 'Business Email',
      type: 'string',
      is_hidden: true,
      is_sortable: true,
      is_searchable: true,
      is_link: false,
      link_type: '',
      additional_data: undefined,
    },
    {
      code: 'owner__title',
      name: 'Producer',
      type: 'string',
      is_hidden: false,
      is_sortable: true,
      is_searchable: false,
      is_link: false,
      link_type: '',
      additional_data: {
        flex: 1.4,
        minWidth: 140,
      },
    },
    {
      code: 'team',
      name: 'Team',
      type: 'string',
      is_hidden: false,
      is_sortable: true,
      is_searchable: false,
      is_link: false,
      link_type: '',
      additional_data: {
        flex: 1.4,
        minWidth: 140,
      },
    },
    {
      code: 'agency',
      name: 'Agency',
      type: 'number',
      is_hidden: false,
      is_sortable: true,
      is_searchable: false,
      is_link: false,
      link_type: '',
      additional_data: {
        flex: 1.4,
        minWidth: 140,
      },
    },
    {
      code: 'created_at',
      name: 'Created Date',
      type: 'date',
      is_hidden: false,
      is_sortable: true,
      is_searchable: false,
      is_link: false,
      link_type: '',
      additional_data: {
        minWidth: 120,
      },
    },
    {
      code: 'expired_at',
      name: 'Valid Thru',
      type: 'date',
      is_hidden: false,
      is_sortable: true,
      is_searchable: false,
      is_link: false,
      link_type: '',
      additional_data: {
        minWidth: 120,
      },
    },
    {
      code: 'contract_start_time',
      name: 'Effective Date',
      type: 'date',
      is_hidden: false,
      is_sortable: true,
      is_searchable: false,
      is_link: false,
      link_type: '',
      additional_data: {
        maxWidth: 140,
      },
    },
    {
      code: 'state__name',
      name: 'Status',
      type: 'string',
      is_hidden: false,
      is_sortable: true,
      is_searchable: false,
      is_link: false,
      link_type: '',
      additional_data: {
        maxWidth: 120,
        minWidth: 120,
        headerAlign: 'center',
      },
    },
  ],
};
