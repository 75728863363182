import { createContext } from 'react';

/// types
import { IQueuesContextType, IQueuesStore } from './types';

export const initialQueuesData: IQueuesStore = {
  data: [],
  queueTasks: {},
  loading: true,
  loaded: false,
  activeQueue: {
    loading: true,
    loaded: false,
    data: {},
  },
  activeQueueTask: undefined,
  pagination: {
    ordering: '',
    page: 1,
    page_size: 20,
    search: '',
    total_count: 0,
  },
};

export const queuesContextDefault: IQueuesContextType = {
  ...initialQueuesData,
  fetch: () => new Promise(() => {}),
  fetchQueueTasks: () => new Promise(() => {}),
  getQueue: () => new Promise(() => {}),
  getQueueTask: () => new Promise(() => {}),
  reFetchData: () => new Promise(() => {}),
};

export const QueuesContext = createContext<IQueuesContextType>(null!);
