import { ConfigContext } from 'providers/ConfigProvider/ConfigProviderContext';
import { IConfigContextType } from 'providers/ConfigProvider/types';
import { useContext } from 'react';

export default function useConfig(): IConfigContextType {
  const context = useContext(ConfigContext);

  /* istanbul ignore if */
  if (!context) {
    throw new Error('useConfig must be used within a ConfigProvider');
  }

  return context;
}
