import { Box, Chip } from '@mui/material';
import React, { FC } from 'react';

interface IChip {
  code: string;
  label: string;
}

interface IBillingChips {
  handleChipClick: (chip: string) => void;
  activeChip: string;
  chips: IChip[];
}

const TableFilters: FC<IBillingChips> = ({ handleChipClick, activeChip, chips }) => (
  <Box
    sx={{
      boxShadow: (theme) => theme.customShadows.policyInfoCardEffect,
      px: 3,
      py: 1,
      mb: 3,
    }}
  >
    {chips?.map((chip) => (
      <Chip
        key={chip.code}
        label={chip.label}
        variant="outlined"
        onClick={() => handleChipClick(chip.code)}
        sx={{
          borderRadius: '12px',
          m: 0.5,
          fontSize: 14,
          borderColor: (theme) => theme.customColors.chip.bg,
          color:
            activeChip === chip.code
              ? (theme) => theme.customColors.white50
              : (theme) => theme.customColors.chip.bg,
          backgroundColor:
            activeChip === chip.code
              ? (theme) => theme.customColors.chip.itemClicked
              : (theme) => theme.customColors.white50,
          '&.MuiButtonBase-root': {
            transition: 'background-color 300ms, color 300ms',
            transitionDelay: '50ms',
          },
          '&.MuiButtonBase-root:hover': {
            color: (theme) => theme.customColors.white50,
            backgroundColor: (theme) => theme.customColors.chip.itemHover,
          },
          '& .MuiChip-label': {
            px: '12px',
          },
        }}
      />
    ))}
  </Box>
);

export default TableFilters;
