import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableFooter,
  TableHead,
  TableRow,
} from '@mui/material';
import {
  calculateModification,
  decimalToPercentage,
  getTotal,
} from 'helpers/ScheduleModificationHelpers';
import { isEmpty, isNaN } from 'lodash-es';
import React, { FC, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import ScheduleModTableRow from './ScheduleModTableRow';

export interface ISchedModTableTotalRow {
  FilingSetID: number;
  Category: string;
  MaxCredit: number;
  MaxDebit: number;
}

export interface ISchedModTableRow extends ISchedModTableTotalRow {
  pol_sched_mod_value_rule: string;
  pol_sched_mod_line: string;
  pol_sched_mod_state: string;
  pol_sched_mod_category: string;
  pol_sched_mod_comment: string;
  pol_sched_mod_value_uw: string;
  locator: string;
  total: number;
}

interface ITableHeader {
  name: string;
  width: string;
  hasBorder: boolean;
  align?: 'inherit' | 'left' | 'center' | 'right' | 'justify';
}

interface ITableFooter {
  id: number;
  name: string;
  align?: 'inherit' | 'left' | 'center' | 'right' | 'justify';
}

interface ScheduleModTableProps {
  rowTotal: ISchedModTableTotalRow;
  state: ISchedModTableRow[];
  setState: (state: any) => void;
  isEdit: boolean;
}

const ScheduleModTable: FC<ScheduleModTableProps> = ({
  rowTotal: totalCategories,
  state,
  setState,
  isEdit,
}) => {
  const { t } = useTranslation();

  const tableHeaders: ITableHeader[] = [
    {
      name: t('Category'),
      width: '15%',
      hasBorder: false,
      align: 'left',
    },
    {
      name: t('Min'),
      width: '10%',
      hasBorder: false,
    },
    {
      name: t('Max'),
      width: '10%',
      hasBorder: false,
    },
    {
      name: t('Rule'),
      width: '10%',
      hasBorder: false,
    },
    {
      name: t('Judgement'),
      width: '15%',
      hasBorder: isEdit,
    },
    {
      name: t('Total'),
      width: '10%',
      hasBorder: false,
    },
    {
      name: t('Comment'),
      width: '30%',
      hasBorder: isEdit,
      align: 'left',
    },
  ];

  const updateState = (updatedObject: ISchedModTableRow) => {
    setState((prevObjects: ISchedModTableRow[]) =>
      prevObjects.map((obj) => (obj.locator === updatedObject.locator ? updatedObject : obj)),
    );
  };

  // eslint-disable-next-line consistent-return
  const total = useMemo(() => {
    if (!isEmpty(state)) {
      return getTotal(state);
    }
  }, [state]);

  const totalOfTotal = useMemo(
    () => calculateModification(total!, totalCategories),
    [total, totalCategories],
  );

  const tableFooters: ITableFooter[] = [
    {
      id: 1,
      name: t('Total'),
      align: 'left',
    },
    {
      id: 2,
      name: decimalToPercentage(totalCategories?.MaxCredit),
      align: 'center',
    },
    {
      id: 3,
      name: decimalToPercentage(totalCategories?.MaxDebit),
      align: 'center',
    },
    {
      id: 4,
      name: '',
    },
    {
      id: 5,
      name: '',
    },
    {
      id: 6,
      name: isNaN(totalOfTotal) ? '%' : `${totalOfTotal.toString()}%`,
      align: 'center',
    },
    {
      id: 7,
      name: '',
    },
  ];

  const buildTableFooterCell = (
    id: number,
    name: string,
    align?: 'inherit' | 'left' | 'center' | 'right' | 'justify' | undefined,
  ): JSX.Element => (
    <TableCell key={id} align={align} sx={{ fontSize: '14px', fontWeight: '700' }}>
      {name}
    </TableCell>
  );

  const buildTableHeaderCell = (
    name: string,
    width: string,
    hasBorder?: Boolean,
    align?: 'inherit' | 'left' | 'center' | 'right' | 'justify',
  ): JSX.Element => (
    <TableCell
      key={name}
      align={align ?? 'center'}
      width={width}
      sx={{
        border: (theme) =>
          hasBorder ? `1px solid ${theme.customColors.drawer.tableBorderColor}` : null,
      }}
    >
      {name}
    </TableCell>
  );

  return (
    <TableContainer>
      <Table
        size="small"
        sx={{
          '& .MuiTableCell-root': {
            borderColor: (theme) => theme.customColors.drawer.tableBorderColor,
          },
        }}
      >
        <TableHead>
          <TableRow>
            {tableHeaders.map(({ name, width, hasBorder, align }: ITableHeader) =>
              buildTableHeaderCell(name, width, hasBorder, align),
            )}
          </TableRow>
        </TableHead>
        <TableBody
          sx={{
            '& .MuiTableCell-root': {
              fontWeight: 400,
            },
          }}
        >
          {!isEmpty(state) &&
            state.map((row: ISchedModTableRow) => (
              <ScheduleModTableRow
                key={row.locator}
                rowData={row}
                updateObject={updateState}
                isEdit={isEdit}
              />
            ))}
        </TableBody>

        <TableFooter sx={{ mt: '10px' }}>
          <TableRow sx={{ '&:last-child td, &:last-child th': { border: 0 }, fontSize: '40px' }}>
            {tableFooters.map(({ id, name, align }: ITableFooter) =>
              buildTableFooterCell(id, name, align),
            )}
          </TableRow>
        </TableFooter>
      </Table>
    </TableContainer>
  );
};

export default ScheduleModTable;
