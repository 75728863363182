import { RouterPromptContext } from 'providers/RouterPromptProvider/RouterPromptProviderContext';
import { IRouterPromptContextType } from 'providers/RouterPromptProvider/types';
import { useContext } from 'react';

export default function useRouterPrompt(): IRouterPromptContextType {
  const context = useContext(RouterPromptContext);

  /* istanbul ignore if */
  if (!context) {
    throw new Error('useRouterPrompt must be used within a RouterPromptProvider');
  }

  return context;
}
