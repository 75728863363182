import React, { FC } from 'react';
import { Helmet } from 'react-helmet';

interface IHead {
  title: string;
  description?: string;
}

const Head: FC<IHead> = ({ title = 'THREE Insurance Portal', description }) => (
  <Helmet>
    {description && <meta name="description" content={description} />}

    <title>{title}</title>
    {description && <meta name="description" content={description} />}
  </Helmet>
);

export default Head;
