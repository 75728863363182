import { PCRBParsedPayload, PCRBResponse } from 'api/models/Integrations/PCRB/PCRBResponse.model';
import { parse } from 'date-fns';
import { zonedTimeToUtc } from 'date-fns-tz';
import { fieldHasValue } from 'helpers/Utils';

export const parsePCRBResponse = (res: PCRBResponse, timezone: string) => {
  const payload: PCRBParsedPayload = {};

  payload.emp_xrated = res.RatingTypeCode;
  payload.emp_response_code = res.ResponseCode?.toString() ?? '';
  payload.emp_response_message = res.ResponseMessage;

  if (payload.emp_response_code === '0') {
    payload.emp_xmod = res?.ExperienceModificationFactor;

    const dateRef = res?.RatingEffectiveDate;

    payload.emp_red = fieldHasValue(dateRef)
      ? zonedTimeToUtc(parse(dateRef!, 'yyyy-MM-dd', new Date()), timezone)
      : undefined;
  }

  return payload;
};
