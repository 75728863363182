import { GridColDef } from '@mui/x-data-grid';

export const AGENT_TABLE_CONFIG: GridColDef[] = [
  {
    field: 'full_name',
    headerName: 'Full Name',
    sortable: true,
    minWidth: 160,
    flex: 1,
  },
  {
    field: 'team_name',
    headerName: 'Team',
    sortable: true,
    minWidth: 160,
    flex: 1,
  },
  {
    field: 'agency_name',
    headerName: 'Agency',
    sortable: true,
    minWidth: 160,
    flex: 1,
  },
  {
    field: 'email',
    headerName: 'Email',
    sortable: true,
    minWidth: 160,
    flex: 1,
  },
  {
    field: 'phone_number',
    headerName: 'Phone Number',
    sortable: false,
    minWidth: 150,
    flex: 1,
  },
  {
    field: 'last_active',
    headerName: 'Last Login Date',
    type: 'date',
    sortable: true,
    minWidth: 250,
    flex: 0.1,
  },
];
