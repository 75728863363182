import { KeyValuesContext } from 'providers/KeyValuesProvider/KeyValuesProviderContext';
import { IKeyValuesContextType } from 'providers/KeyValuesProvider/types';
import { useContext } from 'react';

export default function useKeyValues(): IKeyValuesContextType {
  const context = useContext(KeyValuesContext);

  /* istanbul ignore if */
  if (!context) {
    throw new Error('useKeyValues must be used within a KeyValuesProvider');
  }

  return context;
}
