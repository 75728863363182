import ApiService, { Params } from 'api/helpers/Sender';
import { PolicyDocument } from 'api/models/Documents/policyDocument.model';
import { PolicyDocumentCreate } from 'api/models/Documents/policyDocumentCreate.model';
import { Pagination, PaginationResponse } from 'types/Pagination';

const API = new ApiService();

export const getDocumentsService = async (
  pagination: Pagination,
  reference_type: string,
  reference_locator: string,
): Promise<PaginationResponse<PolicyDocument[]>> => {
  const { data } = await API.get(
    `${reference_type}/${reference_locator}/all-documents`,
    pagination as Params,
  );
  return data;
};

export const addDocumentService = async (
  reference_type: string,
  reference_locator: string,
  formData: FormData,
  onUploadProgress?: (progressEvent: any) => void,
  controller?: AbortController,
): Promise<PolicyDocumentCreate> => {
  const { data } = await API.post(
    `${reference_type}/${reference_locator}/policy-documents`,
    formData,
    {
      headers: {
        'Content-Type': 'multipart/form-data',
      },
      onUploadProgress,
      signal: controller?.signal,
    },
  );
  return data;
};

export const deleteDocumentService = async (policyId: string, documentLocator: string) => {
  await API.delete(`policies/${policyId}/policy-documents/${documentLocator}`);
};

export const getDocumentWithLocator = async (
  policyLocator: string,
  documentLocator: string,
): Promise<PolicyDocument> => {
  const { data } = await API.get(`policies/${policyLocator}/all-documents/${documentLocator}`);
  return data;
};

export const updateDocumentWithLocator = async (
  policyId: string,
  documentLocator: string,
  body: PolicyDocumentCreate,
): Promise<PolicyDocument> => {
  const { data } = await API.patch(
    `policies/${policyId}/policy-documents/${documentLocator}`,
    body,
  );
  return data;
};

export const getDocumentTypesService = async (
  reference_type: string,
): Promise<PaginationResponse<DocumentType[]>> => {
  const { data } = await API.get(`document-types/?category=${reference_type}&page_size=10000`);
  return data;
};
