import { FormikProps } from 'formik';
import React, { FC, useEffect } from 'react';

const ScrollToFormikError: FC<{ formik: FormikProps<any> }> = ({ formik }) => {
  const el =
    document.querySelector('.Mui-error, [data-error]') ??
    document.getElementById(Object.keys(formik?.errors)[0]);
  useEffect(() => {
    if (formik.isSubmitting) {
      (el?.parentElement ?? el)?.scrollIntoView({ behavior: 'smooth', block: 'end' });
    }
  }, [formik.isSubmitting, el]);

  return <></>;
};

export default ScrollToFormikError;
