import { Select, SelectProps } from '@mui/material';
import React from 'react';

// nice to have - try with useRef https://stackoverflow.com/a/8605567/8316747
// first element will be disabled option

const CustomNativeSelect: React.FC<SelectProps> = ({ children, ...props }) => {
  const isReadOnly = props?.inputProps?.readOnly || false;

  return (
    <Select multiple={false} native={!isReadOnly} {...props}>
      <option label=" " value="" hidden selected />
      <option label={props.label ? String(props.label) : ''} value="" disabled>
        {props.label}
      </option>
      {children}
    </Select>
  );
};

export default CustomNativeSelect;
