import WcClassCodeAutocompleteInput from 'components/QuotePolicyDetailEndorsement/Inputs/WcClassCodeAutocomplete';
import { IWcClassCodeAutocomplete } from 'components/QuotePolicyDetailEndorsement/Inputs/WcClassCodeAutocomplete/types';
import useQuoteDetail from 'hooks/useQuoteDetail';
import React, { FC, useMemo } from 'react';

const WcClassCodeAutocomplete: FC<IWcClassCodeAutocomplete> = ({ ...props }) => {
  const { data: quoteDetail } = useQuoteDetail();

  const policyEffectiveDate = useMemo(
    () => new Date(quoteDetail?.characteristics?.[0].started_at!),
    [quoteDetail],
  );

  return <WcClassCodeAutocompleteInput policyEffectiveDate={policyEffectiveDate} {...props} />;
};

export default WcClassCodeAutocomplete;
