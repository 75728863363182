import { Box } from '@mui/system';
import React, { FC } from 'react';

const LocationShow: FC<{ location: { head?: string; tail?: string } }> = ({
  location: { head = '', tail = '' },
}) => (
  <Box sx={{ display: 'flex', width: 1 }} component="span" title={head + tail}>
    <Box
      sx={{ whiteSpace: 'nowrap', textOverflow: 'ellipsis', overflow: 'hidden' }}
      component="span"
    >
      {head}
    </Box>
    <Box sx={{ whiteSpace: 'pre' }} component="span">
      {tail}
    </Box>
  </Box>
);

export default LocationShow;
