/* eslint-disable react/jsx-indent */
/* eslint-disable array-callback-return */
/* eslint-disable consistent-return */
/* eslint-disable react/no-unused-prop-types */
import { Cached, Info, KeyboardArrowDownRounded } from '@mui/icons-material';
import {
  Autocomplete,
  Box,
  Checkbox,
  FormControl,
  FormHelperText,
  FormLabel,
  Grid,
  IconButton,
  InputAdornment,
  InputLabel,
  Radio,
  Skeleton,
  Stack,
  SxProps,
  TextField,
  ToggleButton,
  ToggleButtonGroup,
  Tooltip,
  Typography,
} from '@mui/material';
import { Theme } from '@mui/system';
import { DynamicField } from 'api/models/DynamicFields/dynamicField.model';
import CalendarSchedule from 'assets/images/CalendarSchedule.svg';
import {
  dataFieldTypes,
  defaultDateFormat,
  propertyExposureAffectPremiumCalculationsFields,
} from 'common/constants';
import CustomNativeSelect from 'components/CustomNativeSelect';
import DatePickerComponent from 'components/DatePickerComponent';
import NumberFormatComponent from 'components/NumberFormatComponent';
import { DetailDrawerLoader } from 'components/QuotePolicyDetailEndorsement/DrawerLoader';
import { ILocationSelect } from 'components/QuotePolicyDetailEndorsement/Inputs/LocationSelectInput/types';
import {
  calendarIconStyle,
  checkboxStyle,
  readOnlyCheckboxStyle,
  readOnlyInputStyle,
  readOnlySelectInputStyle,
} from 'helpers/MuiSharedStyles';
import { currencyFormat, splitTextByLine } from 'helpers/Utils';
import useUser from 'hooks/useUser';
import { isEmpty, uniqBy } from 'lodash-es';
import React, { ChangeEvent, FC, useEffect, useMemo } from 'react';
import InputMask from 'react-input-mask';
import { PatternFormat } from 'react-number-format';
import { ReactSVG } from 'react-svg';

interface IProps {
  formik: any;
  isEdit?: boolean;
  showLoader?: boolean;
  fields?: DynamicField[];
  loaded?: boolean;
  splitSize?: number;
  state?: any;
  polState?: any;
  setState?: any;
  rowSpacing?: number;
  columnSpacing?: number;
  isReadOnly?: boolean;
  sx?: SxProps<Theme>;
  onReplacementCostFetch?: () => void;
  onChangeEmited?: ({
    field,
    value,
    oldValue,
  }: {
    field: string;
    value: any;
    oldValue?: any;
  }) => void;
  LocationSelect: FC<ILocationSelect>;
  setIsChangePremiumAffectFields?: (value: boolean) => void;
}

export const PropertyDrawerFieldParser: FC<IProps> = ({
  isEdit = false,
  showLoader = false,
  isReadOnly = false,
  fields,
  splitSize = 2,
  state,
  polState,
  setState,
  rowSpacing = 1.5,
  columnSpacing = 3,
  formik,
  sx,
  onReplacementCostFetch,
  onChangeEmited,
  LocationSelect,
  setIsChangePremiumAffectFields,
}) => {
  const { data: user, loading: userLoading } = useUser();

  const handleFieldType = (field: DynamicField) => {
    const {
      isReplacementCostFetcher,
      loading,
      isLocationSelect,
      emitIsChangedEvent,
      currencyInput,
      showAsKeyValue,
      isReadOnlyForRoles,
    } = field?.additional_data ?? {};

    const isFieldReadOnly =
      field?.is_readonly ||
      isReadOnly ||
      !!isReadOnlyForRoles?.includes(user?.role?.code) ||
      field?.additional_data?.is_readonly;

    const checkEmitIsChanged = ({
      fieldName,
      value,
      oldValue,
    }: {
      fieldName: string;
      value: any;
      oldValue?: any;
    }) => {
      if (emitIsChangedEvent) {
        onChangeEmited?.({ field: fieldName, value, oldValue });
      }

      if (propertyExposureAffectPremiumCalculationsFields.includes(fieldName)) {
        setIsChangePremiumAffectFields?.(true);
      }
    };

    const updateState = (name: string, value: any) => {
      setState((prevState) => ({ ...prevState, [name]: value }));

      formik.setFieldValue([field.code], value);

      checkEmitIsChanged({ fieldName: name, value });
    };

    const handleInputChange = (event: ChangeEvent<HTMLInputElement>) => {
      const { name, value } = event.target;

      updateState(name, value);
    };

    const handlePatternFormatInputChange = (values, sourceInfo) => {
      const { name } = sourceInfo?.event?.target ?? '';
      const { value = '' } = values;

      updateState(name, value);
    };

    if (loading) {
      return (
        <FormControl variant="standard" sx={{ width: '100%' }} key={field.code}>
          <Skeleton sx={{ mr: '40%' }} animation="wave" width="100%" height={37} />
        </FormControl>
      );
    }

    if (isLocationSelect) {
      return (
        <LocationSelect
          state={state}
          setState={setState}
          formik={formik}
          fieldConfig={field}
          onSelect={(value) => checkEmitIsChanged({ fieldName: field.code, value })}
          isReadOnly={isFieldReadOnly}
        />
      );
    } else if (isReplacementCostFetcher) {
      return (
        <FormControl variant="standard" sx={{ width: '100%' }} key={field.code}>
          <TextField
            id={field.code}
            name={field.code}
            label={field.name}
            size="small"
            required={!field.is_optional}
            onChange={handleInputChange}
            value={state[field.code] ?? ''}
            type={currencyInput ? 'text' : 'number'}
            sx={isFieldReadOnly ? readOnlyInputStyle : {}}
            InputLabelProps={isFieldReadOnly ? { shrink: true } : {}}
            inputProps={{
              autoComplete: 'off',
              ...(isFieldReadOnly && { readOnly: true }),
            }}
            // eslint-disable-next-line react/jsx-no-duplicate-props
            InputProps={{
              autoComplete: 'off',
              inputComponent: currencyInput && (NumberFormatComponent as any),
              endAdornment:
                !isEdit && isFieldReadOnly ? undefined : (
                  <InputAdornment position="end">
                    <IconButton
                      onClick={() => onReplacementCostFetch?.()}
                      sx={{
                        '& svg': {
                          fontSize: '23px !important',
                        },
                      }}
                    >
                      <Cached />
                    </IconButton>
                  </InputAdornment>
                ),
            }}
            error={formik.touched[`${field.code}`] && Boolean(formik.errors[`${field.code}`])}
            helperText={formik.touched[`${field.code}`] && formik.errors[`${field.code}`]}
          />
        </FormControl>
      );
    } else if (showAsKeyValue) {
      return (
        <Box
          sx={{
            display: 'flex',
            justifyContent: 'space-between',
            flex: 1,
            mt: -rowSpacing,
            pt: '6px',
            pl: '12px',
          }}
          key={field.code}
        >
          {field?.additional_data?.loading ? (
            <>
              <Skeleton sx={{ mr: '40%' }} animation="wave" width="20%" height={20} />
              <Skeleton animation="wave" width="40%" height={20} />
            </>
          ) : (
            <>
              <Typography
                sx={{
                  fontSize: 14,
                  lineHeight: '21px',
                  fontWeight: 500,
                }}
              >
                {field?.name}
              </Typography>
              <Typography
                sx={{
                  fontSize: 14,
                  lineHeight: '21px',
                  fontWeight: 400,
                }}
              >
                {state[field.code] ?? ''}
              </Typography>
            </>
          )}
        </Box>
      );
    } else if (field.code === 'bdg_sqft') {
      return (
        <FormControl variant="standard" sx={{ width: '100%', maxWidth: '480px' }} key={field.code}>
          <TextField
            sx={[
              {
                '& input::-webkit-outer-spin-button, & input::-webkit-inner-spin-button': {
                  display: 'none',
                },
                '& input[type=number]': {
                  MozAppearance: 'textfield',
                },
              },
              isFieldReadOnly ? readOnlyInputStyle : {},
            ]}
            label={field.name}
            id={field.code}
            name={field.code}
            size="small"
            required={!field.is_optional}
            type="text"
            onChange={handleInputChange}
            value={String(state[field.code]) || ''}
            error={formik.touched[field.code] && Boolean(formik.errors[field.code])}
            helperText={formik.touched[field.code] && Boolean(formik.errors[field.code])}
            InputLabelProps={isFieldReadOnly ? { shrink: true } : {}}
            InputProps={{
              inputComponent: NumberFormatComponent as any,
              inputProps: {
                decimalScale: 0,
              },
              autoComplete: 'off',
              ...(isFieldReadOnly && { readOnly: true }),
            }}
          />
        </FormControl>
      );
    }

    switch (field.type) {
      case dataFieldTypes.STRING:
        return (
          <FormControl variant="standard" sx={{ width: '100%' }} key={field.code}>
            <TextField
              id={field.code}
              name={field.code}
              label={field.name}
              size="small"
              required={!field.is_optional}
              onChange={handleInputChange}
              value={state[field.code] ?? ''}
              sx={isFieldReadOnly ? readOnlyInputStyle : {}}
              InputLabelProps={isFieldReadOnly ? { shrink: true } : {}}
              inputProps={{
                autoComplete: 'off',
                ...(isFieldReadOnly && { readOnly: true }),
              }}
              error={formik.touched[`${field.code}`] && Boolean(formik.errors[`${field.code}`])}
              helperText={formik.touched[`${field.code}`] && formik.errors[`${field.code}`]}
            />
          </FormControl>
        );

      case dataFieldTypes.PHONE:
        return (
          <FormControl
            variant="standard"
            sx={{ width: '100%' }}
            key={field.code}
            required={!field.is_optional}
          >
            <Stack
              sx={{
                '& .MuiFormControl-root': {
                  flex: 'auto',
                },
                py: 1,
                display: 'flex',
                flexDirection: 'row',
                alignItems: 'center',
                justifyContent: 'space-between',
              }}
            >
              <InputMask
                mask="(999) 999-9999"
                disabled={false}
                maskChar=" "
                onChange={handleInputChange}
                value={state[field.code] || ''}
              >
                {() => (
                  <TextField
                    sx={[{ flex: 'auto' }, isFieldReadOnly ? readOnlyInputStyle : {}]}
                    id={field.code}
                    name={field.code}
                    size="small"
                    required={!field.is_optional}
                    value={state[field.code] || ''}
                    inputProps={{
                      autoComplete: 'off',
                      ...(isFieldReadOnly && { readOnly: true }),
                    }}
                    error={
                      formik.touched[`${field.code}`] && Boolean(formik.errors[`${field.code}`])
                    }
                    helperText={formik.touched[`${field.code}`] && formik.errors[`${field.code}`]}
                  />
                )}
              </InputMask>
            </Stack>
          </FormControl>
        );

      case dataFieldTypes.NUMBER:
        return (
          <FormControl variant="standard" sx={{ width: '100%' }} key={field.code}>
            <TextField
              sx={[
                {
                  '& input::-webkit-outer-spin-button, & input::-webkit-inner-spin-button': {
                    display: 'none',
                  },
                  '& input[type=number]': {
                    MozAppearance: 'textfield',
                  },
                },
                isFieldReadOnly ? readOnlyInputStyle : {},
              ]}
              label={field.name}
              id={field.code}
              name={field.code}
              size="small"
              required={!field.is_optional}
              type="text"
              onChange={handleInputChange}
              value={state[field.code] ?? ''}
              inputProps={{
                autoComplete: 'off',
                allowDecimal:
                  typeof field?.additional_data?.allowDecimal === 'boolean'
                    ? field?.additional_data?.allowDecimal
                    : !currencyInput,
                ...(typeof field?.additional_data?.thousandSeparator === 'boolean'
                  ? {
                      thousandSeparator: field?.additional_data?.thousandSeparator,
                    }
                  : {}),
                ...(isFieldReadOnly && {
                  readOnly: true,
                }),
              }}
              InputLabelProps={isFieldReadOnly ? { shrink: true } : {}}
              // eslint-disable-next-line react/jsx-no-duplicate-props
              InputProps={{
                autoComplete: 'new-password',
                inputComponent: NumberFormatComponent as any,
              }}
              error={formik.touched[`${field.code}`] && Boolean(formik.errors[`${field.code}`])}
              helperText={formik.touched[`${field.code}`] && formik.errors[`${field.code}`]}
            />
          </FormControl>
        );

      case dataFieldTypes.EMAIL:
        return (
          <FormControl variant="standard" sx={{ width: '100%' }} key={field.code}>
            <TextField
              sx={[
                {
                  '& .MuiOutlinedInput-root': {
                    borderRadius: '4px',
                    '& .MuiInputBase-input': {
                      py: 1.5,
                    },
                    '&.Mui-focused fieldset': {
                      border: 2,
                    },
                  },
                },
                isFieldReadOnly ? readOnlyInputStyle : {},
              ]}
              id={field.code}
              name={field.code}
              size="small"
              type="email"
              required={!field.is_optional}
              // label={field.name}
              placeholder={`${field.name}${!field.is_optional ? ' *' : ''}`}
              onChange={handleInputChange}
              value={state[field.code] || ''}
              error={formik.touched[field.code] && Boolean(formik.errors[field.code])}
              helperText={formik.touched[field.code] && formik.errors[field.code]}
              inputProps={{
                autoComplete: 'off',
                ...(isFieldReadOnly && { readOnly: true }),
              }}
            />
          </FormControl>
        );

      case dataFieldTypes.DATE:
        return (
          <FormControl
            variant="standard"
            sx={{ width: '100%', ...calendarIconStyle }}
            key={field.code}
          >
            <Stack
              sx={{
                '& .MuiFormControl-root': {
                  flex: 'auto',
                },
                py: 1,
                display: 'flex',
                flexDirection: 'row',
                alignItems: 'center',
                justifyContent: 'space-between',
              }}
            >
              <FormLabel
                sx={{
                  fontSize: 12,
                  lineHeight: 1.5,
                  color: (theme) => theme.customColors.black,
                  mr: (theme) => theme.spacing(1.25),
                  minWidth: '142px',
                  maxWidth: '142px',
                }}
                required={!field.is_optional}
              >
                {field.name}
              </FormLabel>
              <DatePickerComponent
                format={defaultDateFormat}
                minDate={field?.minimum_date}
                maxDate={field?.maximum_date}
                onChange={(newValue) => {
                  setState({ ...state, [field.code]: newValue! });
                  checkEmitIsChanged({ fieldName: field.code, value: newValue });
                }}
                value={state[field.code] ?? null}
                slots={{
                  openPickerIcon: () => (
                    <ReactSVG className="calendar-icon" src={CalendarSchedule} />
                  ),
                }}
                slotProps={{
                  textField: {
                    size: 'small',
                    required: !field.is_optional,
                    id: field.code,
                    name: field.code,
                    inputProps: {
                      autoComplete: 'off',
                    },
                    error:
                      formik.touched[`${field.code}`] && Boolean(formik.errors[`${field.code}`]),
                    helperText: formik.touched[`${field.code}`] && formik.errors[`${field.code}`],
                  },
                }}
              />
            </Stack>
          </FormControl>
        );

      case dataFieldTypes.SELECT:
        return (
          <FormControl
            required={!field.is_optional}
            size="small"
            sx={[{ width: '100%' }, isFieldReadOnly ? readOnlySelectInputStyle : {}]}
            key={field.code}
            error={formik.touched[`${field.code}`] && Boolean(formik.errors[`${field.code}`])}
          >
            <Stack
              sx={{
                '& .MuiFormControl-root': {
                  flex: 'auto',
                },
              }}
            >
              <InputLabel
                required={!field.is_optional}
                id={`${field.code}_label`}
                shrink={isFieldReadOnly ? true : undefined}
              >
                {field.name}
              </InputLabel>
              <CustomNativeSelect
                required={!field.is_optional}
                id={field.code}
                labelId={`${field.code}_label`}
                label={field.name}
                name={field.code}
                IconComponent={KeyboardArrowDownRounded}
                error={formik.touched[`${field.code}`] && Boolean(formik.errors[`${field.code}`])}
                value={state[field.code] ?? ''}
                inputProps={{
                  autoComplete: 'off',
                  readOnly: isFieldReadOnly,
                }}
                sx={{
                  '& > .MuiSelect-select': { display: 'inline-block' },
                  width: '100%',
                }}
                MenuProps={{
                  sx: {
                    width: 100,
                    wordBreak: 'break-all',
                    whiteSpace: 'pre-wrap',
                    '& .MuiMenuItem-root': { whiteSpace: 'normal' },
                  },
                }}
                onChange={(event) => {
                  const oldValue = state[`${event.target.name}`];

                  setState({ ...state, [field.code]: event.target.value });
                  checkEmitIsChanged({
                    fieldName: field.code,
                    value: event.target.value,
                    oldValue,
                  });
                }}
              >
                {field?.choices &&
                  uniqBy([...field.choices], 'code')?.map((type) => (
                    <option key={type.code} value={type.code}>
                      {type.name}
                    </option>
                  ))}
              </CustomNativeSelect>

              {formik.touched[`${field.code}`] && Boolean(formik.errors[`${field.code}`]) && (
                <FormHelperText
                  sx={{
                    mt: 1,
                    fontSize: '12px',
                    lineHeight: '14px',
                    color: (theme) => theme.customColors.alert,
                  }}
                >
                  {formik.errors[`${field.code}`]}
                </FormHelperText>
              )}
            </Stack>
          </FormControl>
        );

      case dataFieldTypes.AUTOCOMPLETE: {
        const autocompleteValue = useMemo(
          () =>
            field?.choices?.find((o) => o.code === `${state[`${field.code}`]}`) || {
              code: '',
              name: '',
            },
          [state, field?.choices],
        );
        return (
          <FormControl
            required={!field.is_optional}
            size="small"
            sx={[
              { width: '100%' },
              isReadOnly || field?.is_readonly ? readOnlySelectInputStyle : {},
            ]}
            key={field.code}
          >
            <Stack
              sx={[
                {
                  '& .MuiFormControl-root': {
                    flex: 'auto',
                  },
                },
                ...(Array.isArray(sx) ? sx : [sx]),
              ]}
            >
              <Autocomplete
                sx={{ width: '100%' }}
                disableClearable
                id={field.code}
                readOnly={isReadOnly}
                getOptionLabel={(option) => (option ? option.code : option)}
                options={field.choices ?? []}
                value={autocompleteValue}
                filterOptions={(options, stateValue) =>
                  options.filter(
                    (option) =>
                      option.code.includes(stateValue.inputValue) ||
                      (option.name as string)
                        .toLowerCase()
                        .includes(stateValue.inputValue.toLowerCase()),
                  )
                }
                onChange={(e, selectedOption) => {
                  const newVal = selectedOption?.code;
                  setState?.({ ...state, [field.code!]: newVal });
                  formik.setFieldValue([field.code], newVal);
                }}
                isOptionEqualToValue={(option, v) => option.code === (v as any)}
                renderInput={(params) => (
                  <TextField
                    required={field!.is_optional ? !field!.is_optional : true}
                    {...params}
                    name={field.code}
                    size="small"
                    label={field.name}
                    error={formik.touched?.[field.code!] && Boolean(formik.errors?.[field.code!])}
                    helperText={formik.touched?.[field.code!] && formik.errors?.[field.code!]}
                    placeholder={isReadOnly ? '' : 'Start typing and select'}
                    InputLabelProps={isReadOnly ? { shrink: true } : {}}
                    InputProps={{
                      ...params.InputProps,
                      endAdornment: null,
                    }}
                  />
                )}
                renderOption={(props, option) => (
                  <li {...props} key={option.code}>
                    {`${option.code} - ${option.name}`}
                  </li>
                )}
              />
            </Stack>
          </FormControl>
        );
      }

      case dataFieldTypes.SELECT_BUTTON:
        return (
          <FormControl size="small" sx={{ width: '100%', position: 'relative' }} key={field.code}>
            {field.name && (
              <Typography
                sx={{
                  fontWeight: 400,
                  fontSize: '12px',
                  lineHeight: '14px',
                  whiteSpace: 'nowrap',
                  mb: 1,
                }}
              >
                {field.name} {!field?.is_optional && '*'}
              </Typography>
            )}
            <ToggleButtonGroup
              exclusive
              value={state[field.code] ?? null}
              onChange={(event, newValue) => {
                if (newValue) {
                  setState({ ...state, [field.code]: newValue });
                  formik.setFieldValue([field.code], newValue);
                  checkEmitIsChanged({ fieldName: field.code, value: newValue });
                }
              }}
              sx={{ gap: 2 }}
              aria-label={field.name}
            >
              {field.choices?.map((choice) => (
                <ToggleButton
                  disabled={isFieldReadOnly}
                  sx={{
                    whiteSpace: 'nowrap',
                    backgroundColor: (theme) => theme.customColors.grey1150,
                    width: '120px',
                    height: '44px',
                    mb: 1,
                    '&.MuiToggleButton-root': {
                      py: 1.25,
                      px: 5,
                      color: (theme) => theme.customColors.gunMetal,
                      borderColor: (theme) => theme.customColors.gunMetal,
                      '&.MuiToggleButtonGroup-grouped:not(:first-of-type) ': {
                        borderLeft: '1px solid #2B2B2A',
                        ml: 0,
                        borderRadius: '2px',
                      },
                      '&.MuiToggleButtonGroup-grouped:not(:last-of-type) ': {
                        borderLeft: '1px solid #2B2B2A',
                        ml: 0,
                        borderRadius: '2px',
                      },
                      '&.Mui-selected': {
                        border: '2px solid !important',
                        borderColor: '#BB4F09 !important',
                      },
                    },
                  }}
                  value={choice.code}
                  key={choice.code}
                  aria-label={choice.name}
                >
                  <Radio
                    checked={state[field.code] === choice.code}
                    size="small"
                    disableRipple
                    sx={{
                      fontSize: 15,
                      width: 15,
                      height: 15,
                      mr: 1,
                      '&:hover': {
                        background: 'none',
                      },
                      color:
                        state[field.code] === choice.code
                          ? (theme) => theme.customColors.copper
                          : (theme) => theme.customColors.gunMetal,
                      '&.Mui-checked': {
                        color:
                          state[field.code] === choice.code
                            ? (theme) => theme.customColors.copper
                            : (theme) => theme.customColors.gunMetal,
                      },
                    }}
                  />

                  <Typography
                    sx={{
                      fontWeight: 500,
                      fontSize: '16px',
                      lineHeight: '24px',
                      textTransform: 'none',
                    }}
                  >
                    {Number.isNaN(Number(choice.name)) ? (
                      choice.name
                    ) : field.additional_data?.withDollarIcon ? (
                      `${currencyFormat('USD', choice.name!).merged}`
                    ) : (
                      <NumberFormatComponent displayType="text" value={choice.name} />
                    )}
                  </Typography>
                </ToggleButton>
              ))}
            </ToggleButtonGroup>
            <FormHelperText
              sx={{ position: 'absolute', bottom: '-24px', mb: 1 }}
              error={formik.touched[field.code] && Boolean(formik.errors[field.code])}
            >
              {formik.touched[field.code] && Boolean(formik.errors[field.code])
                ? formik.errors[field.code]
                : null}
            </FormHelperText>
          </FormControl>
        );

      case dataFieldTypes.LABEL:
        return (
          <Box
            sx={{
              display: 'flex',
              justifyContent: 'space-between',
              flex: 1,
            }}
            key={field.code}
          >
            <>
              <Typography
                sx={{
                  fontSize: 14,
                  lineHeight: '21px',
                  fontWeight: 500,
                }}
              >
                {field?.name}
              </Typography>
              <Typography
                sx={{
                  fontSize: 14,
                  lineHeight: '21px',
                  fontWeight: 400,
                }}
              >
                {state[field.code] ?? ''}
              </Typography>
            </>
          </Box>
        );

      case dataFieldTypes.CHECKBOX:
        return (
          <FormControl
            variant="standard"
            sx={{
              maxHeight: '21px',
              width: '100%',
              display: 'inline-flex',
              flexDirection: 'row',
              alignItems: 'center',
            }}
            key={field.code}
          >
            {field.additional_data?.asYesNo ? (
              <Checkbox
                sx={field?.is_readonly || isReadOnly ? readOnlyCheckboxStyle : checkboxStyle}
                id={field.name}
                name={field.name}
                checked={state[field.code] === 'Yes' ?? false}
                onChange={(event) => {
                  if (!field?.is_readonly && !isReadOnly) {
                    setState?.({ ...state, [field.code]: event.target.checked ? 'Yes' : 'No' });
                  }
                }}
                inputProps={{
                  readOnly: isReadOnly,
                }}
              />
            ) : (
              <Checkbox
                sx={field?.is_readonly || isReadOnly ? readOnlyCheckboxStyle : checkboxStyle}
                id={field.name}
                name={field.name}
                checked={state[field.code] ?? false}
                onChange={(event) => {
                  if (!field?.is_readonly && !isReadOnly) {
                    setState?.({ ...state, [field.code]: event.target.checked });
                  }
                }}
                inputProps={{
                  readOnly: isReadOnly,
                }}
              />
            )}
            <Typography
              sx={{
                fontSize: 14,
                color: (theme) => theme.customColors.black,
                lineHeight: '21px',
                fontWeight: 400,
                ml: 2,
              }}
            >
              {field.name}
            </Typography>
          </FormControl>
        );

      case dataFieldTypes.YEAR:
        return (
          <FormControl
            variant="standard"
            sx={{ width: '100%', maxWidth: '480px' }}
            key={field.code}
          >
            <PatternFormat
              format="####"
              customInput={TextField}
              valueIsNumericString
              id={field.code}
              name={field.code}
              label={field.name}
              size="small"
              required={!field.is_optional}
              value={state[field.code] || ''}
              onValueChange={handlePatternFormatInputChange}
              sx={isFieldReadOnly ? readOnlyInputStyle : {}}
              InputLabelProps={isFieldReadOnly ? { shrink: true } : {}}
              inputProps={{
                autoComplete: 'off',
                ...(isFieldReadOnly && { readOnly: true }),
              }}
              error={formik.touched[`${field.code}`] && Boolean(formik.errors[`${field.code}`])}
              helperText={formik.touched[`${field.code}`] && formik.errors[`${field.code}`]}
            />
          </FormControl>
        );

      default:
    }
  };

  const handleConditionalField = (field) => {
    const handleCondition = {
      select: (condition, fieldCode) => {
        const { value } = condition;
        return value.includes(state[fieldCode]);
      },
      number: (condition, fieldCode) => {
        const { value, operator } = condition;
        const operators = {
          '>': () => state[fieldCode] > +value,
          '<': () => state[fieldCode] < +value,
        };
        return operators[operator]();
      },
    };

    if (field?.condition && Object.keys(field.condition).length > 0) {
      const results: boolean[] = [];
      // eslint-disable-next-line no-restricted-syntax
      for (const [fieldCode, fieldConditions] of Object.entries(
        field.condition,
      ) as unknown as any[]) {
        const result = fieldConditions.every((condition) =>
          handleCondition[condition.type](condition, fieldCode),
        );
        results.push(result);
      }
      return results.every((result) => result);
    }
    return true;
  };

  useEffect(() => {
    fields?.map((f) => {
      if (f.type === dataFieldTypes.SELECT && f.choices?.length === 1) {
        setState({ ...state, [f.code!]: f.choices[0].code });
      }
    });
  }, []);

  return (
    <>
      {showLoader || userLoading ? (
        <DetailDrawerLoader />
      ) : !isEmpty(fields) ? (
        <Grid
          sx={{ alignItems: 'flex-start' }}
          container
          rowSpacing={rowSpacing}
          columnSpacing={columnSpacing}
          columns={splitSize}
        >
          {fields?.map((field) => {
            const { showOnlyForRoles, enabledStates } = field?.additional_data ?? {};

            const isNotAuthorized =
              !isEmpty(showOnlyForRoles) && !showOnlyForRoles?.includes(user?.role?.code);

            const isNotEnabledStates =
              !isEmpty(enabledStates) && !enabledStates?.includes(polState);

            if (isNotEnabledStates || isNotAuthorized) {
              return <React.Fragment key={`${field.code + field.type}__hidden`} />;
            }

            return (
              handleConditionalField(field) &&
              field &&
              !field?.is_hidden && (
                <React.Fragment key={field.code + field.type}>
                  {(field.heading ||
                    field.additional_data?.section_heading ||
                    field.newline ||
                    field.additional_data?.space_above) && (
                    <Grid
                      item
                      key={`${field.code}_header`}
                      xs={splitSize}
                      sx={[
                        field.newline ? { p: '0 !important' } : {},
                        field.additional_data?.space_above ? { mt: 1.5 } : {},
                      ]}
                    >
                      {field.heading && (
                        <Typography
                          sx={{
                            fontWeight: 500,
                            mt: 1.5,
                            fontSize: '16px',
                            lineHeight: 1.5,
                          }}
                        >
                          {field.heading}
                        </Typography>
                      )}
                      {field.additional_data?.section_heading &&
                        (field.additional_data?.section_heading_tooltip ? (
                          <Box sx={{ display: 'flex', alignItems: 'center', mt: 4 }}>
                            <Typography
                              sx={{
                                fontWeight: 500,
                                fontSize: '20px',
                                lineHeight: '26px',
                                color: '#2B2B2A',
                              }}
                            >
                              {field.additional_data.section_heading}
                            </Typography>

                            <Tooltip
                              PopperProps={{
                                disablePortal: true,
                              }}
                              title={splitTextByLine(
                                field.additional_data?.section_heading_tooltip,
                              ).map((item, index) => (
                                <Box
                                  key={index}
                                  sx={{
                                    fontSize: 11,
                                  }}
                                >
                                  {item}
                                </Box>
                              ))}
                              arrow
                              placement="bottom"
                              enterTouchDelay={0}
                            >
                              <Info
                                sx={{
                                  cursor: 'help',
                                  ml: 0.5,
                                  fontSize: '24px',
                                  color: (theme) => theme.customColors.primary.buttonBg,
                                }}
                              />
                            </Tooltip>
                          </Box>
                        ) : (
                          <Typography
                            sx={{
                              mt: 4,
                              fontWeight: 500,
                              fontSize: '20px',
                              lineHeight: '26px',
                              color: '#2B2B2A',
                            }}
                          >
                            {field.additional_data.section_heading}
                          </Typography>
                        ))}
                    </Grid>
                  )}

                  <Grid
                    item
                    key={field.code + field.type}
                    xs={3}
                    sx={[...(Array.isArray(sx) ? sx : [sx])]}
                  >
                    {handleFieldType(field)}
                  </Grid>
                </React.Fragment>
              )
            );
          })}
        </Grid>
      ) : (
        <></>
      )}
    </>
  );
};
