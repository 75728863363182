import { TblDepreciation } from 'api/models/THREEMappings/tblDepreciation/tblDepreciation.model';
import { TblGCW } from 'api/models/THREEMappings/tblGCW/tblGCW.model';
import { TblGVW } from 'api/models/THREEMappings/tblGVW/tblGVW.model';
import { TblVehType } from 'api/models/THREEMappings/tblVehType/tblVehType.model';
import { states } from 'common/constants';

// #region interfaces
interface IGetVehType {
  policyData?: any;
  bodyCategory?: string;
  bodySubCategory?: string;
  table?: TblVehType[];
  vehicleLocationState?: string;
  locFilingSetId?: string;
}

interface IGetVehSubtype {
  policyData: any;
  veh_body_category: string;
  veh_body_subcategory: string;
  veh_type: string;
  veh_gvw: string;
  veh_gcw: string;
  tblGVW?: TblGVW[];
  tblGCW?: TblGCW[];
}

interface IGetVehSubtypeResult {
  veh_subtype: string;
  veh_class: string;
}

interface IGetDepreciatedCost {
  veh_msrp: string;
  veh_year: string;
  yearAdded: string;
  tblDepreciation: TblDepreciation[];
}

interface IGetVehDTType {
  veh_body_category: string;
  veh_type: string;
  veh_body_subcategory: string;
  veh_subtype: string;
}
// #endregion

/**
 * Determine the `VEH_TYPE` with `VEH_BODY_CATEGORY` & `VEH_BODY_SUBCATEGORY`
 *
 * https://dev.azure.com/radity-gmbh/THREE-insurance/_workitems/edit/11485/
 */
export const getVehicleType = ({
  policyData,
  bodyCategory,
  bodySubCategory,
  table,
  vehicleLocationState,
  locFilingSetId,
}: IGetVehType): string => {
  const vehicleType =
    table?.find(
      (c) =>
        c.veh_body_category === bodyCategory &&
        c.veh_body_subcategory === bodySubCategory &&
        c.state === vehicleLocationState &&
        c.FilingSetID === Number(locFilingSetId),
    )?.veh_type ?? '';
  // Get final vehicle type value according to the business rules and location
  switch (vehicleLocationState) {
    case states.AZ:
    case states.CO:
    case states.CT:
    case states.IL:
    case states.IN:
    case states.KS:
    case states.MD:
    case states.MO:
    case states.NC:
    case states.NJ:
    case states.OR:
    case states.PA:
    case states.SC:
    case states.TX:
    case states.IA:
    case states.CA:
      // this is special business rule for TX, NC and v15 states
      if (vehicleType === 'SVM') {
        return policyData?.pol_tax_ca_smallvehiclemapping;
      }
      return vehicleType;

    default:
      return vehicleType;
  }
};

/**
 * Determine the `VEH_SUBTYPE`
 *
 * https://dev.azure.com/radity-gmbh/THREE-insurance/_workitems/edit/11486/
 */
export const getVehicleSubtype = ({
  veh_body_category,
  veh_body_subcategory,
  veh_type,
  veh_gvw,
  tblGVW,
  tblGCW,
  veh_gcw,
}: IGetVehSubtype): IGetVehSubtypeResult => {
  const result = {
    veh_subtype: '',
    veh_class: '',
  };

  if (veh_body_subcategory === 'Service or Utility Trailer (0 - 2000 lbs load capacity)') {
    result.veh_subtype = 'Service or Utility Trailer';
  } else if (veh_body_category === 'Trailer') {
    result.veh_subtype = 'Semitrailer';
  } else if (veh_type === 'TTT' && veh_body_subcategory === 'Truck Tractor') {
    // lookup mapping and find a match
    const foundInMapping = tblGCW?.find((mapping) => veh_gcw === mapping.dropdown);

    if (foundInMapping) {
      result.veh_subtype = foundInMapping?.veh_subtype ?? '';
    }
  } else if (veh_type === 'TTT' && veh_body_subcategory !== 'Truck Tractor') {
    // lookup mapping and find a match
    const foundInMapping = tblGVW?.find((mapping) => veh_gvw === mapping.dropdown);

    if (foundInMapping) {
      result.veh_subtype = foundInMapping?.veh_subtype ?? '';
    }
  }

  return result;
};

const roundToNearest100 = (num: number): number => Math.round(num / 100) * 100;

/**
 * Determine the `VEH_MSRP_DPR`
 *
 * https://dev.azure.com/radity-gmbh/THREE-insurance/_workitems/edit/11178/
 */
export const getDepreciatedCost = ({
  veh_msrp,
  veh_year,
  yearAdded,
  tblDepreciation,
}: IGetDepreciatedCost) => {
  const tmpVehMSRP = parseInt(veh_msrp, 10);
  const tmpVehYear = parseInt(veh_year, 10);
  const tmpYearAdded = parseInt(yearAdded, 10);

  const lag = tmpYearAdded - tmpVehYear;

  let dprFactor = 1;

  if (lag <= 0) {
    dprFactor = 1.0;
  } else {
    const lagLookup = Math.min(lag, 15);

    dprFactor = tblDepreciation?.find((dep) => dep.lag === lagLookup)?.dprFactor!;
  }

  return roundToNearest100(tmpVehMSRP * dprFactor * 1.05);
};

/**
 * Determine the `VEH_DT_TYPE`
 *
 * https://dev.azure.com/radity-gmbh/THREE-insurance/_workitems/edit/11495/
 */
export const getVehicleDTType = ({
  veh_body_category,
  veh_type,
  veh_body_subcategory,
  veh_subtype,
}: IGetVehDTType) => {
  let t = '';

  switch (veh_type) {
    case 'PPT': {
      t = 'All Other';
      break;
    }

    case 'Public': {
      t = 'Publics: 9 to 15 seats';
      break;
    }

    case 'TTT': {
      // https://dev.azure.com/radity-gmbh/THREE-insurance/_workitems/edit/15407/
      if (veh_body_category === 'Trailer') {
        t = 'All Other';
        return t;
      }

      if (veh_body_subcategory === 'Food Truck') {
        t = 'Food Truck';
        return t;
      }

      switch (veh_subtype) {
        case 'Extra-Heavy Truck-Tractor':
          t = 'Extra Heavy Truck-Tractors';
          break;

        case 'Extra-Heavy Truck':
          t = 'Extra-Heavy Truck';
          break;

        case 'Heavy Truck':
          t = 'Heavy Truck';
          break;

        case 'Heavy Truck-Tractor':
          t = 'Heavy Truck-Tractors';
          break;

        case 'Light Truck':
          t = 'Light Truck';
          break;

        case 'Medium Truck':
          t = 'Medium Truck';
          break;

        default:
          break;
      }

      break;
    }

    default:
      break;
  }

  return t;
};
