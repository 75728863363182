import ApiService, { Params } from 'api/helpers/Sender';
import { RiskMeterResponse } from 'api/models/Integrations/Corelogic/RiskMeterResponse.model';
import { RiskMeterWithLocationRequest } from 'api/models/Integrations/Corelogic/RiskMeterWithLocationRequest.model';
import { IGenericIntegrationResponse } from 'api/models/Integrations/IGenericResponse';

const API = new ApiService();

export const getRiskMeterWithLocation = async (
  body: RiskMeterWithLocationRequest,
): Promise<RiskMeterResponse> => {
  const { data }: { data: IGenericIntegrationResponse<RiskMeterResponse> } = await API.get(
    `services/corelogic/risk-meter-with-latitude-and-longitude`,
    body as Params,
  );

  return data?.data ?? {};
};
