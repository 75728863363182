import { QueuesContext } from 'providers/QueuesProvider/QueuesProviderContext';
import { IQueuesContextType } from 'providers/QueuesProvider/types';
import { useContext } from 'react';

export default function useQueues(): IQueuesContextType {
  const context = useContext(QueuesContext);

  /* istanbul ignore if */
  if (!context) {
    throw new Error('useQueues must be used within a QueuesProvider');
  }

  return context;
}
