import ApiService, { Params } from 'api/helpers/Sender';
import { ICreateExposure } from 'api/models/NewQuote/createExposure';
import { ICreatePeril } from 'api/models/NewQuote/createPeril';
import { ProductWorkFlow } from 'api/models/NewQuote/productWorkFlow.model';
import { IUpdateQuote } from 'api/models/NewQuote/updateQuote';
import { IPolicyholder } from 'api/models/Policyholder/policyholder.model';
import { prepareCacheOptions } from 'helpers/AxiosInterceptor/cache';
import { modifyUnderwritingQuestions } from 'helpers/QuestionEngine';
import { CreatePolicyWithPolicyholder } from '../models/NewQuote/createPolicyWithPolicyholder.model';
import { updatePolicyholderWithLocator } from './Policyholders';

const API = new ApiService();

export const getWorkFlowFormSetsFormsWithFormSetType = async ({
  product_code,
  work_flow_name,
  config_formset_type,
  cache = false,
  modifyResultForUnderwritingQuestions = false,
  query,
}: {
  product_code: string;
  work_flow_name: string;
  config_formset_type: string;
  cache?: boolean;
  modifyResultForUnderwritingQuestions?: boolean;
  query?: Params;
}): Promise<ProductWorkFlow[]> => {
  const { data } = await API.get(
    `products/${product_code}/workflows/${work_flow_name}/formsets/${config_formset_type}/forms`,
    query ?? {},
    cache ? { ...prepareCacheOptions() } : {},
  );

  let modifiedForms = data;

  if (modifyResultForUnderwritingQuestions) {
    modifiedForms = modifyUnderwritingQuestions(data);
  }

  return modifiedForms;
};

export const getWorkFlowPerilFormsWithFormCode = async ({
  product_code,
  work_flow_name,
  config_formset_type,
  form_code,
  cache = false,
  query,
}: {
  product_code: string;
  work_flow_name: string;
  config_formset_type: string;
  form_code: string;
  cache?: boolean;
  query?: Params;
}): Promise<ProductWorkFlow[]> => {
  const { data } = await API.get(
    `products/${product_code}/workflows/${work_flow_name}/formsets/${config_formset_type}/forms/${form_code}/formsets/peril/forms`,
    query ?? {},
    cache ? { ...prepareCacheOptions() } : {},
  );

  return data;
};

export const updateQuoteInfo = async (
  policyLocator: string,
  quoteLocator: string,
  body: IUpdateQuote,
  query?: Params,
  hasBusinessNameChanged?: { changed: boolean; newName: any; policyholder: IPolicyholder },
): Promise<any> => {
  const { data } = await API.patch(
    `policies/${policyLocator}/quotes/${quoteLocator}`,
    body,
    {},
    query,
  );

  // Sync policyholder name with `businessName` #11455
  if (hasBusinessNameChanged?.changed) {
    const { newName, policyholder } = hasBusinessNameChanged;

    await updatePolicyholderWithLocator(
      {
        data: {
          ...(policyholder.data ?? {}),
          business_name: newName,
        },
        broker_of_record: null,
      },
      policyholder.locator,
    );
  }

  return data;
};

export const addExposure = async (policyLocator: string, body: ICreateExposure): Promise<any> => {
  const { data } = await API.post(`policies/${policyLocator}/exposures`, body);

  return data;
};

export const updateExposure = async (
  policyLocator: string,
  exposureLocator: string,
  body: { data: any; perils?: ICreatePeril[] },
): Promise<any> => {
  const { data } = await API.patch(`policies/${policyLocator}/exposures/${exposureLocator}`, body);

  return data;
};

export const deleteExposure = async (
  policyLocator: string,
  exposureLocator: string,
): Promise<any> => {
  const { data } = await API.post(`policies/${policyLocator}/exposures/${exposureLocator}/discard`);

  return data;
};

export const addPeril = async (
  policyLocator: string,
  exposureLocator: string,
  body: ICreatePeril,
): Promise<any> => {
  const { data } = await API.post(
    `policies/${policyLocator}/exposures/${exposureLocator}/perils`,
    body,
  );

  return data;
};

export const deletePeril = async (
  policyLocator: string,
  exposureLocator: string,
  perilLocator: string,
  payload?: any,
): Promise<any> => {
  const { data } = await API.post(
    `policies/${policyLocator}/exposures/${exposureLocator}/perils/${perilLocator}/discard`,
    payload,
  );

  return data;
};

export const updatePeril = async (
  policyLocator: string,
  exposureLocator: string,
  body: any,
): Promise<any> => {
  const { data } = await API.patch(`policies/${policyLocator}/exposures/${exposureLocator}`, body);

  return data;
};

export const updatePerilSocotra = async (
  policyLocator: string,
  exposureLocator: string,
  perilLocator: string,
  body: any,
): Promise<any> => {
  const { data } = await API.patch(
    `policies/${policyLocator}/exposures/${exposureLocator}/perils/${perilLocator}/socotra_update`,
    body,
  );

  return data;
};

export const bulkExposureUpdateQuote = async (
  policyLocator: string,
  quoteLocator: string,
  body: any,
) => {
  const { data } = await API.post(
    `policies/${policyLocator}/quotes/${quoteLocator}/bulk-exposure-update`,
    body,
  );

  return data;
};

export const createPolicyWithPolicyholder = async (body: CreatePolicyWithPolicyholder) => {
  const { data } = await API.post(`policies`, body);

  return data;
};
