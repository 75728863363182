import { createContext } from 'react';

/// types
import { ILoaderContextType, ILoaderStore } from './types';

export const initialLoaderData: ILoaderStore = {
  loading: false,
};

export const loaderContextDefault: ILoaderContextType = {
  ...initialLoaderData,
  resetLoaderState: () => {},
  setLoading: () => {},
};

export const LoaderContext = createContext<ILoaderContextType>(null!);
