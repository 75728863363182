import WcClassCodeAutocompleteInput from 'components/QuotePolicyDetailEndorsement/Inputs/WcClassCodeAutocomplete';
import { IWcClassCodeAutocomplete } from 'components/QuotePolicyDetailEndorsement/Inputs/WcClassCodeAutocomplete/types';
import usePolicyDetail from 'hooks/usePolicyDetail';
import React, { FC, useMemo } from 'react';

const WcClassCodeAutocomplete: FC<IWcClassCodeAutocomplete> = ({ ...props }) => {
  const { data: policyDetail } = usePolicyDetail();

  const policyEffectiveDate = useMemo(
    () => new Date(policyDetail?.characteristics?.started_at!),
    [policyDetail],
  );

  return <WcClassCodeAutocompleteInput policyEffectiveDate={policyEffectiveDate} {...props} />;
};

export default WcClassCodeAutocomplete;
