import { KeyboardArrowDownRounded } from '@mui/icons-material';
import {
  Box,
  FormControl,
  FormHelperText,
  InputLabel,
  SelectChangeEvent,
  Skeleton,
  Stack,
  TextField,
  Typography,
} from '@mui/material';
import Button, { ButtonProps } from '@mui/material/Button';
import { styled } from '@mui/material/styles';
import { DocumentType } from 'api/models/Documents/documentType.model';
import { PolicyDocumentCreate } from 'api/models/Documents/policyDocumentCreate.model';
import { globalSearchTypes } from 'common/constants';
import CustomNativeSelect from 'components/CustomNativeSelect';
import DrawerComponent from 'components/DrawerComponent';
import RouterPrompt from 'components/RouterPrompt';
import { useFormik } from 'formik';
import displayToastMessage from 'helpers/DisplayToastMessage';
import {
  drawerFooterPrimaryButtonStyle,
  drawerFooterSecondaryButtonStyle,
} from 'helpers/MuiSharedStyles';
import ScrollToFormikError from 'helpers/ScrollToFormikError';
import { deleteFromQueryStrings, handleBackendErrorsWithFormik } from 'helpers/Utils';
import useDialog from 'hooks/useDialog';
import useDocuments from 'hooks/useDocuments';
import useLoader from 'hooks/useLoader';
import { isEmpty } from 'lodash-es';
import { IContext } from 'providers/types';
import React, { FC, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory, useLocation } from 'react-router-dom';
import { IAxiosError } from 'types/ErrorResponseTypes';
import * as yup from 'yup';
import DocumentFileUploader from '../DocumentFileUploader';

interface AddDocumentProps {
  isDrawerOpen: boolean;
  associated: { reference_type: string; reference_locator: string };
  onCreated?: () => void;
  documentTypes: IContext<DocumentType[]>;
  fetchDocumentTypes: () => void;
}

let uploadController: AbortController;

const CancelUploadButton = styled(Button)<ButtonProps>(({ theme }) => ({
  color: theme.customColors.blue200,
  backgroundColor: theme.customColors.white50,
  fontSize: '14px',
  width: '100%',
  textDecoration: 'underline',
  marginLeft: 'auto',
  '&:hover': {
    backgroundColor: theme.customColors.white50,
    boxShadow: 'none',
    color: theme.palette.error.dark,
  },
}));

const UploadButton = styled(Button)<ButtonProps>(({ theme }) => ({
  color: theme.customColors.white50,
  backgroundColor: theme.customColors.info,
  fontSize: '14px',
  width: '100%',
  marginLeft: 'auto',
  '&:hover': {
    backgroundColor: theme.customColors.blue200,
    color: theme.customColors.white50,
    boxShadow: 'none',
  },
}));

const AddDocumentDrawer: FC<AddDocumentProps> = ({
  isDrawerOpen,
  associated,
  onCreated,
  documentTypes,
  fetchDocumentTypes,
}) => {
  const { t } = useTranslation();
  const HISTORY = useHistory();
  const LOCATION = useLocation();
  const { setLoading } = useLoader();
  const { setDialogOpen } = useDialog();
  const { createDocument } = useDocuments();
  const [upload, setUpload] = useState(false);
  const [isUploadButtonShown, setIsUploadButtonShown] = useState(false);
  const [progress, setProgress] = React.useState(0);
  const [files, setFiles] = useState<any>([]);
  const formData = new FormData();

  const validationSchema = yup.object({
    title: yup
      .string()
      .max(512, t('Subject must be at most {{charLimit}} characters.', { charLimit: 512 }))
      .required(t('This field may not be blank.')),
    doc_type: yup.string().required(t('Announcement Type is required')),
  });

  useEffect(() => {
    fetchDocumentTypes();
  }, []);

  const handleQuery = () => {
    HISTORY.push({
      search: deleteFromQueryStrings({
        locationSearch: LOCATION.search,
        omitKeys: ['addDocument'],
      }),
    });
    setDialogOpen({
      dialog: 'DISCARD_UPLOAD_DOCUMENT',
      isOpen: false,
    });
  };

  const getAssociatedWithType = (type: string) => {
    switch (type) {
      case globalSearchTypes.POLICY:
        return 'policies';

      default:
        return '';
    }
  };

  const formik = useFormik<PolicyDocumentCreate>({
    initialValues: {
      title: '',
      policy: associated.reference_locator,
      document: undefined,
      doc_type: '',
      signed: 'not_applicable',
    },
    validationSchema,
    onSubmit: async (values) => {
      if (formik.isValid) {
        formData.append('document', files!);
        formData.append('title', values.title!);
        formData.append('policy', values.policy!);
        formData.append('doc_type', values.doc_type!);
        formData.append('signed', values.signed!);
        try {
          // setLoading(true);
          setUpload(true);
          setIsUploadButtonShown(false);
          uploadController = new AbortController();
          await createDocument(
            formData,
            getAssociatedWithType(associated.reference_type),
            associated.reference_locator,
            (progressEvent) => {
              const percentComplete = progressEvent.loaded / progressEvent.total;
              const uploadPercantage = percentComplete * 100;
              setProgress(uploadPercantage);
            },
            uploadController,
          );
          setUpload(false);
          onCreated?.();
          displayToastMessage('SUCCESS', t('The document has been uploaded.'));
          handleQuery();
        } catch (error) {
          handleBackendErrorsWithFormik<unknown>(error, formik);
          const e = error as IAxiosError;
          if (e.isAxiosError) {
            if (e.response?.data.messages && e.response?.data.messages[0]) {
              displayToastMessage('ERROR', e.response?.data.messages[0]);
            }
          }
        } finally {
          setLoading(false);
        }
      }
    },
  });

  useEffect(() => {
    formData.append('files', files);
    if (!isEmpty(files)) {
      setIsUploadButtonShown(true);
    }
  }, [files]);

  const canceledUpload = async () => {
    HISTORY.push({
      search: deleteFromQueryStrings({
        locationSearch: LOCATION.search,
        omitKeys: ['addDocument'],
      }),
    });
    await uploadController.abort();
    setProgress(0);
    setUpload(false);
  };

  return (
    <>
      <RouterPrompt
        when={upload}
        onLeave={() => {
          uploadController.abort();
          setProgress(0);
          setUpload(false);
        }}
      />
      <DrawerComponent
        isDrawerOpen={isDrawerOpen}
        onClose={() => {
          if (upload) {
            setDialogOpen({
              dialog: 'DISCARD_UPLOAD_DOCUMENT',
              isOpen: true,
              onAccept: () => canceledUpload(),
            });
          } else {
            HISTORY.push({
              search: deleteFromQueryStrings({
                locationSearch: LOCATION.search,
                omitKeys: ['addDocument'],
              }),
            });
          }
        }}
        // progress={progress}
        header={
          <Typography
            sx={{
              fontSize: 16,
              fontWeight: 500,
              lineHeight: '24px',
              color: (theme) => theme.customColors.drawer.header,
            }}
          >
            {t('Upload Document')}
          </Typography>
        }
        content={
          <>
            <ScrollToFormikError formik={formik} />
            <form onSubmit={formik.handleSubmit} id="newDocumentForm" noValidate>
              <Stack gap={2}>
                <FormControl size="small" sx={{ flex: 1 }}>
                  <TextField
                    required
                    size="small"
                    name="title"
                    label={t('Document Title')}
                    value={formik.values.title}
                    onChange={formik.handleChange}
                    helperText={formik.touched.title && formik.errors.title}
                    error={formik.touched.title && Boolean(formik.errors.title)}
                  />
                </FormControl>
                <FormControl
                  size="small"
                  sx={{ flex: 1 }}
                  required
                  error={formik.touched.doc_type && Boolean(formik.errors.doc_type)}
                >
                  {documentTypes?.loading ? (
                    <Skeleton animation="wave" width="100%" height={37} />
                  ) : (
                    <>
                      <InputLabel id="typeLabel">{t('Document Type')}</InputLabel>
                      <CustomNativeSelect
                        name="doc_type"
                        label={t('Document Type')}
                        error={formik.touched.doc_type && Boolean(formik.errors.doc_type)}
                        id="doc_type"
                        value={formik.values.doc_type}
                        sx={{
                          '& > .MuiSelect-select': { display: 'flex', alignItems: 'center' },
                        }}
                        onChange={(e: SelectChangeEvent<unknown>) => {
                          formik.setFieldValue('doc_type', String(e.target.value));
                          const oldDocumentTitle =
                            documentTypes?.loaded &&
                            documentTypes?.data!.some(
                              (item) => String(item.name) === String(formik.values.title),
                            );
                          if (isEmpty(formik.values.title) || oldDocumentTitle) {
                            const documentTitleLabel =
                              documentTypes?.loaded &&
                              documentTypes?.data?.find(
                                (item) => String(item.code) === String(e.target.value),
                              )?.name;
                            formik.setFieldValue('display_name', documentTitleLabel as string);
                          }
                        }}
                        IconComponent={KeyboardArrowDownRounded}
                      >
                        {documentTypes?.data?.map((item) => (
                          <option value={`${item.code}`} key={item.id}>
                            {item.name}
                          </option>
                        ))}
                      </CustomNativeSelect>
                      {formik.touched.doc_type && formik.errors.doc_type && (
                        <FormHelperText
                          sx={{
                            mt: 1,
                            fontSize: '12px',
                            lineHeight: '14px',
                            color: (theme) => theme.customColors.alert,
                          }}
                        >
                          {t('Document Type is required')}
                        </FormHelperText>
                      )}
                    </>
                  )}
                </FormControl>
                <FormControl size="small" sx={{ flex: 1 }}>
                  <DocumentFileUploader
                    files={files}
                    setFiles={setFiles}
                    progress={progress}
                    upload={upload}
                    setUpload={setUpload}
                  />
                  {isUploadButtonShown && !Array.isArray(files) && files && (
                    <UploadButton type="submit" form="newDocumentForm" variant="contained">
                      {t('Upload')}
                    </UploadButton>
                  )}

                  {upload && progress < 100 && (
                    <CancelUploadButton
                      onClick={() => {
                        if (uploadController) {
                          uploadController.abort();
                          setProgress(0);
                        }
                        setUpload(false);
                        setIsUploadButtonShown(true);
                      }}
                      variant="contained"
                    >
                      {t('Cancel Upload')}
                    </CancelUploadButton>
                  )}
                </FormControl>
              </Stack>
            </form>
          </>
        }
        footer={
          <Box>
            {progress < 100 && (
              <Button
                onClick={() => {
                  handleQuery();
                }}
                sx={[drawerFooterSecondaryButtonStyle]}
              >
                {t('Cancel')}
              </Button>
            )}
            {!upload && progress === 100 && (
              <Button
                onClick={() => {
                  handleQuery();
                }}
                sx={[drawerFooterPrimaryButtonStyle]}
              >
                {t('Done')}
              </Button>
            )}
          </Box>
        }
      />
    </>
  );
};

export default AddDocumentDrawer;
