import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import { Box, Fab, Fade, useScrollTrigger } from '@mui/material';
import React, { FC } from 'react';

interface IProps {
  onClick?: (event: React.MouseEvent<HTMLDivElement, MouseEvent> | undefined) => {};
  targetElementId?: string;
}

const ScrollToTop: FC<IProps> = ({ onClick, targetElementId = 'tabContentContanier' }) => {
  const targetElement = document.getElementById(targetElementId);

  const trigger = useScrollTrigger({
    target: (targetElement as Node) || undefined,
    disableHysteresis: true,
  });

  const handleClick = (_event: React.MouseEvent<HTMLDivElement>) => {
    targetElement?.scrollTo({
      top: 0,
      behavior: 'smooth',
    });
  };

  return (
    <Fade in={trigger}>
      <Box
        onClick={onClick ?? handleClick}
        role="presentation"
        sx={{
          position: 'fixed',
          bottom: 50,
          right: 16,
        }}
      >
        <Fab
          size="small"
          aria-label="scroll back to top"
          sx={{
            background: (theme) => theme.customColors.activeField,
            color: (theme) => theme.customColors.white50,
            '&:hover': {
              background: (theme) => theme.customColors.activeField,
            },
          }}
        >
          <KeyboardArrowUpIcon />
        </Fab>
      </Box>
    </Fade>
  );
};

export default ScrollToTop;
