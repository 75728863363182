import { DialogContext } from 'providers/DialogProvider/DialogProviderContext';
import { IDialogContextType } from 'providers/DialogProvider/types';
import { useContext } from 'react';

export default function useDialog(): IDialogContextType {
  const context = useContext(DialogContext);

  /* istanbul ignore if */
  if (!context) {
    throw new Error('useDialog must be used within a DialogProvider');
  }

  return context;
}
