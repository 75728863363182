import { InputAdornment, TextField } from '@mui/material';
import Search from 'assets/images/Search.svg';
import { searchIconStyle } from 'helpers/MuiSharedStyles';
import { DebouncedFunc } from 'lodash-es';
import React, { ChangeEvent, FC } from 'react';
import { useTranslation } from 'react-i18next';
import { ReactSVG } from 'react-svg';

type searchType = () => void;

export interface ViewSearchProps {
  onChange?: DebouncedFunc<(event: ChangeEvent<HTMLInputElement>) => void> | searchType;
  inputRef?: React.RefObject<HTMLInputElement>;
  defaultValue?: string;
  textPlaceholder?: string;
}

const ViewSearch: FC<ViewSearchProps> = ({ onChange, inputRef, defaultValue, textPlaceholder }) => {
  const { t } = useTranslation();

  return (
    <TextField
      placeholder={textPlaceholder || t('Search this view')}
      InputProps={{
        inputRef,
        startAdornment: (
          <InputAdornment position="end" sx={{ ...searchIconStyle }}>
            <ReactSVG src={Search} className="search-icon" />
          </InputAdornment>
        ),
        inputProps: { 'aria-label': 'search' },
        defaultValue,
      }}
      sx={{
        width: {
          xs: '320px',
          sm: '320px',
          md: '380px',
          lg: '380px',
        },
        color: 'inherit',
        '& .MuiInputBase-root': {
          paddingLeft: {
            xs: '0px',
            sm: '0px',
            md: '8px',
            lg: '8px',
          },
        },
        '& .MuiInputBase-input': {
          padding: (theme) => theme.spacing(1, 0, 1, 1),
          fontSize: {
            xs: 12,
            sm: 14,
          },
        },
        '& .MuiInputBase-input::placeholder': {
          fontSize: {
            xs: 11,
            sm: 11,
            md: 12,
            lg: 12,
          },
        },
      }}
      onChange={onChange}
    />
  );
};

export default ViewSearch;
