/* eslint-disable no-shadow */

export enum SmartAddressTypes {
  STREET_LINE = 'street_line',
  SECONDARY = 'secondary',
  CITY = 'city',
  ZIPCODE = 'zipcode',
  STATE = 'state',
  COUNTRY = 'country',
}
