import { defaultTimezone } from 'common/constants';
import { createContext } from 'react';

/// types
import { IConfigContextType, IConfigStore } from './types';

export const initialConfigData: IConfigStore = {
  loading: true,
  loaded: false,
  timezoneConfig: {
    data: { code: defaultTimezone, name: defaultTimezone },
    loading: false,
    loaded: false,
  },
  endorsementTypes: {},
  advancedSearchConfig: {
    loading: true,
    loaded: false,
    data: [],
  },
  industries: {
    loading: true,
    loaded: false,
    data: [],
  },
  vintelligenceYears: {
    loading: true,
    loaded: false,
    data: [],
  },
  locFilingIdTable: {
    loading: true,
    loaded: false,
    data: [],
  },
  products: {
    loading: true,
    loaded: false,
    data: [],
  },
};

export const configContextDefault: IConfigContextType = {
  ...initialConfigData,
  resetConfigState: () => {},
  getProductsConfig: () => new Promise(() => {}),
  getTimezoneConfig: () => new Promise(() => {}),
  getEndorsementTypes: () => new Promise(() => {}),
  getAdvancedSearchConfig: () => new Promise(() => {}),
  fetchIndustries: () => new Promise(() => {}),
  fetchVintelligenceYears: () => {},
  fetchLocFilingIdTable: () => new Promise(() => {}),
  convertZonedTimeToUtc: () => new Date(),
  formatDateInTimeZone: () => '',
  addYearsDstSafe: () => new Date(),
};

export const ConfigContext = createContext<IConfigContextType>(null!);
