import { Auth } from 'api/models/Auth/auth.model';
import TokenStorage from 'helpers/TokenStorage';
import { createContext } from 'react';

/// types
import { IAuthContext, IAuthStore } from './types';

export const getInitialUserStoreData = (): IAuthStore => {
  // Get if localStorage has saved token
  const auth: Auth | null = TokenStorage.get();

  return {
    auth,
    error: null,
    loading: false,
    verifyLoading: false,
    ssoLoading: false,
  };
};

export const authContextDefault: IAuthContext = {
  ...getInitialUserStoreData(),
  signInWithSSO: () => new Promise(() => {}),
  signOutSSO: () => new Promise(() => {}),
  getAccessTokenFromSSO: () => new Promise(() => {}),
  signOut: () => new Promise(() => {}),
};

export const AuthContext = createContext<IAuthContext>(null!);

AuthContext.displayName = 'AuthContext';
