import ApiService from 'api/helpers/Sender';
import { RuleEngineDecisions } from 'api/models/RuleEngine/ruleEngineDecisions.model';
import { underwritingResultDecisions, userRoles } from 'common/constants';
import { IQueryParams } from 'helpers/prepareQueryParams';
import TokenStorage from 'helpers/TokenStorage';
import decode from 'jwt-decode';
import { DecodedToken } from 'providers/types';

const API = new ApiService();

export const getRuleEngineDecisions = async (
  params: IQueryParams,
): Promise<RuleEngineDecisions[]> => {
  const { data }: { data: RuleEngineDecisions[] } = await API.get('underwriting-decisions', params);

  // #region modify results according to https://dev.azure.com/radity-gmbh/THREE-insurance/_workitems/edit/11312/
  const auth = TokenStorage.get();
  const decodedToken = (auth?.access ? decode(auth.access ?? '') : {}) as DecodedToken;

  const declinedRules: RuleEngineDecisions[] = [];
  const referredRules: RuleEngineDecisions[] = [];

  data?.forEach((result) => {
    switch (result?.rule_engine_decision_result?.code) {
      case underwritingResultDecisions.DECLINED?.code:
        declinedRules.push(result);
        break;

      case underwritingResultDecisions.REFERRED?.code: {
        let tmpResult = { ...result };

        if (decodedToken?.role === userRoles.AGENT.code && tmpResult.is_underwriter_approved) {
          tmpResult = {
            ...tmpResult,
            rule_engine_decision_result: { ...underwritingResultDecisions.APPROVED },
          };
        }

        referredRules.push(tmpResult);
        break;
      }

      default:
        break;
    }
  });
  // #endregion

  return [...declinedRules, ...referredRules];
};

export const updateRuleEngineDecision = async (
  id: number,
  body: any,
): Promise<RuleEngineDecisions> => {
  const { data }: { data: any } = await API.patch(`underwriting-decisions/${id}`, body);

  return data;
};
