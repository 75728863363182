import { Box, Skeleton } from '@mui/material';
import Cancel from 'assets/images/CancelIcon.svg';
import AddNote from 'assets/images/icon_addnote.svg';
import AddDocumnet from 'assets/images/icon_attach_document.svg';
import RequestCancellation from 'assets/images/icon_documents-file.svg';
import GenerateCertificate from 'assets/images/icon_generatecertificate.svg';
import PolicyChange from 'assets/images/icon_policychange.svg';
import { globalSearchTypes, policyDetailStatuses, productCodes, userRoles } from 'common/constants';
import GenerateCertificateDrawer from 'components/Certificate/GenerateCertificate';
import AddDocumentDrawer from 'components/Documents/AddDocument';
import AddNoteDrawer from 'components/Notes/AddNote';
import CancellationDialog from 'components/Policies/Cancellation';
import { CreateEndorsement } from 'components/Policies/Endorsement/CreateEndorsement';
import CreateWcAuditEndorsementModal from 'components/Policies/Endorsement/Modals/CreateWcAuditEndorsement';
import TopActionBar from 'components/TopActionBar';
import TopActionBarButton from 'components/TopActionBarButton';
import TopActionBarDivider from 'components/TopActionBarDivider';
import { isFuture } from 'date-fns';
import { updateQueryStrings } from 'helpers/Utils';
import usePolicyDetail from 'hooks/usePolicyDetail';
import useUser from 'hooks/useUser/useUser';
import qs from 'query-string';
import React, { FC, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory, useLocation } from 'react-router-dom';

export interface TopActionBarProps {
  policyId: string;
  onNoteCreated?: () => void;
  onDocumentCreated?: () => void;
}

const PolicyDetailTopActionBar: FC<TopActionBarProps> = ({
  policyId,
  onNoteCreated,
  onDocumentCreated,
}) => {
  const { t } = useTranslation();
  const LOCATION = useLocation();
  const HISTORY = useHistory();
  const [isCancellationModalOpen, setIsCancellationModalOpen] = useState<boolean>(false);
  const [isCreateEndorsementDialogOpen, setIsCreateEndorsementDialogOpen] =
    useState<boolean>(false);
  const { data: policyDetail, loading, getPolicyDocumentTypes, documentTypes } = usePolicyDetail();
  const { data: userData } = useUser();
  const url = qs.parse(LOCATION.search);

  const isUW = userData?.role?.code === userRoles.UNDERWRITER.code;

  const showAddNoteButton = isUW;
  const isAddNoteDrawerOpen = showAddNoteButton && url?.addNote === 'true';
  const isAddDocumentDrawerOpen = url?.addDocument === 'true';
  const isGenerateCertificateDrawerOpen = url?.generateCertificate === 'true';

  // if it's true means clicked new business in history tab
  const isNewBusinessView = LOCATION.pathname.endsWith('/new-business/');

  const isEffectiveDateInFuture =
    policyDetail?.original_contract_start_time &&
    isFuture(new Date(policyDetail.original_contract_start_time));

  const isPolicyChangeButtonVisible =
    policyDetail?.status?.key! === policyDetailStatuses.ACTIVE ||
    (policyDetail?.status?.key! === policyDetailStatuses.INACTIVE && isEffectiveDateInFuture) ||
    (policyDetail?.status?.key! === policyDetailStatuses.IN_CANCELLATION && isUW);

  const isWCAuditButtonVisible =
    isUW &&
    policyDetail?.product?.code === productCodes.THREE_WITH_WORKERS_COMPENSATION &&
    [policyDetailStatuses.CANCELLED, policyDetailStatuses.EXPIRED].some(
      (k) => k === policyDetail?.status?.key!,
    );

  const isCancelPolicyButtonVisible = policyDetail?.status?.key! !== policyDetailStatuses.CANCELLED;

  const onPolicyChanged = () => {
    HISTORY.push({
      search: updateQueryStrings({
        locationSearch: LOCATION.search,
        newQueries: { createEndorsement: true },
      }),
    });
    setIsCreateEndorsementDialogOpen(true);
  };

  const openWcAuditStarterModal = () => {
    HISTORY.push({
      search: updateQueryStrings({
        locationSearch: LOCATION.search,
        newQueries: { createWcAudit: true },
      }),
    });
  };

  const onPayInvoice = () => {
    HISTORY.push({
      pathname: 'billings',
      search: `?payInvoice=true&step=1`,
    });
  };

  const handleNewNote = () => {
    HISTORY.push({
      search: updateQueryStrings({
        locationSearch: LOCATION.search,
        newQueries: { addNote: true },
      }),
    });
  };

  const handleCancellation = () => {
    HISTORY.push({
      search: updateQueryStrings({
        locationSearch: LOCATION.search,
        newQueries: { requestCancellation: true },
      }),
    });
    setIsCancellationModalOpen(true);
  };

  const handleNewDocument = () => {
    HISTORY.push({
      search: updateQueryStrings({
        locationSearch: LOCATION.search,
        newQueries: { addDocument: true },
      }),
    });
  };

  const handleGenerateCertificate = () => {
    HISTORY.push({
      search: updateQueryStrings({
        locationSearch: LOCATION.search,
        newQueries: { generateCertificate: true },
      }),
    });
  };


  const handleCreateNote = () => {
    const isOnNotePage = LOCATION.pathname.includes('notes');
    if (!isOnNotePage) {
      HISTORY.push({
        pathname: `/policies/${policyId}/notes`,
      });
    }
    onNoteCreated?.();
  };

  const handleCreateDocument = () => {
    const isDocumentPage = LOCATION.pathname.includes('documents');
    if (!isDocumentPage) {
      HISTORY.push({
        pathname: `/policies/${policyId}/documents`,
      });
    }
    onDocumentCreated?.();
  };

  useEffect(() => {
    if (LOCATION.search.includes('requestCancellation')) {
      setIsCancellationModalOpen(true);
    }

    if (!LOCATION.search.includes('requestCancellation')) {
      setIsCancellationModalOpen(false);
    }

    if (LOCATION.search.includes('createEndorsement')) {
      setIsCreateEndorsementDialogOpen(true);
    }
    if (!LOCATION.search.includes('createEndorsement')) {
      setIsCreateEndorsementDialogOpen(false);
    }
  }, [LOCATION]);

  return (
    <>
      {isCreateEndorsementDialogOpen && !loading && (
        <CreateEndorsement
          isModalOpen={isCreateEndorsementDialogOpen}
          setIsModalOpen={setIsCreateEndorsementDialogOpen}
        />
      )}

      <CreateWcAuditEndorsementModal />

      {isAddNoteDrawerOpen && (
        <AddNoteDrawer
          isDrawerOpen={isAddNoteDrawerOpen}
          associated={{
            reference_type: globalSearchTypes.POLICY,
            reference_locator: policyId,
            three_number: policyDetail?.three_policy_number ?? policyId,
          }}
          additionalInfo={policyDetail?.policyholder?.name}
          onCreated={handleCreateNote}
        />
      )}

      {isGenerateCertificateDrawerOpen && (
        <GenerateCertificateDrawer isDrawerOpen={isGenerateCertificateDrawerOpen} />
      )}

      {isCancellationModalOpen && (
        <CancellationDialog
          isModalOpen={isCancellationModalOpen}
          setIsModalOpen={setIsCancellationModalOpen}
        />
      )}

      {isAddDocumentDrawerOpen && (
        <AddDocumentDrawer
          isDrawerOpen={isAddDocumentDrawerOpen}
          associated={{
            reference_type: globalSearchTypes.POLICY,
            reference_locator: policyId,
          }}
          onCreated={handleCreateDocument}
          documentTypes={documentTypes}
          fetchDocumentTypes={getPolicyDocumentTypes}
        />
      )}

      <TopActionBar>
        {loading &&
          [...Array(5).keys()].map((_, i) => (
            <Skeleton
              animation="wave"
              width="5%"
              height={28}
              sx={{ marginY: 1 / 2, marginX: 1, alignSelf: 'center' }}
              key={i}
            />
          ))}
        {!loading && (
          <>
            {isPolicyChangeButtonVisible && (
              <TopActionBarButton
                startIcon={
                  <Box component="img" src={PolicyChange} sx={{ width: '24px', height: '24px' }} />
                }
                onClick={onPolicyChanged}
              >
                {t('Policy Change')}
              </TopActionBarButton>
            )}

            {isWCAuditButtonVisible && (
              <TopActionBarButton
                startIcon={
                  <Box component="img" src={PolicyChange} sx={{ width: '24px', height: '24px' }} />
                }
                onClick={openWcAuditStarterModal}
              >
                {t('WC Audit')}
              </TopActionBarButton>
            )}

            <TopActionBarButton
              startIcon={
                <Box component="img" src={PolicyChange} sx={{ width: '24px', height: '24px' }} />
              }
              onClick={onPayInvoice}
            >
              {t('Pay Invoice')}
            </TopActionBarButton>

            {isCancelPolicyButtonVisible && (
              <TopActionBarButton
                onClick={handleCancellation}
                startIcon={
                  <Box
                    component="img"
                    src={RequestCancellation}
                    sx={{ width: '24px', height: '24px' }}
                  />
                }
              >
                {t('Cancel Policy')}
              </TopActionBarButton>
            )}

            <TopActionBarDivider />

            <TopActionBarButton
              startIcon={
                <Box component="img" src={AddDocumnet} sx={{ width: '24px', height: '24px' }} />
              }
              onClick={handleNewDocument}
            >
              {t('Attach Document')}
            </TopActionBarButton>
            {showAddNoteButton && (
              <TopActionBarButton
                startIcon={
                  <Box component="img" src={AddNote} sx={{ width: '24px', height: '24px' }} />
                }
                onClick={handleNewNote}
              >
                {t('Add Note')}
              </TopActionBarButton>
            )}

            <TopActionBarButton
              startIcon={
                <Box
                  component="img"
                  src={GenerateCertificate}
                  sx={{ width: '24px', height: '24px' }}
                />
              }
              onClick={handleGenerateCertificate}
            >
              {t('Generate Certificate')}
            </TopActionBarButton>
            {isNewBusinessView && (
              <TopActionBarButton
                onClick={() => HISTORY.push(`/policies/${policyId}`)}
                startIcon={
                  <Box component="img" src={Cancel} sx={{ width: '24px', height: '24px' }} />
                }
              >
                {t('Close')}
              </TopActionBarButton>
            )}
          </>
        )}
      </TopActionBar>
    </>
  );
};

export default PolicyDetailTopActionBar;
