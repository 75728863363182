import { createContext } from 'react';

/// types
import { IUserContextType, IUserStore } from './types';

export const initialUserData: IUserStore = {
  data: {},
  loading: true,
  loaded: false,
};

export const userContextDefault: IUserContextType = {
  ...initialUserData,
  resetUserState: () => {},
  fetch: () => new Promise(() => {}),
  updateCurrentUser: () => new Promise(() => {}),
};

export const UserContext = createContext<IUserContextType>(null!);
