/* eslint-disable react/require-default-props */

import { KeyboardArrowDown, KeyboardArrowUp } from '@mui/icons-material';
import { Box, BoxProps, ClickAwayListener, Menu } from '@mui/material';
import React, { FC, useEffect } from 'react';

type Props = BoxProps & {
  anchor: React.ReactNode;
  children: React.ReactNode;
  renderDefaultIcon?: boolean;
  titleMenu?: boolean;
  margintop?: string | number;
  openMenu?: boolean;
  controlOpenMenu?: boolean;
  left?: string | number;
  Width?: string | number;
};

const MenuComponent: FC<Props> = ({
  children,
  titleMenu = false,
  anchor,
  margintop,
  openMenu = false,
  controlOpenMenu = false,
  renderDefaultIcon = true,
  left,
  width,
  ...props
}) => {
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);

  const [anchorWidth, setAnchorWidth] = React.useState<string>('unset');
  const [anchorHeight, setAnchorHeight] = React.useState<string>('unset');

  const handleOpenMenu = (event: React.MouseEvent<HTMLElement>) => {
    const target = event.currentTarget;
    setAnchorWidth(`${target.getBoundingClientRect().width}px`);
    setAnchorHeight(`${target.getBoundingClientRect().height}px`);
    setAnchorEl(target);
  };

  const handleCloseMenu = () => {
    setAnchorEl(null);
  };

  useEffect(() => {
    if (!openMenu) {
      setAnchorEl(null);
    }
  }, [openMenu]);
  return (
    <Box
      sx={{
        display: 'flex',
        alignItems: 'center',
      }}
      {...props}
    >
      <Box
        onClick={handleOpenMenu}
        sx={{
          display: 'flex',
          alignItems: 'center',
          cursor: 'pointer',
        }}
      >
        {anchor}
        {renderDefaultIcon ? (
          !anchorEl ? (
            <KeyboardArrowDown sx={{ opacity: 0.5, ml: 0.5 }} />
          ) : (
            <KeyboardArrowUp sx={{ opacity: 0.5, ml: 0.5 }} />
          )
        ) : (
          <></>
        )}
      </Box>
      <Menu
        anchorEl={anchorEl}
        disableScrollLock
        anchorOrigin={{
          vertical: 'top',
          horizontal: 'left',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'left',
        }}
        open={Boolean(anchorEl)}
        onClose={handleCloseMenu}
        sx={{
          mt: margintop != null ? margintop : anchorHeight,
          left: left && left,
          '& .MuiMenuItem-root': {
            color: (theme) => theme.customColors.menu.itemText,
            '&:hover': {
              color: (theme) => theme.customColors.menu.itemHoverText,
              backgroundColor: (theme) => theme.customColors.menu.itemHoverBg,
              '& > svg,& > p': { color: (theme) => theme.customColors.menu.itemHoverText },
            },
          },
          ...(titleMenu && {
            '& > .MuiPaper-root': {
              borderRadius: 0,
              '& > .MuiList-root': {
                borderRadius: 0,
                p: 0,
                minWidth: !width ? anchorWidth : width,

                '& > div > div > .MuiMenuItem-root': {
                  fontSize: 14,
                  lineHeight: '28px',
                  boxShadow: (theme) => theme.customShadows.shadow5,
                },
              },
            },
          }),
          ...(controlOpenMenu && {
            '& > .MuiPaper-root': {
              borderRadius: 0,
              display: openMenu ? 'block' : 'none',
              '& > .MuiList-root': {
                borderRadius: 0,
                py: 0.5,
                minWidth: !width ? anchorWidth : width,
                '& > div > .MuiMenuItem-root': {
                  fontSize: 14,
                  lineHeight: '28px',
                  boxShadow: (theme) => theme.customShadows.shadow5,
                },
              },
            },
          }),
        }}
      >
        <div onClick={handleCloseMenu}>
          <ClickAwayListener onClickAway={handleCloseMenu}>
            <div>{children}</div>
          </ClickAwayListener>
        </div>
      </Menu>
    </Box>
  );
};

export default MenuComponent;
