import { ExpandMore } from '@mui/icons-material';
import { Accordion, AccordionDetails, AccordionSummary, Box, Typography } from '@mui/material';
import QuestionEngineFieldParser from 'components/QuestionEngineFieldParser';
import { accordionSummaryStyles, accordionSummaryStylesDisabled } from 'helpers/MuiSharedStyles';
import { fieldHasValue } from 'helpers/Utils';
import { pick } from 'lodash-es';
import React, { createRef, FC, useEffect, useState } from 'react';

interface IProps {
  title: string;
  formik: any;
  state: any;
  setState: any;
  fields: any[];
  isReadOnly?: boolean;
  relationalFields: any;
}

const DetailAccordion: FC<IProps> = ({
  title,
  formik,
  state,
  setState,
  fields,
  isReadOnly = false,
  relationalFields,
}) => {
  const questionEngineRef = createRef<{ renderedQuestions: () => any[] }>();
  const [renderSection, setRenderSection] = useState(true);

  const [progress, setProgress] = useState('0 / 0');

  useEffect(() => {
    const renderedQuestions = questionEngineRef?.current?.renderedQuestions();
    const answeredQuestionCount = renderedQuestions?.reduce(
      (a, f) => (fieldHasValue(state[`${f.code}`]) ? a + 1 : a),
      0,
    );

    setProgress(`${answeredQuestionCount} / ${renderedQuestions?.length ?? 0}`);

    setRenderSection((renderedQuestions?.length ?? 0) !== 0);

    setState((prevState) => ({
      ...prevState,
      ...pick(
        prevState,
        renderedQuestions?.map((q) => q.code),
      ),
    }));
  }, [JSON.stringify(state)]);

  return (
    <>
      {!renderSection ? (
        <></>
      ) : (
        <Accordion
          sx={{
            maxWidth: 980,
            boxShadow: 'none',
            mb: 2,
            '&:before': {
              display: 'none',
            },
            '& > .MuiCollapse-root': {
              background: (theme) =>
                isReadOnly ? theme.customColors.grey960 : theme.customColors.grey1150,
              borderBottomRightRadius: 1,
              borderBottomLeftRadius: 1,
            },
          }}
          defaultExpanded
        >
          <AccordionSummary
            expandIcon={<ExpandMore sx={{ fontSize: 27 }} />}
            sx={[isReadOnly ? accordionSummaryStylesDisabled : accordionSummaryStyles]}
          >
            <Typography
              sx={{
                fontWeight: 400,
                fontSize: '16px',
                lineHeight: '24px',
                letterSpacing: '0.15px',
              }}
            >
              {title}
            </Typography>

            {progress && (
              <Typography
                sx={{
                  fontWeight: 400,
                  fontSize: '12px',
                  letterSpacing: '0.15px',
                  lineHeight: '18px',
                  ml: 1.5,
                  color: (theme) => theme.customColors.copper,
                }}
              >
                {progress}
              </Typography>
            )}
          </AccordionSummary>

          <AccordionDetails
            sx={{
              background: (theme) =>
                isReadOnly ? theme.customColors.grey960 : theme.customColors.grey1150,
              borderBottomRightRadius: 1,
              borderBottomLeftRadius: 1,
              padding: '0 16px',
            }}
          >
            <Box
              sx={{
                py: 1.5,
              }}
            >
              <QuestionEngineFieldParser
                formik={formik}
                state={state}
                setState={setState}
                fields={fields ?? []}
                showLoader={false}
                ref={questionEngineRef}
                isReadOnly={isReadOnly}
                relationalFields={relationalFields}
              />
            </Box>
          </AccordionDetails>
        </Accordion>
      )}
    </>
  );
};

export default DetailAccordion;
