import { Box } from '@mui/system';
import { ProductWorkFlow } from 'api/models/NewQuote/productWorkFlow.model';
import {
  policyDetailInfoTabs,
  quoteExtraStatuses,
  submissionDetailInfoTabs,
  TAB_POSITIONS,
  userRoles,
} from 'common/constants';
import NavigationButtons from 'components/QuotePolicyDetailEndorsement/NavigationButtons';
import ScrollToTop from 'components/ScrollToTop';
import { useFormik } from 'formik';
import displayBackendErrorMessage from 'helpers/displayBackendErrorMessage';
import { emitter, Events } from 'helpers/EventBus';
import { PreQualQuestionsLoading } from 'helpers/PreQualQuestionsLoading';
import { handleShowCondition } from 'helpers/QuestionEngine';
import ScrollToFormikError from 'helpers/ScrollToFormikError';
import {
  addRequiredValidationToDynamicFields,
  isUWQuestionsResponseExtraStatusReferOrDecline,
  redirectToProducerTab,
  updateQueryStrings,
} from 'helpers/Utils';
import useLoader from 'hooks/useLoader';
import useQuoteDetail from 'hooks/useQuoteDetail';
import useUser from 'hooks/useUser';
import { isEmpty } from 'lodash-es';
import React, { forwardRef, useEffect, useImperativeHandle, useMemo, useState } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import * as yup from 'yup';
import DetailAccordion from './Accordions/DetailAccordion';

const Operations = forwardRef<any, any>((_props, ref) => {
  const { id } = useParams<{ id: string }>();
  const {
    data: quoteDetail,
    underwritingQuestionsState,
    underwritingQuestions,
    setUnderwritingQuestionsState,
    saveUnderwritingQuestionsState,
    canEdit,
    isPreQual,
    getExposures,
    updateQuoteProgress,
    isHandleNextClicked,
    setHandleNextButtonClicked,
    progress: { data: progressData, loading: progressLoading },
  } = useQuoteDetail();

  const [state, setState] = useState({
    ...underwritingQuestionsState,
  });

  const [canReInitialize, setCanReInitialize] = useState(true);
  const [isOperationsUpdated, setIsOperationsUpdated] = useState(false);

  const isOperationsTabNotCompleted = !(submissionDetailInfoTabs.OPERATIONS.code in progressData);

  const { data: user } = useUser();
  const HISTORY = useHistory();

  const { setLoading } = useLoader();
  const operationsFields = useMemo(
    () =>
      (underwritingQuestions?.policy?.data as ProductWorkFlow[])?.find(
        (f) => f.code === policyDetailInfoTabs.OPERATIONS.code,
      )?.fields ?? [],
    [underwritingQuestions],
  );

  const isProducer = user?.role?.code === userRoles.AGENT.code;

  const isProgressTabs = isProducer && canEdit;

  const visibleFields = operationsFields
    .flatMap((i) => i.nested_fields)
    .filter((i) => i && handleShowCondition(i, state) && !i?.is_hidden);

  const initialValues = useMemo(
    () =>
      operationsFields
        .flatMap((i) => i.nested_fields)
        .reduce((acc, cur) => ({ ...acc, [cur?.code!]: '' }), {}),
    [operationsFields],
  );

  const commonValidations = {
    ...addRequiredValidationToDynamicFields(visibleFields, state, user?.role?.code),
  };

  const validationSchema = yup.lazy(() => {
    const shapes = {
      ...commonValidations,
    };
    return yup.object().shape(shapes);
  });

  const formik = useFormik({
    initialValues: {
      ...initialValues,
      ...state,
    },
    validationSchema,
    enableReinitialize: canReInitialize,
    onSubmit: (_values) => {},
  });

  const replaceBack = () =>
    HISTORY.replace({
      search: updateQueryStrings({
        locationSearch: HISTORY.location.search,
        newQueries: { tab: submissionDetailInfoTabs.OPERATIONS.code },
      }),
    });

  const inputsHasError = async () => {
    // validate this page
    await formik.submitForm();
    const pageErrors = await formik.validateForm();

    await formik.setTouched(
      {
        ...formik.touched,
        ...Object.keys(commonValidations).reduce((a, key) => ({ ...a, [`${key}`]: true }), {}),
      },
      false,
    );

    return !isEmpty(pageErrors);
  };

  const redirectToTab = (underwritingQuestionsResponse: any) => {
    const isQuoteStatusValid = isUWQuestionsResponseExtraStatusReferOrDecline(
      quoteExtraStatuses,
      underwritingQuestionsResponse,
    );

    if (!isQuoteStatusValid && !isPreQual) {
      HISTORY.push({
        search: updateQueryStrings({
          locationSearch: HISTORY.location.search,
          newQueries: { tab: submissionDetailInfoTabs.LOCATIONS.code },
        }),
      });
    } else if (isQuoteStatusValid || isPreQual) {
      HISTORY.push({
        search: updateQueryStrings({
          locationSearch: HISTORY.location.search,
          newQueries: { tab: submissionDetailInfoTabs.UW_RESULTS.code },
        }),
      });
    }
  };

  const handleNext = async ({
    triggerHistoryChange = true,
    saveWithoutValidation = false,
    positionTabClicked = '',
    emitterTriggered = false,
    isNextButtonClicked = false,
  }) => {
    const isNavigatingForward = positionTabClicked === TAB_POSITIONS.NEXT || isNextButtonClicked;

    let underwritingQuestionsResponse = quoteDetail;

    if ((await inputsHasError()) && !saveWithoutValidation) {
      replaceBack();
      throw new Error('Validation error');
    } else if (formik.dirty && (!isOperationsUpdated || emitterTriggered)) {
      try {
        setLoading(true);
        underwritingQuestionsResponse = await saveUnderwritingQuestionsState();
        // rule engine will update the exposures, so we need to fetch them again
        if (!isPreQual) getExposures(id, { page_size: 10000 });

        setIsOperationsUpdated(true);

        if (isProgressTabs) {
          if (isOperationsTabNotCompleted && isNavigatingForward) {
            await updateQuoteProgress({
              locator: id,
              currentTab: submissionDetailInfoTabs.OPERATIONS.code,
              isCompleted:
                !(await inputsHasError()) ??
                progressData[submissionDetailInfoTabs.OPERATIONS.code]?.is_completed,
            });
          }
        }
      } catch (error) {
        displayBackendErrorMessage(error);
        replaceBack();
        throw error;
      } finally {
        setLoading(false);
      }
    }
    // when a submission clone is generated, it ought to trigger the updateQuoteProgress function if the data remains unchanged.
    else if (!formik.dirty && isProgressTabs && isOperationsTabNotCompleted) {
      if (isNavigatingForward) {
        try {
          setLoading(true);
          await updateQuoteProgress({
            locator: id,
            currentTab: submissionDetailInfoTabs.OPERATIONS.code,
            isCompleted:
              !(await inputsHasError()) ??
              progressData[submissionDetailInfoTabs.OPERATIONS.code]?.is_completed,
          });
        } catch (error) {
          displayBackendErrorMessage(error);
        } finally {
          setLoading(false);
        }
      }
    }

    if (triggerHistoryChange) {
      if (isProgressTabs && !progressLoading) {
        const isTriggeredRules = isUWQuestionsResponseExtraStatusReferOrDecline(
          quoteExtraStatuses,
          underwritingQuestionsResponse,
        );

        HISTORY.push({
          search: updateQueryStrings({
            locationSearch: HISTORY.location.search,
            newQueries: {
              tab: redirectToProducerTab({
                currentTab: submissionDetailInfoTabs.OPERATIONS,
                triggeredRules: isTriggeredRules,
                productType: quoteDetail?.product?.code,
              }),
            },
          }),
        });
      } else {
        redirectToTab(underwritingQuestionsResponse);
      }
    }
  };

  const handlePrevious = async () => {
    HISTORY.push({
      search: updateQueryStrings({
        locationSearch: HISTORY.location.search,
        newQueries: { tab: submissionDetailInfoTabs.OPERATIONS.prevTab },
      }),
    });
  };

  const isFormsLoading = underwritingQuestions.policy?.loading ?? true;

  useImperativeHandle(ref, () => ({
    savePageInfo: async ({
      triggerHistoryChange = true,
      saveWithoutValidation = false,
      positionTabClicked = '',
    }) => {
      if (isFormsLoading) {
        replaceBack();
        throw new Error('loading');
      }

      await handleNext({
        triggerHistoryChange,
        saveWithoutValidation,
        positionTabClicked,
      });
    },
    isDirty: () => formik.dirty,
  }));

  useEffect(() => {
    setUnderwritingQuestionsState({ ...state });
  }, [state]);

  // display previous values in case it was declined with blank fields
  useEffect(() => {
    if (!canEdit) {
      setState({ ...underwritingQuestionsState });
      formik.resetForm({
        errors: {},
        touched: {},
      });
    }
  }, [canEdit]);

  useEffect(() => {
    setCanReInitialize(true);

    formik.setValues({ ...initialValues, ...formik.values });

    setTimeout(() => {
      setCanReInitialize(false);
    }, 100);
  }, [JSON.stringify(underwritingQuestions)]);

  useEffect(() => {
    const handleSubmit = async (emitterAction: keyof Events) => {
      // emit event to action bar
      if (!(await inputsHasError())) {
        await handleNext({ triggerHistoryChange: false, emitterTriggered: true });
        emitter.emit(emitterAction, true);
      }
    };

    emitter.on('validateQuestionEngineInputs', handleSubmit);
    emitter.on('declineSubmissionEndorsement', handleSubmit);
    emitter.on('sendBackSubmissionEndorsement', handleSubmit);

    return () => {
      emitter.off('validateQuestionEngineInputs', handleSubmit);
      emitter.off('declineSubmissionEndorsement', handleSubmit);
      emitter.off('sendBackSubmissionEndorsement', handleSubmit);
    };
  }, [underwritingQuestionsState]);

  return isFormsLoading ? (
    <>{PreQualQuestionsLoading()}</>
  ) : (
    <Box sx={{ m: -0.5 }}>
      <ScrollToFormikError formik={formik} />
      {operationsFields.map((section) => (
        <DetailAccordion
          key={section.code}
          title={section.name ?? ''}
          formik={formik}
          state={state}
          setState={setState}
          fields={section.nested_fields ?? []}
          isReadOnly={!canEdit}
          relationalFields={section?.relatedFields}
        />
      ))}

      {canEdit && (
        <NavigationButtons
          isPreviousButtonVisible={isProducer}
          handlePrevious={handlePrevious}
          handleNext={() => {
            setHandleNextButtonClicked(!isHandleNextClicked);
            handleNext({
              triggerHistoryChange: true,
              saveWithoutValidation: false,
              isNextButtonClicked: true,
            }).catch((e) => e);
          }}
        />
      )}
      <ScrollToTop />
    </Box>
  );
});

export default Operations;
