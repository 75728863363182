import { fieldHasValue } from 'helpers/Utils';
import React, { FC, useState } from 'react';

import { NumericFormat } from 'react-number-format';

interface IProps {
  initialValue?: number;
  onInputChange: (value: number) => void;
}

const UwQuestionsPercentageInput: FC<IProps> = ({ initialValue, onInputChange, ...props }) => {
  const [value, setValue] = useState(fieldHasValue(initialValue) ? initialValue! * 100 : '');

  const handleChange = (event) => {
    const v = event.target?.value ?? '';
    const inputValue = parseFloat(v || '0');

    const decimalValue = inputValue / 100;

    setValue(v);
    onInputChange(decimalValue);
  };

  return (
    <NumericFormat
      value={value}
      suffix="%"
      onChange={handleChange}
      allowNegative={false}
      isAllowed={(values) => {
        const { floatValue } = values;

        // @ts-ignore
        return parseFloat(floatValue ?? '0') <= 100;
      }}
      {...props}
    />
  );
};

export default UwQuestionsPercentageInput;
