import { QuotesContext } from 'providers/QuotesProvider/QuotesProviderContext';
import { IQuotesContextType } from 'providers/QuotesProvider/types';
import { useContext } from 'react';

export default function useQuotes(): IQuotesContextType {
  const context = useContext(QuotesContext);

  /* istanbul ignore if */
  if (!context) {
    throw new Error('useQuotes must be used within a QuotesProvider');
  }

  return context;
}
