import { Box, Skeleton, Stack, Typography, useTheme } from '@mui/material';
import { BoxProps } from '@mui/system';
import { defaultDebounceDelay, policyDetailStatuses, userRoles } from 'common/constants';
import CopyButton from 'components/CopyButton';
import LinkTab from 'components/LinkTab';
import PageHeader from 'components/PageHeader';
import StatusBox from 'components/StatusBox';
import ViewSearch from 'components/ViewSearch';
import useConfig from 'hooks/useConfig';
import useEndorsementDetail from 'hooks/useEndorsementDetail';
import useUser from 'hooks/useUser';
import { debounce } from 'lodash-es';
import React, { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';

export interface LinkTabProps {
  policyId: string;
  currentTab: number;
  search?: boolean;
  handleSearchInputChange?: (event: React.ChangeEvent<HTMLInputElement>) => void;
}

const PolicyEndorsementDetailHeader: FC<LinkTabProps> = ({
  policyId,
  currentTab,
  search,
  handleSearchInputChange,
}) => {
  const { t } = useTranslation();
  const { formatDateInTimeZone } = useConfig();
  const { endorsementId } = useParams<{ endorsementId: string }>();
  const { data: user } = useUser();
  const isUnderwriter = user?.role?.code === userRoles.UNDERWRITER.code;
  const importedTheme = useTheme();
  const { data, loaded } = useEndorsementDetail();
  const policyNumber = data?.policy?.three_policy_number ?? policyId;

  const tabsData = [
    { id: 1, label: t('History'), route: `/policies/${policyId}/history`, disabled: true },
    {
      id: 2,
      label: t('Details'),
      route: `/policies/${policyId}/endorsement/${endorsementId}/overview`,
    },
    {
      id: 3,
      label: t('Documents'),
      route: `/policies/${policyId}/endorsement/${endorsementId}/documents`,
    },
    {
      id: 4,
      label: t('Notes'),
      route: `/policies/${policyId}/endorsement/${endorsementId}/notes`,
      hidden: !isUnderwriter,
    },
    {
      id: 5,
      label: t('Messages'),
      route: `/policies/${policyId}/endorsement/${endorsementId}/messages`,
    },
    { id: 6, label: t('Billing'), route: `/policies/${policyId}/billings`, disabled: true },
  ];

  const policyInformations = [
    {
      id: 'start_date',
      key: t('Effective Date'),
      value:
        loaded && data?.policy?.original_contract_start_time
          ? formatDateInTimeZone(data?.policy?.original_contract_start_time)
          : '',
    },
    {
      id: 'end_date',
      key: t('Expiration Date'),
      value:
        loaded && data?.policy?.effective_contract_end_time
          ? formatDateInTimeZone(data?.policy?.effective_contract_end_time)
          : '',
    },
  ];

  const renderBadge = (status) => {
    const statusBackgroundColor = importedTheme.customColors.policiesStatusColor[
      status
    ] as BoxProps['color'];

    switch (status) {
      case policyDetailStatuses.ACTIVE:
        return <StatusBox label={t('In-Force')} color={statusBackgroundColor} />;

      case policyDetailStatuses.CANCELLED:
        return <StatusBox label={t('Cancelled')} color={statusBackgroundColor} />;

      case policyDetailStatuses.EXPIRED:
        return <StatusBox label={t('Expired')} color={statusBackgroundColor} />;

      case policyDetailStatuses.INACTIVE:
        return <StatusBox label={t('Bound')} color={statusBackgroundColor} />;

      case policyDetailStatuses.IN_CANCELLATION:
        return <StatusBox label={t('In Cancellation')} color={statusBackgroundColor} />;

      default:
        return <></>;
    }
  };

  return (
    <>
      <PageHeader
        sx={{
          boxShadow: 'none',
          py: 1,
          flexDirection: {
            xs: 'row',
            md: 'row',
          },
          right: {
            flex: 0,
          },
        }}
        left={
          <>
            <Typography
              variant="h6"
              sx={{
                fontWeight: '500',
                color: (theme) => theme.customColors.pageHeader.title,
              }}
            >
              {!loaded ? (
                <Skeleton animation="wave" width={200} />
              ) : (
                `${data?.policy?.characteristics?.data?.pol_named_insured ?? '-'}`
              )}
            </Typography>
            <Box
              sx={{
                display: 'flex',
                mt: 0.5,
                alignItems: 'center',
                color: (theme) => theme.palette.primary.main,
              }}
            >
              <Typography
                variant="h5"
                sx={{
                  whiteSpace: 'nowrap',
                  fontWeight: 400,
                  mr: 1.5,
                  fontSize: 14,
                  color: (theme) => theme.customColors.pageHeader.subTitle,
                }}
              >
                {!loaded ? <Skeleton animation="wave" width={100} /> : data?.policy?.product?.name}
              </Typography>
            </Box>
            <Box
              sx={{
                display: 'flex',
                alignItems: 'center',
                mt: 0.5,
                color: (theme) => theme.palette.primary.main,
              }}
            >
              <Typography
                variant="h5"
                sx={{
                  whiteSpace: 'nowrap',
                  fontWeight: 400,
                  mr: 0.75,
                  fontSize: 14,
                  lineHeight: '16px',
                  color: (theme) => theme.customColors.pageHeader.subTitle,
                }}
              >
                {!loaded ? (
                  <Skeleton animation="wave" width={100} />
                ) : (
                  <>
                    {t('Policy No.:')}
                    <span> {policyNumber}</span>
                  </>
                )}
              </Typography>
              {!loaded ? (
                <Skeleton animation="wave" width={100} />
              ) : (
                <Box sx={{ display: 'flex', alignItems: 'center', gap: 0.75 }}>
                  <CopyButton copyValue={policyNumber} />
                  {renderBadge(data?.policy?.status?.key!)}
                </Box>
              )}
            </Box>
          </>
        }
        right={
          <>
            {search ? (
              <ViewSearch
                onChange={
                  handleSearchInputChange
                    ? debounce(handleSearchInputChange, defaultDebounceDelay)
                    : () => {}
                }
              />
            ) : (
              <Stack gap={2} direction="row" justifyContent="flex-end">
                {policyInformations.map((item) => (
                  <Stack
                    key={item.id}
                    gap={1 / 4}
                    sx={{
                      maxWidth: item.id === 'created_at' ? '103px' : 'unset',
                      '&:not(&:last-child)': {
                        borderRight: 1,
                        borderColor: (theme) => theme.customColors.grey400,
                        pr: 2,
                      },
                    }}
                  >
                    <Typography
                      variant="body1"
                      sx={{
                        color: (theme) => theme.customColors.gunMetal,
                        fontWeight: 500,
                        whiteSpace: 'nowrap',
                      }}
                    >
                      {!loaded ? <Skeleton animation="wave" width={100} /> : item.key}
                    </Typography>
                    <Typography
                      variant="body1"
                      sx={{
                        color: (theme) => theme.customColors.actionBar.text,
                        ...(item.value === '-' && { textAlign: 'center' }),
                      }}
                    >
                      {!loaded ? <Skeleton animation="wave" width={100} /> : item.value}
                    </Typography>
                  </Stack>
                ))}
              </Stack>
            )}
          </>
        }
      />

      <LinkTab tabs={tabsData} currentTab={currentTab} maxWidth={false} sx={{ mb: 0 }} />
    </>
  );
};

export default PolicyEndorsementDetailHeader;
