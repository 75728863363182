import { Theme } from '@mui/material';
import { SystemStyleObject } from '@mui/system';

export const dropdownItemsStyle: SystemStyleObject<Theme> = {
  px: 2,
  py: 0.8,
  '&:hover svg': {
    rect: {
      stroke: (theme) => theme.customColors.white50,
    },
    path: {
      stroke: (theme) => theme.customColors.white50,
    },
    circle: {
      stroke: (theme) => theme.customColors.white50,
    },
  },
  '& div:first-of-type': {
    height: 20,
    width: 25,
    mr: 0.5,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
};

export const nestedDropdownStyle: SystemStyleObject<Theme> = {
  px: 2,
  py: 0.8,
  '&:hover svg': {
    rect: {
      stroke: (theme) => theme.customColors.white50,
    },
    path: {
      stroke: (theme) => theme.customColors.white50,
    },
    circle: {
      stroke: (theme) => theme.customColors.white50,
    },
  },
  '.MuiBox-root div': {
    mr: 0.1,
    height: 20,
  },
};

export const autocompleteTextFieldStyle: SystemStyleObject<Theme> = {
  '& div.MuiOutlinedInput-root input.MuiOutlinedInput-input': { flexGrow: '0', width: '90%' },
  '& div.MuiOutlinedInput-root div.MuiInputAdornment-root': { position: 'absolute', right: '8px' },
};

export const autocompleteTextFieldStyle2: SystemStyleObject<Theme> = {
  '& div.MuiOutlinedInput-root input.MuiOutlinedInput-input': {
    flexGrow: '0',
    width: '100%',
    marginRight: 3.25,
  },
  '& div.MuiOutlinedInput-root div.MuiInputAdornment-root': { position: 'absolute', right: '8px' },
};

export const autocompleteArrowStyle: SystemStyleObject<Theme> = {
  cursor: 'default',
  '& .MuiOutlinedInput-root': {
    cursor: 'default',
    '& input': {
      cursor: 'default',
    },
  },
  '& .arrow-icon': {
    div: {
      display: 'flex',
      alignItems: 'center',
    },
  },
};

export const calendarIconStyle: SystemStyleObject<Theme> = {
  '& .MuiFormControl-root .MuiInputAdornment-root>button': {
    marginRight: '-5px',
  },
  '& .calendar-icon': {
    div: {
      display: 'flex',
      alignItems: 'center',
      width: 20,
      height: 20,
    },
  },
  '& .Mui-disabled': {
    '& .calendar-icon': {
      div: {
        // opacity: 0.5,
      },
    },
  },
};

export const detailPageGridContainerStyles: SystemStyleObject<Theme> = {
  rowGap: '8px',
};

export const detailPageGridItemStyles: SystemStyleObject<Theme> = {
  minHeight: 44,
  paddingTop: '0px !important',
};

export const detailPageStackStyles: SystemStyleObject<Theme> = {
  alignItems: 'center',
  borderBottom: '1px solid',
  borderColor: (theme) => theme.customColors.grey400,
  minHeight: 44,
  maxWidth: '480px',
  overflowWrap: 'break-word',
  py: '6px !important',
  width: '100%',
};

export const detailPageRowKeyStyles: SystemStyleObject<Theme> = {
  color: (theme) => theme.customColors.grey350,
  fontSize: 14,
  fontWeight: 400,
  lineHeight: '16px',
  minHeight: 16,
  mr: 1.25,
};

export const detailPageRowValueStyles: SystemStyleObject<Theme> = {
  color: (theme) => theme.palette.primary.main,
  fontSize: 14,
  fontWeight: 400,
  lineHeight: '16px',
  minHeight: 16,
};

export const submissionAddEditModalStyles: SystemStyleObject<Theme> = {
  p: 3,
  top: '50%',
  left: '50%',
  boxShadow: 24,
  bgcolor: 'background.paper',
  transform: 'translate(-50%, -50%)',
  position: 'absolute' as 'absolute',
  outline: 'none',
};

export const linkTextWithIcon: SystemStyleObject<Theme> = {
  display: 'flex',
  cursor: 'pointer',
  alignItems: 'center',
  width: 'max-content',
  '& p': {
    fontSize: 14,
    fontWeight: 500,
    lineHeight: '24px',
  },
  color: (theme) => theme.customColors.navigation,
  '&>.icon>div': {
    display: 'flex',
  },
  '& path': {
    stroke: 'currentcolor',
  },
  ':hover': {
    textDecoration: 'underline',
    color: (theme) => theme.customColors.primary.buttonHoverBg,
  },
};

export const searchIconStyle: SystemStyleObject<Theme> = {
  '& .search-icon': {
    marginTop: '5px',
    svg: {
      width: 20,
      height: 20,
    },
    '& path': {
      stroke: (theme) => theme.customColors.commonSearchIcons,
    },
  },
  '& .inline-search': {
    marginTop: '5px',
    svg: {
      width: 20,
      height: 20,
    },
    '& path': {
      stroke: (theme) => theme.customColors.grey600,
    },
  },
};

export const primaryButtonStyle: SystemStyleObject<Theme> = {
  p: (theme) => theme.spacing(1.1, 3),
  lineHeight: '12px',
  fontSize: 12,
  fontWeight: 500,
  borderRadius: '2px',
  transition: (theme) =>
    `background ${theme.transitions.duration.standard}ms, color ${theme.transitions.duration.standard}ms`,
  transitionDelay: '50ms',
  color: (theme) => theme.palette.background.default,
  bgcolor: (theme) => theme.customColors.primary.buttonBg,
  '&:hover': {
    background: (theme) => theme.customColors.primary.buttonHoverBg,
  },
};

export const drawerFooterButtonStyle: SystemStyleObject<Theme> = {
  p: (theme) => theme.spacing(0.75, 3),
  lineHeight: '18px',
  fontSize: 12,
  fontWeight: 500,
  borderRadius: '2px',
  transition: (theme) =>
    `background ${theme.transitions.duration.standard}ms, color ${theme.transitions.duration.standard}ms`,
  transitionDelay: '50ms',
};

export const drawerFooterSecondaryButtonStyle: SystemStyleObject<Theme> = {
  ...drawerFooterButtonStyle,
  mr: 1.5,
  border: (theme) => `1px solid ${theme.customColors.copper}`,
  color: (theme) => theme.customColors.copper,
  '&:hover': {
    color: (theme) => theme.palette.background.default,
    background: (theme) => theme.customColors.primary.buttonHoverBg,
  },
};

export const secondaryButtonStyle: SystemStyleObject<Theme> = {
  ...drawerFooterSecondaryButtonStyle,
};

export const drawerFooterPrimaryButtonStyle: SystemStyleObject<Theme> = {
  ...drawerFooterButtonStyle,
  color: (theme) => theme.palette.background.default,
  border: (theme) => `1px solid ${theme.customColors.primary.buttonBg}`,
  bgcolor: (theme) => theme.customColors.primary.buttonBg,
  '&:hover': {
    background: (theme) => theme.customColors.primary.buttonHoverBg,
  },
};

export const disablePrimaryButtonStyle: SystemStyleObject<Theme> = {
  opacity: 0.26,
  color: (theme) => theme.customColors.white50,
  bgcolor: (theme) => theme.customColors.grey700,
  borderColor: (theme) => theme.customColors.grey700,
  cursor: 'default',
  pointerEvents: 'none',
};

export const greyButtonStyle: SystemStyleObject<Theme> = {
  p: (theme) => theme.spacing(1.1, 3),
  lineHeight: '12px',
  fontSize: 12,
  fontWeight: 600,
  bgcolor: (theme) => theme.customColors.grey400,
  color: (theme) => theme.customColors.grey350,

  '&:hover': {
    backgroundColor: (theme) => theme.customColors.grey100,
  },
};

export const checkboxStyle: SystemStyleObject<Theme> = {
  p: 0,
  '&.Mui-checked': { color: (theme) => theme.customColors.activeCheckbox },
};

export const readOnlyCheckboxStyle: SystemStyleObject<Theme> = {
  ...checkboxStyle,
  '&.Mui-checked': { color: (theme) => theme.customColors.activeCheckbox, opacity: 0.7 },
  '&:hover': { cursor: 'unset', backgroundColor: 'unset' },
  color: (theme) => theme.customColors.grey1150,
};

export const readOnlyInputStyle: SystemStyleObject<Theme> = {
  '.MuiInputBase-root.MuiOutlinedInput-root fieldset': {
    borderColor: (theme) => theme.customColors.grey1150,
    borderWidth: '1px',
    '&:hover': {
      '.MuiOutlinedInput-notchedOutline': {
        borderColor: (theme) => theme.customColors.grey1150,
      },
    },
    '.MuiOutlinedInput-notchedOutline': {
      borderColor: (theme) => theme.customColors.grey1150,
    },
  },
  '.MuiFormLabel-root.MuiInputLabel-root.Mui-error': {
    color: (theme) => theme.customColors.black100,
  },
  borderRadius: 0,
  '.MuiFormLabel-root.MuiInputLabel-root.Mui-focused': {
    color: (theme) => theme.customColors.black100,
  },
  '.MuiInputBase-root.MuiOutlinedInput-root.Mui-focused.Mui-focused fieldset': {
    borderColor: (theme) => theme.customColors.grey1150,
    borderWidth: '1px',
  },
  '.MuiInputBase-readOnly.Mui-focused': {
    color: (theme) => theme.customColors.placeholderColor,
  },
};

export const accordionFieldParserReadOnlyInputStyle: SystemStyleObject<Theme> = {
  '.MuiInputBase-root.MuiOutlinedInput-root fieldset': {
    borderColor: (theme) => theme.customColors.readOnlyBorderColor,
    borderWidth: '1px',
    '&:hover': {
      '.MuiOutlinedInput-notchedOutline': {
        borderColor: (theme) => theme.customColors.readOnlyBorderColor,
      },
    },
    '.MuiOutlinedInput-notchedOutline': {
      borderColor: (theme) => theme.customColors.readOnlyBorderColor,
    },
  },
  '.MuiFormLabel-root.MuiInputLabel-root.Mui-error': {
    color: (theme) => theme.customColors.black100,
  },
  borderRadius: 0,
  '.MuiFormLabel-root.MuiInputLabel-root.Mui-focused': {
    color: (theme) => theme.customColors.black100,
  },
  '.MuiInputBase-root.MuiOutlinedInput-root.Mui-focused.Mui-focused fieldset': {
    borderColor: (theme) => theme.customColors.readOnlyBorderColor,
    borderWidth: '1px',
  },
};

export const accordionFieldParserReadOnlySelectStyle: SystemStyleObject<Theme> = {
  div: {
    borderWidth: '1px',
    borderColor: (theme) => theme.customColors.readOnlyBorderColor,
    '&:hover': {
      cursor: 'text',
      userSelect: 'text',
    },
  },

  '.MuiInputBase-root.MuiOutlinedInput-root fieldset': {
    borderColor: (theme) => theme.customColors.readOnlyBorderColor,
    borderWidth: '1px',
  },
  borderRadius: 0,
  '.MuiFormLabel-root.MuiInputLabel-root.Mui-focused': {
    color: (theme) => theme.customColors.black100,
  },
  '.MuiInputBase-root.MuiOutlinedInput-root.Mui-focused.Mui-focused fieldset': {
    borderColor: (theme) => theme.customColors.readOnlyBorderColor,
    borderWidth: '1px',
  },
};

export const readOnlySelectInputStyle: SystemStyleObject<Theme> = {
  div: {
    borderWidth: '1px',
    borderColor: (theme) => theme.customColors.grey1150,
    '&:hover': {
      cursor: 'text',
      userSelect: 'text',
    },
  },

  '.MuiInputBase-root.MuiOutlinedInput-root fieldset': {
    borderColor: (theme) => theme.customColors.grey1150,
    borderWidth: '1px',
  },
  borderRadius: 0,
  '.MuiFormLabel-root.MuiInputLabel-root.Mui-focused': {
    color: (theme) => theme.customColors.black100,
  },
  '.MuiInputBase-root.MuiOutlinedInput-root.Mui-focused.Mui-focused fieldset': {
    borderColor: (theme) => theme.customColors.grey1150,
    borderWidth: '1px',
  },
};

export const footerMainButtonStyle: SystemStyleObject<Theme> = {
  p: (theme) => theme.spacing(1.1, 3),
  lineHeight: '12px',
  fontSize: 12,
  fontWeight: 600,
  bgcolor: (theme) => theme.customColors.primary.buttonBg,
  color: (theme) => theme.palette.background.default,
  '&:hover': {
    background: (theme) => theme.customColors.primary.buttonHoverBg,
  },
};

export const outlinedPrimaryButton: SystemStyleObject<Theme> = {
  fontSize: 12,
  fontWeight: 500,
  lineHeight: '14px',
  color: (theme) => theme.customColors.primary.buttonBg,
  border: '1px solid',
  borderColor: (theme) => theme.customColors.primary.buttonBg,
  p: (theme) => theme.spacing(1, 3.5),
  '&:hover': {
    background: (theme) => theme.customColors.primary.buttonHoverBg,
    color: (theme) => theme.palette.background.default,
  },
};

export const rowNumStyle: SystemStyleObject<Theme> = {
  backgroundColor: (theme) => theme.customColors.grey400,
  minHeight: '36px',
  height: '100%',
  minWidth: '20px',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  marginLeft: '-8px',
};

export const agencyEditContactsStackStyles: SystemStyleObject<Theme> = {
  '& .MuiFormControl-root': {
    flex: 'auto',
  },
  py: 1,
  display: 'flex',
  flexDirection: 'row',
  alignItems: 'center',
  justifyContent: 'space-between',
};

export const agencyEditableTabsTypographyStyles: SystemStyleObject<Theme> = {
  color: (theme) => theme.customColors.grey800,
  fontSize: 14,
  fontWeight: 400,
  lineHeight: '16px',
  minHeight: 16,
  mr: '6px',
  minWidth: '142px',
  maxWidth: '142px',
};

export const agencyEditableTabsFormControlStyles: SystemStyleObject<Theme> = {
  flex: 1,
  '& input': { minHeight: 37.125, boxSizing: 'border-box' },
};

export const accordionSummaryStyles: SystemStyleObject<Theme> = {
  backgroundColor: (theme) => theme.customColors.grey1150,
  minHeight: 'unset',
  borderRadius: 1,

  '&.Mui-expanded': {
    minHeight: 'unset',
    mt: '0px !important',
    borderBottomRightRadius: '0px !important',
    borderBottomLeftRadius: '0px !important',
    boxShadow: 'inset 0px -1px 0px #E0E0E0',
  },
  '& > .MuiAccordionSummary-content': {
    my: 0,
    px: 0,
    py: 1,
    alignItems: 'center',
  },
  '& > .MuiAccordionSummary-content.Mui-expanded': {
    my: '0px !important',
  },
};

export const accordionSummaryStylesDisabled: SystemStyleObject<Theme> = {
  ...accordionSummaryStyles,
  backgroundColor: (theme) => theme.customColors.grey960,
};

export const showHiddenScroll: SystemStyleObject<Theme> = {
  '&::-webkit-scrollbar': { WebkitAppearance: 'none', width: '8px' },
  '&::-webkit-scrollbar-thumb': {
    borderRadius: '4px',
    backgroundColor: (theme) => theme.customColors.grey150,
  },
};

export const tabTitleStyles: SystemStyleObject<Theme> = {
  fontWeight: 500,
  fontSize: 20,
  mb: (theme) => theme.spacing(0.5),
  lineHeight: (theme) => theme.typography.h1.lineHeight,
  color: (theme) => theme.customColors.gunMetal,
  minWidth: 100,
};

export const columnHeaderTitleStyle: SystemStyleObject<Theme> = {
  pl: 0,
  fontWeight: 900,
  color: (theme) => theme.customColors.table.header,
  whiteSpace: 'normal',
  lineHeight: 1.2,
};

export const sideBarHelpButtonStyle: SystemStyleObject<Theme> = {
  width: 1,
  py: 0.5,
  color: (theme) => theme.customColors.advancedSearch.primaryButtonColor,
  background: (theme) => theme.customColors.advancedSearch.secondaryButtonColor,
  '&:hover': {
    background: (theme) => theme.customColors.advancedSearch.secondaryButtonColor,
  },
  wordSpacing: 1,
  borderRadius: '4px',
  fontWeight: 500,
  fontSize: 14,
};

export const defaultTableCellStyle: SystemStyleObject<Theme> = {
  lineHeight: (theme) => theme.typography.body1.lineHeight,
  fontSize: (theme) => theme.typography.body1.fontSize,
  color: (theme) => theme.customColors.black,
  fontWeight: (theme) => theme.typography.subtitle2.fontWeight,
  textOverflow: 'ellipsis',
  overflow: 'hidden',
  whiteSpace: 'nowrap',
};

export const truncatedTextStyle: SystemStyleObject<Theme> = {
  overflow: 'hidden',
  whiteSpace: 'nowrap',
  display: 'inline-block',
  textOverflow: 'ellipsis',
};
