import ApiService from 'api/helpers/Sender';
import { IGenericIntegrationResponse } from 'api/models/Integrations/IGenericResponse';

const API = new ApiService();

export const getB505Score = async (body: any): Promise<{ B505: null | number }> => {
  const { data }: { data: IGenericIntegrationResponse<{ B505: null | number }> } = await API.post(
    `services/lexisnexis/b505-score`,
    body,
  );

  return data?.data ?? {};
};

export const getC212Score = async (body: any): Promise<{ C212: null | number }> => {
  const { data }: { data: IGenericIntegrationResponse<{ C212: null | number }> } = await API.post(
    `services/lexisnexis/c212-score`,
    body,
  );

  return data?.data ?? {};
};

export const getNCFScore = async (body: any): Promise<any> => {
  const { data }: { data: IGenericIntegrationResponse<any> } = await API.post(
    `services/lexisnexis/ncf-score`,
    body,
  );

  return data?.data ?? {};
};
